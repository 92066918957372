/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { items, UtilityItem } from './items/index';
import {
    profilePropTypes,
    helpPropTypes,
    settingsPropTypes,
    notificationPropTypes,
    installPropTypes,
} from '../../shared/propTypes';
import { extendComponentProps } from '../../shared/functions';
import Tool from './Tool';
import ProfileIcon from './items/profile/ProfileIcon';
import {
    HeaderUtilities,
    HeaderSearch,
    HeaderUtilityName,
    HeaderNotifications,
} from '@gs-ux-uitoolkit-common/header';
import { getUtilitiesForHeader } from '@gs-ux-uitoolkit-common/header';

export interface UtilitiesProps extends HeaderUtilities {
    search: HeaderSearch;
    responsiveSize: 'mobile' | 'tablet' | 'desktop';
}

const Utilities: React.FunctionComponent<UtilitiesProps> = (props: UtilitiesProps) => {
    const { profile, notification, responsiveSize, search } = props;

    // Retrieve the names of the utilities that should exist in the header (as opposed to the
    // hamburger menu)
    const utilitiesForHeader: HeaderUtilityName[] = getUtilitiesForHeader(
        props,
        responsiveSize,
        !!search
    );

    // Retrieve the actual metadata for the utilities that should exist in the header, filtering out
    // items that shouldn't exist in the header
    const utilityItems = items.filter(utilityItem => utilitiesForHeader.includes(utilityItem.name));

    return (
        <div
            className={cx(
                'utilities',
                responsiveSize,
                notification && notification.appearance === 'slideover' ? 'slideover' : ''
            )}
            data-cy="header.utilities"
        >
            {utilityItems.map(utilityItem => (
                <span
                    className="h-100"
                    key={utilityItem.name}
                    data-cy={`headerUtilities.${utilityItem.name}Container`}
                >
                    {getUtility(utilityItem)}
                </span>
            ))}
        </div>
    );

    function getUtility(utilityItem: UtilityItem) {
        if (utilityItem.name === 'profile') {
            // 'Profile' utility is handled separately
            return getProfileUtility(utilityItem);
        } else {
            return getOtherUtility(utilityItem);
        }
    }

    function getProfileUtility(utilityItem: UtilityItem) {
        const { name, iconName, badge, component } = utilityItem;
        const utilityConfig = props[name];

        let profileIconComp = null;
        if (profile && profile.identity && profile.identity.personMenuIcon) {
            profileIconComp = <ProfileIcon imageUrl={profile.identity.imageUrl} />;
        }

        return profile ? (
            <Tool
                key={name}
                customIcon={profileIconComp}
                iconName={iconName}
                badge={badge}
                content={extendComponentProps(component, utilityConfig)}
            />
        ) : null;
    }

    function getOtherUtility(utilityItem: UtilityItem) {
        const utilityItemProps = props[utilityItem.name];

        if (utilityItemProps) {
            const badge = (utilityItemProps as HeaderNotifications).badge
                ? (utilityItemProps as HeaderNotifications).badge
                : null;
            const content = extendComponentProps(utilityItem.component, utilityItemProps);
            const slideoverPanel =
                utilityItem.name === 'notification' &&
                (utilityItemProps as HeaderNotifications).appearance === 'slideover';
            return (
                <Tool
                    key={utilityItem.name}
                    title={utilityItem.name}
                    iconName={utilityItem.iconName}
                    badge={badge}
                    content={content}
                    slideover={slideoverPanel}
                />
            );
        }
    }
};

Utilities.propTypes = {
    responsiveSize: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired as any,
    notification: PropTypes.shape(notificationPropTypes),
    profile: PropTypes.shape(profilePropTypes),
    help: PropTypes.shape(helpPropTypes),
    settings: PropTypes.shape(settingsPropTypes),
    install: PropTypes.shape(installPropTypes),
    primaryUtility: PropTypes.oneOf([
        'notification',
        'profile',
        'help',
        'settings',
        'install',
    ]) as any,
    secondaryUtility: PropTypes.oneOf([
        'notification',
        'profile',
        'help',
        'settings',
        'install',
    ]) as any,
    search: PropTypes.shape({
        placeholder: PropTypes.string,
        callback: PropTypes.func,
        fullExpansion: PropTypes.bool,
    }),
};

export default Utilities;
