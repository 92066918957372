import React from 'react';
import PropTypes from 'prop-types';
import ContactItem from './Item';

const Contact: React.FunctionComponent<any> = ({ closePopover, email, phone, im }) => {
    const items = [
        { propName: 'email', iconName: 'envelope', props: email },
        { propName: 'phone', iconName: 'phone', props: phone },
        { propName: 'im', iconName: 'comments', props: im },
    ];

    const contacts = items.map(contactMethod => {
        if (contactMethod.props) {
            return (
                <ContactItem
                    key={contactMethod.propName}
                    icon={contactMethod.iconName}
                    text={contactMethod.props.text}
                    callback={contactMethod.props.callback}
                    closePopover={closePopover}
                />
            );
        }
        return null;
    });

    return <div className="module-content">{contacts}</div>;
};

Contact.defaultProps = {
    closePopover: () => {},
};
const profileItemPropTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
    callback: PropTypes.func,
};
Contact.propTypes = {
    email: PropTypes.shape(profileItemPropTypes),
    phone: PropTypes.shape(profileItemPropTypes),
    im: PropTypes.shape(profileItemPropTypes),
    closePopover: PropTypes.func,
};

export default Contact;
