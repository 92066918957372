import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import { InputGroup, InputGroupProps } from 'reactstrap';
import cx from 'classnames';
import { OmitIndexSignature } from '../customTypes';

export interface CustomInputGroupProps extends OmitIndexSignature<InputGroupProps> {
    color?: string;
}

export interface CustomInputGroupState {
    focused: boolean;
}

class CustomInputGroup extends React.Component<CustomInputGroupProps, CustomInputGroupState> {
    public static propTypes: { [key in keyof CustomInputGroupProps]: any } = {
        color: PropTypes.string,
    };

    private el?: null | Element | Text;
    private containsButtonBefore?: boolean;
    private containsButtonAfter?: boolean;

    constructor(props: CustomInputGroupProps) {
        super(props);
        this.state = {
            focused: false,
        };
    }

    componentDidMount() {
        this.el = ReactDOM.findDOMNode(this);
        if (this.el instanceof HTMLElement) {
            this.containsButtonBefore =
                this.el.querySelector(
                    '.input-group-prepend > :first-child:not(.input-group-text)'
                ) !== null;
            this.containsButtonAfter =
                this.el.querySelector(
                    '.input-group-append > :first-child:not(.input-group-text)'
                ) !== null;
            if (this.containsButtonBefore) {
                this.el.classList.add('contains-button-before-input');
            }
            if (this.containsButtonAfter) {
                this.el.classList.add('contains-button-after-input');
            }
        }
    }

    elementFocused = (e: React.FocusEvent) => {
        let inputElem = e && e.target ? e.target : null;
        if (inputElem && !inputElem.classList.contains('form-control')) {
            inputElem = inputElem.querySelector('.form-control');
        }
        if (inputElem && inputElem === document.activeElement) {
            this.setState({ focused: true });
        }
    };

    elementBlurred = () => {
        this.setState({ focused: false });
    };

    render() {
        const { className, color, ...other } = this.props;
        const classes = cx(className, {
            focused: this.state.focused,
            [`has-${color}`]: color,
            'contains-button-before-input': this.containsButtonBefore,
            'contains-button-after-input': this.containsButtonAfter,
        });
        return (
            <InputGroup
                {...other}
                className={classes}
                onFocus={this.elementFocused}
                onBlur={this.elementBlurred}
            />
        );
    }
}

export { CustomInputGroup as InputGroup };
