import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from '@gs-ux-uitoolkit-react/core';
import { BeforeInstallPromptEvent } from '@gs-ux-uitoolkit-common/header';

export interface InstallModalProps {
    display: boolean;
    toggle: () => void;
    installPromptEvent: BeforeInstallPromptEvent;
}

const handleContinueClick = (toggle: () => void, e: BeforeInstallPromptEvent) => {
    toggle();
    e.prompt();
};

export const InstallModal: React.FunctionComponent<InstallModalProps> = ({
    display,
    toggle,
    installPromptEvent,
}) => {
    return (
        <Modal isOpen={display} toggle={toggle} className="gs-uitk-header__install-modal">
            <ModalHeader>Are you sure you want to install {document.title}?</ModalHeader>
            <ModalBody>
                Progressive Web Apps (PWAs) feel more like a native app and less like a web app by
                offering a more immersive experience, that is accessed from your desktop or home
                screen. It will be stored under "chrome://apps" and can be uninstalled if desired.
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => handleContinueClick(toggle, installPromptEvent)}
                    color="primary"
                >
                    Continue
                </Button>
                <Button onClick={toggle} color="secondary">
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

InstallModal.propTypes = {
    display: PropTypes.bool,
    toggle: PropTypes.func,
    installPromptEvent: PropTypes.object,
};
