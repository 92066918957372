import React from 'react';
import PropTypes from 'prop-types';
import { Close } from '@gs-ux-uitoolkit-react/shared';
import Icon from '../../../../../shared/components/Icon';

const Header: React.FunctionComponent<any> = ({ category, dismissCategory, id, icon }) => {
    const closeCallback = e => {
        e.stopPropagation();
        dismissCategory(id);
    };

    const handleMouseEnter = e => {
        e.target.parentNode.nextElementSibling.classList.add('disabled-notification-content');
    };

    const handleMouseLeave = e => {
        e.target.parentNode.nextElementSibling.classList.remove('disabled-notification-content');
    };

    return (
        <div className="notification-category-header">
            {icon ? (
                <Icon
                    classes="notification-icon notification-category-icon"
                    iconName={icon}
                    size="gi-lg"
                />
            ) : null}
            <div className="category-header-title">{category}</div>
            <Close
                onClick={closeCallback}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
        </div>
    );
};

Header.defaultProps = {
    dismissCategory: () => {},
    category: '',
    id: '',
};

Header.propTypes = {
    id: PropTypes.string.isRequired,
    icon: PropTypes.string,
    dismissCategory: PropTypes.func,
    category: PropTypes.string,
};

export default Header;
