import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Table, TableProps } from 'reactstrap';
import cx from 'classnames';
import { OmitIndexSignature } from '../customTypes';

export interface CustomTableProps extends OmitIndexSignature<TableProps> {
    size?: string;
    verticalLinesBody?: boolean;
    verticalLinesHeader?: boolean;
    className?: string;
    hover?: boolean;
}

/**
 * @visibleName Table
 */
export const CustomTable: React.FunctionComponent<CustomTableProps> = ({
    size,
    verticalLinesBody = false,
    verticalLinesHeader = true,
    className,
    ...other
}) => {
    const classes = cx(
        {
            'table-sm': size === 'sm',
            'table-lg': size === 'lg',
            'table-vertical-lines-body': verticalLinesBody,
            'table-no-vertical-lines-header': !verticalLinesHeader,
        },
        className
    );
    return <Table {...other} className={classes} />;
};

CustomTable.propTypes = {
    size: PropTypes.string,
    verticalLinesBody: PropTypes.bool,
    verticalLinesHeader: PropTypes.bool,
};
