import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@gs-ux-uitoolkit-react/core';
import HeaderButton from '../../../../../shared/components/HeaderButton';

const handleClick = (id, callback) => callback(id);

const SettingsGroupButton: React.FunctionComponent<any> = ({ id, buttons, callback }) => {
    const btnNodes = buttons.map(button => {
        const buttonProps = {
            classes: 'settings-btn',
            onClick: () => handleClick(button.id, callback),
            color: 'primary',
            size: 'sm',
        };
        const iconProps = { iconName: button.icon };
        return (
            <HeaderButton
                key={button.id}
                text={button.text}
                buttonProps={buttonProps}
                iconProps={iconProps}
            />
        );
    });
    return (
        <div key={id} className="settings-btn-group-container">
            <ButtonGroup size="sm">{btnNodes}</ButtonGroup>
        </div>
    );
};

SettingsGroupButton.propTypes = {
    id: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            text: PropTypes.string,
            icon: PropTypes.string,
        })
    ),
    callback: PropTypes.func,
};

export default SettingsGroupButton;
