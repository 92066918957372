import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Nav } from 'reactstrap';
import cx from 'classnames';
import NormalTabTitle from './NormalTabTitle';
import DropdownTabTitle from './DropdownTabTitle'; // this is for overflow 'tab' in responsive tabs
import { DropdownTab, isDropdownTabProps } from './DropdownTab'; // this is for dropdown tab (tab with a submenu)
import { isTabProps } from './Tab';
import { containsActiveTab, isDropdown } from './tab-utils';

const isValidComponent = (node: React.ReactElement) =>
    isTabProps(node.props) || isDropdownTabProps(node.props);

const createResponsiveTabTitles = (
    tabs: React.ReactElement[],
    activeTabKey: string | number,
    overflowCount: number,
    onSelect: (tabKey: string | number) => void,
    vertical: boolean
) => {
    const pivot = tabs.length - overflowCount;
    const visibleTabs = tabs.slice(0, pivot);
    const overflowTabs = tabs.slice(pivot, tabs.length);
    return [
        ...visibleTabs.map((tab: React.ReactElement) => {
            const {
                props: { disabled, tabKey, title, ...rest },
                props,
            } = tab;
            if (isDropdownTabProps(tab.props)) {
                const submenuSelected =
                    isDropdown(props.children) && containsActiveTab(props.children, activeTabKey);
                const level2NavItems = props.children;
                // this is dropdown tab while in the visible section of responsive tabs
                return (
                    <DropdownTab
                        key={tabKey}
                        active={submenuSelected}
                        disabled={disabled}
                        onSelect={onSelect}
                        tabKey={tabKey}
                        title={title}
                        {...rest}
                        vertical={vertical}
                    >
                        {level2NavItems}
                    </DropdownTab>
                );
            }
            return (
                <NormalTabTitle
                    key={tabKey}
                    active={tabKey === activeTabKey}
                    disabled={disabled}
                    onSelect={onSelect}
                    tabKey={tabKey}
                    title={title}
                    {...rest}
                />
            );
        }),
        // this is the overflow tab
        <DropdownTabTitle
            active={containsActiveTab(overflowTabs, activeTabKey)}
            caret={false}
            key="uitoolkit-dropdown-tab-title"
            inOverflow
            onSelect={onSelect}
            title="..."
        >
            {overflowTabs}
        </DropdownTabTitle>,
    ];
};

export interface TabTitlesProps {
    activeTabKey: string | number;
    onSelect: (tabKey: string | number) => void;
    overflowingTabCount: number;
    responsive: boolean;
    vertical: boolean;
    iconOnly: boolean;
    variant: 'tabs' | 'pills';
    withVerticalBar?: boolean;
    children?: React.ReactNode | React.ReactNode[];
}

export interface TabTitlesState {
    submenuHasHover: boolean;
}
// "vertical" prop on <Nav /> applies "nav-vertical" class instead
// of "flex-column". switch from using className to "vertical"
// prop once resolved:
// https://github.com/reactstrap/reactstrap/issues/295
class TabTitles extends React.Component<TabTitlesProps, TabTitlesState> {
    public static propTypes: { [key in keyof TabTitlesProps]: any } = {
        activeTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        children: PropTypes.node.isRequired,
        onSelect: PropTypes.func.isRequired,
        overflowingTabCount: PropTypes.number.isRequired,
        responsive: PropTypes.bool.isRequired,
        vertical: PropTypes.bool.isRequired,
        iconOnly: PropTypes.bool.isRequired,
        variant: PropTypes.oneOf(['tabs', 'pills']).isRequired,
        withVerticalBar: PropTypes.bool,
    };

    constructor(props: TabTitlesProps) {
        super(props);
        this.onMouseEnterAddBorder = this.onMouseEnterAddBorder.bind(this);
        this.onMouseExitRemoveBorder = this.onMouseExitRemoveBorder.bind(this);
        this.state = {
            submenuHasHover: false,
        };
    }

    onMouseEnterAddBorder() {
        if (this.props.vertical) {
            this.setState({ submenuHasHover: true });
        }
    }

    onMouseExitRemoveBorder() {
        if (this.props.vertical) {
            this.setState({ submenuHasHover: false });
        }
    }

    render() {
        const {
            activeTabKey,
            children,
            onSelect,
            overflowingTabCount,
            responsive,
            vertical,
            variant,
            iconOnly,
            withVerticalBar,
        } = this.props;
        let tabTitles;
        const validComponents = (React.Children.toArray(
            children as React.ReactElement
        ) as any).filter(isValidComponent);
        if (overflowingTabCount > 0) {
            tabTitles = createResponsiveTabTitles(
                validComponents,
                activeTabKey,
                overflowingTabCount,
                onSelect,
                vertical
            );
        } else {
            tabTitles = validComponents.map((component: React.ReactElement) => {
                const {
                    props: { tabKey, disabled, title, iconName, ...rest },
                    props,
                } = component;
                const compChildren = props.children;
                const tabIsActive = tabKey === activeTabKey;
                const dropdownIsActive =
                    isDropdown(compChildren) && containsActiveTab(compChildren, activeTabKey);
                const active = tabIsActive || dropdownIsActive;
                // tabs with submenus when in vertical or horiztonal (ie non-responsive tabs)
                if (isDropdownTabProps(component.props)) {
                    return (
                        <DropdownTab
                            active={dropdownIsActive}
                            key={tabKey}
                            disabled={disabled}
                            onSelect={onSelect}
                            onMouseEnterAddBorder={this.onMouseEnterAddBorder}
                            onMouseExitRemoveBorder={this.onMouseExitRemoveBorder}
                            tabKey={tabKey}
                            title={title}
                            vertical={vertical}
                            {...rest}
                        >
                            {props.children}
                        </DropdownTab>
                    );
                }
                return (
                    <NormalTabTitle
                        active={active}
                        key={tabKey}
                        disabled={disabled}
                        onSelect={onSelect}
                        tabKey={tabKey}
                        title={title}
                        iconOnly={iconOnly}
                        iconName={iconName}
                        {...rest}
                    >
                        {props.children}
                    </NormalTabTitle>
                );
            });
        }
        const classes = cx({
            'flex-column': vertical,
            'uit-responsive': responsive,
            'border-for-vertical-tabs': vertical && this.state.submenuHasHover,
            'border-for-vertical-tabs-transparent': vertical && !this.state.submenuHasHover,
            'icon-only': iconOnly,
            'with-vertical-bar': withVerticalBar,
        });
        return (
            <Nav tabs={variant === 'tabs'} pills={variant === 'pills'} className={classes}>
                {tabTitles}
            </Nav>
        );
    }
}

export default TabTitles;
