import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Toggle from './Toggle';
import Checkbox from './Checkbox';
import InputNumber from './InputNumber';

const settingItems = {
    'toggle-item': Toggle,
    'checkbox-item': Checkbox,
    'input-number-item': InputNumber,
};

class AlignableSetting extends Component<any, any> {
    public callback: Function;
    public id: string;
    public text: string;
    public align: string;
    public step: number;
    public optionItemType: string;

    constructor(props) {
        super(props);
        this.state = {
            isToggled: this.props.active,
        };
        this.getAlignableSetting = this.getAlignableSetting.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    getAlignableSetting(id, text?, align?, step?, optionItem?, optionItemType?) {
        const isToggled = this.state.isToggled;
        const isLeftAligned = align === 'left';
        const toggleText = () => (
            <div
                key={`text-${id}`}
                className={`text ${optionItemType === 'checkbox' ? 'checkbox-text' : ''}
                ${optionItemType === 'input-number' ? 'input-number-text' : ''}`}
            >
                {text}
            </div>
        );
        const content = isLeftAligned
            ? [toggleText(), optionItem(isToggled, step, this.handleClick, { id })]
            : [optionItem(isToggled, step, this.handleClick, this.props), toggleText()];
        return <div className="alignable-setting">{content}</div>;
    }

    handleClick(val) {
        const { callback, id } = this.props;
        const toggled = !this.state.isToggled;
        const optionVal = _.isString(val) ? val : toggled;
        callback(id, optionVal);
        this.setState({ isToggled: toggled });
    }

    render() {
        const { id, text, align, step, optionItemType } = this.props;
        const optionItem = settingItems[`${optionItemType}-item`];
        return this.getAlignableSetting(id, text, align, step, optionItem, optionItemType);
    }

    static defaultProps = {
        text: 'Toggle',
        align: 'left',
        active: false,
    };

    static propTypes = {
        id: PropTypes.string.isRequired,
        text: PropTypes.string,
        align: PropTypes.oneOf(['left', 'center', 'right']),
        active: PropTypes.bool,
        step: PropTypes.number,
        optionItemType: PropTypes.string,
        callback: PropTypes.func,
    };
}

export default AlignableSetting;
