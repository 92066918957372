import * as React from 'react';
import * as PropTypes from 'prop-types';
import ControlledSidePanel from './ControlledSidePanel';

export interface UncontrolledSidePanelProps {
    withMenu: boolean;
    title?: string;
    position: 'left' | 'right';
    togglePanel: (event: React.MouseEvent) => void;
}

export interface UncontrolledSidePanelState {
    isOpen: boolean;
}

class UncontrolledSidePanel extends React.Component<
    UncontrolledSidePanelProps,
    UncontrolledSidePanelState
> {
    public static propTypes: { [key in keyof UncontrolledSidePanelProps]: any } = {
        withMenu: PropTypes.bool.isRequired,
        title: PropTypes.string,
        position: PropTypes.oneOf(['left', 'right']).isRequired,
        togglePanel: PropTypes.func.isRequired,
    };

    constructor(props: UncontrolledSidePanelProps) {
        super(props);
        this.state = {
            isOpen: true,
        };
        this.togglePanel = this.togglePanel.bind(this);
    }

    togglePanel() {
        this.setState((prevState: UncontrolledSidePanelState) => ({
            isOpen: !prevState.isOpen,
        }));
    }

    render() {
        const { isOpen } = this.state;
        return (
            <ControlledSidePanel {...this.props} isOpen={isOpen} togglePanel={this.togglePanel} />
        );
    }
}

export default UncontrolledSidePanel;
