import * as _ from 'lodash';
import { globalHelper } from '../../../libraries/helpers/global-helper';
import { logger } from '../../../libraries/logger';
import {
    AddConfigItemAction,
    EditConfigItemAction,
    RemoveConfigItemAction,
    UpdateEditedConfigItemAction,
} from '../../actions/plugin-based-actions/plugin-based-actions';
import { PluginWithWizardAndItemsState } from '../../plugin-base-state';

export const addConfigItem = <T, U extends PluginWithWizardAndItemsState<T>>(
    state: U,
    action: AddConfigItemAction<T>
): U => {
    // we clone it as it will probably come from outside
    const item = globalHelper.cloneObject(action.configItem);
    // need TS 3.2 for spread on generic : https://stackoverflow.com/questions/51189388/typescript-spread-types-may-only-be-created-from-object-types
    // tslint:disable-next-line:prefer-object-spread
    return Object.assign({}, state, {
        configItemList: [...state.configItemList, item],
    });
};

export const removeConfigItem = <T, U extends PluginWithWizardAndItemsState<T>>(
    state: U,
    action: RemoveConfigItemAction<T>
): U => {
    // need TS 3.2 for spread on generic : https://stackoverflow.com/questions/51189388/typescript-spread-types-may-only-be-created-from-object-types
    let nextState: U;
    const configItemIndex = state.configItemList.indexOf(action.configItem); // index of config item reference

    /*
        While quick filters can be found through references since they don't use `addConfigItem`,
        all other items that use `addConfigItem` cannot since they're cloned before being stored in the new state.
        However, we still use the reference lookup if we can, because object property comparisons can be costly.
    */
    if (configItemIndex >= 0) {
        const leftConfigItemList = state.configItemList.slice(0, configItemIndex);
        const rightConfigItemList = state.configItemList.slice(configItemIndex + 1);
        // tslint:disable-next-line:prefer-object-spread
        nextState = Object.assign({}, state, {
            configItemList: leftConfigItemList.concat(rightConfigItemList),
        });
    } else {
        // tslint:disable-next-line:prefer-object-spread
        nextState = Object.assign({}, state, {
            configItemList: state.configItemList.filter(
                (configItem: T) => !_.isEqual(configItem, action.configItem)
            ),
        });
    }

    return nextState;
};

export const editConfigItem = <T, U extends PluginWithWizardAndItemsState<T>>(
    state: U,
    action: EditConfigItemAction<T>
): U => {
    // need TS 3.2 for spread on generic : https://stackoverflow.com/questions/51189388/typescript-spread-types-may-only-be-created-from-object-types
    // tslint:disable-next-line:prefer-object-spread
    return Object.assign({}, state, {
        // we cannot use the spread operator here due to a TS limitation. see:https://github.com/Microsoft/TypeScript/issues/10727
        // tslint:disable-next-line:prefer-object-spread
        editedItem: Object.assign({}, action.configItem),
        originalEditedItem: action.configItem,
    });
};

export const newConfigItem = <T, U extends PluginWithWizardAndItemsState<T>>(
    state: U,
    emptyConfigItem: T
): U => {
    // need TS 3.2 for spread on generic : https://stackoverflow.com/questions/51189388/typescript-spread-types-may-only-be-created-from-object-types
    // tslint:disable-next-line:prefer-object-spread
    return Object.assign({}, state, {
        editedItem: emptyConfigItem,
    });
};

export const saveEditedConfigItem = <T, U extends PluginWithWizardAndItemsState<T>>(
    state: U
): U => {
    if (state.editedItem) {
        // need TS 3.2 for spread on generic : https://stackoverflow.com/questions/51189388/typescript-spread-types-may-only-be-created-from-object-types
        // tslint:disable-next-line:prefer-object-spread
        return Object.assign({}, state, {
            configItemList: [
                ...state.configItemList.filter(cs => cs !== state.originalEditedItem),
                state.editedItem,
            ],
            editedItem: null,
            originalEditedItem: null,
        });
    }
    logger.error('Cannot save a null config item');
    return state;
};

export const addQuickFilter = <T>(state: PluginWithWizardAndItemsState<T>, item: T) => {
    return {
        ...state,
        configItemList: [...state.configItemList, item],
        editedItem: null,
        originalEditedItem: null,
    };
};

export const updateEditedConfigItem = <T, U extends PluginWithWizardAndItemsState<T>>(
    state: U,
    action: UpdateEditedConfigItemAction<T>
): U => {
    if (state.editedItem) {
        // we cannot use the spread operator here due to a TS limitation. see:https://github.com/Microsoft/TypeScript/issues/10727
        // tslint:disable-next-line:prefer-object-spread
        const clonedEditedItem: T = Object.assign({}, state.editedItem);
        // tslint:disable-next-line:prefer-object-spread
        const clonedConfigItemUpdatedPart: Partial<T> = Object.assign(
            {},
            action.configItemUpdatedPart
        );
        // need TS 3.2 for spread on generic : https://stackoverflow.com/questions/51189388/typescript-spread-types-may-only-be-created-from-object-types
        // tslint:disable-next-line:prefer-object-spread
        return Object.assign({}, state, {
            editedItem: {
                ...(clonedEditedItem as any),
                ...(clonedConfigItemUpdatedPart as any),
            },
        });
    }
    logger.error('Cannot update a null config item');
    return state;
};

export const cancelEditedConfigItem = <T, U extends PluginWithWizardAndItemsState<T>>(
    state: U
): U => {
    // need TS 3.2 for spread on generic : https://stackoverflow.com/questions/51189388/typescript-spread-types-may-only-be-created-from-object-types
    // tslint:disable-next-line:prefer-object-spread
    return Object.assign({}, state, {
        editedItem: null,
        originalEditedItem: null,
    });
};
