import { Logger } from '@gs-ux-uitoolkit-common/shared';

export function getAppTitle() {
    let title;
    const elements = document.getElementsByTagName('title');
    if (elements && elements.length) {
        title = elements[0].innerHTML;
    }

    return title;
}

export function getAppName() {
    let name;
    const elements = document.querySelectorAll('.app-header .app-name h1');
    if (elements && elements.length && elements[0].innerHTML && elements[0].innerHTML.length) {
        name = elements[0].innerHTML
            .replace(/<[^>]*>/g, '')
            .replace(/&nbsp;/g, ' ')
            .trim();
    }

    return name;
}

/**
 * A method that checks if the given hostname is an alias for a deployed
 * environment or a dev environment that can be ignored
 * @param  {[type]}  host [description]
 * @return {Boolean}      [description]
 */
export function isAlias(host: any) {
    const nonAliasPatterns = [
        /.*\.desktop\.services\.gs\.com/,
        /nds[0-9a-z]+\.firmwide\.corp\.gs\.com/,
        /localhost(\.gs\.com)?/,
        /.+\.dc\.gs\.com/,
    ];

    return !nonAliasPatterns.reduce((total: any, cur: any) => total || cur.test(host), null);
}

/**
 * Camelizes a given string
 * @param  {[type]} str [description]
 * @return {[type]}     [description]
 */
export function camelize(str: any) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match: any, index: any) => {
        if (+match === 0) {
            return ''; // or if (/\s+/.test(match)) for white spaces
        }
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

/**
 * An analytics specific logger with a fancy prefix for each log
 * @export
 * @param {any} args arguments to be logged
 */
export function log(...args: any[]) {
    Logger.debug('%cANALYTICS', 'color: #7399C6', ':', ...args);
}

const AnalyticsHelper = { getAppTitle, getAppName, isAlias, camelize, log };

export default AnalyticsHelper;
