import React from 'react';
import PropTypes from 'prop-types';

function preventDefault(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
}

export interface ProfileIconProps {
    imageUrl?: string;
}

const ProfileIcon: React.FC<ProfileIconProps> = ({ imageUrl }) => (
    <a href="#" onClick={preventDefault} className="profile-icon">
        <img src={imageUrl} alt="profile" />
    </a>
);

ProfileIcon.defaultProps = {
    imageUrl: 'https://directory.web.gs.com/directory/api?&size=medium',
};

ProfileIcon.propTypes = {
    imageUrl: PropTypes.string,
};

export default ProfileIcon;
