import Choices from 'choices.js';
import { nonNullableValueOrThrow, KeyHelpers } from '@gs-ux-uitoolkit-common/shared';
import { arraySetsEqual } from '@gs-ux-uitoolkit-common/shared';

import { ChoicesConfig } from './select';
import {
    ChoicesSelectedOption,
    NormalizedSelectMultipleConfig,
    SelectMultipleAddEvent,
    SelectMultipleChangeEvent,
    selectMultipleClassNames,
    SelectMultipleConfig,
    SelectMultipleRemoveEvent,
    SelectOptionLeaf,
    SelectSearchEvent,
} from './options';
import { generateCommonChoicesConfig, getSelectedOptions, getSelectedValues } from './utils';

/**
 * Common implementation of the <SelectMultiple /> component.
 * instantiates choices.js generates configuration options and updates the configuration when possible.
 *
 * @param selectEl The <select> element to decorate with our Select component.
 * @param config The configuration to instantiate the Select component with.
 */
export class CommonSelectMultipleComponent {
    /**
     * The Choices.js object created for the given select component
     */
    private choices!: Choices;

    /**
     * Used to "collect" multiple options during a remove event
     */
    private removedEventsList: SelectOptionLeaf[] = [];

    /**
     * The HTML element that choices.js attaches it self to.
     */
    private selectEl: HTMLSelectElement;

    /**
     * The outermost HTML Element for the Select component.
     */
    private selectOuterContainer: HTMLDivElement;

    /**
     * Flag used to determine if callbacks should be called.
     * You can use this to update the select box, but prevent from calling the developers callback
     */
    private suppressEvents = true;

    /**
     * Props passed down from the client's component.
     */
    private props: NormalizedSelectMultipleConfig;

    /**
     * Flag used to determine if the components dropdown is permitted to close.
     * Look for description in fixDropdownCloseBehavior() for more details.
     */
    private canCloseDropdown: boolean = false;

    /**
     * Flag used to determine if the searchEvent has been fixed. We only want to "fix" the issue
     *  once. For more details on the issue please see `fixSearchEvent()` in this class.
     */
    private isSearchEventFixed: boolean = false;

    constructor(selectContainerEl: HTMLDivElement, config: SelectMultipleConfig) {
        const normalizedConfig = this.normalizeConfig(config);
        this.selectOuterContainer = selectContainerEl;
        this.selectEl = nonNullableValueOrThrow(
            selectContainerEl.querySelector<HTMLSelectElement>(
                '[data-cy=gs-uitk-select-multiple-inner-select-element]'
            ),
            `CommonSelectComponent.constructor(): Unable to find the inner "select" element, ` +
                `please ensure the first argument of this component is the outermost element containing a '<select>' element`
        );
        this.props = normalizedConfig;
        this.setup(normalizedConfig);
    }

    /***
     * Normalizes the select configuration.
     */
    private normalizeConfig(config: SelectMultipleConfig): NormalizedSelectMultipleConfig {
        const normalizedConfig: NormalizedSelectMultipleConfig = {
            disabled: false,
        };
        if (config.disabled) {
            normalizedConfig.disabled = true;
        }

        return { ...config, ...normalizedConfig };
    }

    /**
     * Setup tasks to capture events and config from wrapper component
     */
    private setup(config: NormalizedSelectMultipleConfig) {
        const selectConfig: ChoicesConfig = this.generateChoicesConfig(config);
        this.choices = new Choices(this.selectEl, selectConfig);
        this.choices.removeActiveItems(0);
        this.updateSelectConfig(config);
        this.attachEventListeners();
        this.fixSearchEvent(config);
        this.suppressEvents = false;
    }

    /**
     * Function used to focus the select component.
     * @public
     */
    public focus() {
        this.choices.showDropdown();
    }

    /**
     * Function used to blur the select component.
     * @public
     */
    public blur() {
        this.choices.hideDropdown();
    }

    /**
     * Used to update the configuration on the component
     * @param newConfig - Current config on the component
     * @param prevConfig - Previous config on the component
     */
    public setConfig(newConfig: SelectMultipleConfig, prevConfig: SelectMultipleConfig) {
        const newNormalizedConfig = this.normalizeConfig(newConfig);
        const prevNormalizedConfig = this.normalizeConfig(prevConfig);
        this.props = newNormalizedConfig;
        this.updateSelectConfig(newNormalizedConfig, prevNormalizedConfig);
        this.fixSearchEvent(newNormalizedConfig);
    }

    /**
     * Destroys the choices.js instance.
     */
    public destroy() {
        this.choices.destroy();
    }

    /**
     * Currently Choices.js does not fire a search event when the input is cleared.
     *    This function fixes that behavior. For more details please see
     *    https://github.com/jshjohnson/Choices/issues/630
     *
     * @param config Current config on the component
     */
    private fixSearchEvent(config: NormalizedSelectMultipleConfig) {
        if (!this.isSearchEventFixed && !config.disabled) {
            const inputEl = nonNullableValueOrThrow(
                this.selectOuterContainer.querySelector<HTMLInputElement>(
                    'input.gs-uitk-select-multiple__input'
                ),
                `CommonSelectComponent.fixSearchEvent(): Unable to find the input element. ` +
                    `Please ensure the outermost el is assigned to "selectOuterContainer"`
            );

            // Choices.js currently does not fire an event when the input is
            // cleared. The fix below is a workaround so we fire the "search"
            // event when the input is cleared.
            inputEl.addEventListener('keyup', event => {
                const value = (event.target as HTMLInputElement).value;
                // No easy way to check for CTRL/CMD + X below. the check for CTRL/CMD should
                // be enough since we are also checking the value.
                if (
                    (event.which === KeyHelpers.keyCode.BACKSPACE ||
                        event.which === KeyHelpers.keyCode.DELETE ||
                        KeyHelpers.isCtrlKey(event.which)) &&
                    value === ''
                ) {
                    // `triggerEvent` is a choices specific function.
                    // Currently missing from the choices.js typings.
                    // Hence the cast to "any" below.
                    (this.choices.passedElement as any).triggerEvent('search', {
                        value,
                        resultCount: 0,
                    });
                }
            });
            this.isSearchEventFixed = true;
        }
    }

    /**
     * Returns an object that maps components config to choicesJS config options
     * @param componentConfig - Properties from client's react component
     */
    private generateChoicesConfig(componentConfig: NormalizedSelectMultipleConfig): ChoicesConfig {
        let config: ChoicesConfig = {};

        config.duplicateItemsAllowed = false;

        const commonConfig = generateCommonChoicesConfig(componentConfig);

        if (componentConfig.sortSelectedOptions !== undefined) {
            if (typeof componentConfig.sortSelectedOptions === 'function') {
                config.shouldSortItems = true;
                //choices does not have typings for this interface
                (config as any).sortFn = componentConfig.sortSelectedOptions;
            } else {
                config.shouldSortItems = componentConfig.sortSelectedOptions as boolean;
            }
        }
        if (componentConfig.renderSelectedOptions) {
            config.renderSelectedChoices = 'always';
        }
        if (componentConfig.pasteEnabled) {
            config.paste = componentConfig.pasteEnabled;
        }
        if (componentConfig.maxSelections !== undefined) {
            config.maxItemCount = componentConfig.maxSelections;
        }
        if (componentConfig.maxSelectionsContent !== undefined) {
            config.maxItemText = componentConfig.maxSelectionsContent;
        } else {
            config.maxItemText = 'Maximum selected options reached';
        }
        config.classNames = selectMultipleClassNames;

        return { ...config, ...commonConfig };
    }

    /**
     * Updates the options and selected options
     */
    private async updateSelectConfig(
        config: NormalizedSelectMultipleConfig,
        prevConfig: NormalizedSelectMultipleConfig = { disabled: false }
    ) {
        const { initialValues, options, disabled, values } = config;
        this.suppressEvents = true;

        if (options !== prevConfig.options) {
            this.choices.removeActiveItems(0);
            this.choices.setChoices(options as SelectOptionLeaf[], 'value', 'label', true);
        }
        if (values !== undefined) {
            await this.updateValues();
        }
        if (initialValues) {
            this.choices.setChoiceByValue(initialValues);
        }
        if (!disabled) {
            this.choices.enable();
        } else {
            this.choices.disable();
        }
        this.suppressEvents = false;
    }

    /**
     * Used to update the value of the component when the value is in "controlled" mode (i.e. the
     * parent component is controlling the selected value(s))
     *
     */
    private async updateValues() {
        const { onChange, values } = this.props;
        // If the 'value' is being controlled by the parent component, we need to reset the value
        // here. The parent component will provide a new 'value' prop to update the Select component later
        if (values !== undefined) {
            this.choices.removeActiveItems(0);
            this.choices.setChoiceByValue(values!);
        }

        if (onChange && !this.suppressEvents) {
            const choicesValues = (this.choices.getValue() as unknown) as ChoicesSelectedOption[];

            const changeEvent: SelectMultipleChangeEvent = {
                type: 'remove',
                removedOptions: getSelectedOptions(choicesValues),
                removedValues: getSelectedValues(choicesValues),
                selectedOptions: getSelectedOptions(choicesValues),
                selectedValues: getSelectedValues(choicesValues),
            };
            onChange(changeEvent);
        }
    }

    /**
     * Adds provided event listeners to the select container
     * @param componentConfig - Properties from client react component
     */
    private attachEventListeners(): void {
        this.selectEl.addEventListener('search', event => this.onSearch(event), false);
        this.selectEl.addEventListener('addItem', event => this.onOptionSelected(event), false);
        this.selectEl.addEventListener('removeItem', event => this.onOptionRemoved(event), false);
        this.selectEl.addEventListener('showDropdown', event => this.onDropdownShow(event), false);
        this.selectEl.addEventListener('hideDropdown', event => this.onDropdownHide(event), false);
        // our Angular wrapper uses an EventEmitter called 'change' to emit the change, but parent components subscribing to it
        // could also receive the DOM event called 'change' which is emitted by Choices itself. We want to prevent developers from
        // receiving Choices' event
        this.selectEl.addEventListener(
            'change',
            event => {
                event.stopPropagation();
            },
            false
        );
        this.selectEl.addEventListener('choice', event => this.onOptionClicked(event), false);
        this.fixDropdownCloseBehavior();
    }

    /**
     * We want to allow users the ability to close the dropdown (when the dropdown is open) by clicking the select component.
     * Since we want users to be able to click anywhere in the "input" (whether it be the input itself or anywhere else)
     * we attach an event listener on the outermost element to listen for a click.
     * There is an issue, if a user clicks on the actual input element (input.gs-uitk-select-multiple__input)
     * The dropdown briefly opens and then it immediately closes. This function allow us to "fix" the normal behavior
     * choices.js has (not closing dropdown on click of the component)
     */
    private fixDropdownCloseBehavior(): void {
        // Get a reference to the inputEl
        const inputEl = nonNullableValueOrThrow(
            this.selectOuterContainer.querySelector<HTMLInputElement>(
                'input.gs-uitk-select-multiple__input'
            ),
            'CommonSelectMultipleComponent.attachEventListeners: Unable to find the SelectMultiple input element'
        );

        // Handle clicking on the input element itself
        // The dropdown will close if it's currently open
        inputEl.addEventListener('click', e => {
            e.stopPropagation();
            if (this.canCloseDropdown) {
                this.choices.hideDropdown();
                this.canCloseDropdown = false;
            } else {
                this.canCloseDropdown = true;
            }
        });

        // Handle clicking on the outer element (SelectMultiple Component)
        // The dropdown will close if it's currently open
        this.selectOuterContainer.addEventListener('click', () => {
            if (this.canCloseDropdown) {
                this.choices.hideDropdown();
                this.canCloseDropdown = false;
            } else {
                this.canCloseDropdown = true;
            }
        });

        // Clean-up our attributes we set in case a user clicks outside the component
        // and does not click the input to close.
        inputEl.addEventListener('blur', () => (this.canCloseDropdown = false));
    }

    /**
     * Reformats the 'search' event data and calls the Select components 'onSearch' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onSearch(event: any): void {
        event.stopPropagation();
        const { onSearch } = this.props;
        if (onSearch && !this.suppressEvents) {
            const searchEvent: SelectSearchEvent = {
                searchValue: event.detail.value,
                resultsCount: event.detail.resultCount,
            };
            onSearch(searchEvent);
        }
    }

    /**
     * Reformats the 'addItem' event data and calls the Select components 'onChange' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private async onOptionSelected(event: any): Promise<any> {
        event.stopPropagation();
        const { values, onChange } = this.props;
        const type: SelectMultipleAddEvent['type'] = 'add';
        const choicesValues = (this.choices.getValue() as unknown) as ChoicesSelectedOption[];

        if (!this.suppressEvents) {
            if (onChange) {
                const changeEvent: SelectMultipleChangeEvent = {
                    type,
                    addedOptions: [
                        {
                            value: event.detail.value,
                            label: event.detail.label,
                            ...(event.detail.customProperties && {
                                customProperties: event.detail.customProperties,
                            }),
                        },
                    ],
                    addedValues: [event.detail.value],
                    selectedOptions: getSelectedOptions(choicesValues),
                    selectedValues: getSelectedValues(choicesValues),
                };
                onChange(changeEvent);
            }
            // If an option gets selected when in "controlled" mode we need to unselect the option
            // the user selected and select the option the developer has passed. Below we compare the
            // Currently selected options with the ones the developer provided.
            if (values && !arraySetsEqual(getSelectedValues(choicesValues), values)) {
                this.suppressEvents = true;
                // For now we are awaiting `updateValues` although it is not an async function.
                // If the component is in controlled mode and a user clicks an option(developer does not update the value prop).
                // Then a user clicks another option(developer again does not update t he value prop).
                // The onChange is called 3 times instead of the expected 2.
                await this.updateValues();
                this.suppressEvents = false;
            }
        }
    }

    /**
     * Reformats the 'removeItem' event data and calls the Select components 'onChange' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onOptionRemoved(event: any) {
        event.stopPropagation();
        // The choices.js 'remove' event is fired in an asynchronous fashion.
        // Since users can "remove" multiple items at once and we want to return all those in one event
        // we wait one "tick" to "collect" all of the events together.
        if (this.removedEventsList.length === 0) {
            Promise.resolve().then(() => this.onOptionsRemoved());
        }
        this.removedEventsList.push({ value: event.detail.value, label: event.detail.label });
    }

    /**
     * Used to call the 'onChanges' callback, this is called when multiple options are removed
     * from the select widget.
     */
    private onOptionsRemoved(): void {
        const { onChange } = this.props;
        const type: SelectMultipleRemoveEvent['type'] = 'remove';
        const choicesValues = (this.choices.getValue() as unknown) as ChoicesSelectedOption[];
        if (onChange && !this.suppressEvents) {
            const options: SelectOptionLeaf[] = [...this.removedEventsList];
            const values = options.map(option => option.value);
            const changeEvent: SelectMultipleChangeEvent = {
                type,
                removedOptions: options,
                removedValues: values,
                selectedOptions: getSelectedOptions(choicesValues),
                selectedValues: getSelectedValues(choicesValues),
            };
            onChange(changeEvent);
        }
        this.removedEventsList.length = 0;
    }

    /**
     * Function used to listen to the choices.js "choice" event.
     *
     * We used this function to 'un-select' an option when a user clicks
     * an option that has already been selected.
     *
     * The option will only be unselected if the renderSelectedOptions prop is true
     *
     * @param event - Custom Event emitted from choices event.
     */
    private onOptionClicked(event: any) {
        event.stopPropagation();
        const { renderSelectedOptions } = this.props;
        const choicesValues = (this.choices.getValue(true) as unknown) as ChoicesSelectedOption[];
        const optionClickedValue = event.detail.choice.value;

        if (renderSelectedOptions && choicesValues.includes(optionClickedValue)) {
            this.suppressEvents = true;
            this.choices.removeActiveItemsByValue(optionClickedValue);
            this.suppressEvents = false;
        }
    }

    /**
     * Calls the Select components 'onDropdownShow' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onDropdownShow(event: any): void {
        event.stopPropagation();
        const { onDropdownShow } = this.props;
        if (onDropdownShow && !this.suppressEvents) {
            onDropdownShow();
        }
    }

    /**
     * Calls the Select components 'onDropdownHide' callback.
     * @param event - Custom Event emitted from choices event.
     * @param componentConfig - config for client component.
     */
    private onDropdownHide(event: any): void {
        event.stopPropagation();
        const { onDropdownHide } = this.props;
        if (onDropdownHide && !this.suppressEvents) {
            onDropdownHide();
        }
    }
}
