import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label, FormGroup, Radio } from '@gs-ux-uitoolkit-react/core';

class RadioGroup extends Component<any, any> {
    public callback: any;
    public id: any;
    public buttons: any;

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: this.props.selected || '',
        };
        this.getRadios = this.getRadios.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    getRadios(id, buttons?) {
        return buttons.map(btn => (
            <Radio
                key={btn.id}
                value={btn.id}
                name={id}
                checked={this.state.selectedOption === btn.id}
                onChange={this.handleOptionChange}
            >
                {btn.text}
            </Radio>
        ));
    }

    handleOptionChange(changeEvent) {
        const { callback, id } = this.props;
        callback(id, changeEvent.target.value);
        this.setState({ selectedOption: changeEvent.target.value });
    }

    render() {
        const { id, buttons } = this.props;
        return <FormGroup>{this.getRadios(id, buttons)}</FormGroup>;
    }

    static propTypes = {
        id: PropTypes.string.isRequired,
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                text: PropTypes.string,
                icon: PropTypes.string,
            })
        ),
        callback: PropTypes.func,
        selected: PropTypes.string,
    };
}

export default RadioGroup;
