import React from 'react';
import PropTypes, { Requireable } from 'prop-types';
import cx from 'classnames';
import { Badge } from '@gs-ux-uitoolkit-react/core';
import { resolveIconName } from '@gs-ux-uitoolkit-common/icons';

export interface IconProps {
    iconName?: string;
    classes?: string;
    size?: '' | 'gi-lg' | 'gi-2x' | 'gi-3x' | 'gi-4x' | 'gi-5x';
    onClick?: () => void;
    badge?: number;
    nonTabbable?: boolean;
    color?: string;
    dataUtilityName?: string;
}

const Icon: React.FunctionComponent<IconProps> = ({
    iconName,
    classes,
    size,
    onClick,
    badge,
    nonTabbable,
    color,
    dataUtilityName,
}) => {
    const badgeIcon = badge ? (
        <Badge className="icon-badge" color="danger">
            {badge}
        </Badge>
    ) : null;
    return (
        <span
            className={cx('icon', classes)}
            onClick={onClick}
            tabIndex={nonTabbable ? -1 : undefined}
            data-cy="header.iconContainer"
            data-utility-name={dataUtilityName}
        >
            <i
                className={`gi gi-fw ${size} gi-${iconName} gsi-${resolveIconName(iconName)}`}
                style={{ color }}
                data-cy="header.appIcon"
            />
            {badgeIcon}
        </span>
    );
};

Icon.defaultProps = {
    iconName: '',
    classes: '',
    size: '',
    onClick: () => {},
};

Icon.propTypes = {
    iconName: PropTypes.string,
    classes: PropTypes.string,
    size: PropTypes.oneOf(['', 'gi-lg', 'gi-2x', 'gi-3x', 'gi-4x', 'gi-5x']) as Requireable<
        '' | 'gi-lg' | 'gi-2x' | 'gi-3x' | 'gi-4x' | 'gi-5x'
    >,
    onClick: PropTypes.func,
    badge: PropTypes.number,
    nonTabbable: PropTypes.bool,
    color: PropTypes.string,
    dataUtilityName: PropTypes.string,
};

export default Icon;
