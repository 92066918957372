import * as React from 'react';
import { Label as ReactStrapLabel, LabelProps } from 'reactstrap';
import cx from 'classnames';

const Label: React.FunctionComponent<LabelProps> = ({ children, ...props }) => {
    const { size } = props;
    const customProps = {
        ...props,
        className: cx(props.className || '', 'form-check-label', {
            'form-check-label--lg': size === 'lg',
            'form-check-label--sm': size === 'sm',
        }),
    };
    return <ReactStrapLabel {...customProps}>{children}</ReactStrapLabel>;
};

Label.propTypes = (ReactStrapLabel as any).propTypes;

export { Label, LabelProps };
