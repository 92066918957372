export function domReady() {
    return new Promise(resolve => {
        if (document.readyState === 'loading') {
            const eventHandler = function() {
                if (document.readyState !== 'loading') {
                    document.removeEventListener('readystatechange', eventHandler);
                    resolve();
                }
            };
            document.addEventListener('readystatechange', eventHandler);
        } else {
            resolve();
        }
    });
}
