import { logger } from '../../libraries/logger';
import { CrossModelActionKeys } from '../actions/action-constants';
import { CrossModelActionTypes } from '../actions/cross-model-action';
import { CrossModelState } from '../cross-model-state';

export const initialCrossModelState: CrossModelState = {};

/**
 * Reducer for the CrossModelState
 * @param state State of the CrossModelState
 * @param action Action received
 */
export const crossModelReducer = (
    state: CrossModelState = initialCrossModelState,
    action: CrossModelActionTypes
): CrossModelState => {
    switch (action.type) {
        case CrossModelActionKeys.ADD_CROSS_MODEL: {
            const crossModelKey = action.crossModelKey;
            if (!state[crossModelKey]) {
                return {
                    ...state,
                    [crossModelKey]: { ...action.crossModel },
                };
            }
            logger.error(
                `Could not add cross model with id ${crossModelKey}. Cross Model with that id already exists.`
            );
            return state;
        }
        case CrossModelActionKeys.UPDATE_CROSS_MODEL: {
            const crossModelKey = action.crossModelKey;
            const newCrossModelKey = action.newCrossModelKey;
            if (state[crossModelKey]) {
                const newState = { ...state };
                if (newCrossModelKey) {
                    delete newState[crossModelKey];
                    newState[newCrossModelKey] = { ...action.crossModel };
                    return newState;
                }
                return {
                    ...state,
                    [crossModelKey]: { ...action.crossModel },
                };
            }
            logger.error(
                `Could not update cross model with id ${crossModelKey}. Cross Model with that id does not exist.`
            );
            return state;
        }
        case CrossModelActionKeys.REMOVE_CROSS_MODEL: {
            const newState = { ...state };
            const crossModelToDelete = state[action.crossModelKey];
            if (crossModelToDelete) {
                delete newState[action.crossModelKey];
                return newState;
            }
            logger.error(`Couldn not delete the cross model ${action.crossModelKey}`);
            return state;
        }
        default:
            return state;
    }
};
