import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

export interface SidePanelMenuPopoverProps {
    isOpen?: boolean;
    hideMenuPopover: (key: string, event: React.MouseEvent | React.FocusEvent) => void;
    text?: string;
    badge?: {
        color?: string;
        text?: string;
    };
    positionStyles?: React.CSSProperties;
    subMenuItems?: React.ReactElement<{
        id?: string;
        text?: string;
    }>[];
}

const SidePanelMenuPopover: React.FunctionComponent<SidePanelMenuPopoverProps> = ({
    positionStyles,
    isOpen,
    hideMenuPopover,
    text,
    badge,
    subMenuItems,
}) => (
    <div className="side-panel-popover" style={positionStyles}>
        {!isOpen ? (
            <div
                onMouseLeave={e => hideMenuPopover('', e)}
                onBlur={e => hideMenuPopover('', e)}
                className="popover-title-container"
            >
                <div className="popover-title">{text}</div>
                {badge ? <Badge color={badge.color}>{badge.text}</Badge> : null}
            </div>
        ) : null}
        {subMenuItems}
    </div>
);

SidePanelMenuPopover.propTypes = {
    isOpen: PropTypes.bool,
    hideMenuPopover: PropTypes.func.isRequired,
    text: PropTypes.string,
    badge: PropTypes.shape({
        color: PropTypes.string,
        text: PropTypes.string,
    }),
    positionStyles: PropTypes.shape({
        position: PropTypes.string,
        top: PropTypes.string,
        left: PropTypes.string,
    }),
};

export default SidePanelMenuPopover;
