/**
 * Represent the type of data
 */
export enum DataType {
    /**
     * Unknow type. default if uninitialized
     */
    Unknown = 'Unknown',
    /**
     * String Data
     */
    String = 'String',
    /**
     * Number data : float or fixed
     */
    Number = 'Number',
    /**
     * Date data without time
     */
    Date = 'Date',
    /**
     * Date data with time
     */
    DateTime = 'DateTime',
    /**
     * Time data
     */
    Time = 'Time',
    /**
     * Boolean data
     */
    Boolean = 'Boolean',
}
