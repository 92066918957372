import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from '@gs-ux-uitoolkit-react/core';
import Notification from '../item/Notification';

const ItemList: React.FunctionComponent<any> = ({
    closePopover,
    callbacks,
    notifications,
    groupedNotifications,
}) => {
    const NotificationDisplay = notifications.map(notification => (
        <Notification
            key={notification.id}
            closePopover={closePopover}
            action={callbacks.action}
            id={notification.id}
            {...notification}
            callbacks={callbacks}
            groupedNotifications={groupedNotifications}
        />
    ));

    return <ListGroup>{NotificationDisplay}</ListGroup>;
};

ItemList.defaultProps = {
    callbacks: {},
    notifications: [],
    closePopover: () => {}, // This prop comes from the Popover component
};

ItemList.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            primaryInfo: PropTypes.string,
            secondaryInfo: PropTypes.string,
            icon: PropTypes.string,
            time: PropTypes.string,
        })
    ),
    callbacks: PropTypes.shape({
        action: PropTypes.func,
        dismiss: PropTypes.func,
        dismissCategory: PropTypes.func,
    }),
    closePopover: PropTypes.func,
    groupedNotifications: PropTypes.bool,
};

export default ItemList;
