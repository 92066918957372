import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { Badge } from 'reactstrap';
import { resolveIconName } from '@gs-ux-uitoolkit-common/icons';

export interface SidePanelSubMenuItem {
    id?: string;
    text?: string;
    callback?: (...args: any[]) => any;
}

export interface BadgeItem {
    color?: string;
    text?: string;
}

export interface SidePanelMenuItemProps {
    isSubMenu?: boolean;
    isOpen: boolean;
    showIcon: boolean;
    menuSelect: (id: string, isSubMenu: boolean) => void;
    showMenuPopover?: (
        id: string,
        event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>
    ) => void;
    hideMenuPopover?: (id: string, event: React.MouseEvent | React.FocusEvent) => void;
    active: boolean;
    id: string;
    icon?: string;
    text: string;
    subMenu?: SidePanelSubMenuItem[];
    badge?: BadgeItem;
}

const SidePanelMenuItem: React.FunctionComponent<SidePanelMenuItemProps> = ({
    showIcon,
    isOpen,
    isSubMenu,
    active,
    id,
    icon,
    text,
    badge,
    menuSelect,
    showMenuPopover,
    hideMenuPopover,
}) => {
    const menuSelectWithKey = () => {
        if (menuSelect) {
            menuSelect(id, !!isSubMenu);
        }
    };
    const menuItemEnterWithKey = (
        e: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>
    ) => {
        if (showMenuPopover) {
            showMenuPopover(id, e);
        }
    };
    const menuItemLeaveWithKey = (e: React.MouseEvent | React.FocusEvent) => {
        if (hideMenuPopover) {
            hideMenuPopover(id, e);
        }
    };
    const menuItemIcon = showIcon ? (
        <i
            className={cx(
                'gi',
                'gi-fw',
                `gi-${icon}`,
                `gsi-${resolveIconName(icon || '')}`,
                { 'mr-2': isOpen },
                { 'ml-5': isOpen }
            )}
        />
    ) : null;
    const menuItemBadge = badge ? <Badge color={badge.color}>{badge.text}</Badge> : null;
    return (
        <div
            id={id}
            className={cx('menu-item', { active }, { 'sub-menu': isSubMenu })}
            onClick={menuSelectWithKey}
            onMouseEnter={menuItemEnterWithKey}
            onMouseLeave={menuItemLeaveWithKey}
            onFocus={menuItemEnterWithKey}
            onBlur={menuItemLeaveWithKey}
            onKeyDown={menuSelectWithKey}
        >
            {menuItemIcon}
            {isOpen || isSubMenu ? (
                <div className={cx('menu-title', { 'ml-5': !showIcon && !isSubMenu })} title={text}>
                    {text}
                </div>
            ) : null}
            {menuItemBadge}
        </div>
    );
};

SidePanelMenuItem.defaultProps = {
    isSubMenu: false,
};

SidePanelMenuItem.propTypes = {
    isSubMenu: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    showIcon: PropTypes.bool.isRequired,
    menuSelect: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    icon: PropTypes.string,
    text: PropTypes.string.isRequired,
    subMenu: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            text: PropTypes.string,
            callback: PropTypes.func,
        })
    ) as PropTypes.Validator<SidePanelSubMenuItem[]>,
    badge: PropTypes.shape({
        color: PropTypes.string,
        text: PropTypes.string,
    }),
};

export default SidePanelMenuItem;
