import { startsWith } from '@gs-ux-uitoolkit-common/shared';
/**
 * This function is used to extract any prop that starts with `data-`.
 *
 * A common pattern is to add "data-cy" attribute to components.
 * This comes in handy when writing e2e test since you can target the
 * component by that attribute. Usually these attributes will never change.
 *
 * This function allows us to iterate over all of the components props and extract any prop
 * that begins with `data-`. later on you can pass these onto the outermost el.
 *
 * @param props The props of the given component.
 */
export function extractDataAttributes(props: { [key: string]: any }) {
    let dataAttrs: { [key: string]: string } = {};
    for (let key of Object.keys(props)) {
        if (startsWith(key, 'data-')) {
            dataAttrs[key] = props[key];
        }
    }
    return dataAttrs;
}
