import * as React from 'react';
import * as PropTypes from 'prop-types';

export interface AccordionProps {
    children?: React.ReactNode | React.ReactNode[];
}

export const Accordion: React.FunctionComponent<AccordionProps> = ({ children }) => (
    <div
        data-cy="gs-uitk-accordion"
        className="accordion"
        role="tablist"
        aria-multiselectable="true"
    >
        {children}
    </div>
);

Accordion.propTypes = {
    children: PropTypes.node,
};
