import * as React from 'react';
import * as PropTypes from 'prop-types';
import { TabContent, TabContentProps } from 'reactstrap';
import { isTabProps } from './Tab';
import { isDropdownTabProps } from './DropdownTab';

const isValidComponent = (node: React.ReactElement) =>
    isTabProps(node.props) || isDropdownTabProps(node.props);
/**
 *  <DropdownTab>
 *      <Tab />
 *  <DropdownTab>
 *  <Tab />
 *
 *  flattened to...
 *
 *  <Tab />
 *  <Tab />
 */
const flattenTabPanes = (nestedTabPanes: React.ReactElement[]): React.ReactElement[] =>
    nestedTabPanes.reduce(
        (a: React.ReactElement[], b: React.ReactElement) =>
            a.concat(
                isDropdownTabProps(b.props)
                    ? flattenTabPanes(b.props.children as React.ReactElement[])
                    : b
            ),
        []
    );

const createTabPanes = (nodes: React.ReactElement) =>
    flattenTabPanes(
        (React.Children.toArray(nodes) as React.ReactElement[]).filter(isValidComponent)
    );

export interface TabPanesProps extends TabContentProps {
    activeTabKey?: string | number;
}

const TabPanes: React.FunctionComponent<TabPanesProps> = ({ activeTabKey, children, ...other }) => (
    <TabContent {...other} activeTab={activeTabKey}>
        {createTabPanes(children as React.ReactElement)}
    </TabContent>
);

TabPanes.propTypes = {
    activeTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node.isRequired,
} as { [key: string]: any };

export default TabPanes;
