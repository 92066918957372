import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $clamp from 'clamp-js';

class Info extends Component<any, any> {
    public notificationContent: any;
    public secondaryInfo: any;

    componentDidUpdate() {
        $clamp(this.notificationContent, { clamp: 4 }); // Clamp-js is a utility that handles multiline overflow ellipsis
    }

    render() {
        const { secondaryInfo } = this.props;
        return (
            <div
                ref={content => {
                    this.notificationContent = content;
                }}
            >
                {secondaryInfo}
            </div>
        );
    }

    static defaultProps = {
        secondaryInfo: '',
    };

    static propTypes = {
        secondaryInfo: PropTypes.string,
    };
}

export default Info;
