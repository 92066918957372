import React from 'react';
import PropTypes from 'prop-types';
import { HeaderUtils } from '@gs-ux-uitoolkit-common/header';
import { Close } from '@gs-ux-uitoolkit-react/shared';
import Icon from '../../../../../shared/components/Icon';

const Header: React.FunctionComponent<any> = ({
    primaryInfo,
    time,
    close,
    dismiss,
    id,
    closePopover,
    secondaryIcon,
    secondaryIconColor,
}) => {
    const closeCallback = e => {
        e.stopPropagation();
        closePopover();
        dismiss(id);
    };

    const handleMouseEnter = e => {
        e.target.parentNode.parentNode.classList.add('disabled-notification-content');
    };

    const handleMouseLeave = e => {
        e.target.parentNode.parentNode.classList.remove('disabled-notification-content');
    };

    const rightContent = close ? (
        <Close
            onClick={closeCallback}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        />
    ) : (
        <div className="notification-label">{HeaderUtils.getDisplayTime(time)}</div>
    );
    return (
        <div className="notification-header">
            <span className="notification-header-text">{primaryInfo}</span>
            {secondaryIcon ? (
                <Icon
                    classes="secondary-icon"
                    iconName={secondaryIcon}
                    color={secondaryIconColor}
                />
            ) : null}
            {rightContent}
        </div>
    );
};

Header.defaultProps = {
    primaryInfo: '',
    time: '',
    dismiss: () => {},
    closePopover: () => {},
    id: '',
    close: true,
};

Header.propTypes = {
    primaryInfo: PropTypes.string,
    time: PropTypes.string,
    close: PropTypes.bool,
    closePopover: PropTypes.func,
    dismiss: PropTypes.func,
    secondaryIcon: PropTypes.string,
    secondaryIconColor: PropTypes.string,
    id: PropTypes.string.isRequired,
};

export default Header;
