/**
 * A "controllable" promise where the owner of the Deferred object owns the ability to resolve or
 * reject the Promise given by the `promise` property.
 *
 * Example usage:
 *
 *     const deferred = new Deferred<string>();
 *     const promise = deferred.promise;
 *
 *     promise.then(msg => console.log(`Hello, ${msg}!`));
 *
 *     deferred.resolve('world');  // console logs 'Hello, world!'
 */
export class Deferred<ResolutionType> {
    public readonly promise: Promise<ResolutionType>;

    private resolveFn: (value: ResolutionType) => void;
    private rejectFn: (error: any) => void;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolveFn = resolve;
            this.rejectFn = reject;
        });
    }

    public resolve(value: ResolutionType) {
        this.resolveFn(value);
    }

    public reject(error: any) {
        this.rejectFn(error);
    }
}
