import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Category from './category/Category';

const Container: React.FunctionComponent<any> = ({ list, closePopover, callbacks }) => {
    const groupedNotifications = !(list.length === 1 && list[0].category === undefined);
    const notificationCategories = !_.isEmpty(list) ? (
        list.map(notificationGroup => {
            const { notifications, category, id, icon } = notificationGroup;
            return (
                <Category
                    key={id}
                    id={id}
                    icon={icon}
                    category={category}
                    notifications={notifications}
                    callbacks={callbacks}
                    closePopover={closePopover}
                    groupedNotifications={groupedNotifications}
                />
            );
        })
    ) : (
        <h1 style={{ textAlign: 'center' }}>No new notifications</h1>
    );

    return <div className="notifications-container">{notificationCategories}</div>;
};

Container.defaultProps = {
    list: [],
    closePopover: () => {}, // This prop comes from the Popover component
    callbacks: {},
};

Container.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            category: PropTypes.string,
            notifications: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    primaryInfo: PropTypes.string,
                    secondaryInfo: PropTypes.string,
                    icon: PropTypes.string,
                    time: PropTypes.string,
                })
            ),
        })
    ),
    callbacks: PropTypes.shape({
        action: PropTypes.func,
        dismiss: PropTypes.func,
        dismissCategory: PropTypes.func,
    }),
    closePopover: PropTypes.func,
};

export default Container;
