import * as React from 'react';
import * as PropTypes from 'prop-types';
import ControlledSidePanel from './ControlledSidePanel';
import UncontrolledSidePanel from './UncontrolledSidePanel';

export interface SidePanelProps {
    /**
     * Indicates if the component is open or collapsed.
     *
     * This is a controlled prop, and the SidePanel will always reflect the state of this
     * prop. To find out when the user has requested that the SidePanel be expanded or
     * collapsed, use the {@link #togglePanel} callback.
     */
    isOpen?: boolean;

    /**
     * Callback called when {@link isOpen} changes.
     *
     * This callback is required when using the {@link #isOpen} controlled prop to find
     * out when the user has requested that the panel be expanded or collapsed.
     */
    togglePanel?: (event: React.MouseEvent) => void;

    /**
     * Indicates if the component content is SidePanelMenu.
     */
    withMenu?: boolean;

    /**
     * The title of the component.
     */
    title?: string;

    /**
     * Component position.
     */
    position?: 'left' | 'right';

    /**
     * Component to render instead of the {@link title}.
     */
    header?: JSX.Element;

    /**
     * Custom css classes to add on the SidePanel.
     */
    className?: string;
}

export const SidePanel: React.FunctionComponent<SidePanelProps> = ({
    isOpen,
    withMenu = false,
    position = 'left',
    togglePanel = () => {},
    ...rest
}) => {
    if (typeof isOpen === 'undefined') {
        return (
            <UncontrolledSidePanel
                withMenu={withMenu}
                position={position}
                togglePanel={togglePanel}
                {...rest}
            />
        );
    }
    return (
        <ControlledSidePanel
            isOpen={isOpen}
            withMenu={withMenu}
            position={position}
            togglePanel={togglePanel}
            {...rest}
        />
    );
};

SidePanel.defaultProps = {
    withMenu: false,
    position: 'left',
};

SidePanel.propTypes = {
    isOpen: PropTypes.bool,
    togglePanel: PropTypes.func as PropTypes.Validator<(event: React.MouseEvent) => void>,
    withMenu: PropTypes.bool as PropTypes.Validator<boolean>,
    title: PropTypes.string,
    position: PropTypes.oneOf(['left', 'right']) as PropTypes.Validator<'left' | 'right'>,
    header: PropTypes.element,
    className: PropTypes.string,
};
