/**
 * The height of menu items in the header.
 */
export const menuItemHeight = 40;

/**
 * The height of the 'Back' button in submenus on 'mobile'-sized devices for flipping back to a
 * previous submenu.
 */
export const backBtnHeight = 48;
