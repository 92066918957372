import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { Manager, Target, Popper, Arrow } from 'react-popper';
import outy from 'outy';
import { extendComponentProps } from '../functions';

class Popover extends Component<any, any> {
    public outsideClick: any;
    public target: any;
    public popper: any;
    public popoverTarget: any;
    public placement: any;
    public popoverContent: any;

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
        this.setOutsideClick = this.setOutsideClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.handleTargetClick = this.handleTargetClick.bind(this);
        this.handleActiveUtility = this.handleActiveUtility.bind(this);
    }

    componentDidMount() {
        this.setOutsideClick();
    }

    componentDidUpdate(lastProps, lastState) {
        if (lastState.isOpen !== this.state.isOpen) {
            setTimeout(() => this.setOutsideClick());
        }
    }

    componentWillUnmount() {
        this.outsideClick.remove();
    }

    setOutsideClick() {
        const elements = [this.target];

        if (this.popper) {
            elements.push(this.popper);
        }

        if (this.outsideClick) {
            this.outsideClick.remove();
        }

        this.outsideClick = outy(elements, ['click', 'touchstart'], this.handleOutsideClick);
    }

    handleOutsideClick() {
        this.setState({ isOpen: false });
        this.target &&
            this.target.parentNode.parentNode.parentNode.classList.remove('active-utility');
    }

    handleTargetClick() {
        this.setState({ isOpen: !this.state.isOpen });
        this.target && this.handleActiveUtility();
    }

    handleActiveUtility() {
        const targetParentClasses = this.target.parentNode.parentNode.parentNode.classList;
        if (targetParentClasses.contains('active-utility')) {
            targetParentClasses.remove('active-utility');
        } else {
            targetParentClasses.add('active-utility');
        }
    }

    render() {
        const { popoverTarget, placement, popoverContent } = this.props;
        // popoverContent is cloned to add in an extra prop to send down to components that use the popover
        // so that they can call this to close the popover on some action from inside the popover.
        const popper = this.state.isOpen ? (
            <Popper
                placement={placement}
                modifiers={{
                    flip: {
                        enabled: false,
                    },
                }}
                className="popper"
                innerRef={c => (this.popper = findDOMNode(c))}
            >
                <div className="popover-content">
                    {extendComponentProps(popoverContent, {
                        closePopover: this.handleOutsideClick,
                    })}
                </div>
                <Arrow className="popper-arrow-base popper-arrow-shadow">
                    <span className="popper-arrow-base popper-arrow-main" />
                </Arrow>
            </Popper>
        ) : null;
        return (
            <Manager>
                <Target
                    aria-expanded={this.state.isOpen}
                    innerRef={c => (this.target = findDOMNode(c))}
                    onClick={this.handleTargetClick}
                >
                    {popoverTarget}
                </Target>
                {popper}
            </Manager>
        );
    }

    static defaultProps = {
        placement: 'bottom',
    };

    static propTypes = {
        popoverContent: PropTypes.node,
        popoverTarget: PropTypes.node,
        placement: PropTypes.string,
    };
}

export default Popover;
