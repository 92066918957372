import React from 'react';
import ReactDOM from 'react-dom';

import {
    CommonSelectComponent,
    CommonSelectProps,
    OptionRendererData,
    createOptionRendererData,
} from '@gs-ux-uitoolkit-common/select';
import { extractDataAttributes } from '@gs-ux-uitoolkit-react/shared';

import { SelectProps } from './select-props';
/**
 * The Select component is used to select a single option from a list of options.
 * If you need to select multiple options please use the
 *    [SelectMultiple](#/Components/SelectMultiple) component.
 *
 * **Terminology**
 * - **option:** is defined as a single option inside the Select components dropdown.
 * - **selectedOption:** is defined as the active option in the input box.
 *
 * <small>Note: Select is currently powered by the excellent [Choices.js](https://github.com/jshjohnson/Choices)
 * library. However, please do not rely on this fact because the underlying implementation may be
 * changed at a future date as new business requirements and feature requests come forward.
 * The UI Toolkit Select API will remain the same, however, regardless of the underlying
 * implementation.</small><br><br>
 *
 * @visibleName Select
 */
export class Select extends React.Component<SelectProps, {}> {
    public static defaultProps = {
        className: undefined,
        disabled: false,
        filterOptionsOnSearch: true,
        initialValue: null,
        noOptionsContent: 'No options to choose from',
        noResultsContent: 'No results found',
        onChange: undefined,
        onDropdownHide: undefined,
        onDropdownShow: undefined,
        onSearch: undefined,
        optionRenderer: null,
        options: [],
        pasteEnabled: true,
        placeholder: undefined,
        removeButton: true,
        searchEnabled: true,
        selectedOptionRenderer: null,
        style: undefined,
        value: undefined,
    };

    private selectContainerEl: HTMLDivElement | undefined;
    private commonSelectComponent!: CommonSelectComponent;

    public componentDidMount() {
        this.commonSelectComponent = new CommonSelectComponent(
            this.selectContainerEl!,
            this.formatReactProps(this.props)
        );
    }

    public componentDidUpdate(prevProps: SelectProps) {
        this.commonSelectComponent.setConfig(
            this.formatReactProps(this.props),
            this.formatReactProps(prevProps)
        );
    }

    public componentWillUnmount() {
        if (this.commonSelectComponent) {
            this.commonSelectComponent.destroy();
        }
    }

    /**
     * Function used to focus the select component.
     * This will focus the input element and open the dropdown.
     * @public
     */
    public focus() {
        this.commonSelectComponent.focus();
    }

    /**
     * Function used to blur the select component.
     * This will blur the input element and close the dropdown.
     * @public
     */
    public blur() {
        this.commonSelectComponent.blur();
    }

    public render() {
        const { className, style } = this.props;
        const dataAttrs = extractDataAttributes(this.props);

        return (
            <div
                {...dataAttrs}
                className={className}
                data-gs-uitk-component="gs-uitk-select"
                style={style}
                ref={this.setSelectContainerEl}
            >
                <select data-cy="gs-uitk-inner-select-element" />
            </div>
        );
    }

    private setSelectContainerEl = (selectContainerEl: HTMLDivElement) => {
        this.selectContainerEl = selectContainerEl;
    };

    /**
     * Function used when we need to transform any of the components props.
     * @param props - The components props to transform.
     */
    private formatReactProps(props: SelectProps): CommonSelectProps {
        const {
            optionRenderer: userOptionRenderer,
            selectedOptionRenderer: userSelectedOptionRenderer,
        } = props;
        let newOptionRenderer: CommonSelectProps['optionRenderer'];
        let newSelectedOptionRenderer: CommonSelectProps['selectedOptionRenderer'];

        if (userOptionRenderer) {
            newOptionRenderer = (dataAttributes: OptionRendererData) => {
                const result = userOptionRenderer(createOptionRendererData(dataAttributes));

                // If the developer provides us a ReactElement, render it using ReactDOM.
                if (React.isValidElement(result)) {
                    const div = document.createElement('div');
                    ReactDOM.render(result, div);
                    return div;
                } else {
                    // Let the common implementation of Select handle it.
                    return result;
                }
            };
        }

        if (userSelectedOptionRenderer) {
            newSelectedOptionRenderer = (dataAttributes: OptionRendererData) => {
                const result = userSelectedOptionRenderer(createOptionRendererData(dataAttributes));
                // If the developer provides us a ReactElement, render it using ReactDOM.
                if (React.isValidElement(result)) {
                    const div = document.createElement('div');
                    ReactDOM.render(result, div);
                    return div;
                } else {
                    // Let the common implementation of Select handle it.
                    return result;
                }
            };
        }

        return {
            ...props,
            optionRenderer: newOptionRenderer,
            selectedOptionRenderer: newSelectedOptionRenderer,
        };
    }
}
