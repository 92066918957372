import {
    HeaderMenuItem,
    HeaderSubmenu,
    NormalizedHeaderMenuItem,
    NormalizedHeaderSubmenu,
} from '../options/navigation/header-menu-item';
import { logWarningOnce } from '@gs-ux-uitoolkit-common/core';
import { uniqueId } from 'lodash';

/**
 * Utility function to retrieve a {@link HeaderMenuItem}'s submenus, and display a warning if
 * using the old APIs.
 */
export function getSubmenus(headerMenuItem: HeaderMenuItem): HeaderSubmenu[];
export function getSubmenus(headerMenuItem: NormalizedHeaderMenuItem): NormalizedHeaderSubmenu[];
export function getSubmenus(
    headerMenuItem: HeaderMenuItem | NormalizedHeaderMenuItem
): (HeaderSubmenu | NormalizedHeaderSubmenu)[] {
    let resultSubmenus: HeaderSubmenu[] | null = null;
    const { submenus, submenu, dropdowns } = headerMenuItem as HeaderMenuItem;

    if (submenus) {
        resultSubmenus = submenus; // preferred API
    } else if (submenu) {
        logWarningOnce(
            `GS UI Toolkit Header: Use the "submenus" property instead of "submenu" for menu items. Support for "submenu" is deprecated in v10`
        );
        resultSubmenus = submenu;
    } else if (dropdowns) {
        logWarningOnce(
            `GS UI Toolkit Header: Use the "submenus" property instead of "dropdowns" for menu items. Support for "dropdowns" is deprecated in v10`
        );
        if (dropdowns.length > 0) {
            // only create the submenu object if the 'dropdowns' actually has elements
            resultSubmenus = [
                {
                    header: undefined,
                    key: uniqueId(`header-submenu-`),
                    submenuItems: dropdowns.slice(0),
                },
            ];
        }
    }

    return resultSubmenus || [];
}
