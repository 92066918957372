import Choices from 'choices.js';
import { defaults, nonNullableValueOrThrow, KeyHelpers } from '@gs-ux-uitoolkit-common/shared';

import { generateCommonChoicesConfig } from './utils';
import { Choice } from './options/choices-js-interface';
import {
    ChoicesSelectedOption,
    NormalizedSelectConfig,
    SelectChangeEvent,
    selectClassNames,
    SelectConfig,
    SelectOptionLeaf,
    SelectSearchEvent,
} from './options';

export type ChoicesConfig = Partial<Choices['config']>;
export type ClassNames = ChoicesConfig['classNames'];

/**
 * Common implementation of the <Select /> component.
 * instantiates choices.js generates configuration options and updates the configuration when possible.
 *
 * @param selectEl The <select> element to decorate with our Select component.
 * @param config The configuration to instantiate the Select component with.
 */
export class CommonSelectComponent {
    /**
     * The Choices.js object created for the given select component
     */
    private choices!: Choices;

    /**
     * The HTML element that choices.js attaches it self to.
     */
    private selectEl: HTMLSelectElement;

    /**
     * The outermost HTML Element for the Select component.
     */
    private selectOuterContainer: HTMLDivElement;

    /**
     * Flag used to determine if callbacks should be called.
     * You can use this to update the select box, but prevent from calling the developers callback.
     */
    private suppressEvents: boolean = true;

    /**
     * Props passed down from the client's component.
     */
    private props: NormalizedSelectConfig;

    /**
     * Flag used to determine if the searchEvent has been fixed. We only want to "fix" the issue
     *  once. For more details on the issue please see `fixSearchEvent()` in this class.
     */
    private isSearchEventFixed: boolean = false;

    constructor(selectContainerEl: HTMLDivElement, config: SelectConfig) {
        const normalizedConfig = this.normalizeConfig(config);
        this.selectOuterContainer = selectContainerEl;
        this.selectEl = nonNullableValueOrThrow(
            selectContainerEl.querySelector<HTMLSelectElement>(
                '[data-cy="gs-uitk-inner-select-element"]'
            ),
            `CommonSelectComponent.constructor(): Unable to find the inner "select" element, ` +
                `please ensure the first argument of this component is the outermost element containing a '<select>' element`
        );
        this.props = normalizedConfig;
        this.setup(normalizedConfig);
    }

    /***
     * Normalizes the select configuration.
     */
    private normalizeConfig(config: SelectConfig): NormalizedSelectConfig {
        return defaults({}, config, {
            disabled: false,
            searchEnabled: true,
        });
    }

    /**
     * Setup tasks to capture events and config from wrapper component
     */
    private setup(config: NormalizedSelectConfig) {
        const selectConfig: ChoicesConfig = this.generateChoicesConfig(config);
        this.choices = new Choices(this.selectEl, selectConfig);
        this.choices.removeActiveItems(0);
        this.updateSelectConfig(config);
        this.attachEventListeners();
        this.fixSearchEvent(config);
        this.suppressEvents = false;
    }

    /**
     * Used to update the configuration on the component
     * @param newConfig - Current config on the component
     * @param prevConfig - Previous config on the component
     */
    public setConfig(newConfig: SelectConfig, prevConfig: SelectConfig) {
        const newNormalizedConfig = this.normalizeConfig(newConfig);
        const prevNormalizedConfig = this.normalizeConfig(prevConfig);
        this.props = newNormalizedConfig;
        this.updateSelectConfig(newNormalizedConfig, prevNormalizedConfig);
        this.fixSearchEvent(newNormalizedConfig);
    }

    /**
     * Function used to focus the select component.
     */
    public focus() {
        this.choices.showDropdown();
    }

    /**
     * Function used to blur the select component.
     */
    public blur() {
        this.choices.hideDropdown();
    }

    /**
     * Destroys the component.
     */
    public destroy() {
        this.choices.destroy();
    }

    /**
     * Returns an object that maps components config to choicesJS config options
     * @param componentConfig - Properties from client's react component
     */
    private generateChoicesConfig(componentConfig: NormalizedSelectConfig): ChoicesConfig {
        let config: ChoicesConfig = {};

        const commonConfig = generateCommonChoicesConfig(componentConfig);
        config.classNames = selectClassNames;

        if (componentConfig.searchEnabled === false) {
            config.searchEnabled = componentConfig.searchEnabled;
            config.classNames = {
                ...config.classNames,
                listDropdown: 'gs-uitk-select__list-dropdown-no-search',
            };
        }

        return { ...commonConfig, ...config };
    }

    /**
     * Updates the options and selected options
     */
    private async updateSelectConfig(
        config: NormalizedSelectConfig,
        prevConfig: NormalizedSelectConfig = {
            disabled: false,
            searchEnabled: true,
        }
    ) {
        this.suppressEvents = true;
        const { initialValue, options, disabled, placeholder, value, onChange } = config;

        // Choices typings are wrong so asserting to our own interface here
        let currentValue = (this.choices.getValue() as unknown) as Choice;

        if (options !== prevConfig.options) {
            this.choices.removeActiveItems(0);
            this.choices.setChoices(options as SelectOptionLeaf[], 'value', 'label', true);
        }
        if (!disabled) {
            this.choices.enable();
        }
        if (disabled) {
            this.choices.disable();
        }
        // Sets the placeholder prop when the component is in un-controlled mode.
        if (
            (placeholder && currentValue === undefined) ||
            (currentValue && currentValue.value === placeholder)
        ) {
            this.showPlaceholder();
        }
        // We want to respect the 'value' prop unless it is `undefined`.
        if (value !== undefined) {
            await this.updateValue();

            // Sets the placeholder prop when the component is in controlled mode.
            if (placeholder && value === null) {
                this.showPlaceholder();
            }
        }
        if (initialValue != null) {
            this.choices.setChoiceByValue(initialValue);

            // If a developer tries to set the initialValue as something that is not valid.
            // We want to alert them that nothing was set.
            if (onChange && !currentValue) {
                this.suppressEvents = false;
                this.onOptionRemoved();
                this.suppressEvents = true;
            }
        }
        this.suppressEvents = false;
    }

    /**
     * Used to update the value of the component when the value is in "controlled" mode (i.e. the
     * parent component is controlling the selected value.
     */
    private async updateValue() {
        const { value } = this.props;
        const currentValue = this.choices.getValue();

        // If the 'value' is being controlled by the parent component, we need to reset the value
        // here. The parent component will provide a new 'value' prop to update the Select component later
        if (value !== undefined) {
            this.choices.removeActiveItems(0);
            this.choices.setChoiceByValue(value!);

            // If a developer tries to set a value that does not exist, we must notify them that the value was "reset" to null
            if (value && value !== currentValue) {
                this.suppressEvents = false;
                this.onOptionRemoved();
                this.suppressEvents = true;
            }
        }
    }

    /**
     * Displays (Shows) the placeholder provided by the developer.
     */
    private showPlaceholder(): void {
        const { placeholder } = this.props;
        this.choices.setChoices(
            [
                {
                    selected: true,
                    placeholder: true,
                    label: placeholder || '',
                    value: placeholder,
                },
            ],
            'value',
            'label'
        );
    }

    /**
     * Adds provided event listeners to the select container
     * @param componentConfig - Properties from client react component
     */
    private attachEventListeners(): void {
        this.selectEl.addEventListener('search', event => this.onSearch(event), false);
        this.selectEl.addEventListener('addItem', event => this.onOptionSelected(event), false);
        this.selectEl.addEventListener('removeItem', event => this.onOptionRemoved(event), false);
        this.selectEl.addEventListener('showDropdown', event => this.onDropdownShow(event), false);
        this.selectEl.addEventListener('hideDropdown', event => this.onDropdownHide(event), false);

        // our Angular wrapper uses an EventEmitter called 'change' to emit the change, but parent components subscribing to it
        // could also receive the DOM event called 'change' which is emitted by Choices itself. We want to prevent developers from
        // receiving Choices' event
        this.selectEl.addEventListener(
            'change',
            event => {
                event.stopPropagation();
            },
            false
        );

        this.selectOuterContainer.addEventListener('click', () => this.choices.hideDropdown());
    }

    /**
     * Currently Choices.js does not fire a search event when the input is cleared.
     *    This function fixes that behavior. For more details please see
     *    https://github.com/jshjohnson/Choices/issues/630
     *
     * @param config Current config on the component
     */
    private fixSearchEvent(config: NormalizedSelectConfig) {
        if (!this.isSearchEventFixed && config.searchEnabled && !config.disabled) {
            const inputEl = nonNullableValueOrThrow(
                this.selectOuterContainer.querySelector<HTMLInputElement>(
                    'input.gs-uitk-select__input'
                ),
                `CommonSelectComponent.fixSearchEvent(): Unable to find the input element. ` +
                    `Please ensure the outermost el is assigned to "selectOuterContainer"`
            );

            // Choices.js currently does not fire an event when the input is
            // cleared. The fix below is a workaround so we fire the "search"
            // event when the input is cleared.
            inputEl.addEventListener('keyup', event => {
                const value = (event.target as HTMLInputElement).value;
                // No easy way to check for CTRL/CMD + X below. the check for CTRL/CMD should
                // be enough since we are also checking the value.
                if (
                    (event.which === KeyHelpers.keyCode.BACKSPACE ||
                        event.which === KeyHelpers.keyCode.DELETE ||
                        KeyHelpers.isCtrlKey(event.which)) &&
                    value === ''
                ) {
                    // `triggerEvent` is a choices specific function.
                    // Currently missing from the choices.js typings.
                    // Hence the cast to "any" below.
                    (this.choices.passedElement as any).triggerEvent('search', {
                        value,
                        resultCount: 0,
                    });
                }
            });
            this.isSearchEventFixed = true;
        }
    }

    /**
     * Reformats the 'search' event data and calls the Select components 'onSearch' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onSearch(event: any): void {
        event.stopPropagation();
        const { onSearch } = this.props;
        if (onSearch && !this.suppressEvents) {
            const searchEvent: SelectSearchEvent = {
                searchValue: event.detail.value,
                resultsCount: event.detail.resultCount,
            };
            onSearch(searchEvent);
        }
    }

    /**
     * Reformats the 'addItem' event data and calls the Select components 'onChange' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private async onOptionSelected(event: any) {
        event.stopPropagation();
        const { onChange, value, placeholder } = this.props;
        const choicesOption = (this.choices.getValue() as unknown) as ChoicesSelectedOption;
        const selectedValue = event.detail.value;

        // It looks like choices.js is firing an event when we remove an existing option to re-add a placeholder.
        // We are checking to make sure the value to give developers is not a placeholder being added back.
        if (!this.suppressEvents && selectedValue !== placeholder) {
            if (onChange) {
                const changeEvent: SelectChangeEvent = {
                    selectedOption: {
                        value: selectedValue,
                        label: event.detail.label,
                        ...(event.detail.customProperties && {
                            customProperties: event.detail.customProperties,
                        }),
                    },
                    selectedValue,
                };
                onChange(changeEvent);
            }
            if (choicesOption.value !== value && value !== undefined) {
                this.suppressEvents = true;
                await this.updateValue();
                this.suppressEvents = false;
            }
        }
    }

    /**
     * Reformats the 'removeItem' event data and calls the Select components 'onChange' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onOptionRemoved(event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        const { onChange } = this.props;
        if (onChange && !this.suppressEvents && this.choices.getValue() === undefined) {
            const changeEvent: SelectChangeEvent = {
                selectedOption: null,
                selectedValue: null,
            };
            onChange(changeEvent);
        }
    }

    /**
     * Calls the Select components 'onDropdownShow' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onDropdownShow(event: any): void {
        event.stopPropagation();
        const { onDropdownShow } = this.props;
        if (onDropdownShow && !this.suppressEvents) {
            onDropdownShow();
        }
    }

    /**
     * Calls the Select components 'onDropdownHide' callback.
     * @param event - Custom Event emitted from choices event.
     * @param componentConfig - config for client component.
     */
    private onDropdownHide(event: any): void {
        event.stopPropagation();
        const { onDropdownHide } = this.props;
        if (onDropdownHide && !this.suppressEvents) {
            onDropdownHide();
        }
    }
}
