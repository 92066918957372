import { ExpressionQuery } from '../interfaces/expression';
import { SimpleEventDispatcher } from '../libraries/event';
import { logger } from '../libraries/logger';
import { DraDataType, DraSortDirection, DraSortType, DraViewConfig } from './dra-view-config';

/**
 * Ideally this should replace the dummy-tooolkit-datasource but boltweb boys are injecting their own datasource and overriding ours
 * So we create it only after
 */
export class DraDatasource {
    public recordInserted: SimpleEventDispatcher<
        RecordInsertedEventArgs
    > = new SimpleEventDispatcher();
    public datasetChanged: SimpleEventDispatcher<{}> = new SimpleEventDispatcher();
    public recordUpdated: SimpleEventDispatcher<
        RecordUpdatedEventArgs
    > = new SimpleEventDispatcher();
    public recordDeleted: SimpleEventDispatcher<
        RecordDeletedEventArgs
    > = new SimpleEventDispatcher();
    private dataSet: Record[];
    private dataSize: number = 0;
    private effectiveHeaderIndices: number[] = [];

    constructor(public provider: DraProvider) {
        this.dataSet = [];
    }

    public connect(): Promise<void> {
        this.provider.on('view-config', viewConfig => {
            const config = viewConfig as DraViewConfig;
            logger.debug('Dra ViewConfig', viewConfig);
            this.effectiveHeaderIndices = this.provider.viewConfig.isDynamicSchemaEnabled
                ? this.provider.viewConfig.effectiveHeaderIndices
                : // tslint:disable-next-line:variable-name
                  this.provider.viewConfig.allFields.map((_x, idx) => idx);
            if (!config.isNewAPI) {
                throw new Error('Old DRA API not supported');
            }
        });

        // we listen for data messages on the bus
        this.provider.on('data', this.onDataFunc);

        return this.provider.connect();
    }

    public destroy() {
        this.provider.off('data', this.onDataFunc);
        this.recordInserted.destroy();
        this.datasetChanged.destroy();
        this.recordUpdated.destroy();
        this.recordDeleted.destroy();
    }

    public getData(): Record[] {
        return [...this.dataSet];
    }

    public getDataSize(): number {
        return this.dataSize;
    }

    /**
     * It allows to do the mapping from a DRA message to the viewConfig header
     * i.e. if effective header is [0, 2] and view config header is [column1,column2,column3]
     * when we receive ['yo',3] when know that column1='yo' and column3 = 3
     * @param effectiveHeaderIndices list of fields index that we want DRa to send i.e. visible columns
     */
    public setEffectiveHeaderIndices(effectiveHeaderIndices: number[]): void {
        if (this.provider.viewConfig.isDynamicSchemaEnabled) {
            this.effectiveHeaderIndices = [...effectiveHeaderIndices];
            this.provider.viewConfig.effectiveHeaderIndices = [...effectiveHeaderIndices];
            // DRA is going to resend the whole lot of data for the viewport
            this.dataSet.length = 0;
        }
    }

    private onDataFunc = (msg: any) => {
        this.processData(msg as DraDatum[]);
    };

    private processData(data: DraDatum[]) {
        if (!this.provider) {
            const msg = 'cannot process msg as draProvider is null';
            logger.error(msg);
            throw new Error(msg);
        }
        const draProvider = this.provider;
        data.forEach(datum => {
            const draOperation = datum[0];
            const indexOfTheRecord = datum[1];
            const datumArg: any[] | number = datum[2];
            switch (draOperation) {
                case DraDatumOperation.INSERT: {
                    // in the case of INSERT the arg is the records without the properties i.e. an array of values
                    const records = datumArg as any[][];
                    const newRecords: Record[] = [];
                    records.forEach((record, insertOffset) => {
                        if (
                            this.provider.viewConfig.isDynamicSchemaEnabled &&
                            record.length !== this.effectiveHeaderIndices.length
                        ) {
                            logger.error(
                                'Header of the data received has a different size than the expected one. Ignoring this DRA INSERT',
                                record,
                                this.effectiveHeaderIndices
                            );
                        } else {
                            const row = this.createRow(record, draProvider);
                            newRecords.push(row);
                            // we insert the created record at the right position
                            this.dataSet.splice(indexOfTheRecord + insertOffset, 0, row);
                        }
                    });
                    if (newRecords.length > 0) {
                        this.recordInserted.dispatchAsync({ recordList: newRecords });
                        this.datasetChanged.dispatchAsync({});
                    }
                    break;
                }
                case DraDatumOperation.UPDATE: {
                    // for effective header
                    if (this.dataSet.length > 0) {
                        // when update the arg if the [fieldNumber, newValue][]
                        const updates = datumArg as [number, any][];
                        const updatesFields = updates.map(update => ({
                            field:
                                draProvider.viewConfig.allFields[
                                    this.effectiveHeaderIndices[update[0]]
                                ],
                            value: update[1],
                        }));
                        const row = this.dataSet[indexOfTheRecord];

                        updatesFields.forEach(update => {
                            row[update.field] = update.value;
                        });
                        this.recordUpdated.dispatchAsync({
                            deltaUpdates: updatesFields,
                            record: row,
                        });
                        this.datasetChanged.dispatchAsync({});
                    }
                    break;
                }
                case DraDatumOperation.DELETE: {
                    const numberOfRecordToDelete = datumArg as number;
                    const deletedRecordList = this.dataSet.splice(
                        indexOfTheRecord,
                        numberOfRecordToDelete
                    );
                    this.recordDeleted.dispatchAsync({ recordList: deletedRecordList });
                    this.datasetChanged.dispatchAsync({});
                    break;
                }
            }
        });

        this.dataSize =
            this.dataSet.length > 0 ? this.dataSet[0][draProvider.viewConfig.numRowsField] : 0;
    }

    private createRow(rowArray: any[], draProvider: DraProvider): Record {
        const row: Record = {};

        this.effectiveHeaderIndices.forEach((x, idx) => {
            const value = rowArray[idx];
            const field = draProvider.viewConfig.allFields[x];
            row[field] = value;
        });

        return row;
    }
}

export enum DraPivotDimension {
    Vertical = 0,
    Horizontal = 1,
}

/**
 * Interface of the DraProvider from the JSI package
 */
export interface DraProvider {
    viewConfig: DraViewConfig;
    on: (eventType: string, callback: (msg: DraDatum[] | DraViewConfig) => void) => void;
    off: (eventType: string, callback: (msg: DraDatum[] | DraViewConfig) => void) => void;
    reconnect(): void;
    close(): void;
    connect(): Promise<void>;
    edit(updatedRows: DraEdit[]): void;
    subscribe(): void;
    setViewRange(firstRow: number, lastRow: number): void;
    closePath(draPivotDimension: DraPivotDimension, path: string[]): void;
    openPath(draPivotDimension: DraPivotDimension, path: string[]): void;
    pivotAndDepth(
        pivots: string[],
        draPivotDimension: DraPivotDimension,
        expansionDepth: number
    ): void;
    sort(sortModel: DraSortInfo[]): void;
    filter(filter: ExpressionQuery): void;
    setAggregationColumns(columns: string[], effectiveHeaderIndices: number[]): void;
    setSelection(row: Record, selection: DraSelectionOperation): void;
    setRangeSelection(begin: Record, end: Record): void;
    setExpansionDepth(depth: number): void;
}

export interface DashGridColumn {
    field: string;
    name?: string;
    groupingDelimiter?: string;
    type?: DraDataType;
    hidden?: boolean;
}

export interface ChartsColumn {
    field: string;
    name?: string;
    groupingDelimiter?: string;
    type?: DraDataType;
}

export type DraSortInfo = [string, DraSortDirection, DraSortType];

export interface Record {
    [name: string]: any;
}

/**
 * When you call DRASetup this is the object that is being returned
 */
export interface DraSetupReturn {
    datasource: DraDatasource;
}

/**
 * DRA message operation type
 */
export enum DraDatumOperation {
    INSERT = 'INSERT',
    DELETE = 'DELETE',
    UPDATE = 'UPDATE',
}
export type DraDatum = [DraDatumOperation, number, any];

export enum DraSelectionOperation {
    REPLACE = 'REPLACE',
    APPEND = 'APPEND',
    RANGE = 'RANGE',
    CLEAR = 'CLEAR',
}

export interface DraOptions {
    checkboxSelection?: boolean;
    hideLeafCheckbox?: boolean;
    showRowGroupLeafName?: boolean;
    enableRowGroupColumnSorting?: boolean;
    treeColInnerRenderer?: (params: any) => HTMLElement | string;
}

export interface RecordInsertedEventArgs {
    recordList: Record[];
}
export interface RecordUpdatedEventArgs {
    record: Record;
    deltaUpdates: { field: string; value: any }[];
}
export interface RecordDeletedEventArgs {
    recordList: Record;
}

export interface DraEdit {
    row: { [name: string]: any };
    updates: { [name: string]: any };
}
