import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { CustomInputProps } from '../customTypes';
import classnames from 'classnames';

export interface CustomInputState {
    filesSelected: string;
}

class CustomInput extends React.Component<CustomInputProps, CustomInputState> {
    public static propTypes: { [key in keyof CustomInputProps]: any } = {
        type: PropTypes.string,
    };

    constructor(props: CustomInputProps) {
        super(props);
        this.state = {
            filesSelected: '',
        };
    }

    private fileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value.split('\\').pop();
        if (e.target.files) {
            if (e.target.files.length === 1) {
                inputValue = e.target.files[0].name;
            } else {
                inputValue = `${e.target.files.length} files selected`;
            }
        }
        this.setState({ filesSelected: inputValue || '' });
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    public render() {
        const { type, className, warning, awaiting, ...other } = this.props;
        if (type === 'file') {
            const fileClasses = classnames(className, 'custom-file-input', {
                ['is-warning']: warning,
                ['is-loading']: awaiting,
            });
            return (
                <div className="custom-file">
                    <label className="custom-file-label">
                        <Input
                            type="file"
                            {...other}
                            onChange={this.fileSelected}
                            className={fileClasses}
                        />
                        <span className="custom-file-control">{this.state.filesSelected}</span>
                    </label>
                </div>
            );
        }
        const classes = classnames(className, {
            ['is-warning']: warning,
            ['is-loading']: awaiting,
        });
        return <Input {...other} className={classes} type={type} />;
    }
}

export { CustomInput as Input };
