import { setAttributes } from '@gs-ux-uitoolkit-common/shared';
// @ts-ignore: needed because api-extractor doesn't support dynamic imports when parsing .d.ts files
import { Choices } from 'choices.js';

import { ChoicesConfig, ClassNames } from './select';
import { Choice, Item } from './options/choices-js-interface';
import {
    ChoicesSelectedOption,
    SelectOptionGroup,
    SelectCommonConfig,
    SelectOptionLeaf,
    OptionRendererData,
} from './options';

/**
 * Returns an object that maps common components config to choicesJS config options
 * @param componentConfig - Properties from client's react component
 */
export const generateCommonChoicesConfig = (componentConfig: SelectCommonConfig): ChoicesConfig => {
    let config: ChoicesConfig = {};

    config.removeItemButton = true;
    // never sort the items in the dropdown - that's on the developer to provide the ordering.
    config.shouldSort = false;
    config.searchResultLimit = 1000;
    config.fuseOptions = {
        // We want to keep the threshold at 0.3 here for now. We decided on 0.3 because
        // it is the value that most closely matches a 'contains' statement.
        threshold: 0.3,
    };
    config.noChoicesText = 'No options to choose from';

    if (componentConfig.noResultsContent !== undefined) {
        config.noResultsText = componentConfig.noResultsContent;
    } else {
        config.noResultsText = 'No options found'; // set the default
    }

    if (componentConfig.options) {
        (config as any).choices = componentConfig.options;
        // Handle nested options for option groups
        componentConfig.options.forEach((option, i) => {
            if ((option as SelectOptionGroup).options) {
                (config.choices![i] as any).choices = (option as SelectOptionGroup).options;
            }
        });
    }
    if (componentConfig.removeButton !== undefined) {
        config.removeItemButton = componentConfig.removeButton;
    }
    if (componentConfig.noOptionsContent !== undefined) {
        config.noChoicesText = componentConfig.noOptionsContent;
    }

    const filterOptionsOnSearch = componentConfig.filterOptionsOnSearch;
    if (filterOptionsOnSearch === false) {
        config.searchChoices = filterOptionsOnSearch as boolean;
    } else if (typeof filterOptionsOnSearch === 'object') {
        if (filterOptionsOnSearch.minChars) {
            config.searchFloor = filterOptionsOnSearch.minChars;
        }
        if (filterOptionsOnSearch.maxSearchResults) {
            config.searchResultLimit = filterOptionsOnSearch.maxSearchResults;
        }
    }
    if (componentConfig.placeholder) {
        config.placeholderValue = componentConfig.placeholder;
        config.searchPlaceholderValue = componentConfig.placeholder;
    }
    if (componentConfig.selectedOptionRenderer || componentConfig.optionRenderer) {
        config.callbackOnCreateTemplates = optionRenderer({
            optionRenderer: componentConfig.optionRenderer,
            selectedOptionRenderer: componentConfig.selectedOptionRenderer,
        });
    }

    return config;
};

/**
 * Formats choices.js selectedOptions into our selected options format (ie. {@link SelectOptionLeaf})
 */
export const getSelectedOptions = (
    selectedOptions: ChoicesSelectedOption[]
): SelectOptionLeaf[] => {
    return selectedOptions.map((option: ChoicesSelectedOption) => {
        return {
            label: option.label,
            value: option.value,
            ...(option.customProperties && { customProperties: option.customProperties }),
        };
    });
};

/**
 * Retrieves the currently selected values and returns them in the change event format
 */
export const getSelectedValues = (selectedOptions: ChoicesSelectedOption[]): string[] => {
    return selectedOptions.map((option: ChoicesSelectedOption) => {
        return option.value;
    });
};

/**
 * Used to create the callback choices.js uses to for {@link callbackOnCreateTemplates}.
 * Note: for now we are not letting users modify the attributes on the option <div />.
 *
 * This can be used to handle the simple use case of customizing the content in between the div.
 *
 * @param optionRenderer - The callback containing the custom HTML for the options
 * @param selectedOptionRenderer - The callback containing the custom HTML for the selected options
 */
function optionRenderer({
    optionRenderer,
    selectedOptionRenderer,
}: {
    optionRenderer?: (data: OptionRendererData) => string | HTMLElement;
    selectedOptionRenderer?: (data: OptionRendererData) => string | HTMLElement;
}) {
    return function() {
        return {
            ...(optionRenderer && {
                choice: (classNames: ClassNames, data: any) => {
                    const rendererResult = optionRenderer(createOptionRendererData(data));
                    // Check if the renderer is a string. If it is we must first convert it to an
                    // HTML element and then pass it to choices.js
                    if (typeof rendererResult === 'string') {
                        return convertStringToOptionElement(
                            data,
                            classNames,
                            // If the option is a placeholder just use the label.
                            data.placeholder ? data.label : rendererResult
                        );
                    } else {
                        const div = document.createElement('div');
                        const attributes = getOptionAttributes(data, classNames);
                        setAttributes(div, attributes);
                        div.appendChild(rendererResult);
                        return div;
                    }
                },
            }),
            ...(selectedOptionRenderer && {
                item: (classNames: ClassNames, data: any) => {
                    const rendererResult = selectedOptionRenderer(createOptionRendererData(data));
                    // Check if the renderer is a string. If it is we must first convert it to an
                    // HTML element and then pass it to choices.js
                    if (typeof rendererResult === 'string') {
                        return convertStringToSelectedOptionElement(
                            data,
                            classNames,
                            // If the option is a placeholder just use the label.
                            data.placeholder ? data.label : rendererResult
                        );
                    } else {
                        const div = document.createElement('div');
                        const attributes = getSelectedOptionAttributes(data, classNames);
                        setAttributes(div, attributes);
                        div.appendChild(rendererResult);
                        return div;
                    }
                },
            }),
        };
    };
}

/**
 * Filters the standard choices.js data object for relevant properties.
 * @param dataAttributes The original choices.js object. (ie the object to filter on)
 */
export function createOptionRendererData(dataAttributes: Choice): OptionRendererData {
    const allowed = ['disabled', 'label', 'selected', 'value', 'customProperties'];
    const filtered = Object.keys(dataAttributes)
        .filter(key => allowed.includes(key))
        .reduce(
            (obj, key) => {
                (obj as any)[key] = (dataAttributes as any)[key];
                return obj;
            },
            {} as OptionRendererData
        );
    return filtered;
}

/**
 * Function used to re-use the html template of the dropdown options.
 *
 * @param dataAttributes The original choices.js data object
 *    (contains all of the relevant details of each option).
 * @param classNames The choices.js style classes
 *    (contains the appropriate classes that choices.js should assign).
 * @param renderedHtml The custom HTML renderer to render.
 */
function convertStringToOptionElement(
    dataAttributes: Choice,
    classNames: ClassNames,
    renderedHtml: string
) {
    const div = document.createElement('div');
    setAttributes(div, getOptionAttributes(dataAttributes, classNames));
    div.innerHTML = renderedHtml;
    return div;
}

/**
 * Function used to re-use the html template of the selectedOptions.
 *
 * @param dataAttributes The original choices.js data object
 *    (contains all of the relevant details of each option).
 * @param classNames The choices.js style classes
 *    (contains the appropriate classes that choices.js should assign).
 * @param renderedHtml The custom HTML renderer to render.
 */
function convertStringToSelectedOptionElement(
    dataAttributes: Choice,
    classNames: ClassNames,
    renderedHtml: string
) {
    const div = document.createElement('div');
    setAttributes(div, getSelectedOptionAttributes(dataAttributes, classNames));
    div.innerHTML = renderedHtml;
    return div;
}

/**
 * Returns an object with the appropriate attributes an element needs to be considered a {@link SelectOptionLeaf}
 * @param dataAttributes The original choices.js data object (contains all of the relevant
 *    details of each option).
 * @param classNames The choices.js style classes (contains the appropriate classes that
 *    choices.js should assign).
 */
export function getOptionAttributes(dataAttributes: Choice, classNames: ClassNames) {
    return {
        'data-choice': '',
        'data-id': dataAttributes.id,
        'data-value': dataAttributes.value,
        ...(dataAttributes.disabled
            ? { 'data-choice-disabled': '', 'aria-disabled': 'true' }
            : { 'data-choice-selectable': '' }),
        class: classNames
            ? `${classNames.item} ` +
              `${classNames.itemChoice} ` +
              `${dataAttributes.placeholder ? classNames.placeholder : ''} ` +
              `${dataAttributes.disabled ? classNames.itemDisabled : classNames.itemSelectable} `
            : '',
    };
}

/**
 * Returns an object with the appropriate attributes that an element needs to be considered a {@link selectedOption}
 * @param dataAttributes The original choices.js data object (contains all of the relevant
 *    details of each option).
 * @param classNames The choices.js style classes (contains the appropriate classes that
 *    choices.js should assign).
 */
export function getSelectedOptionAttributes(dataAttributes: Item, classNames: ClassNames) {
    return {
        'data-item': '',
        'data-id': dataAttributes.id,
        'data-value': dataAttributes.value,
        ...(dataAttributes.disabled ? { 'aria-disabled': 'true' } : ''),
        ...(dataAttributes.active ? { 'aria-selected': 'true' } : ''),
        class: classNames
            ? `${classNames.item} ` +
              `${
                  dataAttributes.highlighted
                      ? classNames.highlightedState
                      : classNames.itemSelectable
              } ` +
              `${dataAttributes.placeholder ? classNames.placeholder : ''}`
            : '',
    };
}
