import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { Label } from './Label';

export interface ToggleSwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
    disabled?: boolean;
    checked?: boolean;
    defaultChecked?: boolean;
    invalid?: boolean;
    name?: string;
    onChange?: (e: React.ChangeEvent) => void;
    children?: React.ReactNode | React.ReactNode[];
}

// With custom form controls, input elements cannot be nested inside labels, so
// we must link labels to inputs by id.
let uidIndex = 0;
function getUid() {
    uidIndex += 1;
    return `gs-uitk-toggle-switch-${uidIndex}`;
}

export const ToggleSwitch: React.FunctionComponent<ToggleSwitchProps> = ({
    children,
    className,
    invalid,
    ...other
}) => {
    const uid = getUid();
    const props = {
        ...other,
        id: uid,
    };
    const inputClasses = cx(className || '', 'gs-uitk-toggle-switch-input', {
        'is-invalid': invalid,
    });
    return (
        <div className="gs-uitk-toggle-switch">
            <input type="checkbox" className={inputClasses} {...props} />
            <Label className="gs-uitk-toggle-switch-label" for={uid}>
                {children}
            </Label>
        </div>
    );
};

ToggleSwitch.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
};
