export interface HeaderFeatures {
    brand: {
        hasAppIcon: boolean;
        hasAppName: boolean;
        hasCallback: boolean;
        envBadge: {
            hasName: boolean;
            hasCustomColor: boolean;
        };
    };
    navigation: {
        appMenu: {
            enable: boolean;
            hasIcon: boolean;
            applicationsLength: number;
        };
        tabsLength: number;
        menuLength: number;
        hasTabSelected: boolean;
        hasMenuSelected: boolean;
        hasOpenSubmenusOnHover: boolean;
        openSubmenusOnHover: boolean;
        hasAutoSelectMenuItem: boolean;
        autoSelectMenuItem: boolean;
        CTAButton: {
            hasButtonText: boolean;
            hasCallback: boolean;
            hasIconName: boolean;
        };
    };
    search: {
        hasPlaceholder: boolean;
        hasCallback: boolean;
        fullExpansion: boolean;
    };
    utilities: {
        notification: {
            hasBadge: boolean;
            listLength: number;
            callbacks: {
                hasAction: boolean;
                hasDismiss: boolean;
                hasDismissCategory: boolean;
            };
        };
        profile: {
            identity: {
                hasPrimaryInfo: boolean;
                hasSecondaryInfo: boolean;
                hasImageUrl: boolean;
                personMenuIcon: boolean;
            };
            contact: {
                email: {
                    hasIcon: boolean;
                    hasText: boolean;
                    hasCallback: boolean;
                };
                phone: {
                    hasIcon: boolean;
                    hasText: boolean;
                    hasCallback: boolean;
                };
                im: {
                    hasIcon: boolean;
                    hasText: boolean;
                    hasCallback: boolean;
                };
            };
            linksLength: number;
            action: {
                hasColor: boolean;
                hasText: boolean;
                hasCallback: boolean;
            };
        };
        help: {
            linksLength: number;
            action: {
                hasColor: boolean;
                hasText: boolean;
                hasCallback: boolean;
            };
        };
        settings: {
            listLength: number;
            hasCallback: boolean;
        };
    };
    responsiveView: string;
    compact: boolean;
    compactOnScroll: boolean;
    stickToTop: boolean;
    width: {
        setMaxWidth: boolean;
        maxWidth: string;
    };
}

const normalizeFeatures = (properties: any): HeaderFeatures => ({
    brand: {
        hasAppIcon: properties.brand && !!properties.brand.appIcon,
        hasAppName: properties.brand && !!properties.brand.appName,
        hasCallback: properties.brand && !!properties.brand.callback,
        envBadge: {
            hasName:
                properties.brand && properties.brand.envBadge && !!properties.brand.envBadge.name,
            hasCustomColor:
                properties.brand &&
                properties.brand.envBadge &&
                !!properties.brand.envBadge.customColor,
        },
    },
    navigation: {
        appMenu: {
            enable:
                properties.navigation &&
                properties.navigation.appMenu &&
                !!properties.navigation.appMenu.enable,
            hasIcon:
                properties.navigation &&
                properties.navigation.appMenu &&
                !!properties.navigation.appMenu.icon,
            applicationsLength:
                properties.navigation &&
                properties.navigation.appMenu &&
                properties.navigation.appMenu.applications &&
                properties.navigation.appMenu.applications.length,
        },
        tabsLength:
            properties.navigation &&
            properties.navigation.tabs &&
            properties.navigation.tabs.length,
        menuLength:
            properties.navigation &&
            properties.navigation.menu &&
            properties.navigation.menu.length,
        hasTabSelected: properties.navigation && !!properties.navigation.tabSelected,
        hasMenuSelected: properties.navigation && !!properties.navigation.menuSelected,
        hasOpenSubmenusOnHover:
            properties.navigation && properties.navigation.openSubmenusOnHover !== undefined,
        openSubmenusOnHover: properties.navigation && properties.navigation.openSubmenusOnHover,
        hasAutoSelectMenuItem:
            properties.navigation && properties.navigation.autoSelectMenuItem !== undefined,
        autoSelectMenuItem: properties.navigation && properties.navigation.autoSelectMenuItem,
        CTAButton: {
            hasButtonText:
                properties.navigation &&
                properties.navigation.CTAButton &&
                !!properties.navigation.CTAButton.buttonText,
            hasCallback:
                properties.navigation &&
                properties.navigation.CTAButton &&
                !!properties.navigation.CTAButton.callback,
            hasIconName:
                properties.navigation &&
                properties.navigation.CTAButton &&
                !!properties.navigation.CTAButton.iconName,
        },
    },
    search: {
        hasPlaceholder: properties.search && !!properties.search.placeholder,
        hasCallback: properties.search && !!properties.search.callback,
        fullExpansion: properties.search && properties.search.fullExpansion,
    },
    utilities: {
        notification: {
            hasBadge:
                properties.utilities &&
                properties.utilities.notification &&
                !!properties.utilities.notification.badge,
            listLength:
                properties.utilities &&
                properties.utilities.notification &&
                properties.utilities.notification.list &&
                properties.utilities.notification.list.length,
            callbacks: {
                hasAction:
                    properties.utilities &&
                    properties.utilities.notification &&
                    properties.utilities.notification.callbacks &&
                    !!properties.utilities.notification.callbacks.action,
                hasDismiss:
                    properties.utilities &&
                    properties.utilities.notification &&
                    properties.utilities.notification.callbacks &&
                    !!properties.utilities.notification.callbacks.dismiss,
                hasDismissCategory:
                    properties.utilities &&
                    properties.utilities.notification &&
                    properties.utilities.notification.callbacks &&
                    !!properties.utilities.notification.callbacks.dismissCategory,
            },
        },
        profile: {
            identity: {
                hasPrimaryInfo:
                    properties.utilities &&
                    properties.utilities.profile &&
                    properties.utilities.profile.identity &&
                    !!properties.utilities.profile.identity.primaryInfo,
                hasSecondaryInfo:
                    properties.utilities &&
                    properties.utilities.profile &&
                    properties.utilities.profile.identity &&
                    !!properties.utilities.profile.identity.secondaryInfo,
                hasImageUrl:
                    properties.utilities &&
                    properties.utilities.profile &&
                    properties.utilities.profile.identity &&
                    !!properties.utilities.profile.identity.imageUrl,
                personMenuIcon:
                    properties.utilities &&
                    properties.utilities.profile &&
                    properties.utilities.profile.identity &&
                    !!properties.utilities.profile.identity.personMenuIcon,
            },
            contact: {
                email: {
                    hasIcon:
                        properties.utilities &&
                        properties.utilities.profile &&
                        properties.utilities.profile.contact &&
                        properties.utilities.profile.contact.email &&
                        !!properties.utilities.profile.contact.email.icon,
                    hasText:
                        properties.utilities &&
                        properties.utilities.profile &&
                        properties.utilities.profile.contact &&
                        properties.utilities.profile.contact.email &&
                        !!properties.utilities.profile.contact.email.text,
                    hasCallback:
                        properties.utilities &&
                        properties.utilities.profile &&
                        properties.utilities.profile.contact &&
                        properties.utilities.profile.contact.email &&
                        !!properties.utilities.profile.contact.email.callback,
                },
                phone: {
                    hasIcon:
                        properties.utilities &&
                        properties.utilities.profile &&
                        properties.utilities.profile.contact &&
                        properties.utilities.profile.contact.phone &&
                        !!properties.utilities.profile.contact.phone.icon,
                    hasText:
                        properties.utilities &&
                        properties.utilities.profile &&
                        properties.utilities.profile.contact &&
                        properties.utilities.profile.contact.phone &&
                        !!properties.utilities.profile.contact.phone.text,
                    hasCallback:
                        properties.utilities &&
                        properties.utilities.profile &&
                        properties.utilities.profile.contact &&
                        properties.utilities.profile.contact.phone &&
                        !!properties.utilities.profile.contact.phone.callback,
                },
                im: {
                    hasIcon:
                        properties.utilities &&
                        properties.utilities.profile &&
                        properties.utilities.profile.contact &&
                        properties.utilities.profile.contact.im &&
                        !!properties.utilities.profile.contact.im.icon,
                    hasText:
                        properties.utilities &&
                        properties.utilities.profile &&
                        properties.utilities.profile.contact &&
                        properties.utilities.profile.contact.im &&
                        !!properties.utilities.profile.contact.im.text,
                    hasCallback:
                        properties.utilities &&
                        properties.utilities.profile &&
                        properties.utilities.profile.contact &&
                        properties.utilities.profile.contact.im &&
                        !!properties.utilities.profile.contact.im.callback,
                },
            },
            linksLength:
                properties.utilities &&
                properties.utilities.profile &&
                properties.utilities.profile.links &&
                properties.utilities.profile.links.length,
            action: {
                hasColor:
                    properties.utilities &&
                    properties.utilities.profile &&
                    properties.utilities.profile.action &&
                    !!properties.utilities.profile.action.color,
                hasText:
                    properties.utilities &&
                    properties.utilities.profile &&
                    properties.utilities.profile.action &&
                    !!properties.utilities.profile.action.text,
                hasCallback:
                    properties.utilities &&
                    properties.utilities.profile &&
                    properties.utilities.profile.action &&
                    !!properties.utilities.profile.action.callback,
            },
        },
        help: {
            linksLength:
                properties.utilities &&
                properties.utilities.help &&
                properties.utilities.help.links &&
                properties.utilities.help.links.length,
            action: {
                hasColor:
                    properties.utilities &&
                    properties.utilities.help &&
                    properties.utilities.help.action &&
                    !!properties.utilities.help.action.color,
                hasText:
                    properties.utilities &&
                    properties.utilities.help &&
                    properties.utilities.help.action &&
                    !!properties.utilities.help.action.text,
                hasCallback:
                    properties.utilities &&
                    properties.utilities.help &&
                    properties.utilities.help.action &&
                    !!properties.utilities.help.action.callback,
            },
        },
        settings: {
            listLength:
                properties.utilities &&
                properties.utilities.settings &&
                properties.utilities.settings.list &&
                properties.utilities.settings.list.length,
            hasCallback:
                properties.utilities &&
                properties.utilities.settings &&
                !!properties.utilities.settings.callback,
        },
    },
    responsiveView: properties.responsiveView,
    compact: properties.compact,
    compactOnScroll: properties.compactOnScroll,
    stickToTop: properties.stickToTop,
    width: {
        setMaxWidth: properties.width && properties.width.setMaxWidth,
        maxWidth: properties.width && properties.width.maxWidth,
    },
});

export { normalizeFeatures };
