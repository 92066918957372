import * as React from 'react';
import * as PropTypes from 'prop-types';
import { TabPane, TabPaneProps } from 'reactstrap';

const TAB_TYPE_NAME: 'tab' = 'tab';

export interface TabProps extends TabPaneProps {
    tabType?: 'tab';
    iconName?: string;
    tabKey?: string | number;
    title?: string;
}

export function isTabProps(props: any): props is TabProps {
    return props && props.tabType === TAB_TYPE_NAME;
}

export const Tab: React.FunctionComponent<TabProps> = ({
    tabKey,
    children,
    iconName,
    tabType,
    title,
    ...other
}) => (
    <TabPane {...other} tabId={tabKey}>
        {children}
    </TabPane>
);

Tab.defaultProps = {
    tabType: TAB_TYPE_NAME,
};

Tab.propTypes = {
    children: PropTypes.node,
    iconName: PropTypes.string,
    tabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
} as { [key: string]: any };
