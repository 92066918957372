export interface ThemeItem {
    themeClassNames: string;
    headerHeight: number;
    rowHeight: number;
}

export enum Theme {
    Condensed = 'Condensed',
    Normal = 'Normal',
    UltraCondensed = 'Ultra-Condensed',
}

export const ThemeTypeClassName = {
    AG_GRID_THEME: 'ag-theme-balham',
    CONDENSED: 'ag-condensed',
    NORMAL: 'ag-normal',
    ROOT: 'gs-ux-uitoolkit-ag-grid',
    ULTRA_CONDENSED: 'ag-ultra-condensed',
};

export const ThemeTypeString = {
    Condensed: `${ThemeTypeClassName.ROOT} ${ThemeTypeClassName.CONDENSED}`,
    Normal: `${ThemeTypeClassName.ROOT} ${ThemeTypeClassName.NORMAL}`,
    UltraCondensed: `${ThemeTypeClassName.ROOT} ${ThemeTypeClassName.ULTRA_CONDENSED}`,
};

export const ThemeHeight = {
    condensedHeaderHeight: 20,
    condensedRowHeight: 20,
    normalHeaderHeight: 28,
    normalRowHeight: 28,
    ultraCondensedHeaderHeight: 16,
    ultraCondensedRowHeight: 16,
};

export const ThemeManager = {
    getTheme(theme: Theme): ThemeItem {
        switch (theme.toLowerCase()) {
            case Theme.Condensed.toLowerCase():
                return {
                    headerHeight: ThemeHeight.condensedHeaderHeight,
                    rowHeight: ThemeHeight.condensedRowHeight,
                    themeClassNames: ThemeTypeString.Condensed,
                };
            case Theme.UltraCondensed.toLowerCase():
                return {
                    headerHeight: ThemeHeight.ultraCondensedHeaderHeight,
                    rowHeight: ThemeHeight.ultraCondensedRowHeight,
                    themeClassNames: ThemeTypeString.UltraCondensed,
                };
            default:
                return {
                    headerHeight: ThemeHeight.normalHeaderHeight,
                    rowHeight: ThemeHeight.normalRowHeight,
                    themeClassNames: ThemeTypeString.Normal,
                };
        }
    },
};
