import { GridOptions, GridReadyEvent, ToolPanelDef } from '@ag-grid-community/all-modules';
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import {
    DataStackComponent,
    DataStackPlugin,
    DataType,
    DraDatasource,
    DraOptions,
    logger,
    Registries,
    RegistryContext,
    Theme,
    ThemeManager,
    ThemeTypeClassName,
    UIToolkitDeepPartial,
} from '@gs-ux-uitoolkit-common/datacore';
import {
    AgGridWrapper,
    checkagGridVersionCompatibility,
    ColumnApi,
    CustomDRAOptions,
    CustomLoadingCellRenderer,
    DataGridApi,
    DataGridState,
    Datasource,
    DatasourceConfiguration,
    DatasourceFactory,
    DatasourceType,
    DEFAULT_SWALLOW_FORMATTING_ERROR_CELL_VALUE,
    DraDisconnectedOverlay,
    DraViewportDatasource,
    ExportCallbacks,
    FirstDataRenderedEvent,
    GridApi,
    LoadingAgOverlay,
    normalizeFeatures,
    NoRowsOverlay,
    onDataGridSuppressKeyboardEventForDra,
    processCellForExport,
    SystemConfigurationSetGridWrapper,
    SystemConfigurationSetTheme,
    SuppressKeyboardEventParams,
} from '@gs-ux-uitoolkit-common/datagrid';
import { ErrorBoundary } from '@gs-ux-uitoolkit-react/core';
import * as _ from 'lodash';
import * as React from 'react';
import { LibUsage } from '../../analytics';

/**
 * The props of the AgGrid React Wrapper
 */
export interface DataGridProps extends AgGridReactProps, DataStackComponent<DataGridApi> {
    /**
     * The id of the grid
     */
    id: string;
    /**
     * Datasource Configuration
     */
    datasourceConfiguration?: DatasourceConfiguration;
    /**
     * Mapping from column field to type
     */
    columnDataTypeDefinition?: { [index: string]: DataType };
    /**
     * set a theme
     */
    theme?: Theme;
    /**
     * Callbacks available to customize exporting.
     */
    exportCallbacks?: ExportCallbacks;
    /**
     * Initial grid configuration
     */
    initialGridState?: UIToolkitDeepPartial<DataGridState>;
    /**
     * DRA specific options
     */
    draOptions?: DraOptions;
    /**
     * If enabled it will swallow formatters error and log an error message
     * It's effective only after initial render and has been built especially for DRA but can be used anytime
     * The cell value will be the swallowFormattingErrorCellValue
     */
    swallowFormattingError?: boolean;
    /**
     * The value of the cell when a formatter throws an error
     */
    swallowFormattingErrorCellValue?: string;
    /**
     * If set then our custom filtering is not enabled.
     */
    useNativeGridFiltering?: boolean;
}

export interface DataGridInternalState {
    api: GridApi | null;
    columnApi: ColumnApi | null;
    setRegistriesAndStore: ((registries: Registries, plugins: DataStackPlugin[]) => void) | null;
    hasError: boolean;
    gridWrapper: AgGridWrapper | null;
    firstAgGridOptions: GridOptions;
}
/**
 * The React wrapper that houses the AgGrid Wrapper
 * @param props The props of the wrapper and the underlying agGrid
 */
export class DataGridInternal extends React.Component<DataGridProps, DataGridInternalState> {
    public static defaultProps: Partial<DataGridProps> = {
        swallowFormattingErrorCellValue: DEFAULT_SWALLOW_FORMATTING_ERROR_CELL_VALUE,
        theme: Theme.Normal,
    };
    private isUnmounted = false;
    private theDatasource: Datasource | null = null;
    public constructor(props: DataGridProps) {
        super(props);
        this.state = {
            api: null,
            columnApi: null,
            firstAgGridOptions: this.props.gridOptions || {},
            gridWrapper: null,
            hasError: false,
            setRegistriesAndStore: null,
        };
        checkagGridVersionCompatibility();
    }

    public componentDidMount() {
        const features = normalizeFeatures(this.props);
        LibUsage.logComponentUsage('datagrid', features);
    }

    public async componentDidUpdate(prevProps: DataGridProps) {
        // we want to compare that only property values of the datasource config have changes

        const previousDatasourceConfigWithoutFixedDRAFilter = this.datasourceConfigWithoutFixedDraFilter(
            prevProps.datasourceConfiguration
        );
        const currentDatasourceConfigWithoutFixedDRAFilter = this.datasourceConfigWithoutFixedDraFilter(
            this.props.datasourceConfiguration
        );

        if (
            previousDatasourceConfigWithoutFixedDRAFilter &&
            currentDatasourceConfigWithoutFixedDRAFilter &&
            !_.isMatch(
                previousDatasourceConfigWithoutFixedDRAFilter,
                currentDatasourceConfigWithoutFixedDRAFilter
            )
        ) {
            logger.info(
                'New datasource configuration received. Creating a new gridWrapper',
                this.props.datasourceConfiguration
            );
            const customOptions: CustomDRAOptions = this.buildCustomOptions();
            const { draDatasource } = await this.createDataSource(customOptions);
            if (this.state.gridWrapper && this.props.datasourceConfiguration) {
                this.state.gridWrapper.reset(draDatasource, this.props.datasourceConfiguration);
            }
        } else if (
            // If only the fixedDraFilter has changed then we update the store and refresh the filtering
            this.state.gridWrapper &&
            prevProps.datasourceConfiguration &&
            this.props.datasourceConfiguration &&
            !_.isEqual(
                prevProps.datasourceConfiguration.fixedDRAFilter,
                this.props.datasourceConfiguration.fixedDRAFilter
            )
        ) {
            const gridWrapperConfig = this.state.gridWrapper.getReduxStore().getState()
                .systemConfiguration.gridWrapperConfiguration;
            const dsConfig = gridWrapperConfig.datasourceConfiguration;

            if (dsConfig) {
                const gridWrapperConfigDatsourceConfigClone = {
                    ...dsConfig,
                };
                gridWrapperConfigDatsourceConfigClone.fixedDRAFilter = this.props.datasourceConfiguration.fixedDRAFilter;

                const gridWrapperConfigClone = {
                    ...gridWrapperConfig,
                };
                gridWrapperConfigClone.datasourceConfiguration = gridWrapperConfigDatsourceConfigClone;
                this.state.gridWrapper
                    .getReduxStore()
                    .dispatch(SystemConfigurationSetGridWrapper(gridWrapperConfigClone));
            }
        }
        if (prevProps.id !== this.props.id && this.state.gridWrapper) {
            this.state.gridWrapper.setId(this.props.id);
        }
        if (
            prevProps.columnDataTypeDefinition &&
            this.props.columnDataTypeDefinition &&
            this.state.gridWrapper &&
            !_.isEqual(prevProps.columnDataTypeDefinition, this.props.columnDataTypeDefinition)
        ) {
            this.state.gridWrapper.setColumnDataTypeDefinition(this.props.columnDataTypeDefinition);
        }
    }

    public componentWillUnmount() {
        this.isUnmounted = true;
        if (this.state.gridWrapper) {
            this.state.gridWrapper.destroy();
        }
    }

    public render() {
        if (this.state.hasError) {
            throw new Error('Error while trying to render the grid');
        }
        const {
            id,
            datasourceConfiguration,
            gridOptions,
            initialGridState,
            rowClassRules,
            swallowFormattingError,
            swallowFormattingErrorCellValue,
            frameworkComponents,
            columnDefs,
            ...restProps
        } = this.props;

        /**
         * We use onGridReady for initialising the grid for DRA and URL datasources
         * as we won't have the data to create ColDefs
         * @param gridReadyEvent Ag grid event for when ready
         * @param setRegistriesAndStore
         */
        const onGridReady = async (
            gridReadyEvent: GridReadyEvent,
            setRegistriesAndStore?: (registries: Registries, plugins: DataStackPlugin[]) => void
        ) => {
            if (
                this.props.datasourceConfiguration &&
                (this.props.datasourceConfiguration.datasourceType === DatasourceType.DRA ||
                    this.props.datasourceConfiguration.url)
            ) {
                if (!gridReadyEvent.api) {
                    const msg = 'gridReadyEvent.api is null check ag-Grid configuration';
                    logger.error(msg);
                    throw new Error(msg);
                }

                if (!gridReadyEvent.columnApi) {
                    const msg = 'gridReadyEvent.columnApi is null check ag-Grid configuration';
                    logger.error(msg);
                    throw new Error(msg);
                }
                if (!this.isUnmounted) {
                    const sideBar = gridReadyEvent.api.getSideBar();
                    if (sideBar && sideBar.toolPanels) {
                        const columnsPanel = sideBar.toolPanels.find(x => {
                            if (x.hasOwnProperty('id')) {
                                const toolPanelDef = x as ToolPanelDef;
                                return toolPanelDef.id === 'columns';
                            }
                            return false;
                        });
                        if (columnsPanel) {
                            const columnsPanelDef = columnsPanel as ToolPanelDef;
                            if (!columnsPanelDef.toolPanelParams) {
                                columnsPanelDef.toolPanelParams = {
                                    // DRA doesn't support dynamic change of aggregation so hiding the panel
                                    suppressValues: true,
                                };
                            } else {
                                // DRA doesn't support dynamic change of aggregation so hiding the panel
                                columnsPanelDef.toolPanelParams.suppressValues = true;
                            }
                            gridReadyEvent.api.setSideBar(sideBar);
                        }
                    }
                    this.setState(
                        {
                            api: gridReadyEvent.api,
                            columnApi: gridReadyEvent.columnApi,
                            setRegistriesAndStore: setRegistriesAndStore || null,
                        },
                        async () => {
                            await this.instantiateNewGridWrapper();
                            callOnGridReadyProp(gridReadyEvent);
                        }
                    );
                } else {
                    callOnGridReadyProp(gridReadyEvent);
                }
            } else if (
                !datasourceConfiguration ||
                (datasourceConfiguration &&
                    datasourceConfiguration.datasourceType === DatasourceType.InMemory &&
                    !datasourceConfiguration.url)
            ) {
                /**
                 * We check the if there is data provided, if not then we set the registriesAndStore so that
                 * the toolbar can be populate with plugins. If there is data then this is handled in onFirstDataRendered
                 * so that the plugins can be applied when we have data and do things like autofit etc
                 */
                if (
                    !this.isUnmounted &&
                    (!this.props.rowData || (this.props.rowData && this.props.rowData.length === 0))
                ) {
                    this.setState(
                        {
                            api: gridReadyEvent.api,
                            columnApi: gridReadyEvent.columnApi,
                            setRegistriesAndStore: setRegistriesAndStore || null,
                        },
                        async () => {
                            await this.instantiateNewGridWrapper();
                            callOnGridReadyProp(gridReadyEvent);
                        }
                    );
                } else {
                    callOnGridReadyProp(gridReadyEvent);
                }
            } else {
                callOnGridReadyProp(gridReadyEvent);
            }
        };

        const callOnGridReadyProp = (gridReadyEvent: GridReadyEvent) => {
            if (typeof this.props.onGridReady === 'function') {
                this.props.onGridReady(gridReadyEvent);
            }
        };

        /**
         * We used onFirstDataRendered for InMemory sources (exc. URL) for initializing
         * the grid since we will have data to be able to create the columns
         * @param firstDataRenderedEvent
         * @param setRegistriesAndStore
         */
        const onFirstDataRendered = async (
            firstDataRenderedEvent: FirstDataRenderedEvent,
            setRegistriesAndStore?: (registries: Registries, plugins: DataStackPlugin[]) => void
        ) => {
            // tslint:disable-next-line: no-shadowed-variable
            const { datasourceConfiguration } = this.props;
            if (
                !datasourceConfiguration ||
                (datasourceConfiguration &&
                    datasourceConfiguration.datasourceType === DatasourceType.InMemory &&
                    !datasourceConfiguration.url)
            ) {
                if (!firstDataRenderedEvent.api) {
                    const msg = 'gridReadyEvent.api is null check ag-Grid configuration';
                    logger.error(msg);
                    throw new Error(msg);
                }
                if (!firstDataRenderedEvent.columnApi) {
                    const msg = 'gridReadyEvent.columnApi is null check ag-Grid configuration';
                    logger.error(msg);
                    throw new Error(msg);
                }
                /**
                 * This is so that the toolbar is populated with plugins if we have data. If there is no data then
                 * it is handled in onGridReady to setRegistriesAndStore
                 */
                if (!this.isUnmounted && this.props.rowData && this.props.rowData.length >= 0) {
                    this.setState(
                        {
                            api: firstDataRenderedEvent.api,
                            columnApi: firstDataRenderedEvent.columnApi,
                            setRegistriesAndStore: setRegistriesAndStore || null,
                        },
                        async () => {
                            callOnFirstDataRenderedProp(firstDataRenderedEvent);
                            await this.instantiateNewGridWrapper();
                        }
                    );
                } else {
                    callOnFirstDataRenderedProp(firstDataRenderedEvent);
                }
            } else {
                callOnFirstDataRenderedProp(firstDataRenderedEvent);
            }
        };

        const callOnFirstDataRenderedProp = (firstDataRenderedEvent: FirstDataRenderedEvent) => {
            if (typeof this.props.onFirstDataRendered === 'function') {
                this.props.onFirstDataRendered(firstDataRenderedEvent);
            }
        };

        const theme = this.getTheme();
        const themeConfig = ThemeManager.getTheme(theme);
        const newRowClassRules: { [cssClassName: string]: ((params: any) => boolean) | string } = {
            'ag-row-even': () => false,
            'ag-row-odd': () => false,
            ...rowClassRules,
        };

        const onDataGridSuppressKeyboardEvent = (params: SuppressKeyboardEventParams): boolean => {
            // We handle the Ctrl + A shortcut for DRA select all so that the selection gets handled on the back end
            // Consequently we need to suppress Ctrl + A in ag grid so that we can handle it ourselves
            if (
                this.props.datasourceConfiguration &&
                this.props.datasourceConfiguration.datasourceType === DatasourceType.DRA
            ) {
                const suppress = onDataGridSuppressKeyboardEventForDra(params);

                // Combine with the user supression too
                if (this.props.suppressKeyboardEvent) {
                    return suppress && this.props.suppressKeyboardEvent(params);
                }
                return suppress;
            }
            if (this.props.suppressKeyboardEvent) {
                return this.props.suppressKeyboardEvent(params);
            }
            return false;
        };

        return (
            <div
                className={themeConfig.themeClassNames}
                id={this.props.id}
                style={{
                    height: '100%',
                    position: 'relative',
                }}
            >
                <div
                    className={`${ThemeTypeClassName.AG_GRID_THEME}`}
                    style={{
                        height: '100%',
                    }}
                >
                    <RegistryContext.Consumer>
                        {context => (
                            <AgGridReact
                                // ColumnDefs for dra are handled by passing them down the the customDRAOptions, using the ag-grid api means that the rowGrouping isn't handled correctly
                                columnDefs={
                                    this.props.datasourceConfiguration &&
                                    this.props.datasourceConfiguration.datasourceType ===
                                        DatasourceType.DRA
                                        ? undefined
                                        : columnDefs
                                }
                                groupHeaderHeight={themeConfig.headerHeight}
                                headerHeight={themeConfig.headerHeight}
                                rowClassRules={newRowClassRules}
                                rowHeight={themeConfig.rowHeight}
                                rowModelType={
                                    this.props.datasourceConfiguration &&
                                    this.props.datasourceConfiguration.datasourceType ===
                                        DatasourceType.DRA
                                        ? 'viewport'
                                        : undefined
                                }
                                loadingCellRenderer={CustomLoadingCellRenderer}
                                loadingCellRendererParams={this.props.loadingCellRendererParams}
                                groupSuppressAutoColumn={
                                    this.props.datasourceConfiguration &&
                                    this.props.datasourceConfiguration.datasourceType ===
                                        DatasourceType.DRA
                                        ? true
                                        : undefined
                                }
                                // Workaround as groupSuppressAutoColumn doesn't work when pivot ON
                                // see https://ag-grid.zendesk.com/hc/en-us/requests/9496
                                // AG-3507 - Regression: groupSuppressAutoColumn has no effect in pivot mode
                                // this will unfortunatly log a warn ; backend.js:6 ag-grid: since version 18.2.x, 'groupSuppressRow' should not be used anymore. Instead remove row groups and perform custom sorting.
                                groupSuppressRow={
                                    this.props.datasourceConfiguration &&
                                    this.props.datasourceConfiguration.datasourceType ===
                                        DatasourceType.DRA
                                        ? true
                                        : undefined
                                }
                                suppressAggFuncInHeader={
                                    this.props.datasourceConfiguration &&
                                    this.props.datasourceConfiguration.datasourceType ===
                                        DatasourceType.DRA
                                        ? true
                                        : undefined
                                }
                                // if we use a datasource we know we are managing flat objects so we suppressFieldDotNotation
                                suppressFieldDotNotation={
                                    this.props.datasourceConfiguration ? true : undefined
                                }
                                // we return cell value except for rowGroupColumnColId where we return the TreeCol value
                                processCellForClipboard={paramsCell =>
                                    processCellForExport(
                                        paramsCell,
                                        null,
                                        null,
                                        [],
                                        this.props.processCellForClipboard
                                    )
                                }
                                modules={AllModules}
                                {...restProps}
                                gridOptions={
                                    this.props.gridOptions || this.state.firstAgGridOptions
                                }
                                onGridReady={event =>
                                    onGridReady(event, context.setRegistriesAndStore)
                                }
                                onFirstDataRendered={event =>
                                    onFirstDataRendered(event, context.setRegistriesAndStore)
                                }
                                frameworkComponents={{
                                    draDisconnectedOverlay: DraDisconnectedOverlay,
                                    loadingOverlay: LoadingAgOverlay,
                                    noRowsOverlay: NoRowsOverlay,
                                    ...frameworkComponents,
                                }}
                                loadingOverlayComponent={'loadingOverlay'}
                                noRowsOverlayComponent={
                                    this.props.datasourceConfiguration &&
                                    this.props.datasourceConfiguration.datasourceType ===
                                        DatasourceType.DRA
                                        ? 'draDisconnectedOverlay'
                                        : 'noRowsOverlay'
                                }
                                suppressKeyboardEvent={params =>
                                    onDataGridSuppressKeyboardEvent(params)
                                }
                            />
                        )}
                    </RegistryContext.Consumer>
                </div>
            </div>
        );
    }

    private datasourceConfigWithoutFixedDraFilter(
        datasourceConfig: DatasourceConfiguration | undefined
    ) {
        let datasourceConfigWithoutFixedDRAFilter: DatasourceConfiguration | undefined;
        if (datasourceConfig) {
            datasourceConfigWithoutFixedDRAFilter = { ...datasourceConfig };
            delete datasourceConfigWithoutFixedDRAFilter.fixedDRAFilter;
        }
        return datasourceConfigWithoutFixedDRAFilter;
    }

    private getTheme = (): Theme => (this.props.theme ? this.props.theme : Theme.Normal);

    private async instantiateNewGridWrapper() {
        if (!this.state.api) {
            const msg = 'api is null check ag-Grid configuration';
            logger.error(msg);
            throw new Error(msg);
        }
        if (!this.state.columnApi) {
            const msg = 'columnApi is null check ag-Grid configuration';
            logger.error(msg);
            throw new Error(msg);
        }
        const {
            columnDataTypeDefinition,
            initialGridState,
            swallowFormattingError,
            swallowFormattingErrorCellValue,
            exportCallbacks,
            useNativeGridFiltering,
        } = this.props;

        const customOptions = this.buildCustomOptions();

        const { draDatasource } = await this.createDataSource(customOptions);

        const wrapper = new AgGridWrapper(
            {
                swallowFormattingError,
                swallowFormattingErrorCellValue,
                useNativeGridFiltering,
                datasourceConfiguration: this.props.datasourceConfiguration,
                id: this.props.id,
            },
            this.state.firstAgGridOptions,
            this.state.api,
            this.state.columnApi,
            columnDataTypeDefinition,
            draDatasource,
            initialGridState || {},
            customOptions,
            exportCallbacks || {}
        );
        wrapper.getReduxStore().dispatch(SystemConfigurationSetTheme(this.getTheme()));

        // We provide the created registries to our component (Toolbar) that is using RegistryContext.Provider
        if (this.state.setRegistriesAndStore) {
            this.state.setRegistriesAndStore(
                {
                    actionsRegistry: wrapper.getActionsRegistry(),
                    screensRegistry: wrapper.getScreensRegistry(),
                    widgetsRegistry: wrapper.getWidgetsRegistry(),
                },
                wrapper.getPlugins()
            );
        }
        if (this.props.instanceCallback) {
            this.props.instanceCallback(new DataGridApi(wrapper));
        }
        this.setState({ gridWrapper: wrapper });
    }

    private buildCustomOptions(): CustomDRAOptions {
        const customOptions: CustomDRAOptions = {};
        if (
            this.props.datasourceConfiguration &&
            this.props.datasourceConfiguration.datasourceType === DatasourceType.DRA
        ) {
            const rowSelectionVal =
                this.props.rowSelection ||
                (this.props.gridOptions && this.props.gridOptions.rowSelection);
            if (rowSelectionVal) {
                customOptions.rowSelection = rowSelectionVal;
            }
            if (this.props.columnDefs) {
                customOptions.columnDefs = this.props.columnDefs;
            }
            if (this.props.draOptions) {
                customOptions.checkboxSelection = this.props.draOptions.checkboxSelection;
                customOptions.hideLeafCheckbox = this.props.draOptions.hideLeafCheckbox;
                customOptions.showRowGroupLeafName = this.props.draOptions.showRowGroupLeafName;
                customOptions.enableRowGroupColumnSorting = this.props.draOptions.enableRowGroupColumnSorting;
                customOptions.treeColInnerRenderer = this.props.draOptions.treeColInnerRenderer;
            }
            if (this.props.datasourceConfiguration.draFilterTransformer) {
                customOptions.draFilterTransformer = this.props.datasourceConfiguration.draFilterTransformer;
            }
        }
        return customOptions;
    }

    private async createDataSource(customOptions: CustomDRAOptions) {
        if (!this.state.api) {
            const msg = 'api is null check ag-Grid configuration';
            logger.error(msg);
            throw new Error(msg);
        }
        if (!this.state.columnApi) {
            const msg = 'columnApi is null check ag-Grid configuration';
            logger.error(msg);
            throw new Error(msg);
        }
        if (this.theDatasource) {
            this.theDatasource.destroy();
        }
        let theDatasource: Datasource | null = null;
        if (this.props.datasourceConfiguration) {
            theDatasource = DatasourceFactory.getDatasource(
                this.props.datasourceConfiguration,
                this.state.api,
                this.state.columnApi,
                customOptions
            );
            this.state.api.showLoadingOverlay();

            this.theDatasource = theDatasource;
            await theDatasource
                .connect(
                    this.props.rowData ||
                        (this.props.gridOptions ? this.props.gridOptions.rowData : null)
                )
                .catch(() => {
                    const msg = 'Cannot connect to the Datasource using the configuration';
                    logger.error(msg, this.props.datasourceConfiguration);
                    this.setState({ hasError: true });
                });
        }
        let draDatasource: DraDatasource | null = null;
        if (
            this.props.datasourceConfiguration &&
            this.props.datasourceConfiguration.datasourceType === DatasourceType.DRA &&
            theDatasource
        ) {
            const draViewportDatasource = theDatasource as DraViewportDatasource;
            draDatasource = draViewportDatasource.getDraDatasource();
        }
        return { draDatasource };
    }
}

/**
 * The DataGrid is a very thinly-wrapped ag-Grid with:
 * * all the native ag-Grid APIs exposed
 * * GS UI Toolkit Styling
 * * DRA integration
 * * all the functionality you had in Dash Grid ported over (e.g. column-masking,
 *    horizontal-pivoting, auto-fit, header-filter, etc)
 * * powerful UIs allowing the end-user to control the grid (via DataToolbar)
 */
export const DataGrid: React.SFC<DataGridProps> = (props: DataGridProps) => (
    <ErrorBoundary>
        <DataGridInternal {...props} />
    </ErrorBoundary>
);
