import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import SidePanelHeader from './SidePanelHeader';

export interface ControlledSidePanelProps {
    isOpen: boolean;
    togglePanel: (event: React.MouseEvent) => void;
    withMenu: boolean;
    position: 'left' | 'right';
    title?: string;
    header?: JSX.Element;
    className?: string;
}

const ControlledSidePanel: React.FunctionComponent<ControlledSidePanelProps> = ({
    title,
    header,
    position,
    children,
    withMenu,
    isOpen,
    className,
    togglePanel,
}) => {
    const sidePanelClassNames = cx(
        'side-panel',
        `position-${position}`,
        { collapsed: !isOpen },
        className
    );
    let enhancedChildren = null;
    if (withMenu && children) {
        if (Array.isArray(children) && children.length > 0) {
            const clonedChildren = children.slice() as React.ReactElement[];
            enhancedChildren = [
                React.cloneElement(clonedChildren[0], {
                    ...clonedChildren[0].props,
                    isOpen,
                    position,
                    key: 'SidePanelMenu',
                }),
            ];
            clonedChildren.shift(); // Remove child (SidePanelMenu) that we've already added
            enhancedChildren = enhancedChildren.concat(clonedChildren);
        } else {
            const child = children as React.ReactElement;
            enhancedChildren = React.cloneElement(child, {
                ...child.props,
                isOpen,
                position,
            });
        }
    } else {
        enhancedChildren = isOpen ? children : null;
    }
    return (
        <div className={sidePanelClassNames}>
            <SidePanelHeader
                title={title}
                header={header}
                togglePanel={togglePanel}
                isOpen={isOpen}
                position={position}
            />
            <div className="side-panel-content">{enhancedChildren}</div>
        </div>
    );
};

ControlledSidePanel.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    togglePanel: PropTypes.func.isRequired,
    withMenu: PropTypes.bool.isRequired,
    position: PropTypes.oneOf(['left', 'right']).isRequired as PropTypes.Validator<
        'left' | 'right'
    >,
    title: PropTypes.string,
    header: PropTypes.element,
    className: PropTypes.string,
};

export default ControlledSidePanel;
