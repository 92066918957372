import React from 'react';
import PropTypes from 'prop-types';
import LinkItem from '../../components/utilities/items/profile/modules/Item';

const Links: React.FunctionComponent<any> = ({ links, closePopover }) => {
    const linksComp = links.map(link => {
        const { id, href, icon, text, callback } = link;
        return (
            <LinkItem
                key={id}
                href={href}
                icon={icon}
                text={text}
                callback={callback}
                closePopover={closePopover}
            />
        );
    });

    return (
        <div className="module-content" data-cy="header.moduleContent">
            {linksComp}
        </div>
    );
};

Links.defaultProps = {
    links: [],
};

Links.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            href: PropTypes.string,
            icon: PropTypes.string,
            text: PropTypes.string,
            callback: PropTypes.func,
        })
    ),
    closePopover: PropTypes.func,
};

export default Links;
