import { HeaderUtilityName } from '@gs-ux-uitoolkit-common/header';
import React from 'react';
import NotificationContainer from './notification/NotificationContainer';
import Profile from './profile/Profile';
import Help from './help/Help';
import Settings from './settings/Settings';
import { Install } from './install/Install';

const items: UtilityItem[] = [
    { name: 'profile', iconName: 'user', component: <Profile /> },
    { name: 'notification', iconName: 'bell', component: <NotificationContainer /> },
    { name: 'install', iconName: 'plus-circle', component: <Install /> },
    { name: 'settings', iconName: 'cog', component: <Settings /> },
    { name: 'help', iconName: 'question-circle', component: <Help /> },
];

export interface UtilityItem {
    name: HeaderUtilityName;
    iconName: string;
    component: JSX.Element;
    badge?: any;
}

export { NotificationContainer, Profile, Help, items };
