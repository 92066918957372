import React from 'react';
import PropTypes from 'prop-types';
import Links from '../../../../shared/components/Links';
import { extendCallbackAction } from '../../../../shared/functions';
import HeaderButton from '../../../../shared/components/HeaderButton';

const Help: React.FunctionComponent<any> = ({ links = [], action, closePopover }) => {
    const buttonProps = {
        onClick: () => {
            extendCallbackAction(action.callback || (() => {}), closePopover);
        },
        color: 'primary',
    };
    const actionComp = action.text ? (
        <div className="module-content" data-cy="headerUtilities.moduleContent">
            <HeaderButton text={action.text} buttonProps={buttonProps} />
        </div>
    ) : null;
    return (
        <div className="help-container" data-cy="headerUtilities.helpPopoverContent">
            <Links closePopover={closePopover} links={links} />
            {actionComp}
        </div>
    );
};

Help.defaultProps = {
    links: [],
    action: {},
};

Help.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            href: PropTypes.string,
            icon: PropTypes.string,
            text: PropTypes.string,
            callback: PropTypes.func,
        })
    ).isRequired,
    action: PropTypes.shape({
        color: PropTypes.string,
        text: PropTypes.string,
        callback: PropTypes.func,
    }),
    closePopover: PropTypes.func,
};

export default Help;
