import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import { Button } from '@gs-ux-uitoolkit-react/core';
import Icon from './Icon';

const CustomButton: React.FunctionComponent<any> = ({
    buttonProps: { classes, ...otherProps },
    text,
    iconProps,
}) => {
    const btnClasses = cx('clickable', 'btn-sm', classes);
    const icon = !_.isEmpty(iconProps) ? <Icon {...iconProps} /> : null;
    return (
        <Button className={btnClasses} {...otherProps}>
            {icon}
            {text}
        </Button>
    );
};

CustomButton.defaultProps = {
    buttonProps: {
        classes: '',
    },
    text: '',
    iconProps: {},
};

CustomButton.propTypes = {
    text: PropTypes.string,
    buttonProps: PropTypes.shape({
        classes: PropTypes.string,
    }),
    iconProps: PropTypes.shape({
        iconName: PropTypes.string,
        classes: PropTypes.string,
        hoverClasses: PropTypes.string,
        size: PropTypes.oneOf(['', 'gi-lg', 'gi-2x', 'gi-3x', 'gi-4x', 'gi-5x']),
        onClick: PropTypes.func,
    }),
};

export default CustomButton;
