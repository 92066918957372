import * as Redux from 'redux';
import { DataStackPlugin, PluginAction, PluginScreen, PluginWidget } from '../interfaces/plugins';
import { logger } from '../libraries/logger';
import { Registry } from '../libraries/registry';

/**
 * The base class of the Plugins which will manage the lifecycle of the plugins and all the necessary registrations
 */

export interface ComponentWrapper {
    getScreensRegistry: () => Registry<PluginScreen>;
    getWidgetsRegistry: () => Registry<PluginWidget>;
    getActionsRegistry: () => Registry<PluginAction>;
    getReduxStore(): Redux.Store<any>;
}

export abstract class PluginBase<U extends ComponentWrapper, V, T = {}> implements DataStackPlugin {
    protected screens: PluginScreen[] = [];
    protected widgets: PluginWidget[] = [];
    protected actions: PluginAction[] = [];
    private pluginState: T | null = null;
    private previousPluginState: T | null = null;
    private storeSubscription: Redux.Unsubscribe | null = null;
    constructor(
        protected pluginName: string,
        protected category: string,
        protected icon: string,
        protected wrapper: U,
        protected stateNeeded: (state: V) => T
    ) {}

    public initialize() {
        logger.debug('Initializing plugin:' + this.pluginName);

        // register modals
        this.screens.forEach(screen =>
            this.wrapper.getScreensRegistry().register(screen.componentId, screen)
        );

        // register widgets
        this.widgets.forEach(widget =>
            this.wrapper.getWidgetsRegistry().register(widget.componentId, widget)
        );

        // register actions
        this.actions.forEach(action => {
            this.wrapper.getActionsRegistry().register(action.componentId, action);
        });
        logger.debug('Initialized plugin:' + this.pluginName);
    }

    public start() {
        logger.debug('Starting plugin:' + this.pluginName);
        let existingState: T;

        this.storeSubscription = this.wrapper.getReduxStore().subscribe(() => {
            existingState = this.stateNeeded(this.wrapper.getReduxStore().getState());
            if (existingState !== this.pluginState) {
                this.previousPluginState = this.pluginState;
                this.pluginState = existingState;
                this.stateChangedOrStart();
            }
        });
        existingState = this.stateNeeded(this.wrapper.getReduxStore().getState());
        this.pluginState = existingState;

        this.stateChangedOrStart();
        logger.debug('Started plugin:' + this.pluginName);
    }

    public stop() {
        logger.debug('Stopping plugin:' + this.pluginName);
        if (this.storeSubscription) {
            this.storeSubscription();
        }
        this.internalStop();
        logger.debug('Stopped plugin:' + this.pluginName);
    }

    public getName(): string {
        return this.pluginName;
    }
    public getIcon(): string {
        return this.icon;
    }
    public getScreens(): PluginScreen[] {
        return [...this.screens];
    }

    /**
     * This method gets called on startup and when the relevent part of the state changes as determined by stateNeeded
     */
    protected abstract stateChangedOrStart(): void;
    /**
     * This method gets called on Stopping the plugin
     */
    protected abstract internalStop(): void;

    protected getPluginState(): T {
        if (this.pluginState !== null) {
            return this.pluginState;
        }
        throw new Error(`$this.pluginName} state is null. Plugin should have a state.`);
    }

    protected getPreviousPluginState(): T | null {
        return this.previousPluginState;
    }
}
