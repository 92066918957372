import React from 'react';

export default (isToggled, step, handleClick, { id }) => {
    const handleClickWithVal = event => handleClick(event.target.value);
    return (
        <input
            className="input-number"
            key={`input-number-${id}`}
            step={step}
            type="number"
            onClick={handleClickWithVal}
        />
    );
};
