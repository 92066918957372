import { logWarningOnce } from '@gs-ux-uitoolkit-common/core';

function getDisplayTime(time: any) {
    return new Date(time)
        .toLocaleString('en', {
            weekday: 'short',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        })
        .toUpperCase();
}

function warnIfInstallEventNotCaptured() {
    logWarningOnce(
        "GS UI Toolkit Header: Your application doesn't seem to qualify as a PWA. " +
            'See here for more information: https://developers.google.com/web/progressive-web-apps/checklist.'
    );
}

export default { getDisplayTime, warnIfInstallEventNotCaptured };
