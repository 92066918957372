import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@gs-ux-uitoolkit-react/core';
import { ENV_COLORS, EnvBadge as EnvBadgeOptions } from '@gs-ux-uitoolkit-common/header';

export interface BrandProps extends EnvBadgeOptions {}

const EnvBadge: React.FunctionComponent<BrandProps> = ({ name, customColor }) => (
    <Badge color={customColor || ENV_COLORS[name] || 'light'} data-cy="header.envBadge">
        {name}
    </Badge>
);

EnvBadge.propTypes = {
    name: PropTypes.string.isRequired,
    customColor: PropTypes.string,
};

export default EnvBadge;
