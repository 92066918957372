import * as React from 'react';
import * as PropTypes from 'prop-types';
import ControlledTabs from './ControlledTabs';
import UncontrolledTabs from './UncontrolledTabs';

export interface TabsProps {
    /**
     * Determines if the tab is currently active or not.
     */
    activeTabKey?: string | number;

    /**
     * Determines if the tabs content should be underneath.
     * Set this to `false` to display the tabs content next to vertical tabs.
     */
    contentUnderneath?: boolean;

    /**
     * Determines if the tabs should only use an icon.
     * Set this to `true` to only display icons (no titles).
     */
    iconOnly?: boolean;

    /**
     * Callback used when a tab is selected.
     */
    onSelect?: (mode: any) => void;

    /**
     * Set this to true to enable responsive behavior with horizontal tabs.
     * Tabs will be displayed until the width reaches a breakpoint,
     * after which the remaining tabs will be shown in a dropdown.
     */
    responsive?: boolean;

    /**
     * Determines which mode the navbar should be in.
     */
    variant?: 'tabs' | 'pills';

    /**
     * Determines if the tabs should be in vertical mode.
     * Set this to true to orient the tabs vertically.
     */
    vertical?: boolean;

    /**
     * Determines if a vertical bar is displayed when {@link vertical} is `true`.
     */
    withVerticalBar?: boolean;
}

/**
 * Tabs organize related content on a page without requiring navigation to a new page.
 */
export const Tabs: React.FunctionComponent<TabsProps> = props => {
    if (typeof props.activeTabKey === 'undefined') {
        return <UncontrolledTabs {...props} />;
    }
    return <ControlledTabs {...(props as { activeTabKey: string | number })} />;
};

Tabs.propTypes = {
    activeTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Tabs.defaultProps = {
    // TODO: We need to figure out why setting the defaults below break the component.
    // activeTabKey: 1,
    contentUnderneath: true,
    iconOnly: false,
    // onSelect: undefined,
    variant: 'tabs',
    vertical: false,
    withVerticalBar: false,
};
