import React from 'react';
import { ToggleSwitch } from '@gs-ux-uitoolkit-react/core';

export default (isToggled, step, handleClick, { id }) => (
    <ToggleSwitch
        key={`switch-${id}`}
        name="toggle"
        defaultChecked={isToggled}
        onChange={handleClick}
    />
);
