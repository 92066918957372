import { HeaderNavigation, HeaderMenuItem } from '../options';
import { logWarningOnce } from '@gs-ux-uitoolkit-common/core';

/**
 * Given a HeaderNavigation object, retrieves the menu items from either the preferred `menuItems`
 * property, or the legacy/deprecated `menu` or `tabs` properties.
 *
 * If the legacy/deprecated properties are detected, a warning is emitted to the console.
 */
export function getMenuItems(navigation: HeaderNavigation): HeaderMenuItem[] {
    if (navigation.menuItems) {
        return navigation.menuItems;
    } else if (navigation.tabs) {
        warnOnOldMenuItemsApi('tabs');
        return navigation.tabs;
    } else if (navigation.menu) {
        warnOnOldMenuItemsApi('menu');
        return navigation.menu;
    } else {
        return [];
    }
}

function warnOnOldMenuItemsApi(propertyName: 'tabs' | 'menu') {
    logWarningOnce(
        `GS UI Toolkit Header: Use "menuItems" instead of "${propertyName}". Support for "${propertyName}" is deprecated in v10.`
    );
}
