import * as React from 'react';
import { Alert } from '../alert/Alert';
import { Collapse, Button } from 'reactstrap';

export interface ErrorBoundaryProps {
    message?: string;
}
export interface ErrorBoundaryState {
    showCollapse: boolean;
    error: Error | null;
}

/**
 * Follow react pattern so we show an alert when a component throws an exception when rendering
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: null, showCollapse: false };
    }

    componentDidCatch(error: Error): void {
        this.setState({ error });
    }

    render() {
        if (this.state.error) {
            return (
                <div>
                    <Alert status="error">
                        {this.props.message
                            ? this.props.message
                            : 'An error occured in this component'}
                        <Button
                            className="ml-1"
                            onClick={this.toggleCollapse}
                            data-cy="gs-uitk-error-boundary-toggle"
                        >
                            {this.state.showCollapse ? 'Hide' : 'Show'} Error
                        </Button>
                        <Collapse
                            isOpen={this.state.showCollapse}
                            data-cy="gs-uitk-error-boundary-collapse"
                        >
                            {this.state.error.message}
                            {this.state.error.stack ? this.state.error.stack : 'No stack trace'}
                        </Collapse>
                    </Alert>
                </div>
            );
        }
        return this.props.children;
    }
    toggleCollapse = (event: React.MouseEvent) => {
        event.stopPropagation();
        this.setState({
            showCollapse: !this.state.showCollapse,
        });
    };
}
