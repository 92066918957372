import React from 'react';
import Title from './Title';
import AlignableSetting from './AlignableSetting';
import SettingsButton from './SettingsButton';
import ButtonGroup from './ButtonGroup';
import RadioGroup from './RadioGroup';

const settingItems = {
    title: <Title />,
    toggle: <AlignableSetting id="alignableSetting" />,
    checkbox: <AlignableSetting id="alignableSetting" />,
    button: <SettingsButton id="settingsButton" />,
    'button-group': <ButtonGroup id="buttonGroup" />,
    'input-number': <AlignableSetting id="alignableSetting" />,
    'radio-buttons': <RadioGroup id="radioGroup" />,
};

export default settingItems;
