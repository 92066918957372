/**
 * Shallowly applies the properties given in `...sources` to the `obj`. Any
 * property that resolves to `undefined` (either missing the property, or the
 * value is `undefined`) will be overwritten by a property of the same name in
 * the `source` object(s).
 *
 * The properties in source objects are applied to the `obj` from left to
 * right.
 *
 * Example:
 *
 *     _.defaults({ 'a': 1 }, { 'b': 2 }, { 'a': 3 }, { 'b': 4 });
 *     // => { 'a': 1, 'b': 2 }
 */
export function defaults<TObject extends {}>(object: TObject): NonNullable<TObject>;
export function defaults<TObject extends {}, TSource extends {}>(
    object: TObject,
    source: TSource
): NonNullable<TSource & TObject>;
export function defaults<TObject extends {}, TSource1 extends {}, TSource2 extends {}>(
    object: TObject,
    source1: TSource1,
    source2: TSource2
): NonNullable<TSource2 & TSource1 & TObject>;
export function defaults<
    TObject extends {},
    TSource1 extends {},
    TSource2 extends {},
    TSource3 extends {}
>(
    object: TObject,
    source1: TSource1,
    source2: TSource2,
    source3: TSource3
): NonNullable<TSource3 & TSource2 & TSource1 & TObject>;
export function defaults<
    TObject extends {},
    TSource1 extends {},
    TSource2 extends {},
    TSource3 extends {},
    TSource4 extends {}
>(
    object: TObject,
    source1: TSource1,
    source2: TSource2,
    source3: TSource3,
    source4: TSource4
): NonNullable<TSource4 & TSource3 & TSource2 & TSource1 & TObject>;
export function defaults(object: {}, ...sources: {}[]): any {
    for (const source of sources) {
        for (const prop in source) {
            if (object[prop] === undefined) {
                object[prop] = source[prop];
            }
        }
    }
    return object;
}
