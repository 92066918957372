import {
    HeaderUtilityName,
    HeaderUtilities,
    allHeaderUtilityNames,
} from '../options/utilities/header-utilities';
import { assertNever } from '@gs-ux-uitoolkit-common/core';

/**
 * Given the HeaderUtilities, which size the device is, and if the 'Search' bar is present in the
 * header, returns the list of the utility names to put inside the Header itself (as opposed to
 * moving them to the hamburger menu).
 *
 * On mobile and tablet, we move either all or a subset of the "utilities" to the hamburger
 * menu. This function returns the utilities that should remain on the Header itself.
 *
 * On mobile: move all "utilities" to the hamburger menu
 * On tablet: move all "utilities" except the primary utility, and if 'search' is defined, then
 *   also move the secondary utility.
 */
export function getUtilitiesForHeader(
    utilities: HeaderUtilities,
    responsiveSize: 'mobile' | 'tablet' | 'desktop',
    searchIsPresentInHeader: boolean
): HeaderUtilityName[] {
    // Use the allHeaderUtilityNames array to make sure that the utilities end up in the same order
    // inside the Hamburger menu as they would on the header itself
    return allHeaderUtilityNames.filter(utilityName => {
        if (!(utilityName in utilities)) {
            return false; // don't include any utility that the user hasn't specified
        }

        return !utilityBelongsOnHamburgerMenu(
            utilities,
            utilityName,
            responsiveSize,
            searchIsPresentInHeader
        );
    });
}

/**
 * Given the HeaderUtilities, which size the device is, and if the 'Search' bar is present in the
 * header, returns the list of the utility names to put inside the hamburger menu.
 *
 * On mobile and tablet, we move either all or a subset of the "utilities" to the hamburger
 * menu.
 *
 * On mobile: move all "utilities" to the hamburger menu
 * On tablet: move all "utilities" except the primary utility, and if 'search' is defined, then
 *   also move the secondary utility.
 */
export function getUtilitiesForHamburgerMenu(
    utilities: HeaderUtilities,
    responsiveSize: 'mobile' | 'tablet' | 'desktop',
    searchIsPresentInHeader: boolean
): HeaderUtilityName[] {
    // Use the allHeaderUtilityNames array to make sure that the utilities end up in the same order
    // inside the Hamburger menu as they would on the header itself
    return allHeaderUtilityNames.filter(utilityName => {
        if (!(utilityName in utilities)) {
            return false; // don't include any utility that the user hasn't specified
        }

        return utilityBelongsOnHamburgerMenu(
            utilities,
            utilityName,
            responsiveSize,
            searchIsPresentInHeader
        );
    });
}

/**
 * Private helper function to determine if a given utility belongs on the hamburger menu. If the
 * function returns false, it means the utility belongs on the top header.
 */
function utilityBelongsOnHamburgerMenu(
    utilities: HeaderUtilities,
    utilityName: HeaderUtilityName,
    responsiveSize: 'mobile' | 'tablet' | 'desktop',
    searchIsPresentInHeader: boolean
): boolean {
    switch (responsiveSize) {
        case 'desktop':
            return false; // keep all utilities on the main Header on desktop

        case 'tablet':
            if (utilityName === utilities.primaryUtility) {
                return false; // primary utility stays on the header itself
            } else if (utilityName === utilities.secondaryUtility && !searchIsPresentInHeader) {
                return false; // secondary utility stays on the header itself *unless* the 'search' bar is present on the header, in which case we'll move it to the hamburger menu to make room for the search bar
            } else {
                return true; // move to the hamburger menu
            }

        case 'mobile':
            return true; // move all menu items to the hamburger menu

        default:
            return assertNever(responsiveSize);
    }
}
