import * as React from 'react';
import { DataStackPlugin } from '../interfaces/plugins';
import { Registries } from '../interfaces/registries';
import { getSingletonInstance } from '../libraries/singleton';

export interface RegistryAndStoreContextArgs {
    registries?: Registries;
    plugins?: DataStackPlugin[];
    setRegistriesAndStore?: (registries: Registries, plugins: DataStackPlugin[]) => void;
}

export const RegistryContext = getSingletonInstance('DataCore.RegistryContext', () =>
    React.createContext<RegistryAndStoreContextArgs>({})
);
