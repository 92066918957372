import React from 'react';
import PropTypes from 'prop-types';
import { resolveIconName } from '@gs-ux-uitoolkit-common/icons';

const Item: React.FunctionComponent<any> = ({ href, text, icon, callback, closePopover }) => {
    const extendCallbackAction = () => {
        closePopover();
        callback();
    };

    return (
        <a
            className="header-button clickable"
            {...(href ? { href: href, target: '_blank' } : '')}
            onClick={extendCallbackAction}
            data-cy="header.buttonLink"
        >
            <i
                className={`gi gi-fw gi-${icon} gsi-${resolveIconName(icon)} header-button-icon`}
                data-cy="header.buttonIcon"
            />
            {text}
        </a>
    );
};

Item.defaultProps = {
    href: '',
    icon: '',
    text: '',
    callback: () => {},
    closePopover: () => {},
};

Item.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
    callback: PropTypes.func,
    closePopover: PropTypes.func,
};

export default Item;
