import * as React from 'react';
import * as PropTypes from 'prop-types';

export interface ExpanderLeftProps {
    isMobile?: boolean;
    title?: string;
    className?: string;
    isExpanded?: boolean;
}

export interface ExpanderLeftState {
    isMobile: boolean;
    isExpanded: boolean;
}

export class ExpanderLeft extends React.Component<ExpanderLeftProps, ExpanderLeftState> {
    public static propTypes: { [key in keyof ExpanderLeftProps]: any } = {
        isMobile: PropTypes.bool,
        title: PropTypes.string,
        className: PropTypes.string,
    };

    constructor(props: ExpanderLeftProps) {
        super(props);
        this.state = {
            isMobile: false,
            isExpanded: typeof this.props.isExpanded === 'undefined' ? true : this.props.isExpanded,
        };
        this.toggle = this.toggle.bind(this);
    }

    componentWillReceiveProps(nextProps: ExpanderLeftProps) {
        if (this.state.isMobile === nextProps.isMobile) {
            return;
        }
        this.setState({
            isMobile: !!nextProps.isMobile,
            isExpanded: !nextProps.isMobile,
        });
    }

    toggle(e: React.MouseEvent<HTMLElement>) {
        this.setState({ isExpanded: !this.state.isExpanded });
        e.preventDefault();
    }

    render() {
        const { isMobile, title, className, children } = this.props;
        const { isExpanded } = this.state;
        const expanderClassNames = [
            'expander-left',
            isExpanded ? '' : 'collapsed',
            isMobile ? 'mobile' : '',
            className,
        ].join(' ');
        const togglerClassNames = [
            'expander-toggle',
            'gi',
            isExpanded ? 'gi-chevron-left' : 'gi-chevron-right',
        ].join(' ');
        return (
            <div className={expanderClassNames}>
                <div className="expander-left-content">{children}</div>
                <div className="expander-title">
                    <h5 className="">{title}</h5>
                    <a className="expander-toggle" href="#" onClick={this.toggle}>
                        <i className={togglerClassNames} />
                    </a>
                </div>
            </div>
        );
    }
}
