import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Button, ButtonProps } from 'reactstrap';
import * as _ from 'lodash';
import { OmitIndexSignature } from '../customTypes';

export interface DropdownSplitButtonProps extends OmitIndexSignature<ButtonProps> {
    isOpen: boolean;
    toggle: (event: React.MouseEvent<HTMLElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

/**
 * This instantiates Button for use in the context of a split button dropdown.
 * See https://github.com/reactstrap/reactstrap/blob/master/src/DropdownItem.js
 */
export class DropdownSplitButton extends React.Component<DropdownSplitButtonProps, {}> {
    public static contextTypes: { [key in keyof DropdownSplitButtonProps]: any } = {
        isOpen: PropTypes.bool,
        toggle: PropTypes.func,
        onClick: PropTypes.func,
    };

    render() {
        const props = _.assign({}, this.props, { onClick: this.onClick });
        return <Button {...props}>{this.props.children}</Button>;
    }

    onClick(event: React.MouseEvent<HTMLElement>) {
        if (this.props.onClick) {
            this.props.onClick(event);
        }
        if (this.context.isOpen) {
            this.context.toggle(event);
        }
    }
}
