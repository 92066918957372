import * as React from 'react';
import * as PropTypes from 'prop-types';

export interface LoadingOverlayProps {
    show?: boolean;
    fullScreen?: boolean;
    children?: React.ReactNode | React.ReactNode[];
}

/**
 * @visibleName LoadingOverlay
 */
export const LoadingOverlay: React.FunctionComponent<LoadingOverlayProps> = ({
    show,
    fullScreen,
    children,
}) => {
    const overlayClasses = [
        'loading-overlay',
        show ? '' : 'hidden',
        fullScreen ? 'loading-full-screen' : '',
    ];
    return <div className={overlayClasses.join(' ')}>{children}</div>;
};

LoadingOverlay.propTypes = {
    show: PropTypes.bool,
    fullScreen: PropTypes.bool,
    children: PropTypes.node,
};
