import * as React from 'react';
import { Form, CustomFormProps } from './Form';

/**
 * The Non Submitable Form button
 * @param props the props of the edit button
 */
export const NonSubmitableForm = (props: CustomFormProps) => {
    return (
        <Form
            {...props}
            onSubmit={(e: React.FormEvent) => {
                e.preventDefault();
                return false;
            }}
        />
    );
};
