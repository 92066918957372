import { Logger } from '@gs-ux-uitoolkit-common/shared';

declare global {
    // For window
    interface Window {
        GS_UX_UITOOLKIT_VERSIONS_CHECK_DISABLE?: boolean;
    }
    // For Global
    namespace NodeJS {
        interface Global {
            GS_UX_UITOOLKIT_VERSIONS_CHECK_DISABLE?: boolean;
        }
    }
}

// For globalThis
declare global {
    var GS_UX_UITOOLKIT_VERSIONS_CHECK_DISABLE: boolean;
}

/**
 * Naive implementation until Node 12 is used on all projects and we can have globalThis
 */
const getGlobalThis = () => {
    if (typeof globalThis !== 'undefined') return globalThis;
    if (typeof global !== 'undefined') return global;
    if (typeof window !== 'undefined') return window;
    throw new Error('Unable to locate global `this`');
};

/**
 * This is used to track the different toolkit component used in an application
 * For ex:
 * {
 *     "firstRegisteredVersion": "12.1.1",
 *     "components": {
 *          "@gs-ux-uitoolkit-react/core" : "12.1.1",
 *          "@gs-ux-uitoolkit-react/header" : "12.1.1"
 *     }
 * }
 */
interface ToolkitVersionsTracker {
    /**
     * First tracked component registered. Its version will be used as a baseline for the other component
     */
    firstRegisteredVersion: string | undefined;
    /**
     * Individual component versions tracking
     */
    components: {
        [componentName: string]: string;
    };
}

const GS_UX_UITOOLKIT_VERSIONS = 'GS_UX_UITOOLKIT_VERSIONS';

/**
 * Track the versions of the toolkit component in the application. If any version is different it will throw an exception unless UITOOLKIT_VERSIONS_CHECK_DISABLE is set to true
 * @param componentName The name of the component i.e. @gs-ux-uitoolkit-react/core
 * @param componentVersion The version of the component i.e. "12.1.1"
 */
export function trackComponentVersion(componentName: string, componentVersion: string) {
    const versions = getComponentVersionTracker();

    //we store the component versions purely for tracking and debugging purposes
    versions.components[componentName] = componentVersion;

    // if firstRegisteredVersion is not set it means that this component is the first one to be registered and therefor its version will be used as baseline
    if (!versions.firstRegisteredVersion) {
        versions.firstRegisteredVersion = componentVersion;
    } else {
        // if there was already a firstRegisteredVersion set then we just compare with the version of the component and log an error when different
        if (versions.firstRegisteredVersion !== componentVersion) {
            if (getGlobalThis().GS_UX_UITOOLKIT_VERSIONS_CHECK_DISABLE) {
                Logger.warn('UI Toolkit versions registered: ', versions);
            } else {
                Logger.error('UI Toolkit versions registered: ', versions);
                throw new Error(
                    `****************************************************************************************************************************************************
                    Your application is using UI Toolkit packages with different versions. See the breakdown of versions above.
    
                    Please update your project dependencies by running 'npm update' or 'yarn update'.
    
                    In the scenario where this has been done on purpose you can deactivate this message by setting 'window.GS_UX_UITOOLKIT_VERSIONS_CHECK_DISABLE = true'
                    *****************************************************************************************************************************************************`
                );
            }
        }
    }
}

function getComponentVersionTracker(): ToolkitVersionsTracker {
    // if it's the first time it's being called we create the instance
    if (!getGlobalThis()[GS_UX_UITOOLKIT_VERSIONS]) {
        const newVersionTracker: ToolkitVersionsTracker = {
            firstRegisteredVersion: undefined,
            components: {},
        };
        getGlobalThis()[GS_UX_UITOOLKIT_VERSIONS] = newVersionTracker;
    }
    return getGlobalThis()[GS_UX_UITOOLKIT_VERSIONS];
}
