import React from 'react';
import { Checkbox } from '@gs-ux-uitoolkit-react/core';

export default (isToggled, step, handleClick, { id }) => (
    <Checkbox
        key={`checkbox-${id}`}
        name="checkbox1"
        onChange={handleClick}
        defaultChecked={isToggled}
    />
);
