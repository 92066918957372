import { cloneElement, DetailedReactHTMLElement } from 'react';

const extendComponentProps: (component: any, props: any) => DetailedReactHTMLElement<any, any> = (
    component,
    props
) => cloneElement(component, { ...props });

const extendCallbackAction = (callbackFn, extendFn) => {
    extendFn();
    callbackFn();
};

export { extendComponentProps, extendCallbackAction };
