import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Label } from './Label';
import cx from 'classnames';
import { CustomInputProps } from '../customTypes';

export interface RadioProps extends CustomInputProps {
    disabled?: boolean;
    invalid?: boolean;
    checked?: boolean;
    inline?: boolean;
}

export const Radio: React.FunctionComponent<RadioProps> = ({
    children,
    disabled,
    invalid,
    inline,
    ...other
}) => {
    const formCheckClasses = cx('form-check', { 'form-check-inline': inline, 'mr-3': inline });
    const inputClasses = cx('form-check-input', { 'is-invalid': invalid });
    const labelClasses = cx({ 'form-check-label--disabled': disabled });
    return (
        <div className={formCheckClasses}>
            <Label className={labelClasses}>
                <input {...other} type="radio" className={inputClasses} disabled={disabled} />
                {children}
            </Label>
        </div>
    );
};

Radio.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
};
