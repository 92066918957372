import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Form, FormProps } from 'reactstrap';
import cx from 'classnames';
import { OmitIndexSignature } from '../customTypes';

type ReactStrapFormProps = OmitIndexSignature<FormProps>;
// Removing the incorrect ref type, caused by FormGroupProps improperly extending React.HTMLProps<HTMLDivElement>
// reference: https://html.developreference.com/article/11191679/ButtonProps+from+the+package+is+incompatible+with+Button+component+from+the+package
type ReactStrapFormPropsFixed = Omit<ReactStrapFormProps, 'ref'>;
// Our form repurposes the 'size' field, so it needs to be removed from the ReactStrap interface before we extend it
type ReactStrapFormPropsDeduped = Omit<ReactStrapFormPropsFixed, 'size'>;

export interface CustomFormProps extends ReactStrapFormPropsDeduped {
    size?: string;
    tooltips?: boolean;
    className?: string;
}
/**
 * @visibleName Form
 */
const CustomForm: React.FunctionComponent<CustomFormProps> = ({
    size,
    tooltips,
    className,
    ...other
}) => {
    const classes = cx(
        {
            'form-sm': size === 'sm',
            'form-lg': size === 'lg',
            'form-tooltips': tooltips,
        },
        className
    );
    return <Form {...other} className={classes} />;
};

CustomForm.propTypes = {
    size: PropTypes.string,
    tooltips: PropTypes.bool,
};

export { CustomForm as Form };
