import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';

export interface SidePanelHeaderProps {
    title?: string;
    header?: JSX.Element;
    togglePanel: (event: React.MouseEvent) => void;
    isOpen: boolean;
    position: 'left' | 'right';
}

const SidePanelHeader: React.FunctionComponent<SidePanelHeaderProps> = ({
    title,
    togglePanel,
    isOpen,
    header,
    position,
}) => {
    const panelHeader = header || <div className="title">{title}</div>;
    const chevronClassName = cx(
        'panel-chevron',
        'gi',
        'gi-fw',
        { 'gi-chevron-left': position === 'left' ? isOpen : !isOpen },
        { 'gi-chevron-right': position === 'left' ? !isOpen : isOpen }
    );
    return (
        <div className="side-panel-header" data-cy="gs-uitk-side-panel__side-panel-header">
            {isOpen ? panelHeader : null}
            <i className={chevronClassName} onClick={togglePanel} />
        </div>
    );
};

SidePanelHeader.propTypes = {
    title: PropTypes.string,
    header: PropTypes.element,
    togglePanel: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    position: PropTypes.oneOf(['left', 'right']).isRequired as PropTypes.Validator<
        'left' | 'right'
    >,
};

export default SidePanelHeader;
