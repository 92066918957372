import * as React from 'react';
import * as PropTypes from 'prop-types';

export interface LoadingIconProps {
    size?: string;
    variant?: string;
    loadingLabel?: string | boolean;
}

/**
 * @visibleName LoadingIcon
 */
export const LoadingIcon: React.FunctionComponent<LoadingIconProps> = ({
    size,
    variant,
    loadingLabel,
}) => {
    let mappedVariant = variant;
    if (variant && (variant === 'white' || variant === 'black')) {
        mappedVariant = 'gray';
    }
    const iconClasses = [
        'loading-icon',
        size ? `icon-${size}` : '',
        variant ? `icon-${mappedVariant}` : '',
    ];
    const iconClassName = iconClasses.join(' ');
    const labelText = typeof loadingLabel === 'boolean' ? 'Loading...' : loadingLabel;
    return (
        <div className={iconClassName} aria-live="assertive">
            <svg width="100%" height="100%" viewBox="0 0 100 100">
                <polygon className="loading-paper center" points="50,0 100,50 50,100 0,50" />

                <polygon className="loading-paper corner corner-tl" points="0,0 50,0 0,50" />
                <polygon className="loading-paper corner corner-tr" points="50,0 100,0 100,50" />
                <polygon
                    className="loading-paper corner corner-br"
                    points="50,100 100,100 100,50"
                />
                <polygon className="loading-paper corner corner-bl" points="0,50 0,100 50,100" />
            </svg>
            {loadingLabel ? <span className="loading-label">{labelText}</span> : ''}
        </div>
    );
};

LoadingIcon.propTypes = {
    size: PropTypes.string,
    variant: PropTypes.string,
    loadingLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
