import React from 'react';
import PropTypes from 'prop-types';
import { Contact, Links } from './modules';
import HeaderButton from '../../../../shared/components/HeaderButton';
import { profileContactItemPropTypes } from '../../../../shared/propTypes';
import { extendCallbackAction } from '../../../../shared/functions';

const Profile: React.FunctionComponent<any> = ({
    identity,
    contact,
    links,
    action,
    closePopover = () => {},
}) => {
    const buttonProps = {
        onClick: () => {
            extendCallbackAction(action.callback, closePopover);
        },
        color: 'primary',
    };

    const contactComp = contact ? <Contact closePopover={closePopover} {...contact} /> : null;
    const linksComp = links ? <Links closePopover={closePopover} links={links} /> : null;
    const actionComp = action ? (
        <div className="module-content">
            <HeaderButton text={action.text} buttonProps={buttonProps} />
        </div>
    ) : null;
    const { primaryInfo, imageUrl, secondaryInfo } = identity;
    return (
        <div className="profile-container">
            <div className="profile-details-container">
                <div className="profile-img-container">
                    <img className="profile-img" src={imageUrl} alt="profile image" />
                </div>
                <div className="profile-content">
                    <div className="profile-name">{primaryInfo}</div>
                    <div className="profile-secondary-info">{secondaryInfo}</div>
                </div>
            </div>
            {contactComp}
            {linksComp}
            {actionComp}
        </div>
    );
};

Profile.defaultProps = { identity: {} };
Profile.propTypes = {
    identity: PropTypes.shape({
        primaryInfo: PropTypes.string,
        secondaryInfo: PropTypes.string,
        imageUrl: PropTypes.string,
        personMenuIcon: PropTypes.bool,
    }).isRequired,
    contact: PropTypes.shape({
        email: PropTypes.shape(profileContactItemPropTypes),
        phone: PropTypes.shape(profileContactItemPropTypes),
        im: PropTypes.shape(profileContactItemPropTypes),
    }),
    links: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            icon: PropTypes.string,
            text: PropTypes.string,
            callback: PropTypes.func,
        })
    ),
    action: PropTypes.shape({
        color: PropTypes.string,
        text: PropTypes.string,
        callback: PropTypes.func,
    }),
    closePopover: PropTypes.func,
};

export default Profile;
