import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@gs-ux-uitoolkit-react/core';
import { Icon } from '../../../../shared/components';
import { InstallModal } from './InstallModal';
import { BeforeInstallPromptEvent } from '@gs-ux-uitoolkit-common/header';
import { uniqueId } from 'lodash';

interface InstallProps {
    readonly installPromptEvent?: BeforeInstallPromptEvent;
}

interface InstallState {
    tooltipOpen: boolean;
    modalOpen: boolean;
    utilityUniqueId: string;
}

export class Install extends Component<InstallProps, InstallState> {
    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen: false,
            modalOpen: false,
            utilityUniqueId: uniqueId(),
        };

        this.toggleTooltip = this.toggleTooltip.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleTooltip() {
        this.setState({ tooltipOpen: !this.state.tooltipOpen });
    }

    toggleModal() {
        this.setState({ modalOpen: !this.state.modalOpen });
    }

    render() {
        const icon = (
            <Icon
                iconName={'plus-circle'}
                classes="clickable"
                onClick={this.toggleModal}
                dataUtilityName={`header.install${this.state.utilityUniqueId}`}
            />
        );
        const tooltip = (
            <Tooltip
                isOpen={this.state.tooltipOpen}
                target={`[data-cy="header.iconContainer"][data-utility-name="header.install${this.state.utilityUniqueId}"]`}
                toggle={this.toggleTooltip}
            >
                Install {document.title} to Desktop
            </Tooltip>
        );

        return (
            <React.Fragment>
                {icon}
                {tooltip}
                <InstallModal
                    display={this.state.modalOpen}
                    toggle={this.toggleModal}
                    installPromptEvent={this.props.installPromptEvent}
                />
            </React.Fragment>
        );
    }

    static propTypes = {
        installPromptEvent: PropTypes.object,
    };
}
