import { AnalyticsConfig } from './interface';

export const uiToolkitLibConfig: AnalyticsConfig = {
    QA: {
        TOKEN: '94ad9122-e982-4a68-a94e-98b69f981419',
        URL: 'https://gs-analytics-qa.url.gs.com:8443/appevent/bulk',
        DASH_UI: {
            APP_ID: '27399',
            DEPLOYMENT_ID: '148898',
        },
    },
    PROD: {
        TOKEN: '98311094-bcd0-41ab-8346-2d9cc6b715c4',
        URL: 'https://gs-analytics.url.gs.com:8443/appevent/bulk',
        DASH_UI: {
            APP_ID: '27399',
            DEPLOYMENT_ID: '148907',
        },
    },
    MAX_BATCH_ITEMS: 50,
    ERROR_TOLERANCE: 10,
    INTERVAL_DURATION: 10 * 1000,
    DEDUPLICATE: true,
    CREDENTIALS: false,
};
