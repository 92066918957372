import * as _ from 'lodash';
import * as PropTypes from 'prop-types';
import * as React from 'react';
// tslint:disable:import-name
import Draggable from 'react-draggable';
import { Popover } from 'reactstrap';
import { colors as toolkitColors } from '@gs-ux-uitoolkit-common/design-system';
import { ColorName, ColorValue } from '@gs-ux-uitoolkit-common/design-system';

export interface ColorPickerColor {
    hex: string;
    label: string;
}

export interface ColorRamp {
    id: string;
    colors: ColorPickerColor[];
}

/**
 * Determintes how the selector is dislpayed
 */
export enum ColorPickerSelectorMode {
    Chip = 'Chip',
    FormChip = 'FormChip',
    Icon = 'Icon',
}

/**
 * Determines the size of the selector
 */
export enum ColorPickerSelectorSize {
    Sm = 'sm',
    Md = 'md',
    Lg = 'lg',
}

export interface ColorPickerState {
    /**
     * Current color value
     */
    selectedColor: ColorPickerColor;
    /**
     * Is the reactstrap popover currently visible
     */
    popoverOpen: boolean;
    /**
     * Color the user is currently hovering over
     */
    hoveredColor: ColorPickerColor | null;
    /**
     * Whether the popup contents are scrolled to the top
     */
    scrolledToTop: boolean;
}

export interface ColorPickerProps {
    /**
     * Event fired on tile select
     */
    onSelect?: (hex: string) => void;
    /**
     * Populate selected color hex value e.g. #001100
     */
    selectedColor?: string;
    /**
     * Optional classname to add to the container
     */
    className?: string;
    /**
     * Size mode - defaults to md
     */
    size?: ColorPickerSelectorSize;
    /**
     * How the selector is displayed - defaults to inline-chip
     */

    mode?: ColorPickerSelectorMode;
}

const white: ColorPickerColor = {
    hex: '#ffffff',
    label: 'white',
};

/**
 * Keys used in the generate ramps method
 */
const rampKeys: ColorName[] = [
    'gray',
    'red',
    'orange',
    'yellow',
    'lime',
    'green',
    'teal',
    'turquoise',
    'aqua',
    'blue',
    'ultramarine',
    'purple',
    'pink',
];

/**
 * Custom color picker component built specifically to
 * handle exported toolkit palettes from common/core sass
 */
export class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
    public static propTypes: { [key in keyof ColorPickerProps]: any } = {
        className: PropTypes.string,
        mode: PropTypes.oneOf([
            ColorPickerSelectorMode.Icon,
            ColorPickerSelectorMode.Chip,
            ColorPickerSelectorMode.FormChip,
        ]),
        onSelect: PropTypes.func,
        selectedColor: PropTypes.string,
        size: PropTypes.oneOf([
            ColorPickerSelectorSize.Lg,
            ColorPickerSelectorSize.Md,
            ColorPickerSelectorSize.Sm,
        ]),
    };
    private baseClassName = 'gs-uitk-color-picker';
    private ramps: ColorRamp[];
    private targetId: string;
    private containerRef: React.RefObject<HTMLDivElement>;
    private firstContentItemRef: React.RefObject<HTMLDivElement>;

    public constructor(props: ColorPickerProps) {
        super(props);

        this.ramps = this.generateRamps();
        this.targetId = `color-picker-id-${_.uniqueId()}`;

        this.containerRef = React.createRef();
        this.firstContentItemRef = React.createRef();

        this.state = {
            hoveredColor: null,
            popoverOpen: false,
            scrolledToTop: true,
            selectedColor: this.processSelectedColor(this.props.selectedColor),
        };
    }

    public componentDidUpdate(prevProps: ColorPickerProps) {
        const selectedColor = this.props.selectedColor;
        if (prevProps.selectedColor !== selectedColor) {
            this.setState({
                selectedColor: this.processSelectedColor(selectedColor),
            });
        }
    }

    public render() {
        const { selectedColor, popoverOpen, hoveredColor, scrolledToTop } = this.state;
        const { className } = this.props;
        const baseClassName = this.baseClassName;

        return (
            <div className={`${baseClassName} ${className || ''}`} data-cy={baseClassName}>
                {this.renderSelector()}
                <Popover
                    trigger="legacy"
                    placement="top"
                    isOpen={popoverOpen}
                    target={this.targetId}
                    toggle={this.toggle}
                    className={`${baseClassName}-popover-override`}
                    hideArrow={true}
                    data-cy={`${baseClassName}-popup-container`}
                >
                    <Draggable>
                        <div
                            className={`${baseClassName}-popup`}
                            ref={this.containerRef}
                            onScroll={this.onScroll}
                        >
                            <div
                                className={`${baseClassName}-popup__contents`}
                                data-cy={`${baseClassName}-popup__contents`}
                            >
                                <div
                                    className={`${baseClassName}-popup__subheader`}
                                    data-cy={`${baseClassName}-popup__subheader`}
                                    ref={this.firstContentItemRef}
                                >
                                    Primary Palette
                                </div>
                                <div
                                    className={`${baseClassName}__ramps-container`}
                                    onMouseLeave={this.resetHoverColor}
                                >
                                    {this.renderPrimaries()}
                                </div>

                                <div
                                    className={`${baseClassName}-popup__subheader`}
                                    data-cy={`${baseClassName}-popup__subheader`}
                                >
                                    Full Palette
                                </div>
                                <div
                                    className={`${baseClassName}__ramps-container`}
                                    onMouseLeave={this.resetHoverColor}
                                >
                                    {this.ramps.map(r => (
                                        <div key={r.id}>
                                            {r.colors.map(c => this.renderTile(c))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={`${baseClassName}__footer`}>
                                <div
                                    className={`${baseClassName}__footer-item`}
                                    data-cy={`${baseClassName}__name-footer-item`}
                                >
                                    <span
                                        className={`${baseClassName}__footer-label`}
                                        data-cy={`${baseClassName}__name-footer-label`}
                                    >
                                        Name:
                                    </span>
                                    {this.generateLabelDisplayName(
                                        (hoveredColor && hoveredColor.label) || selectedColor.label
                                    )}
                                </div>
                                <div
                                    className={`${baseClassName}__footer-item`}
                                    data-cy={`${baseClassName}__hex-footer-item`}
                                >
                                    <span
                                        className={`${baseClassName}__footer-label`}
                                        data-cy={`${baseClassName}__hex-footer-label`}
                                    >
                                        Hex:
                                    </span>
                                    {(hoveredColor && hoveredColor.hex) || selectedColor.hex}
                                </div>
                            </div>
                            <div
                                className={`${baseClassName}__scroll-screen ${!scrolledToTop &&
                                    `${baseClassName}__scroll-screen--active`}`}
                            />
                            <div
                                className={`${baseClassName}__close-button`}
                                data-cy={`${baseClassName}__close-button`}
                                onClick={this.toggle}
                            >
                                &#215;
                            </div>
                        </div>
                    </Draggable>
                </Popover>
            </div>
        );
    }

    /**
     * Process a passed in color or set to default white
     */
    private processSelectedColor(selectedColor: string | undefined): ColorPickerColor {
        let foundLabel;
        if (selectedColor) {
            this.verifyColorFormat(selectedColor);
            foundLabel = _.invert(toolkitColors)[selectedColor.toLowerCase()];
        }

        return {
            hex: selectedColor || white.hex,
            label: selectedColor ? foundLabel || 'Custom' : white.label,
        };
    }

    /**
     * Make sure passed color conforms to hex format
     */
    private verifyColorFormat(color: string) {
        const hexColorRegex = /^#[\dA-Fa-f]{6}$/;
        if (!hexColorRegex.test(color)) {
            throw new Error(
                `Initial color prop value '${color}' passed into ColorPicker should be a hex value of format: "#000000"`
            );
        }
    }

    /**
     * Generate an array of ramps from the toolkit colors
     */
    private generateRamps(): ColorRamp[] {
        const ramps: ColorRamp[] = [];
        rampKeys.forEach(c => {
            const ramp: ColorRamp = {
                colors: [],
                id: `${c}-${_.uniqueId()}`,
            };
            for (let i = 100; i > 0; i -= 10) {
                const key = `${c}-${String(i).padStart(3, '0')}` as ColorValue;
                const result = toolkitColors[key];
                if (result) {
                    ramp.colors.push({
                        hex: result.toUpperCase(),
                        label: key,
                    });
                }
            }
            if (ramp.colors.length) ramps.push(ramp);
        });
        return ramps;
    }

    /**
     * Open/close the popover
     */
    private toggle = () => {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    };

    /**
     * Clear out the currently hovered color
     */
    private resetHoverColor = () => {
        this.setState({ hoveredColor: null });
    };

    /**
     * Sets the color currently being hovered over
     */
    private setHoverColor(color: ColorPickerColor) {
        this.setState({ hoveredColor: color });
    }

    /**
     * Process the color key name into a friendly display name
     * Convention: light-blue-300 => Light Blue 300
     */
    private generateLabelDisplayName(label: string): string {
        return label
            .replace(/-/g, ' ')
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    /**
     * Local tile select event - also triggers passed in callback
     */
    private onSelect(color: ColorPickerColor) {
        this.setState({
            selectedColor: color,
        });
        if (this.props.onSelect) {
            this.props.onSelect(color.hex);
        }
    }

    /**
     * Creates the popup selector
     */
    private renderSelector(): JSX.Element {
        const style = { background: this.state.selectedColor.hex };
        let clsName = this.baseClassName;
        let contents;

        switch (this.props.mode || ColorPickerSelectorMode.Chip) {
            case ColorPickerSelectorMode.Chip:
                clsName += '__chip-selector';
                contents = (
                    <div
                        style={style}
                        className={`${this.baseClassName}__selector-inner`}
                        data-cy={`${this.baseClassName}__selector-inner`}
                    />
                );
                break;

            case ColorPickerSelectorMode.FormChip:
                clsName += '__form-selector';
                contents = (
                    <div
                        style={style}
                        className={`${this.baseClassName}__selector-inner`}
                        data-cy={`${this.baseClassName}__selector-inner`}
                    />
                );
                break;

            case ColorPickerSelectorMode.Icon:
                clsName += '__icon-selector';
                contents = <i className="gi gi-paint-brush" />;
                break;
        }

        return (
            <div
                className={`${clsName} ${clsName}--${this.props.size || 'md'}`}
                onClick={this.toggle}
                id={this.targetId}
                data-cy={`${this.baseClassName}__selector`}
            >
                {contents}
            </div>
        );
    }

    /**
     * Parse ramps for primaries and create tiles
     * Convention: take 060 as primary
     */
    private renderPrimaries(): JSX.Element[] {
        return this.ramps
            .map(ramp => ramp.colors[4]) // 100 090 080 070 060 050 040 030 020 010
            .filter(color => !!color)
            .map(color => {
                const tileColor = color.label === 'gray-060' ? white : color;
                return <div key={`${tileColor.hex}-primary`}>{this.renderTile(tileColor)}</div>;
            });
    }

    /**
     * Create a color tile
     */
    private renderTile(color: ColorPickerColor): JSX.Element {
        const isActive = color.hex === this.state.selectedColor.hex;
        return (
            <div
                className={`${this.baseClassName}__ramp-tile`}
                key={color.hex}
                style={{
                    background: color.hex,
                    border: `1px solid ${color.hex === white.hex ? '#000000' : color.hex}`,
                }}
                onClick={() => this.onSelect(color)}
                onMouseEnter={() => this.setHoverColor(color)}
                data-cy={`${this.baseClassName}__ramp-tile`}
                data-color={`${color.hex}`}
            >
                {isActive && (
                    <div
                        className={`${this.baseClassName}__active-marker`}
                        data-cy={`${this.baseClassName}__active-marker`}
                    />
                )}
            </div>
        );
    }

    /**
     * Handle scroll event to add transparent white box
     * behind close button when scrolling starts
     */
    private onScroll = () => {
        const container = this.containerRef.current;
        const containerItem = this.firstContentItemRef.current;
        if (container && containerItem) {
            const containerOffset = container.getBoundingClientRect().top;
            const containerItemOffset = containerItem.getBoundingClientRect().top;
            const scrollDifference = containerOffset - containerItemOffset;
            this.setState({
                scrolledToTop: scrollDifference < -5,
            });
        }
    };
}
