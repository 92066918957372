import {
    Expression,
    ExpressionCondition,
    ExpressionMode,
    ExpressionOperator,
    ExpressionQuery,
    ExpressionRule,
    ExpressionValue,
} from '../../interfaces/expression';
import { SimpleEventDispatcher } from '../../libraries/event';
import {
    CreateComponentState,
    CreateControllerState,
    UpdateComponentRecordSelection,
} from './redux/actions/all-action-types';
import { CrossModelControllerState } from './redux/cross-model-state';
import { CrossModelStore } from './redux/cross-model-store';

export class CrossModelController {
    private componentMap: Map<
        string,
        {
            api: CrossModelControllerApi;
            onSelect: CrossModelSelect;
        }
    > = new Map();
    private controllerState: CrossModelControllerState;

    constructor(private controllerName: string, private crossModelStore: CrossModelStore) {
        // we initialise the crontroller state
        this.crossModelStore.getReduxStore().dispatch(CreateControllerState(controllerName));
        // we keep the first reference
        this.controllerState = this.crossModelStore.getReduxStore().getState()[controllerName];
        // we subscribe to the store and compare the controller state
        this.crossModelStore.getReduxStore().subscribe(() => {
            const newState = this.crossModelStore.getReduxStore().getState()[controllerName];
            if (this.controllerState !== newState) {
                for (const componentId in newState) {
                    if (this.controllerState[componentId] !== newState[componentId]) {
                        this.handleControllerStateChange(componentId, newState);
                    }
                }
                this.controllerState = newState;
            }
        });
    }

    public registerComponent(
        componentId: string,
        onSelect: CrossModelSelect
    ): CrossModelControllerApi {
        this.crossModelStore
            .getReduxStore()
            .dispatch(CreateComponentState(this.controllerName, componentId));
        const api = this.generateApi(componentId);
        this.componentMap.set(componentId, { api, onSelect });
        return api;
    }

    public getControllerName(): string {
        return this.controllerName;
    }

    private generateApi(componentId: string): CrossModelControllerApi {
        return {
            onRecordSelectionChange: (
                selectedRowIdList,
                selectedRowIndexList,
                selectedRowCompositeIdList
            ) =>
                this.crossModelStore
                    .getReduxStore()
                    .dispatch(
                        UpdateComponentRecordSelection(
                            this.controllerName,
                            componentId,
                            selectedRowIdList,
                            selectedRowIndexList,
                            selectedRowCompositeIdList
                        )
                    ),
            updateFilter: new SimpleEventDispatcher(),
            updateRecordSelection: new SimpleEventDispatcher(),
        };
    }

    private handleControllerStateChange(
        componentId: string,
        state: CrossModelControllerState
    ): void {
        this.componentMap.forEach((value, key) => {
            if (key !== componentId) {
                switch (value.onSelect) {
                    case CrossModelSelect.CompositeIdSelect:
                        value.api.updateRecordSelection.dispatchAsync({
                            rowCompositeIdList: state[componentId].selectedRecordCompositeIdList,
                            rowIdList: [],
                            rowIndexList: [],
                        });
                        break;
                    case CrossModelSelect.RowIdSelect:
                        value.api.updateRecordSelection.dispatchAsync({
                            rowCompositeIdList: [],
                            rowIdList: state[componentId].selectedRowIdList,
                            rowIndexList: [],
                        });
                        break;
                    case CrossModelSelect.RowIndexSelect:
                        value.api.updateRecordSelection.dispatchAsync({
                            rowCompositeIdList: [],
                            rowIdList: [],
                            rowIndexList: state[componentId].selectedRowIndexList,
                        });
                        break;
                    case CrossModelSelect.Filter:
                        const compositeIds = state[componentId].selectedRecordCompositeIdList;
                        if (compositeIds.length > 0) {
                            const expressionQuery: ExpressionQuery = {
                                condition: ExpressionCondition.Or,
                                rules: compositeIds.map(id => ({
                                    condition: ExpressionCondition.And,
                                    rules: Object.entries(id).map(keyValuePair => {
                                        const expressionRule: ExpressionRule = {
                                            field: keyValuePair[0],
                                            operator: ExpressionOperator.Equals,
                                            value: keyValuePair[1],
                                            formattedValue: keyValuePair[1],
                                        };
                                        return expressionRule;
                                    }),
                                })),
                            };
                            value.api.updateFilter.dispatchAsync({
                                filterExpression: {
                                    mode: ExpressionMode.Experienced,
                                    query: expressionQuery,
                                },
                            });
                        } else {
                            value.api.updateFilter.dispatchAsync({
                                filterExpression: null,
                            });
                        }
                        break;
                }
            }
        });
    }
}

export interface CrossModelControllerApi {
    onRecordSelectionChange: (
        selectedRowIdList: string[],
        selectedRowIndexList: number[],
        selectedRowCompositeIdList: { [key: string]: ExpressionValue }[]
    ) => void;
    updateRecordSelection: SimpleEventDispatcher<UpdateRecordSelectionEvent>;
    updateFilter: SimpleEventDispatcher<UpdateFilterEvent>;
}

export interface UpdateRecordSelectionEvent {
    rowIdList: string[];
    rowIndexList: number[];
    rowCompositeIdList: { [key: string]: ExpressionValue }[];
}

export interface UpdateFilterEvent {
    filterExpression: Expression | null;
}

export enum CrossModelSelect {
    None = 'None',
    RowIdSelect = 'RowIdSelect',
    RowIndexSelect = 'RowIndexSelect',
    CompositeIdSelect = 'CompositeIdSelect',
    Filter = 'Filter',
}
