import React from 'react';
import cx from 'classnames';
import { ZIndexProperty } from 'csstype';

import {
    NormalizedHeaderSubmenu,
    menuItemHeight,
    backBtnHeight,
    NormalizedHeaderMenuItem,
    HeaderMenuItemsTree,
} from '@gs-ux-uitoolkit-common/header';

import { items } from '../../utilities/items/index';
import { extendComponentProps } from '../../../shared/functions';
import Icon from '../../../shared/components/Icon';
import ProfileIcon from '../../utilities/items/profile/ProfileIcon';

export interface SubmenusContainerProps {
    depth: number; // the depth of this submenus container in the menuItems tree. 0 is a top-level menu item
    menuItemsTree: HeaderMenuItemsTree; // the top level menu items tree (used for calculation of the submenu positioning)
    menuItem: NormalizedHeaderMenuItem; // the MenuItem to display submenus for
    selectedMenuItemKey: string;
    expandedSubMenus: string[];
    enforceHamburger?: boolean;
    enableTabs?: boolean;
    maxHamburgerHeight: number;
    menuHeight: number;
    responsiveSize: 'desktop' | 'mobile' | 'tablet';
    submenuZindex: ZIndexProperty;
    isUtilityMenuItem?: boolean; // true if the submenu belongs to a "utility" taken from the header itself and put into the hamburger menu
    preventMouseEnterOnTouchDevices: () => void;
    onMenuItemClick: (evt: React.MouseEvent<HTMLElement>) => void;
    onMenuItemKeyDown: (evt: React.KeyboardEvent<HTMLElement>) => void;
    onMenuItemMouseEnter: (evt: React.MouseEvent<HTMLElement>) => void;
    onMenuItemMouseLeave: (evt: React.MouseEvent<HTMLElement>) => void;
    onSubmenuBackClick: (evt: React.MouseEvent<HTMLElement>) => void;
    closeHamburgerMenu: () => void;
}

const SubmenusContainer: React.FunctionComponent<SubmenusContainerProps> = ({
    depth,
    menuItemsTree,
    menuItem,
    selectedMenuItemKey,
    expandedSubMenus,
    enforceHamburger = false,
    enableTabs = false,
    maxHamburgerHeight,
    menuHeight,
    responsiveSize,
    submenuZindex,
    isUtilityMenuItem = false,
    preventMouseEnterOnTouchDevices,
    onMenuItemClick,
    onMenuItemKeyDown,
    onMenuItemMouseEnter,
    onMenuItemMouseLeave,
    onSubmenuBackClick,
    closeHamburgerMenu,
}) => {
    const submenus = menuItem.submenus;

    const submenusAboveMenuItem = menuItemsTree.getSubmenusPriorTo(menuItem.key);
    const marginTop =
        -1 *
        submenusAboveMenuItem.reduce((marginTop: number, submenu: NormalizedHeaderSubmenu) => {
            const numItemsInSubmenu = (submenu.header ? 1 : 0) + submenu.submenuItems.length;

            return (marginTop +=
                numItemsInSubmenu * menuItemHeight +
                (responsiveSize === 'mobile' ? backBtnHeight : 0));
        }, 0);

    /*eslint-disable */
    return (
        <React.Fragment>
            {submenus.map((submenuItem, i) => {
                const numItemsInPreviousSubmenus = submenus
                    .slice(0, i)
                    .reduce((numItems: number, submenu: NormalizedHeaderSubmenu) => {
                        if (submenu.header) numItems++;
                        numItems += submenu.submenuItems.length;

                        return numItems;
                    }, 0);

                const ulTop =
                    numItemsInPreviousSubmenus * menuItemHeight +
                    (responsiveSize === 'mobile' && numItemsInPreviousSubmenus !== 0
                        ? backBtnHeight
                        : 0);
                return (
                    <ul
                        className="hamburger-menu-list"
                        key={submenuItem.key}
                        style={{
                            top: ulTop,
                            height: menuHeight !== undefined ? menuHeight - ulTop : undefined,
                            zIndex: (responsiveSize === 'mobile' && submenuZindex) || 'auto',
                            marginTop: marginTop,
                        }}
                        data-cy="headerSubmenuContainer.submenu"
                        data-submenu-key={submenuItem.key}
                    >
                        {responsiveSize === 'mobile' && numItemsInPreviousSubmenus === 0 && (
                            <div
                                className="hamburger-back-container"
                                onClick={onSubmenuBackClick}
                                data-cy="headerSubmenu.backButton"
                            >
                                <i className="gi gi-fw gi-chevron-left hamburger-left-icon" />
                                <div className="hamburger-back">Back</div>
                            </div>
                        )}
                        {submenuItem.header && (
                            <div className="submenu-header" data-cy="headerSubmenu.header">
                                {submenuItem.header}
                            </div>
                        )}
                        {!isUtilityMenuItem &&
                            submenuItem.submenuItems.map(menuItem => (
                                <MenuItem
                                    key={menuItem.key}
                                    menuItemsTree={menuItemsTree}
                                    menuItem={menuItem}
                                    menuHeight={menuHeight}
                                    depth={depth}
                                    selectedMenuItemKey={selectedMenuItemKey}
                                    enableTabs={enableTabs}
                                    enforceHamburger={enforceHamburger}
                                    responsiveSize={responsiveSize}
                                    expandedSubMenus={expandedSubMenus}
                                    maxHamburgerHeight={maxHamburgerHeight}
                                    preventMouseEnterOnTouchDevices={
                                        preventMouseEnterOnTouchDevices
                                    }
                                    onMenuItemClick={onMenuItemClick}
                                    onMenuItemKeyDown={onMenuItemKeyDown}
                                    onSubmenuBackClick={onSubmenuBackClick}
                                    onMenuItemMouseEnter={onMenuItemMouseEnter}
                                    onMenuItemMouseLeave={onMenuItemMouseLeave}
                                    closeHamburgerMenu={closeHamburgerMenu}
                                />
                            ))}

                        {isUtilityMenuItem &&
                            items.map(item => {
                                if (submenuItem.key === `__utility-item-submenu-${item.name}`) {
                                    return (
                                        <li
                                            className="hamburger-list-item"
                                            key={submenuItem.key}
                                            onClick={e => {
                                                e.stopPropagation();
                                            }}
                                            onKeyPress={e => {
                                                if (e.keyCode || e.which === 13) {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        >
                                            {extendComponentProps(
                                                item.component,
                                                submenuItem.submenuItems[0].utilitySubmenu
                                            )}
                                        </li>
                                    );
                                }
                            })}
                    </ul>
                );
            })}
        </React.Fragment>
    );
};

export default SubmenusContainer;

// IMPORTANT: Unfortunately, by adding a <MenuItem> component, we introduce a circular dependency
//            between MenuItem -> SubmenusContainer -> MenuItem. Therefore, SubmenusContainer and
//            MenuItem must be in the same file for now, as they can't import from each other.

interface MenuItemProps {
    menuItemsTree: HeaderMenuItemsTree; // the top level menu items tree (used for calculation of the submenu positioning)
    menuItem: NormalizedHeaderMenuItem;
    selectedMenuItemKey: string | undefined;
    depth: number; // the depth of this menuItem in the menuItems tree. 0 is a top-level menu item
    enableTabs: boolean;
    enforceHamburger: boolean;
    responsiveSize: 'mobile' | 'tablet' | 'desktop';
    expandedSubMenus: string[];
    maxHamburgerHeight: number;
    menuHeight: number;
    preventMouseEnterOnTouchDevices: () => void;
    onMenuItemClick: (evt: React.MouseEvent<HTMLElement>) => void;
    onMenuItemKeyDown: (evt: React.KeyboardEvent<HTMLElement>) => void;
    onMenuItemMouseEnter: (evt: React.MouseEvent<HTMLElement>) => void;
    onMenuItemMouseLeave: (evt: React.MouseEvent<HTMLElement>) => void;
    onSubmenuBackClick: (evt: React.MouseEvent<HTMLElement>) => void;
    closeHamburgerMenu: () => void;
}

export const MenuItem: React.FunctionComponent<MenuItemProps> = (props: MenuItemProps) => {
    const {
        menuItemsTree,
        menuItem,
        selectedMenuItemKey,
        depth,
        enableTabs,
        enforceHamburger,
        responsiveSize,
        expandedSubMenus,
        maxHamburgerHeight,
        menuHeight,
        preventMouseEnterOnTouchDevices,
        onMenuItemClick,
        onMenuItemKeyDown,
        onSubmenuBackClick,
        onMenuItemMouseEnter,
        onMenuItemMouseLeave,
        closeHamburgerMenu,
    } = props;
    const href = menuItem.href || '#';
    return (
        <React.Fragment>
            {menuItem.type === 'divider' && (
                <li data-cy="header.menuItem" data-menu-item-key={menuItem.key} key={menuItem.key}>
                    <hr data-cy="header.dividerItem" className="divider" />
                </li>
            )}
            {menuItem.type !== 'divider' && (
                <li
                    className={cx({
                        'hamburger-list-item': true,
                        'gs-uitk-menu-underline-reveal': enableTabs && !enforceHamburger,
                        'gs-uitk-menu-selected': menuItemsTree
                            .getPathToMenuItem(selectedMenuItemKey)
                            .includes(menuItem.key),
                        'utility-item': menuItem.isUtility,
                    })}
                    tabIndex={0}
                    key={menuItem.key}
                    onTouchStart={preventMouseEnterOnTouchDevices}
                    onClick={onMenuItemClick}
                    onMouseEnter={onMenuItemMouseEnter}
                    onMouseLeave={onMenuItemMouseLeave}
                    onKeyDown={onMenuItemKeyDown}
                    style={{ pointerEvents: menuItem.name === '' ? 'none' : 'auto' }}
                    data-cy={menuItem.isUtility ? 'header.utilityMenuItem' : 'header.menuItem'}
                    data-menu-item-key={menuItem.key}
                    data-utility-item-name={menuItem.utilityId}
                >
                    {menuItem.submenus.length === 0 && (
                        <a
                            className="hamburger-link"
                            href={href}
                            title={menuItem.name}
                            onClick={evt => {
                                // TODO: This handler should be a specific onLinkMenuItemClick,
                                // and combine it with the handler in the <li> tag
                                if (!href || href === '#') {
                                    evt.preventDefault();
                                    closeHamburgerMenu();
                                }
                            }}
                            data-cy="headerMenuItem.link"
                            data-menu-item-key={menuItem.key}
                        >
                            {menuItem.iconName && <Icon iconName={menuItem.iconName} />}
                            {menuItem.name}
                        </a>
                    )}
                    {menuItem.submenus.length > 0 && (
                        <div
                            className="hamburger-submenu-wrapper"
                            data-cy="headerMenuItem.submenuContainer"
                        >
                            {menuItem.iconName && menuItem.name !== 'Profile' && (
                                <Icon iconName={menuItem.iconName} />
                            )}
                            {menuItem.isUtility && menuItem.name === 'Profile' && (
                                <ProfileIcon imageUrl={menuItem.iconName} />
                            )}
                            <span
                                className="hamburger-list-item-name"
                                data-cy="headerMenuItem.submenuName"
                            >
                                {menuItem.name}
                            </span>
                            <span>
                                <i className="gi gi-fw gi-chevron-right hamburger-right-icon" />
                                <div
                                    className={cx({
                                        'gs-uitk-header__submenu-container': true,
                                        'gs-uitk-header__submenu-container--visible': expandedSubMenus.includes(
                                            menuItem.key
                                        ),
                                    })}
                                    data-cy="headerMenuItem.submenusContainer"
                                    data-menu-item-key={menuItem.key}
                                >
                                    <SubmenusContainer
                                        menuItemsTree={menuItemsTree}
                                        menuItem={menuItem}
                                        selectedMenuItemKey={selectedMenuItemKey}
                                        expandedSubMenus={expandedSubMenus}
                                        depth={depth + 1}
                                        maxHamburgerHeight={maxHamburgerHeight}
                                        menuHeight={menuHeight}
                                        responsiveSize={responsiveSize}
                                        submenuZindex={depth === 0 ? 'auto' : 10}
                                        isUtilityMenuItem={menuItem.isUtility}
                                        preventMouseEnterOnTouchDevices={
                                            preventMouseEnterOnTouchDevices
                                        }
                                        onMenuItemClick={onMenuItemClick}
                                        onMenuItemKeyDown={onMenuItemKeyDown}
                                        onSubmenuBackClick={onSubmenuBackClick}
                                        onMenuItemMouseEnter={onMenuItemMouseEnter}
                                        onMenuItemMouseLeave={onMenuItemMouseLeave}
                                        closeHamburgerMenu={closeHamburgerMenu}
                                    />
                                </div>
                            </span>
                        </div>
                    )}
                </li>
            )}
        </React.Fragment>
    );
};
