import * as _ from 'lodash';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { UncontrolledTooltip, UncontrolledTooltipProps } from 'reactstrap';
import { OmitIndexSignature } from '../customTypes';

export interface LabelIconProps extends OmitIndexSignature<UncontrolledTooltipProps> {
    type: string;
    tooltip?: string;
}

export const LabelIcon: React.FunctionComponent<LabelIconProps> = props => {
    const { type, tooltip, ...tooltipOptions } = props;
    const wrapperClasses = cx('label-icon', `has-${type}`);
    const iconClasses = cx('gi', {
        'gi-info-circle': type === 'info' || type === 'warning',
        'gi-check': type === 'success',
        'gi-spinner': type === 'loading',
        'gi-exclamation-triangle': type === 'danger',
    });
    const target = tooltip ? `__icon-${_.uniqueId()}` : undefined;
    const getToolTip = () => {
        if (tooltip && target) {
            return (
                <UncontrolledTooltip
                    placement="top"
                    {...tooltipOptions}
                    target={target}
                    container={target}
                >
                    {tooltip}
                </UncontrolledTooltip>
            );
        }
        return undefined;
    };
    return (
        <span className={wrapperClasses} id={target}>
            <i className={iconClasses} />
            {getToolTip()}
        </span>
    );
};

LabelIcon.propTypes = {
    type: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
};
