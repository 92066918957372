import React from 'react';
import PropTypes from 'prop-types';

const Title: React.FunctionComponent<any> = props => <div className="title">{props.text}</div>;

Title.defaultProps = {
    id: '',
    text: 'Title',
};

Title.propTypes = {
    text: PropTypes.string,
};

export default Title;
