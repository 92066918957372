// tslint:disable:no-submodule-imports
import { Instance } from 'flatpickr/dist/types/instance';

/**
 * Checks if the flatpickr instance has a calendar to render plugins to
 *
 * @param instance Flatpickr instance
 */
export const hasCalendar = (instance: Instance): boolean =>
    !(instance.config.noCalendar || instance.isMobile);
