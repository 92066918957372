import { LibUsage, uiToolkitLibConfig } from '@gs-ux-uitoolkit-react/core';

import { Version } from './version';

const usage = new LibUsage(
    'UI Toolkit',
    Version.name,
    Version.version,
    'React',
    uiToolkitLibConfig
);
export { usage as LibUsage };
