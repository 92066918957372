import { HeaderUtilities } from '../options/utilities/header-utilities';
import {
    NormalizedHeaderMenuItem,
    NormalizedHeaderSubmenu,
    HeaderItemType,
} from '../options/navigation/header-menu-item';
import { uniqueId, upperFirst } from 'lodash';
import { getUtilitiesForHamburgerMenu } from './get-utilities';

/**
 * The current implementation of the header's "utility" items is that they are normally in the
 * header itself on desktop, but for tablet and mobile, either some or all of them are moved to
 * the "hamburger" menu in order to save space on the header on those smaller devices.
 *
 * For mobile: all 4 utilities are moved to the menu
 * For tablet:
 *   1. The "primary" utility is left on the header itself
 *   2. The "secondary" utility is left on the header itself, unless the 'search' button is
 *      present on the header. If 'search' is present, then the secondary utility is moved to
 *      the menu.
 *   3. The remaining utilities are moved to the menu.
 *
 * This function generates the HeaderMenuItem instances to represent the "utilities" when they are
 * 'moved' to the hamburger menu.
 */
export function generateUtilityMenuItems(
    utilities: Readonly<HeaderUtilities>,
    responsiveSize: 'mobile' | 'tablet' | 'desktop',
    searchIsPresentInHeader: boolean
): NormalizedHeaderMenuItem[] {
    const utilityIcons = {
        profile: 'user',
        appChooser: 'th',
        notification: 'bell',
        settings: 'cog',
        help: 'question-circle',
        install: 'plus-circle',
    };

    if (responsiveSize === 'desktop') {
        return []; // no utilities should be moved to the hamburger menu on desktop. We'll leave all utilities in the header itself
    }

    const utilitiesToMoveToMenu = getUtilitiesForHamburgerMenu(
        utilities,
        responsiveSize,
        searchIsPresentInHeader
    );
    return utilitiesToMoveToMenu.map(utilityName => {
        let displayName = upperFirst(utilityName);

        if (utilityName === 'install') {
            displayName += ' to Desktop';
        }

        // grab the icon corresponding to the utility
        let iconName = utilityIcons[utilityName];

        // need directory image for profile icon
        if (iconName === 'user') {
            const profile = utilities.profile || {};
            const identity = profile.identity || {};

            iconName = identity.imageUrl || 'user';
        }

        let submenus: NormalizedHeaderSubmenu[] = [];

        if (utilityName !== 'install') {
            submenus = [
                {
                    key: `__utility-item-submenu-${utilityName}`,
                    submenuItems: [
                        {
                            type: 'link' as HeaderItemType,
                            key: uniqueId('__utility-item-submenu-item-'),
                            utilitySubmenu: utilities[utilityName],
                            isUtility: false, // the utility itself is the parent menu item
                            submenus: [],
                        },
                    ],
                },
            ];
        }

        return {
            key: uniqueId('__utility-item-'),
            name: displayName,
            iconName,
            type: 'link' as HeaderItemType,
            isUtility: true,
            utilityId: utilityName,
            submenus,
        };
    });
}
