/**
 * Simple implementation of the `String.prototype.startsWith` function.
 *
 * This allows us to support older browsers such as Ie11.
 *
 * @param targetString The string to search on (ie. the one that we will test)
 * @param searchString The string to search (ie. the pattern we are looking for)
 */
export function startsWith(targetString: string, searchString: string) {
    return targetString.slice(0, searchString.length) === searchString;
}
