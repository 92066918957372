import * as React from 'react';
import * as PropTypes from 'prop-types';

export const LabelIconGroup: React.FunctionComponent<{}> = props => {
    const { children } = props;
    return <span className="label-icon-group">{children}</span>;
};

LabelIconGroup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
