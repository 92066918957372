import * as React from 'react';
import * as PropTypes from 'prop-types';
import { FormGroup, FormGroupProps } from 'reactstrap';
import cx from 'classnames';
import { OmitIndexSignature } from '../customTypes';

type ReactStrapFormGroupProps = OmitIndexSignature<FormGroupProps>;
// Removing the incorrect ref type, caused by FormGroupProps improperly extending React.HTMLProps<HTMLDivElement>
// reference: https://html.developreference.com/article/11191679/ButtonProps+from+the+package+is+incompatible+with+Button+component+from+the+package
type ReactStrapFormGroupPropsFixed = Omit<ReactStrapFormGroupProps, 'ref'>;

export interface CustomFormGroupProps extends OmitIndexSignature<ReactStrapFormGroupPropsFixed> {
    color?: string;
    check?: boolean;
    style?: React.CSSProperties;
    className?: string;
    tag?: React.ReactType;
    row?: boolean;
}

class CustomFormGroup extends React.Component<CustomFormGroupProps, {}> {
    public static propTypes: { [key in keyof CustomFormGroupProps]: any } = {
        color: PropTypes.string,
    };

    render() {
        const { className, ...formGroupProps } = this.props;
        const color = formGroupProps.color;

        const classes = cx(className, {
            [`has-${color}`]: color,
        });
        return (
            <FormGroup
                {...formGroupProps}
                className={classes}
                aria-invalid={color === 'warning' || color === 'danger'}
            />
        );
    }
}

export { CustomFormGroup as FormGroup };
