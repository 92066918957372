import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import outy from 'outy';
import Icon from './Icon';

class SideMenu extends Component<any, any> {
    public outsideClick: any;
    public slideoverTarget: any;
    public slideoverContainer: any;
    public isOpen: any;
    public children: any;
    public classes: any;
    public compact: any;
    public title: any;
    public slideover: any;
    public target: any;

    constructor(props) {
        super(props);
        this.state = {
            slideoverOpen: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.disableSlideover = this.disableSlideover.bind(this);
    }

    componentDidMount() {
        if (!this.props.isMobile) {
            this.setOutsideClick();
        }
    }

    componentDidUpdate(lastProps, lastState) {
        if (!this.props.isMobile && lastState.slideoverOpen !== this.state.slideoverOpen) {
            setTimeout(() => this.setOutsideClick());
        }
    }

    componentWillUnmount() {
        if (!this.props.isMobile) {
            this.outsideClick.remove();
        }
    }

    setOutsideClick() {
        const elements = [this.slideoverTarget];

        if (this.slideoverContainer) {
            elements.push(this.slideoverContainer);
        }

        if (this.outsideClick) {
            this.outsideClick.remove();
        }

        this.outsideClick = outy(elements, ['click', 'touchstart'], this.disableSlideover);
    }

    handleClick() {
        this.setState({ slideoverOpen: !this.state.slideoverOpen });
    }

    disableSlideover() {
        this.setState({ slideoverOpen: false });
    }

    render() {
        const { isOpen, children, classes, compact, title, slideover, target } = this.props;
        const allClasses = cx(
            'side-menu',
            { open: isOpen || this.state.slideoverOpen, compact },
            classes
        );
        return (
            <div>
                {slideover && (
                    <div
                        ref={node => {
                            this.slideoverTarget = node;
                        }}
                        onClick={this.handleClick}
                        aria-expanded={this.state.slideoverOpen}
                    >
                        {target}
                        {this.state.slideoverOpen && <div className="sidemenu-icon-border" />}
                    </div>
                )}
                <div
                    className={allClasses}
                    ref={node => {
                        this.slideoverContainer = node;
                    }}
                >
                    {slideover && (
                        <div className="sidemenu-title-container">
                            <div className="sidemenu-title">{title}</div>
                            <div className="sidemenu-close-icon" onClick={this.disableSlideover}>
                                <Icon iconName="angle-double-right" classes="clickable" />
                            </div>
                        </div>
                    )}
                    {children}
                </div>
            </div>
        );
    }

    static defaultProps = {
        classes: '',
        isOpen: false,
        children: null,
        compact: false,
    };

    static propTypes = {
        isOpen: PropTypes.bool,
        classes: PropTypes.string,
        children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
        compact: PropTypes.bool,
        title: PropTypes.string,
        slideover: PropTypes.bool,
        target: PropTypes.node,
        isMobile: PropTypes.bool,
    };
}

export default SideMenu;
