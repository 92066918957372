import { InsideOutsideEventListener } from '@gs-ux-uitoolkit-common/core';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import outy from 'outy';
import cx from 'classnames';
import _ from 'lodash';
import {
    HeaderMenuItemsTree,
    HeaderMenuItem,
    HeaderUtilities,
    HeaderCTAButton,
    NormalizedHeaderMenuItem,
    generateUtilityMenuItems,
    menuItemHeight,
    normalizeMenuItems,
    HeaderInstallEventContainer,
} from '@gs-ux-uitoolkit-common/header';
import memoizeOne from 'memoize-one';
import { MenuItem } from './SubmenusContainer';
import CallToActionButton from '../CTAButton';
import { KeyHelpers } from '@gs-ux-uitoolkit-common/shared';
import { InstallModal } from '../../utilities/items/install/InstallModal';

const emptyObj = {}; // an empty object that will always have the same reference

export interface MenuProps {
    readonly type: 'hamburger' | 'header' | 'appMenu';
    readonly menuItems: HeaderMenuItem[];
    readonly selectedMenuItemKey: string | undefined;
    readonly utilities?: HeaderUtilities;
    readonly appMenuIcon?: string;
    readonly CTAButton?: HeaderCTAButton;
    readonly openSubmenusOnHover?: boolean;
    readonly autoSelectMenuItem?: boolean;
    readonly enableTabs?: boolean;
    readonly switchToHamburger: (useHamburger: boolean) => void;
    readonly responsiveSize: 'mobile' | 'tablet' | 'desktop';
    readonly searchIsPresentInHeader: boolean;
}

export interface MenuState {
    readonly hamburgerMenuOpen: boolean;
    readonly enforceHamburger: boolean;
    readonly maxHamburgerHeight: number;
    readonly selectedMenuItemKey: string;
    readonly expandedSubMenus: string[];
    installModalOpen: boolean;
}

class Menu extends Component<MenuProps, MenuState> {
    /**
     * Dictionary of height values to use for each menu based on the key of the top level menu items.
     * The maximum height of any submenu is used for the height of each menu in that hierarchy.
     */
    private menuHeights: { [key: string]: number };
    private headerMenuHeight: number;
    private hamburgerHeight: number;
    private hamburgerMenu: HTMLElement;
    private hamburgerTarget: HTMLElement;
    private outsideClick: { remove: () => void };

    private setOutsideClickTimeout: number;

    /**
     * The event listener object that detects when the user has moved their mouse outside of the
     * menu, or back into it.
     */
    private insideOutsideMouseoverListener: InsideOutsideEventListener;

    /**
     * Timeout for closing the menu after the mouse has been moved outside of the menu's area.
     *
     * If the mouse is moved back inside the menu's area, then the timeout is canceled.
     */
    private closeHamburgerOnMouseoutTimeout?: any; // TODO: 'number' when @types/node has been removed from the root node_modules (or the root node_modules has been removed)

    private focusMenuTimeout: any;

    private menuElRef = React.createRef<HTMLDivElement>();

    /**
     * On touch screens, when the user touches a menu item, it triggers the 'onmouseenter' event.
     * This is seemingly getting in the way of the 'onclick' event that happens at the same time. My
     * current theory is that React is updating the DOM as part of the mouseenter setState() call to
     * show the "selected" state, and we lose the 'click' event handler in the same cycle. When
     * tapping on the *same* menu item again, the 'click' event works, seemingly because React
     * doesn't need to update the DOM to show the "selected" state. This theory has some holes but
     * it's my best guess at the moment.
     *
     * As such, using the 'touchstart' event to prevent the 'mouseenter' handler from doing
     * anything (no call to setState()), thus allowing the 'onclick' handler to actually execute.
     *
     * TODO: Would keeping the same 'click' handler reference (i.e. not using an arrow function in
     * the jsx) solve this issue?
     */
    private mouseEnterDisabledDueToTouch = false;

    constructor(props: MenuProps) {
        super(props);

        this.state = {
            hamburgerMenuOpen: !!this.props.enableTabs,
            enforceHamburger: !this.props.enableTabs,
            maxHamburgerHeight: 0,
            selectedMenuItemKey: this.props.selectedMenuItemKey,
            expandedSubMenus: [],
            installModalOpen: false,
        };

        this.menuHeights = {};
        this.headerMenuHeight = 0;
        this.hamburgerHeight = 0;
    }

    componentDidMount() {
        this.addOutsideClickListener();
        this.calculateHamburgerMenuHeight();
        // to switch between Hamburger and Menu when the viewport re-sizes
        window.addEventListener('resize', this.updateMenuLocation);
    }

    componentDidUpdate(lastProps: MenuProps, lastState: MenuState) {
        const { hamburgerMenuOpen, enforceHamburger } = this.state;
        const { menuItems, selectedMenuItemKey } = this.props;

        if (lastState.hamburgerMenuOpen !== hamburgerMenuOpen) {
            if (this.setOutsideClickTimeout) clearTimeout(this.setOutsideClickTimeout); // clear any previous timeout

            this.setOutsideClickTimeout = setTimeout(() => this.addOutsideClickListener());
        }

        const hamburgerMenuWidth = this.getHamburgerMenuWidth();
        const navItemsWidth = this.getNavItemsWidth();
        const searchWidth = this.getSearchWidth();

        if (
            ((this.props.responsiveSize === 'mobile' || this.props.responsiveSize === 'tablet') &&
                !enforceHamburger) ||
            // to handle when the Search is in expanded mode
            (hamburgerMenuWidth >= navItemsWidth + searchWidth &&
                // don't switch to hamburger just because Search is in its full expansion mode
                !document.querySelector('.navigation .search.expand-search') &&
                // to not run in an infinite loop
                !enforceHamburger)
        ) {
            this.setState({
                enforceHamburger: true,
                hamburgerMenuOpen: false,
                maxHamburgerHeight: this.hamburgerHeight,
            });
            this.callSwitchToHamburgerCb(true);
        }

        if (
            lastProps.menuItems !== menuItems ||
            lastProps.selectedMenuItemKey !== selectedMenuItemKey
        ) {
            this.calculateHamburgerMenuHeight();
            this.selectMenu(selectedMenuItemKey);
        }
    }

    componentWillUnmount() {
        if (this.setOutsideClickTimeout) {
            clearTimeout(this.setOutsideClickTimeout);
        }
        this.outsideClick.remove();
        if (this.insideOutsideMouseoverListener) {
            this.insideOutsideMouseoverListener.remove();
        }
        this.cancelScheduledCloseHamburger();

        window.removeEventListener('resize', this.updateMenuLocation);
    }

    addOutsideClickListener() {
        const elements: any[] = [];

        if (this.hamburgerTarget) {
            elements.push(this.hamburgerTarget);
        }
        if (this.hamburgerMenu) {
            elements.push(this.hamburgerMenu);
        }

        if (this.outsideClick) {
            this.outsideClick.remove();
        }
        this.outsideClick = outy(elements, ['click', 'touchstart'], this.closeHamburgerMenu);

        if (this.insideOutsideMouseoverListener) {
            this.insideOutsideMouseoverListener.remove(); // remove previous one
        }
        this.insideOutsideMouseoverListener = new InsideOutsideEventListener(
            elements,
            'mouseover',
            {
                onOutside: this.scheduleCloseHamburger,
                onInside: this.cancelScheduledCloseHamburger,
            }
        );
    }

    /**
     * When the user mouses-out from the menu, we want to schedule it to close in half a second
     */
    private scheduleCloseHamburger = () => {
        if (this.props.openSubmenusOnHover && !this.closeHamburgerOnMouseoutTimeout) {
            this.closeHamburgerOnMouseoutTimeout = setTimeout(this.closeHamburgerMenu, 500);
        }
    };

    /**
     * When the user mouses-out from the menu, we schedule for it to close in a timeout. However,
     * if the user mouses back into the menu, we want to cancel that scheduled close of the menu.
     *
     * This is also used to clean up the timeout when the menu is closed, or the component is
     * destroyed.
     */
    private cancelScheduledCloseHamburger = () => {
        if (this.closeHamburgerOnMouseoutTimeout) {
            clearTimeout(this.closeHamburgerOnMouseoutTimeout);

            this.closeHamburgerOnMouseoutTimeout = undefined;
        }
    };

    // triggered on re-size of the screen to move the menu to either the header or the hamburger
    updateMenuLocation = () => {
        if (this.props.responsiveSize === 'mobile' || this.props.responsiveSize === 'tablet') {
            this.setState({
                enforceHamburger: true,
                hamburgerMenuOpen: false,
                maxHamburgerHeight: this.hamburgerHeight,
            });
            this.callSwitchToHamburgerCb(true);
        } else {
            const hamburgerMenuWidth = this.getHamburgerMenuWidth();
            const navItemsWidth = this.getNavItemsWidth();

            if (hamburgerMenuWidth >= navItemsWidth) {
                this.setState({
                    enforceHamburger: true,
                    hamburgerMenuOpen: false,
                    maxHamburgerHeight: this.hamburgerHeight,
                });
                this.callSwitchToHamburgerCb(true);
            } else if (hamburgerMenuWidth < navItemsWidth) {
                this.setState({
                    enforceHamburger: false,
                    hamburgerMenuOpen: false,
                    maxHamburgerHeight: this.headerMenuHeight,
                });
                this.callSwitchToHamburgerCb(false);
            }
        }
    };

    callSwitchToHamburgerCb(val: boolean) {
        if (this.props.switchToHamburger) {
            this.props.switchToHamburger(val);
        }
    }

    private getHamburgerMenuWidth(): number | undefined {
        return this.hamburgerMenu && this.hamburgerMenu.offsetWidth;
    }

    private getNavItemsWidth(): number | undefined {
        const navItemsEl = document.querySelector('.navigation .nav-items') as HTMLElement | null; // TODO: Remove query selector
        return navItemsEl && navItemsEl.offsetWidth;
    }

    private getSearchWidth(): number | undefined {
        const searchEl = document.querySelector('.search') as HTMLElement | null;
        return searchEl && searchEl.offsetWidth;
    }

    calculateHamburgerMenuHeight() {
        let primaryItemsCount = 0;
        let secondaryItemsMax = 0;
        let tertiaryItemsMax = 0;
        let tertiaryItemsCount = 0;

        const menuItems = this.getNormalizedMenuItems();
        const utilityMenuItems = this.getUtilityMenuItems();
        const hasSeparator = menuItems.length > 0 && utilityMenuItems.length > 0;

        [...menuItems, ...utilityMenuItems].forEach(menuItem => {
            primaryItemsCount += 1;
            let secondaryItemsCount = 0;

            menuItem.submenus.forEach(submenuItem => {
                if (submenuItem.header) secondaryItemsCount += 1;
                if (submenuItem.submenuItems) {
                    secondaryItemsCount += submenuItem.submenuItems.length;

                    tertiaryItemsCount = 0;
                    submenuItem.submenuItems.forEach(menuItem => {
                        menuItem.submenus.forEach(submenuItem => {
                            if (submenuItem.header) tertiaryItemsCount += 1;
                            tertiaryItemsCount += submenuItem.submenuItems.length;
                        });

                        if (tertiaryItemsCount > tertiaryItemsMax)
                            tertiaryItemsMax = tertiaryItemsCount;
                    });
                }
                if (secondaryItemsCount > secondaryItemsMax) {
                    secondaryItemsMax = secondaryItemsCount;
                }

                this.menuHeights[menuItem.key] = Math.max(
                    secondaryItemsCount * menuItemHeight,
                    tertiaryItemsCount * menuItemHeight
                );
            });
        });

        // Add an item if the CTA button is displayed
        if (!_.isEmpty(this.props.CTAButton) && this.props.responsiveSize !== 'desktop') {
            primaryItemsCount += 1;
        }

        // when Menu is active
        this.headerMenuHeight = Math.max(
            secondaryItemsMax * menuItemHeight,
            tertiaryItemsMax * menuItemHeight
        );

        // when Hamburger is active
        this.hamburgerHeight = Math.max(
            primaryItemsCount * menuItemHeight + (hasSeparator ? 16 : 0),
            secondaryItemsMax * menuItemHeight,
            tertiaryItemsMax * menuItemHeight
        );

        if (this.props.enableTabs && !this.state.enforceHamburger) {
            this.setState({
                maxHamburgerHeight: this.headerMenuHeight,
            });
        } else {
            this.setState({
                maxHamburgerHeight: this.hamburgerHeight,
            });
        }
    }

    selectMenu(selectedKey: string) {
        this.setState({ selectedMenuItemKey: selectedKey });
    }

    toggleHamburgerMenu = event => {
        event.preventDefault();
        if (this.state.hamburgerMenuOpen) {
            this.closeHamburgerMenu();
        } else {
            this.openHamburgerMenu();
        }
    };

    openHamburgerMenu() {
        this.setState({ hamburgerMenuOpen: true });
        // Focus on the list element so that keyboard events can be captured once it is open.
        // Timeout is needed so that focus is called only once the item has been shown.
        this.focusMenuTimeout = setTimeout(() => {
            const menuListEl = this.menuElRef.current.querySelector(
                '[data-cy="header.menuItemsContainer"]'
            );
            if (menuListEl) {
                const items = menuListEl.getElementsByTagName('li');
                if (items.length > 0) {
                    items[0].focus();
                }
            }
        }, 10);
    }

    closeHamburgerMenu = () => {
        this.cancelScheduledCloseHamburger();
        if (this.focusMenuTimeout) {
            clearTimeout(this.focusMenuTimeout);
        }

        this.setState(state => {
            if (state.hamburgerMenuOpen || state.expandedSubMenus.length > 0) {
                return {
                    hamburgerMenuOpen: false,
                    expandedSubMenus: [],
                };
            }
        });
    };

    showSubmenu(menuItemKey: string) {
        const menuItemsTree = this.getMenuItemsTree();
        this.setState({
            expandedSubMenus: menuItemsTree.getPathToMenuItem(menuItemKey),
        });
    }

    hideSubmenu(menuItemKey: string) {
        const menuItemsTree = this.getMenuItemsTree();
        const menuItem = menuItemsTree.getMenuItem(menuItemKey);
        if (menuItem.submenus.length > 0) {
            const path = menuItemsTree.getPathToMenuItem(menuItemKey);
            this.setState({
                expandedSubMenus: path.slice(0, path.length - 1),
            });
        }
    }

    toggleInstallModal = () => {
        this.setState({ installModalOpen: !this.state.installModalOpen });
    };

    /* eslint-disable */
    render() {
        const {
            hamburgerMenuOpen,
            maxHamburgerHeight,
            selectedMenuItemKey,
            enforceHamburger,
            expandedSubMenus,
        } = this.state;
        const { appMenuIcon, enableTabs, responsiveSize, type, CTAButton, utilities } = this.props;
        const {
            preventMouseEnterOnTouchDevices,
            onMenuItemClick,
            onMenuItemKeyDown,
            onSubmenuBackClick,
            onMenuItemMouseEnter,
            onMenuItemMouseLeave,
            closeHamburgerMenu,
            menuHeights,
        } = this;

        const _this = this;

        const menuItems = this.getNormalizedMenuItems();
        const utilityMenuItems = this.getUtilityMenuItems();
        const menuItemsTree = this.getMenuItemsTree();

        const ctaBtn = !_.isEmpty(CTAButton) ? (
            <CallToActionButton
                {...CTAButton}
                className="cta-container"
                data-cy="header.ctaButton"
            />
        ) : null;

        return (
            <div
                ref={this.menuElRef}
                className={cx({
                    'hamburger-menu-container': true,
                    'header-menu-enabled': enableTabs && !enforceHamburger,
                    'hide-app-menu': appMenuIcon,
                })}
                data-cy="header.menu"
                data-menu-type={type}
            >
                {/* Hamburger Icon */}
                <a
                    className={cx({
                        'gs-hamburger': true,
                        clickable: true,
                        'is-active': hamburgerMenuOpen,
                        'gs-uitk-app-menu-icon-container': appMenuIcon,
                    })}
                    href="#"
                    onClick={this.toggleHamburgerMenu}
                    ref={this.setHamburgerTarget}
                    data-cy="header.hamburgerIcon"
                >
                    {appMenuIcon ? (
                        <i className={`app-menu-icon ${appMenuIcon}`} />
                    ) : (
                        <div className="hamburger-box">
                            <div className="hamburger-inner" />
                        </div>
                    )}
                </a>

                <div
                    className={cx({
                        'hamburger-menu': true,
                        'show-hamburger-menu':
                            (enableTabs && !enforceHamburger) || hamburgerMenuOpen,
                    })}
                    ref={this.setHamburgerMenu}
                >
                    <ul
                        className={cx({
                            'hamburger-menu-list': true,
                            'enforce-auto-height': enableTabs && !enforceHamburger,
                        })}
                        style={{ height: maxHamburgerHeight }}
                        data-cy="header.menuItemsContainer"
                    >
                        {/* Menu Items */}
                        {menuItems.map(generateMenuItemComponent)}

                        {/* Separator between Menu Items and Utility Menu Items */}
                        {menuItems.length > 0 && utilityMenuItems.length > 0 && (
                            <li className="hamburger-list-item" />
                        )}

                        {/* "Utility" Menu Items that have been moved from the header into the hamburger for mobile */}
                        {utilityMenuItems.map(generateMenuItemComponent)}

                        {utilities && utilities.install ? (
                            <InstallModal
                                display={this.state.installModalOpen}
                                toggle={this.toggleInstallModal}
                                // Won't work without typecasting as utilities.install can also be a boolean
                                installPromptEvent={
                                    (utilities.install as HeaderInstallEventContainer)
                                        .installPromptEvent
                                }
                            />
                        ) : null}

                        {/* CTA Button on mobile and tablet */}
                        {(this.props.responsiveSize === 'mobile' ||
                            this.props.responsiveSize === 'tablet') && (
                            <li className="cta-list-item">
                                <div className="cta-container">{ctaBtn}</div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        );

        function generateMenuItemComponent(menuItem: NormalizedHeaderMenuItem): JSX.Element {
            if (menuItem.utilityId === 'install') {
                menuItem.callback = _this.toggleInstallModal;
            }

            return (
                <MenuItem
                    key={menuItem.key}
                    menuItemsTree={menuItemsTree}
                    menuItem={menuItem}
                    menuHeight={type === 'header' ? menuHeights[menuItem.key] : maxHamburgerHeight}
                    selectedMenuItemKey={selectedMenuItemKey}
                    depth={0}
                    enableTabs={enableTabs}
                    enforceHamburger={enforceHamburger}
                    responsiveSize={responsiveSize}
                    expandedSubMenus={expandedSubMenus}
                    maxHamburgerHeight={maxHamburgerHeight}
                    preventMouseEnterOnTouchDevices={preventMouseEnterOnTouchDevices}
                    onMenuItemClick={onMenuItemClick}
                    onMenuItemKeyDown={onMenuItemKeyDown}
                    onSubmenuBackClick={onSubmenuBackClick}
                    onMenuItemMouseEnter={onMenuItemMouseEnter}
                    onMenuItemMouseLeave={onMenuItemMouseLeave}
                    closeHamburgerMenu={closeHamburgerMenu}
                />
            );
        }
    }

    private setHamburgerTarget = (element: HTMLElement) => {
        this.hamburgerTarget = element;
    };

    private setHamburgerMenu = (element: HTMLElement) => {
        this.hamburgerMenu = element;
    };

    /**
     * Returns the combination of the user-provided MenuItems, and the utilities ('profile',
     * 'notification', 'install, 'settings', 'help') that may have been moved to the Menu for mobile/tablet
     * devices.
     */
    private getCombinedMenuItems(): NormalizedHeaderMenuItem[] {
        const menuItems = this.getNormalizedMenuItems();
        const utilityMenuItems = this.getUtilityMenuItems(); // the "utilities" that have been moved into the hamburger menu depending on the screen size (mobile, tablet, desktop) in order to save room in the header itself

        return this.doGetCombinedMenuItems(menuItems, utilityMenuItems);
    }

    /**
     * Memoized version of {@link #getCombinedMenuItems} for this Menu instance.
     *
     * This is mainly to make sure we maintain the same array instance for memoized functions that
     * rely on it.
     */
    private doGetCombinedMenuItems = memoizeOne(
        (menuItems: NormalizedHeaderMenuItem[], utilityMenuItems: NormalizedHeaderMenuItem[]) => {
            return [...menuItems, ...utilityMenuItems];
        }
    );

    /**
     * Helper method which always returns the *normalized* HeaderMenuItem instances.
     *
     * This method makes sure to normalize all deprecated/legacy properties in these objects, and
     * returns the result. It also checks that the menu has all unique keys, or otherwise rewrites
     * them to become unique while providing a console warning to the developer.
     *
     * This method may be called multiple times performantly, where the normalization only happens
     * again if the 'menuItems' prop changes.
     */
    private getNormalizedMenuItems(): NormalizedHeaderMenuItem[] {
        return this.normalizeMenuItems(this.props.menuItems || []);
    }

    /**
     * Memoized version of {@link #normalizeMenuItems} for this Menu instance.
     */
    private normalizeMenuItems = memoizeOne(normalizeMenuItems);

    /**
     * Generates the Menu Items for the "utilities" that have been moved into the hamburger menu
     * on mobile/tablet devices.
     *
     * See the description of the {@link generateUtilityMenuItems} function for more details.
     */
    private getUtilityMenuItems() {
        return this.generateUtilityMenuItems(
            this.props.utilities || emptyObj,
            this.props.responsiveSize,
            this.props.searchIsPresentInHeader
        );
    }

    /**
     * Memoized version of {@link #getUtilityMenuItems} for this Menu instance.
     */
    private generateUtilityMenuItems = memoizeOne(generateUtilityMenuItems);

    /**
     * Creates and retrieves the HeaderMenuItemsTree instance used to query menu items. This
     * includes the menu items that the user has specified, as well as the utility menu items.
     *
     * This method may be called multiple times performantly, where the tree is only recreated
     * if the 'menuItems' prop changes.
     */
    private getMenuItemsTree() {
        return this.createMenuItemsTree(this.getCombinedMenuItems());
    }

    /**
     * Memoized version of {@link #getMenuItemsTree} for this Menu instance.
     */
    private createMenuItemsTree = memoizeOne((combinedMenuItems: NormalizedHeaderMenuItem[]) => {
        return new HeaderMenuItemsTree(combinedMenuItems);
    });

    /**
     * Handles when the user begins to touch a menu item (touch screens only) by preventing the
     * 'mouseenter' event handler from running on these devices.
     *
     * There is currently an issue that on touch devices, tapping a menu item triggers the
     * 'onmouseenter' event, which after calling setState(), prevents the 'onclick' event handler
     * from being called immediately after. See more in the 'mouseEnterDisabledDueToTouch' property
     * description.
     */
    preventMouseEnterOnTouchDevices = () => {
        this.mouseEnterDisabledDueToTouch = true; // prevent mouseenter event handler from being used - see property description
    };

    /**
     * When a menu item is moused over, open its submenu (if it has one)
     */
    onMenuItemMouseEnter = (evt: React.MouseEvent<HTMLElement>) => {
        if (this.mouseEnterDisabledDueToTouch) {
            return; // prevent mouseenter event handler from being used - see property description
        }
        const menuItem = this.getMenuItemFromEventTarget(evt);
        if (this.props.openSubmenusOnHover) {
            this.showSubmenu(menuItem.key);
        }
    };

    /**
     * When a menu item is moused out, close its submenu (if it has one)
     */
    onMenuItemMouseLeave = (evt: React.MouseEvent<HTMLElement>) => {
        if (this.mouseEnterDisabledDueToTouch) {
            return; // prevent mouseenter event handler from being used - see property description
        }
        if (this.props.openSubmenusOnHover) {
            const menuItem = this.getMenuItemFromEventTarget(evt);
            this.hideSubmenu(menuItem.key);
        }
    };

    /**
     * Handles the click to either a regular menu item which navigates the user somewhere, or a
     * submenu.
     */
    onMenuItemClick = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation(); // don't let parent menus handle this

        const menuItem = this.getMenuItemFromEventTarget(evt);
        this.handleMenuItemSelect(menuItem, evt);

        if (menuItem.submenus.length === 0) {
            this.closeHamburgerMenu();
        } else {
            this.showSubmenu(menuItem.key);
        }
    };

    /**
     * Handles a keydown event on a menu item.
     */
    onMenuItemKeyDown = (evt: React.KeyboardEvent<HTMLElement>) => {
        evt.stopPropagation(); // don't let parent menus handle this

        if (evt.keyCode) {
            if (evt.which === KeyHelpers.keyCode.ENTER) {
                const menuItem = this.getMenuItemFromEventTarget(evt);
                this.handleMenuItemSelect(menuItem, evt);

                if (menuItem.submenus.length === 0) {
                    this.closeHamburgerMenu();
                }
            } else if (evt.which === KeyHelpers.keyCode.ESCAPE) {
                // On ESC key, close the currently-visible submenu, or if none are visible, close the main menu
                const { expandedSubMenus } = this.state;
                if (expandedSubMenus.length > 0) {
                    this.setState({
                        expandedSubMenus: expandedSubMenus.slice(0, -1), // remove the last entry, which will have the effect of going back to the previous submenu
                    });
                } else {
                    this.closeHamburgerMenu();
                }
            }
        }
    };

    /**
     * Handles the selection of a menu item (either by clicking, or pressing 'enter')
     */
    private handleMenuItemSelect(
        menuItem: NormalizedHeaderMenuItem,
        evt: React.MouseEvent | React.KeyboardEvent
    ) {
        if (menuItem.callback) {
            menuItem.callback(evt);
        }
        if (menuItem.submenus.length === 0 && this.props.autoSelectMenuItem) {
            this.selectMenu(menuItem.key);
        }
    }

    /**
     * Handles a click to the 'Back' button when inside a submenu, when the menu is in hamburger
     * mode. Moves the user to the previous submenu.
     *
     * Note: this 'Back' button is only displayed inside submenus on mobile (phone) size devices.
     */
    onSubmenuBackClick = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation(); // don't let parent menus handle this

        const { expandedSubMenus } = this.state;

        this.setState({
            expandedSubMenus: expandedSubMenus.slice(0, -1), // remove the last entry, which will have the effect of going back to the previous submenu
        });
    };

    /**
     * Helper method that given an HTML event (click, keydown, etc), will retrieve the associated
     * HeaderMenuItem object instance.
     *
     * The event's currentTarget must have a `data-menu-item-key` attribute. If not, the method
     * throws.
     */
    private getMenuItemFromEventTarget(
        evt: React.SyntheticEvent<HTMLElement>
    ): NormalizedHeaderMenuItem {
        const el = evt.currentTarget;
        const menuItemKeyAttr = 'data-menu-item-key';

        if (!el.hasAttribute(menuItemKeyAttr)) {
            throw new Error(
                `GS UI Toolkit Header: The event's currentTarget element did not have the attribute '${menuItemKeyAttr}' in the element ${el.outerHTML}. This is a bug in the toolkit, please report.`
            );
        }

        const menuItemKey = el.getAttribute('data-menu-item-key');
        const menuItemsTree = this.getMenuItemsTree();
        const menuItem = menuItemsTree.getMenuItem(menuItemKey);

        if (!menuItem) {
            throw new Error(
                `GS UI Toolkit Header: The MenuItem object for key '${menuItemKey}' could not be resolved. This is a bug in the toolkit, please report.`
            );
        }
        return menuItem;
    }

    static propTypes = {
        appMenuIcon: PropTypes.string,
        openSubmenusOnHover: PropTypes.bool,
        autoSelectMenuItem: PropTypes.bool,
        CTAButton: PropTypes.shape({
            buttonText: PropTypes.string,
            callback: PropTypes.func,
            iconName: PropTypes.string,
            compact: PropTypes.bool,
        }),
        menu: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                key: PropTypes.string,
                callback: PropTypes.func,
                iconName: PropTypes.string,
                submenu: PropTypes.arrayOf(
                    PropTypes.shape({
                        header: PropTypes.string,
                        key: PropTypes.string,
                        submenuItems: PropTypes.arrayOf(
                            PropTypes.shape({
                                name: PropTypes.string,
                                key: PropTypes.string,
                                callback: PropTypes.func,
                                submenu: PropTypes.arrayOf(
                                    PropTypes.shape({
                                        header: PropTypes.string,
                                        key: PropTypes.string,
                                        submenuItems: PropTypes.arrayOf(
                                            PropTypes.shape({
                                                name: PropTypes.string,
                                                key: PropTypes.string,
                                                callback: PropTypes.func,
                                            })
                                        ),
                                    })
                                ),
                            })
                        ),
                    })
                ),
            })
        ),
        selectedMenuItem: PropTypes.string,
        enableTabs: PropTypes.bool,
        responsiveView: PropTypes.oneOf(['desktop', 'mobile', 'tablet']),
    };
}

export default Menu;
