import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';

export interface AccordionItemProps {
    heading?: React.ReactNode;
    isDefaultOpen?: boolean;
    isOpen?: boolean;
    itemToggled?: (state: boolean) => void;
    children?: React.ReactNode | React.ReactNode[];
}

export interface AccordionItemState {
    isOpen: boolean;
}

/**
 * @visibleName Accordion
 */
export class AccordionItem extends React.Component<AccordionItemProps, AccordionItemState> {
    public static propTypes: { [key in keyof AccordionItemProps]: any } = {
        heading: PropTypes.node,
        children: PropTypes.node,
        isDefaultOpen: PropTypes.bool,
        isOpen: PropTypes.bool,
        itemToggled: PropTypes.func,
    };

    constructor(props: AccordionItemProps) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen:
                this.props.isOpen !== undefined
                    ? this.props.isOpen
                    : this.props.isDefaultOpen || false,
        };
    }

    toggle(event: React.MouseEvent) {
        if (event) {
            event.preventDefault();
        }
        const nextState = !this.state.isOpen;
        this.setState({ isOpen: nextState });
        this.itemToggledCallback(nextState);
    }

    itemToggledCallback(nextState: boolean) {
        if (this.props.itemToggled && typeof this.props.itemToggled === 'function') {
            this.props.itemToggled(nextState);
        }
    }
    componentWillReceiveProps(nextProps: AccordionItemProps) {
        if (nextProps.isOpen !== undefined && nextProps.isOpen !== this.state.isOpen) {
            this.setState({ isOpen: nextProps.isOpen });
            this.itemToggledCallback(nextProps.isOpen);
        }
    }

    render() {
        const { heading, children } = this.props;
        const { isOpen } = this.state;
        const ToggleClass = isOpen ? 'card-header active' : 'card-header';
        return (
            <div data-cy="gs-uitk-accordion-item" className="card">
                <div className={ToggleClass}>
                    <a
                        data-cy="gs-uitk-accordion-item-toggle"
                        href="#"
                        onClick={this.toggle}
                        aria-expanded={isOpen}
                    >
                        {heading}
                    </a>
                </div>
                <Collapse isOpen={isOpen} className="card-body" role="presentation">
                    {children}
                </Collapse>
            </div>
        );
    }
}
