import {
    NormalizedHeaderMenuItem,
    HeaderMenuItem,
    NormalizedHeaderSubmenu,
    HeaderSubmenu,
    HeaderItemType,
    HeaderMenuItemCallbackEvent,
} from '../options';
import { uniqueId } from 'lodash';
import { getSubmenus } from './get-submenus';
import { normalizeSubmenu, normalizeMenuItem } from './normalize-menu-items';
import { logWarningOnce } from '@gs-ux-uitoolkit-common/core';

/**
 * Class which wraps a {@link HeaderMenuItem} object, and provides getters (which matches the
 * {@link NormalizedHeaderMenuItem} interface) to normalize the data retrieval from the underlying
 * object.
 *
 * This class is only used for Angular, where users of the Header may mutate the properties of
 * HeaderMenuItems, and we need to display those updates on the UI. For React, we normalize the
 * entire HeaderMenuItems tree up front, and only re-normalize when the top level menuItems array is
 * changed.
 */
export class HeaderMenuItemWrapper implements NormalizedHeaderMenuItem {
    private generatedKey: string | undefined; // a 'key' generated for the menu item if one was not provided
    private lastSubmenusArrayRef: HeaderSubmenu[] = [];
    private normalizedSubmenus: NormalizedHeaderSubmenu[] = [];

    constructor(private menuItem: HeaderMenuItem) {}

    public get name(): string | undefined {
        return this.menuItem.name;
    }

    public get key(): string {
        if (this.menuItem.key != null) {
            return this.menuItem.key;
        } else {
            return this.generatedKey != null
                ? this.generatedKey
                : (this.generatedKey = uniqueId('__menu-item-generated-key-'));
        }
    }

    public get type(): HeaderItemType {
        return this.menuItem.type || 'link';
    }

    public get href(): string | undefined {
        return this.menuItem.href;
    }

    public get iconName(): string | undefined {
        return this.menuItem.iconName;
    }

    public get callback(): ((evt: HeaderMenuItemCallbackEvent) => void) | undefined {
        return this.menuItem.callback;
    }

    public get submenus(): NormalizedHeaderSubmenu[] {
        // Special case for dropdowns, where we only want to convert the dropdowns to submenus
        // once rather than generating a new array each time as getSubmenus() would do
        const dropdowns = this.menuItem.dropdowns;
        if (dropdowns) {
            if (dropdowns !== this.lastSubmenusArrayRef) {
                this.lastSubmenusArrayRef = dropdowns;

                logWarningOnce(
                    `GS UI Toolkit Header: Use the "submenus" property instead of "dropdowns" for menu items. Support for "dropdowns" is deprecated in v10`
                );
                // only create the submenu object if the 'dropdowns' actually has elements
                this.normalizedSubmenus =
                    dropdowns.length === 0
                        ? []
                        : [
                              {
                                  header: undefined,
                                  key: uniqueId(`header-submenu-`),
                                  submenuItems: dropdowns.slice(0).map(normalizeMenuItem),
                              },
                          ];
            }
        } else {
            const submenus = getSubmenus(this.menuItem);

            // Submenus in the header can currently NOT be mutated directly due to using
            // ChangeDetectionStrategy.OnPush on the <gs-header-submenus-container> component.
            // Therefore, we can cache the results of the normalization of this array. We only need to
            // re-normalize if the array reference changes.
            if (submenus !== this.lastSubmenusArrayRef) {
                // Note: purposfully checking array references here - the array itself must be replaced by the user in order to update submenus
                this.lastSubmenusArrayRef = submenus;
                this.normalizedSubmenus = submenus.map(normalizeSubmenu);
            }
        }

        return this.normalizedSubmenus;
    }

    public get isUtility() {
        return false; // for the purpose of this wrapper being used by Angular only at the time, this always returns false as this value is only needed in the React implementation
    }

    public get utilitySubmenu() {
        return undefined;
    }
}
