import * as React from 'react';

/**
 * Helper for anything Enum related
 */
export class EnumHelper {
    public stringToEnum<T extends number>(stringRep: string, selectedEnum: any): T {
        return selectedEnum[stringRep];
    }

    public stringToStringEnum<T>(stringRep: string, enumType: any): T | null {
        const item = Object.entries(enumType).find(x => x[1] === stringRep);
        return item ? (item[1] as T) : null;
    }

    public numberToNumberEnum<T>(numberRep: number, enumType: any): T | null {
        const item = Object.entries(enumType).find(x => x[1] === numberRep);
        return item ? (item[1] as T) : null;
    }

    public getOptionsFromEnum<T, U>(enumType: T, notToInlude: U[] = []): JSX.Element[] {
        return Object.entries(enumType)
            .filter(x => !notToInlude.includes(x[1]))
            .map(x => (
                <option key={x['0']} value={x['1']}>
                    {x['0']}
                </option>
            ));
    }
}

export const enumHelper = new EnumHelper();
