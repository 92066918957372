/*
 * These colors were generated from @mq/aurora-scripts version 20191213.20191213.1975781
 * @mq/aurora-scripts/dist/colors
 */
export interface Color {
    name: ColorName; // example: 'blue'
    swatch: ColorSwatch; // example: '010'
    value: ColorValue; // example: 'blue-010'
    hex: ColorHex; // example: '#f0f4fa'
}

/**
 * List of possible color names in the design system.
 */
export type ColorName =
    | 'gray'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'lime'
    | 'green'
    | 'teal'
    | 'turquoise'
    | 'aqua'
    | 'blue'
    | 'ultramarine'
    | 'purple'
    | 'pink';

/**
 * Array of possible color names to allow for easier iteration in demos, etc.
 */
export const colorNames: ColorName[] = [
    'gray',
    'red',
    'orange',
    'yellow',
    'lime',
    'green',
    'teal',
    'turquoise',
    'aqua',
    'blue',
    'ultramarine',
    'purple',
    'pink',
];

/**
 * List of possible color swatches in the design system.
 */
export type ColorSwatch =
    | '010'
    | '020'
    | '030'
    | '040'
    | '050'
    | '060'
    | '070'
    | '080'
    | '090'
    | '100';

/**
 * List of possible color values in the design system.
 */
export type ColorValue =
    | 'gray-010'
    | 'gray-020'
    | 'gray-030'
    | 'gray-040'
    | 'gray-050'
    | 'gray-060'
    | 'gray-070'
    | 'gray-080'
    | 'gray-090'
    | 'gray-100'
    | 'aqua-010'
    | 'aqua-020'
    | 'aqua-030'
    | 'aqua-040'
    | 'aqua-050'
    | 'aqua-060'
    | 'aqua-070'
    | 'aqua-080'
    | 'aqua-090'
    | 'aqua-100'
    | 'blue-010'
    | 'blue-020'
    | 'blue-030'
    | 'blue-040'
    | 'blue-050'
    | 'blue-060'
    | 'blue-070'
    | 'blue-080'
    | 'blue-090'
    | 'blue-100'
    | 'green-010'
    | 'green-020'
    | 'green-030'
    | 'green-040'
    | 'green-050'
    | 'green-060'
    | 'green-070'
    | 'green-080'
    | 'green-090'
    | 'green-100'
    | 'lime-010'
    | 'lime-020'
    | 'lime-030'
    | 'lime-040'
    | 'lime-050'
    | 'lime-060'
    | 'lime-070'
    | 'lime-080'
    | 'lime-090'
    | 'lime-100'
    | 'orange-010'
    | 'orange-020'
    | 'orange-030'
    | 'orange-040'
    | 'orange-050'
    | 'orange-060'
    | 'orange-070'
    | 'orange-080'
    | 'orange-090'
    | 'orange-100'
    | 'pink-010'
    | 'pink-020'
    | 'pink-030'
    | 'pink-040'
    | 'pink-050'
    | 'pink-060'
    | 'pink-070'
    | 'pink-080'
    | 'pink-090'
    | 'pink-100'
    | 'purple-010'
    | 'purple-020'
    | 'purple-030'
    | 'purple-040'
    | 'purple-050'
    | 'purple-060'
    | 'purple-070'
    | 'purple-080'
    | 'purple-090'
    | 'purple-100'
    | 'red-010'
    | 'red-020'
    | 'red-030'
    | 'red-040'
    | 'red-050'
    | 'red-060'
    | 'red-070'
    | 'red-080'
    | 'red-090'
    | 'red-100'
    | 'teal-010'
    | 'teal-020'
    | 'teal-030'
    | 'teal-040'
    | 'teal-050'
    | 'teal-060'
    | 'teal-070'
    | 'teal-080'
    | 'teal-090'
    | 'teal-100'
    | 'turquoise-010'
    | 'turquoise-020'
    | 'turquoise-030'
    | 'turquoise-040'
    | 'turquoise-050'
    | 'turquoise-060'
    | 'turquoise-070'
    | 'turquoise-080'
    | 'turquoise-090'
    | 'turquoise-100'
    | 'ultramarine-010'
    | 'ultramarine-020'
    | 'ultramarine-030'
    | 'ultramarine-040'
    | 'ultramarine-050'
    | 'ultramarine-060'
    | 'ultramarine-070'
    | 'ultramarine-080'
    | 'ultramarine-090'
    | 'ultramarine-100'
    | 'yellow-010'
    | 'yellow-020'
    | 'yellow-030'
    | 'yellow-040'
    | 'yellow-050'
    | 'yellow-060'
    | 'yellow-070'
    | 'yellow-080'
    | 'yellow-090'
    | 'yellow-100';

/**
 * List of possible color hex values in the design system.
 */
export type ColorHex =
    | '#f2f5f7'
    | '#dce3e8'
    | '#c1ccd6'
    | '#9fb1bd'
    | '#7a909e'
    | '#5b7282'
    | '#3e5463'
    | '#2a3f4d'
    | '#1c2b36'
    | '#0e171f'
    | '#ebf3f7'
    | '#c9e7f5'
    | '#8bd3f7'
    | '#48b8f0'
    | '#1195d6'
    | '#0073ba'
    | '#08548a'
    | '#0e3d66'
    | '#0c2b45'
    | '#0b1724'
    | '#f0f4fa'
    | '#d4e4fa'
    | '#adccf7'
    | '#75b1ff'
    | '#3d8df5'
    | '#186ade'
    | '#0d4ea6'
    | '#103a75'
    | '#11294d'
    | '#0d1826'
    | '#ebf7ed'
    | '#c7ebd1'
    | '#88dba8'
    | '#43c478'
    | '#16a163'
    | '#077d55'
    | '#075e45'
    | '#094536'
    | '#092e25'
    | '#081a15'
    | '#ebf7da'
    | '#d5f0b1'
    | '#aad971'
    | '#78bf39'
    | '#52a31d'
    | '#3c7d0e'
    | '#2e5c0e'
    | '#254211'
    | '#1c2e10'
    | '#121a0d'
    | '#fcf2eb'
    | '#fcddc7'
    | '#fcbc97'
    | '#fc9162'
    | '#e86427'
    | '#bf4815'
    | '#8f3415'
    | '#632b17'
    | '#402117'
    | '#1f1410'
    | '#fcf0f8'
    | '#f7daed'
    | '#f7b7e2'
    | '#fa87d4'
    | '#ed4cb7'
    | '#cc1d92'
    | '#961574'
    | '#6b155a'
    | '#47153f'
    | '#241020'
    | '#f7f2fc'
    | '#eadcfc'
    | '#dabefa'
    | '#c89afc'
    | '#ac71f0'
    | '#8f49de'
    | '#6b30ab'
    | '#4c277d'
    | '#331f4d'
    | '#1c1229'
    | '#fcf0f0'
    | '#fadcd9'
    | '#fabbb4'
    | '#fc9086'
    | '#fa5343'
    | '#d91f11'
    | '#a1160a'
    | '#75160c'
    | '#4f150f'
    | '#24120c'
    | '#ebf5f4'
    | '#beebe7'
    | '#86d9d4'
    | '#4ebfb9'
    | '#279c9c'
    | '#167b7d'
    | '#155c5e'
    | '#124241'
    | '#102e2d'
    | '#0c1a19'
    | '#ebf5f4'
    | '#c7e8ed'
    | '#81d8e6'
    | '#45bcd1'
    | '#159ab3'
    | '#067a91'
    | '#09596b'
    | '#0c424d'
    | '#102d33'
    | '#0f181a'
    | '#f2f3fa'
    | '#dee0fa'
    | '#c2c7fc'
    | '#a0a7fa'
    | '#7681fc'
    | '#545fe8'
    | '#3a45b0'
    | '#2f337d'
    | '#23254d'
    | '#141429'
    | '#faf6cf'
    | '#f7e379'
    | '#f5c518'
    | '#d9a514'
    | '#b3870e'
    | '#946613'
    | '#70491c'
    | '#54341f'
    | '#38251b'
    | '#1c1613';

export const colors: { [key in ColorValue]: ColorHex } = {
    'gray-010': '#f2f5f7',
    'gray-020': '#dce3e8',
    'gray-030': '#c1ccd6',
    'gray-040': '#9fb1bd',
    'gray-050': '#7a909e',
    'gray-060': '#5b7282',
    'gray-070': '#3e5463',
    'gray-080': '#2a3f4d',
    'gray-090': '#1c2b36',
    'gray-100': '#0e171f',
    'aqua-010': '#ebf3f7',
    'aqua-020': '#c9e7f5',
    'aqua-030': '#8bd3f7',
    'aqua-040': '#48b8f0',
    'aqua-050': '#1195d6',
    'aqua-060': '#0073ba',
    'aqua-070': '#08548a',
    'aqua-080': '#0e3d66',
    'aqua-090': '#0c2b45',
    'aqua-100': '#0b1724',
    'blue-010': '#f0f4fa',
    'blue-020': '#d4e4fa',
    'blue-030': '#adccf7',
    'blue-040': '#75b1ff',
    'blue-050': '#3d8df5',
    'blue-060': '#186ade',
    'blue-070': '#0d4ea6',
    'blue-080': '#103a75',
    'blue-090': '#11294d',
    'blue-100': '#0d1826',
    'green-010': '#ebf7ed',
    'green-020': '#c7ebd1',
    'green-030': '#88dba8',
    'green-040': '#43c478',
    'green-050': '#16a163',
    'green-060': '#077d55',
    'green-070': '#075e45',
    'green-080': '#094536',
    'green-090': '#092e25',
    'green-100': '#081a15',
    'lime-010': '#ebf7da',
    'lime-020': '#d5f0b1',
    'lime-030': '#aad971',
    'lime-040': '#78bf39',
    'lime-050': '#52a31d',
    'lime-060': '#3c7d0e',
    'lime-070': '#2e5c0e',
    'lime-080': '#254211',
    'lime-090': '#1c2e10',
    'lime-100': '#121a0d',
    'orange-010': '#fcf2eb',
    'orange-020': '#fcddc7',
    'orange-030': '#fcbc97',
    'orange-040': '#fc9162',
    'orange-050': '#e86427',
    'orange-060': '#bf4815',
    'orange-070': '#8f3415',
    'orange-080': '#632b17',
    'orange-090': '#402117',
    'orange-100': '#1f1410',
    'pink-010': '#fcf0f8',
    'pink-020': '#f7daed',
    'pink-030': '#f7b7e2',
    'pink-040': '#fa87d4',
    'pink-050': '#ed4cb7',
    'pink-060': '#cc1d92',
    'pink-070': '#961574',
    'pink-080': '#6b155a',
    'pink-090': '#47153f',
    'pink-100': '#241020',
    'purple-010': '#f7f2fc',
    'purple-020': '#eadcfc',
    'purple-030': '#dabefa',
    'purple-040': '#c89afc',
    'purple-050': '#ac71f0',
    'purple-060': '#8f49de',
    'purple-070': '#6b30ab',
    'purple-080': '#4c277d',
    'purple-090': '#331f4d',
    'purple-100': '#1c1229',
    'red-010': '#fcf0f0',
    'red-020': '#fadcd9',
    'red-030': '#fabbb4',
    'red-040': '#fc9086',
    'red-050': '#fa5343',
    'red-060': '#d91f11',
    'red-070': '#a1160a',
    'red-080': '#75160c',
    'red-090': '#4f150f',
    'red-100': '#24120c',
    'teal-010': '#ebf5f4',
    'teal-020': '#beebe7',
    'teal-030': '#86d9d4',
    'teal-040': '#4ebfb9',
    'teal-050': '#279c9c',
    'teal-060': '#167b7d',
    'teal-070': '#155c5e',
    'teal-080': '#124241',
    'teal-090': '#102e2d',
    'teal-100': '#0c1a19',
    'turquoise-010': '#ebf5f4',
    'turquoise-020': '#c7e8ed',
    'turquoise-030': '#81d8e6',
    'turquoise-040': '#45bcd1',
    'turquoise-050': '#159ab3',
    'turquoise-060': '#067a91',
    'turquoise-070': '#09596b',
    'turquoise-080': '#0c424d',
    'turquoise-090': '#102d33',
    'turquoise-100': '#0f181a',
    'ultramarine-010': '#f2f3fa',
    'ultramarine-020': '#dee0fa',
    'ultramarine-030': '#c2c7fc',
    'ultramarine-040': '#a0a7fa',
    'ultramarine-050': '#7681fc',
    'ultramarine-060': '#545fe8',
    'ultramarine-070': '#3a45b0',
    'ultramarine-080': '#2f337d',
    'ultramarine-090': '#23254d',
    'ultramarine-100': '#141429',
    'yellow-010': '#faf6cf',
    'yellow-020': '#f7e379',
    'yellow-030': '#f5c518',
    'yellow-040': '#d9a514',
    'yellow-050': '#b3870e',
    'yellow-060': '#946613',
    'yellow-070': '#70491c',
    'yellow-080': '#54341f',
    'yellow-090': '#38251b',
    'yellow-100': '#1c1613',
};
