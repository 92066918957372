import React from 'react';
import PropTypes from 'prop-types';
import HeaderButton from '../../../../../shared/components/HeaderButton';

const handleClick = (id, callback) => callback(id);

const SettingsButton: React.FunctionComponent<any> = ({ id, icon, text, callback, color }) => {
    const buttonProps = {
        classes: 'settings-btn',
        key: id,
        onClick: () => handleClick(id, callback),
        color: color || 'primary',
    };
    const iconProps = icon ? { iconName: icon } : null;
    return (
        <div key={id} className="settings-btn-container">
            <HeaderButton text={text} buttonProps={buttonProps} iconProps={iconProps} />
        </div>
    );
};

SettingsButton.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
    callback: PropTypes.func,
    color: PropTypes.string,
};

export default SettingsButton;
