import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import settingItems from './items';
import { extendComponentProps } from '../../../../shared/functions';
import MoreSettings from './items/SettingsButton';

const Settings: React.FunctionComponent<any> = ({ list, callback, moreSettingsModule }) => {
    const getItem = (type, options = { id: _.uniqueId() }) =>
        settingItems[type]
            ? extendComponentProps(settingItems[type], {
                  callback,
                  ...options,
                  key: options.id,
                  optionItemType: type,
              })
            : null;

    const getItemsFromProps = () => {
        const divider = id => <hr key={id} className="divider" />;
        return list.map(item =>
            item.type === 'divider'
                ? divider(_.uniqueId('divider_'))
                : getItem(item.type, item.options)
        );
    };

    const settingItemNodes = getItemsFromProps();
    const moreSettingsLink = !_.isEmpty(moreSettingsModule) ? (
        <MoreSettings
            text="More Settings"
            callback={callback}
            color="primary"
            {...moreSettingsModule}
        />
    ) : null;
    return (
        <div className="settings">
            {settingItemNodes}
            {moreSettingsLink}
        </div>
    );
};

Settings.defaultProps = {
    list: [],
    callback: () => {},
    moreSettingsModule: {},
};

Settings.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf([
                'title',
                'toggle',
                'divider',
                'checkbox',
                'button',
                'button-group',
                'input-number',
                'radio-buttons',
                'link',
            ]),
            options: PropTypes.shape({
                id: PropTypes.string.isRequired,
                text: PropTypes.string,
                align: PropTypes.oneOf(['left', 'center', 'right']),
                active: PropTypes.bool,
                icon: PropTypes.string,
                buttons: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string.isRequired,
                        text: PropTypes.string,
                        icon: PropTypes.string,
                    })
                ),
                size: PropTypes.oneOf(['small', 'medium', 'large']),
            }),
        })
    ),
    callback: PropTypes.func,
    moreSettingsModule: PropTypes.any, // PropTypes.shape()
};

export default Settings;
