import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gs-ux-uitoolkit-react/core';
import { resolveIconName } from '@gs-ux-uitoolkit-common/icons';
import cx from 'classnames';

const CTAButton: React.FunctionComponent<any> = ({ buttonText, callback, iconName, compact }) => {
    const btnClasses = cx('cta-btn', 'clickable');
    return (
        <Button
            className={btnClasses}
            outline
            color={compact ? 'secondary' : 'primary'}
            onClick={callback}
            data-cy="header.ctaButton"
        >
            <i
                className={`gi gi-fw gi-${iconName} gsi-${resolveIconName(iconName)} cta-icon`}
                data-cy="ctaButton.icon"
            />
            {buttonText}
        </Button>
    );
};

CTAButton.defaultProps = {
    buttonText: 'Default Text',
    callback: () => {},
    iconName: '',
    compact: false,
};

CTAButton.propTypes = {
    buttonText: PropTypes.string,
    callback: PropTypes.func,
    iconName: PropTypes.string,
    compact: PropTypes.bool,
};

export default CTAButton;
