import {
    HeaderMenuItem,
    NormalizedHeaderMenuItem,
    HeaderSubmenu,
    NormalizedHeaderSubmenu,
} from '../options/navigation/header-menu-item';
import { ensureUniqueKeysInMenuItems } from './ensure-unique-keys-in-menu-items';
import { uniqueId } from 'lodash';
import { logWarningOnce } from '@gs-ux-uitoolkit-common/core';
import { getSubmenus } from './get-submenus';

// NOTE: Due to the circular nature of these functions, they must all exist in the same file rather
// than their own separate files

/**
 * Normalizes the given set of HeaderMenuItems to account for the previous (now-deprecated) property
 * names, and check for duplicate `key` properties.
 */
export function normalizeMenuItems(menuItems: HeaderMenuItem[]): NormalizedHeaderMenuItem[] {
    const normalizedMenuItems = menuItems.map(normalizeMenuItem);

    // Make sure that all keys in the menu items are unique. If they're not, they will be renamed
    // to become unique
    return ensureUniqueKeysInMenuItems(normalizedMenuItems);
}

/**
 * Normalizes a given HeaderMenuItem to account for the previous (now-deprecated) property
 * names, and to assign a `key` if missing.
 */
export function normalizeMenuItem(menuItem: HeaderMenuItem): NormalizedHeaderMenuItem {
    // Note: although our typings require the 'key' property on HeaderMenuItem, pure JS users using
    // React may not have added it.
    if (menuItem.key == null) {
        const extraMessage =
            `Please add a 'key' property to ` +
            (menuItem.name ? `the menu item with name: '${menuItem.name}'` : `all menu items`);

        logWarningOnce(
            `GS UI Toolkit Header: A 'key' property is recommended on all menu items for performance reasons when updating the header's menu. ${extraMessage}.`
        );
    }

    return {
        name: menuItem.name,
        key: menuItem.key == null ? uniqueId('headerMenuItem-') : menuItem.key,
        href: menuItem.href,
        type: menuItem.type || 'link',
        iconName: menuItem.iconName,
        callback: menuItem.callback,
        submenus: getSubmenus(menuItem).map(normalizeSubmenu),
        isUtility: false,
        utilitySubmenu: undefined,
    };
}

/**
 * Normalizes a given HeaderSubmenu to make sure to assign a `key` if missing.
 */
export function normalizeSubmenu(submenu: HeaderSubmenu): NormalizedHeaderSubmenu {
    if (submenu.key == null) {
        const extraMessage =
            `Please add a 'key' property to ` +
            (submenu.header
                ? `the submenu with header: '${submenu.header}'`
                : `all 'submenu' objects`);

        logWarningOnce(
            `GS UI Toolkit Header: A 'key' property is recommended on all submenus for performance reasons when updating the header's menu. ${extraMessage}.`
        );
    }

    return {
        header: submenu.header,
        key: submenu.key == null ? uniqueId('header-submenu-') : submenu.key,
        submenuItems: (submenu.submenuItems || []).map(normalizeMenuItem),
    };
}
