import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import SidePanelMenuItemComponent from './SidePanelMenuItem';
import SidePanelMenuPopover from './SidePanelMenuPopover';
const allMenuItems: { [key: string]: SidePanelMenuItem } = {};

export interface SidePanelSubMenuItem {
    id: string;
    text: string;
}

export interface SidePanelMenuItem {
    id: string;
    icon?: string;
    text: string;
    badge?: {
        color?: string;
        text?: string;
    };
    subMenu?: SidePanelSubMenuItem[];
}

export interface SidePanelMenuProps {
    /**
     * ID of the active/selected menu item.
     */
    activeItemKey: string | null;

    /**
     * Indicates if the component is open or collapsed.
     */
    isOpen?: boolean;

    /**
     * List of menu item objects.
     */
    menuItems?: SidePanelMenuItem[];

    /**
     * Callback called when {@link activeItemKey} changes.
     */
    onSelect: (key: string, menuItem: SidePanelMenuItem) => void;

    /**
     * Menu position.
     */
    position?: string;

    /**
     * Indicates if the icons should be displayed when panel is collapsed.
     */
    showIconsWhenCollapsed?: boolean;
}

export interface SidePanelMenuState {
    hoverOverKey: string;
    showSubMenuPopover: boolean;
    positionStyles: {};
}

export class SidePanelMenu extends React.Component<SidePanelMenuProps, SidePanelMenuState> {
    public static propTypes: { [key in keyof SidePanelMenuProps]: any } = {
        showIconsWhenCollapsed: PropTypes.bool,
        isOpen: PropTypes.bool,
        position: PropTypes.string,
        activeItemKey: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
        menuItems: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.string,
                text: PropTypes.string,
                badge: PropTypes.shape({
                    color: PropTypes.string,
                    text: PropTypes.string,
                }),
                subMenu: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string,
                        text: PropTypes.string,
                    })
                ),
            })
        ),
    };

    public static defaultProps = {
        showIconsWhenCollapsed: true,
        menuItems: [],
        activeItemKey: null,
    };

    constructor(props: SidePanelMenuProps) {
        super(props);
        this.state = {
            showSubMenuPopover: false,
            hoverOverKey: '',
            positionStyles: {},
        };
        this.menuSelect = this.menuSelect.bind(this);
        this.showMenuPopover = this.showMenuPopover.bind(this);
        this.hideMenuPopover = this.hideMenuPopover.bind(this);
        this.getMenuItems = this.getMenuItems.bind(this);
    }

    getMenuItems(menuItem: SidePanelMenuItem) {
        const { showIconsWhenCollapsed, isOpen, activeItemKey } = this.props;
        const { showSubMenuPopover, hoverOverKey, positionStyles } = this.state;
        const { id, icon, subMenu, badge, text } = menuItem;
        let menuItemActive = false;
        let isSubMenuInlineVisible = false;
        allMenuItems[id] = menuItem;
        const showIcon = !!icon && ((!isOpen && showIconsWhenCollapsed) || isOpen);
        menuItemActive = activeItemKey === id;
        const isSubMenuPopoverVisible = subMenu && showSubMenuPopover && hoverOverKey === id;
        let subMenuItems: React.ReactElement[] | undefined = undefined;
        if (subMenu) {
            subMenuItems = subMenu.map((subMenuItem: SidePanelSubMenuItem, index) => {
                const subMenuItemId = subMenuItem.id;
                let subMenuItemActive = false;
                if (index === 0 && menuItemActive) {
                    subMenuItemActive = true;
                    menuItemActive = false;
                } else if (activeItemKey === subMenuItemId) {
                    subMenuItemActive = true;
                }
                if (!isSubMenuInlineVisible) {
                    isSubMenuInlineVisible = subMenuItemActive && !!isOpen;
                }
                allMenuItems[subMenuItemId] = subMenuItem;
                if (!isOpen && subMenuItemActive) {
                    menuItemActive = true;
                }
                return (
                    <SidePanelMenuItemComponent
                        isSubMenu
                        active={subMenuItemActive}
                        showIcon={false}
                        isOpen={!!isOpen}
                        menuSelect={this.menuSelect}
                        hideMenuPopover={this.hideMenuPopover}
                        {...subMenuItem}
                        key={subMenuItemId}
                    />
                );
            });
        }
        let subMenuComponent = null;
        if (isSubMenuPopoverVisible && !isSubMenuInlineVisible) {
            subMenuComponent = (
                <SidePanelMenuPopover
                    subMenuItems={subMenuItems}
                    positionStyles={positionStyles}
                    isOpen={isOpen}
                    hideMenuPopover={this.hideMenuPopover}
                    text={text}
                    badge={badge}
                />
            );
        } else if (isSubMenuInlineVisible) {
            subMenuComponent = subMenuItems;
        }
        return (
            <React.Fragment key={id}>
                <SidePanelMenuItemComponent
                    active={menuItemActive}
                    showIcon={!!showIcon}
                    isOpen={!!isOpen}
                    menuSelect={this.menuSelect}
                    showMenuPopover={this.showMenuPopover}
                    hideMenuPopover={this.hideMenuPopover}
                    icon={icon}
                    id={id}
                    text={text}
                    badge={badge}
                    key={id}
                />
                {subMenuComponent}
            </React.Fragment>
        );
    }
    menuSelect(key: string) {
        const { onSelect } = this.props;
        const subMenu = allMenuItems[key].subMenu;
        if (subMenu) {
            onSelect(subMenu[0].id, allMenuItems[key]);
        } else {
            onSelect(key, allMenuItems[key]);
        }
    }
    showMenuPopover(key: string, e: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>) {
        const { isOpen, position } = this.props;
        const x = e.currentTarget.offsetLeft;
        const y = e.currentTarget.offsetTop;
        const sidePanelWidth = isOpen ? 320 : 64;
        let positionStyles = {};
        if (position === 'left') {
            positionStyles = {
                position: 'absolute',
                top: `${y}px`,
                left: `${x + sidePanelWidth}px`,
            };
        } else {
            positionStyles = {
                position: 'absolute',
                top: `${y}px`,
                left: `${x - 220}px`,
            };
        }
        this.setState({
            hoverOverKey: key,
            showSubMenuPopover: true,
            positionStyles,
        });
    }
    hideMenuPopover(_key: string, e: React.MouseEvent | React.FocusEvent) {
        // tslint-disable-line no-unused-variable
        // element into which the mouse enters on mouse leave from currentTarget
        // element to which listener is attached to. Can be a menu option or a sub menu option
        const { relatedTarget, currentTarget } = e;
        // Don't hide the popover if the relatedTarget lives within side panel menu which includes the popover
        if (
            relatedTarget !== window &&
            currentTarget.parentElement &&
            currentTarget.parentElement.contains(relatedTarget as Node)
        ) {
            return;
        }
        this.setState({
            hoverOverKey: '',
            showSubMenuPopover: false,
            positionStyles: {},
        });
    }
    render() {
        const { showIconsWhenCollapsed, menuItems } = this.props;
        const sidePanelMenuClassNames = cx('side-panel-menu', {
            'menu-show-icon': showIconsWhenCollapsed,
        });
        const menuItemsCopy = JSON.parse(JSON.stringify(menuItems));
        return (
            <div className={sidePanelMenuClassNames}>{menuItemsCopy.map(this.getMenuItems)}</div>
        );
    }
}
