import { Expression, ExpressionCondition, ExpressionMode } from '../../interfaces/expression';
import { globalHelper } from '../../libraries/helpers/global-helper';
import { logger } from '../../libraries/logger';
import { QuickFilter } from '../../plugin/quick-filter/quickfilter';
import { QuickFilterActionTypes } from '../actions/quick-filter/quick-filter-action';
import { QuickFilterActionKeys } from '../actions/quick-filter/quick-filter-action-keys';
import { QuickFilterState } from '../quick-filter-state';
import {
    addQuickFilter,
    cancelEditedConfigItem,
    editConfigItem,
    newConfigItem,
    removeConfigItem,
    saveEditedConfigItem,
    updateEditedConfigItem,
} from '../reducer/plugin-based-reducer/plugin-based-reducer';
export const initialQuickFilterState: QuickFilterState = {
    configItemList: [],
    editedItem: null,
    originalEditedItem: null,
};
const expression: Expression = {
    mode: ExpressionMode.Basic,
    query: { condition: ExpressionCondition.And, rules: [] },
};
export const emptyQuickFilter: QuickFilter = { expression, name: '', isEnabled: true };

/**
 * Reducer for the QuickFilterState
 * @param state State of the QuickFilterState
 * @param action Action received
 */
export const quickFilterReducer = (
    state: QuickFilterState = initialQuickFilterState,
    action: QuickFilterActionTypes
): QuickFilterState => {
    switch (action.type) {
        case QuickFilterActionKeys.REMOVE_QUICK_FILTER:
            return removeConfigItem(state, action);
        case QuickFilterActionKeys.EDIT_QUICK_FILTER:
            return editConfigItem(state, action);
        case QuickFilterActionKeys.NEW_QUICK_FILTER:
            return newConfigItem(state, emptyQuickFilter);
        case QuickFilterActionKeys.SAVE_EDITED_QUICK_FILTER:
            return saveEditedConfigItem(state);
        case QuickFilterActionKeys.UPDATE_EDITED_QUICK_FILTER:
            return updateEditedConfigItem(state, action);
        case QuickFilterActionKeys.CANCEL_EDITED_QUICK_FILTER:
            return cancelEditedConfigItem(state);
        case QuickFilterActionKeys.ADD_QUICK_FILTER:
            return addQuickFilter(state, action.quickFilter);
        case QuickFilterActionKeys.TOGGLE_ENABLED_QUICK_FILTER:
            const item = state.configItemList.find((quickFilter: QuickFilter) => {
                return quickFilter === action.quickFilter;
            });
            if (item) {
                const clonedItem = globalHelper.cloneObject(item);
                clonedItem.isEnabled = !clonedItem.isEnabled;
                return {
                    ...state,
                    configItemList: [
                        ...state.configItemList.filter(configItem => configItem !== item),
                        clonedItem,
                    ],
                };
            }
            logger.error(`cannot find the quickfilter item`, action.quickFilter);
            return state;
        case QuickFilterActionKeys.SET_ENABLED_QUICK_FILTER_LIST:
            const newList = [...state.configItemList];
            newList.forEach((configItem, idx) => {
                if (!configItem.isEnabled && action.quickFilterList.includes(configItem)) {
                    const clonedItem = globalHelper.cloneObject(configItem);
                    clonedItem.isEnabled = true;
                    newList[idx] = clonedItem;
                } else if (configItem.isEnabled && !action.quickFilterList.includes(configItem)) {
                    const clonedItem = globalHelper.cloneObject(configItem);
                    clonedItem.isEnabled = false;
                    newList[idx] = clonedItem;
                }
            });
            return {
                ...state,
                configItemList: newList,
            };
        default:
            return state;
    }
};
