import * as React from 'react';
import * as PropTypes from 'prop-types';

export interface AbstractInputButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
    type: string;
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
}

const AbstractInputButton: React.FunctionComponent<AbstractInputButtonProps> = ({
    type,
    children,
    ...other
}) => {
    return (
        <label className={`btn btn-primary btn-toggle ${other.className || ''}`}>
            <input {...other} type={type} />
            <span>{children}</span>
        </label>
    );
};

AbstractInputButton.propTypes = {
    children: PropTypes.node,
    type: PropTypes.string.isRequired,
};

export default AbstractInputButton;
