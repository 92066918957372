import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as Reactstrap from 'reactstrap';
import * as _ from 'lodash';
import { AlertStatus, logWarningOnce } from '@gs-ux-uitoolkit-common/core';

export { AlertStatus };

// Taken and modified from node_modules/@types/reactstrap/lib/Alert.d.ts
export interface UncontrolledAlertProps extends React.HTMLAttributes<HTMLElement> {
    children?: React.ReactNode;
    className?: string;
    closeClassName?: string;
    closeAriaLabel?: string;
    color?: string; // deprecated - set as string so to not break current usages
    cssModule?: { [key: string]: any };
    fade?: boolean;
    tag?: string | React.ReactType;
    transition?: Reactstrap.FadeProps;
    innerRef?: any;
    // Added For OneGS
    status?: AlertStatus;
}

// Taken and modified from node_modules/@types/reactstrap/lib/Alert.d.ts
export interface AlertProps extends UncontrolledAlertProps {
    isOpen?: boolean;
    toggle?: React.MouseEventHandler<any>;
}

const alertStatuses: AlertStatus[] = ['none', 'information', 'success', 'warning', 'error'];

// Taken and modified from node_modules/reactstrap/src/Alert.js
const uncontrolledAlertPropTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    closeClassName: PropTypes.string,
    closeAriaLabel: PropTypes.string,
    color: PropTypes.string, // deprecated - set as string so to not break current usages
    cssModule: PropTypes.object,
    fade: PropTypes.bool,
    tag: (Reactstrap as any).Util.tagPropType,
    transition: PropTypes.shape((Reactstrap as any).Fade.propTypes),
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
    // Added For OneGS
    status: PropTypes.oneOf(alertStatuses),
};

const alertPropTypes = {
    ...uncontrolledAlertPropTypes,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
};

// Taken and modified from node_modules/reactstrap/src/Alert.js
const uncontrolledAlertDefaultProps: UncontrolledAlertProps = {
    tag: 'div',
    closeAriaLabel: 'Close',
    fade: true,
    transition: {
        ...(Reactstrap as any).Fade.defaultProps,
        unmountOnExit: true,
    },
};
const alertDefaultProps: AlertProps = {
    ...uncontrolledAlertDefaultProps,
    isOpen: true,
};

/**
 * Alerts provide feedback messages for typical user actions, usually at page-level or
 *    component-level (for example, at the top of a grid).
 */
export const Alert: React.FC<AlertProps> = props => {
    const { color: deprecatedColor, status, ...other } = props;
    const color = getColor(status, deprecatedColor);
    return <Reactstrap.Alert color={color} {...other} />;
};
Alert.propTypes = alertPropTypes;
Alert.defaultProps = alertDefaultProps;

export const UncontrolledAlert: React.FC<UncontrolledAlertProps> = props => {
    const { color: deprecatedColor, status, ...other } = props;
    const color = getColor(status, deprecatedColor);
    return <Reactstrap.UncontrolledAlert color={color} {...other} />;
};
UncontrolledAlert.propTypes = uncontrolledAlertPropTypes;
UncontrolledAlert.defaultProps = uncontrolledAlertDefaultProps;

function getColor(status: AlertStatus | undefined, color: string | undefined) {
    const colorMap: {
        [key: string]: AlertStatus;
    } = {
        primary: 'information',
        secondary: 'none',
        light: 'none',
        dark: 'none',
        success: 'success',
        danger: 'error',
        warning: 'warning',
        info: 'information',
    };

    if (color != undefined && status == undefined) {
        logWarningOnce(
            `Alert: As part of OneGS the "color" prop has been deprecated. ` +
                `Use the "status" prop instead. ` +
                `Please see http://ui.web.gs.com/toolkit/components/alerts. `
        );
        status = colorMap[color];
    }

    // Set default
    if (status == undefined) {
        status = 'information';
    }

    return status;
}
