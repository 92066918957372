import { isTabProps } from './Tab';

export const containsActiveTab: any = (
    tabs: React.ReactElement[],
    activeTabKey: string | number
): boolean => tabs.some(({ props: { tabKey } }) => tabKey === activeTabKey);

export const isDropdown: any = (tabChildren: React.ReactElement[]): boolean =>
    tabChildren !== undefined &&
    Object.prototype.toString.call(tabChildren) === '[object Array]' &&
    tabChildren.some(child => isTabProps(child.props));
