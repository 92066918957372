import React from 'react';
import ReactDOM from 'react-dom';

import {
    CommonSelectMultipleComponent,
    CommonSelectMultipleProps,
    createOptionRendererData,
    OptionRendererData,
} from '@gs-ux-uitoolkit-common/select';
import { extractDataAttributes } from '@gs-ux-uitoolkit-react/shared';

import { SelectMultipleProps } from './select-multiple-props';

/**
 * The SelectMultiple component is used to select multiple options from a list of options.
 * If you need to select a single option please use the [Select](#/Components/Select) component.
 *
 * **Terminology**
 * - **option:** is defined as a single option inside the SelectMultiple's dropdown.
 * - **selectedOption:** is defined as a single option which is currently active in he input box.
 *
 * <small>Note: SelectMultiple is currently powered by the excellent [Choices.js](https://github.com/jshjohnson/Choices)
 * library. However, please do not rely on this fact because the underlying implementation may be
 * changed at a future date as new business requirements and feature requests come forward.
 * The UI Toolkit SelectMultiple API will remain the same, however, regardless of the underlying
 * implementation.</small><br><br>
 */
export class SelectMultiple extends React.Component<SelectMultipleProps, {}> {
    public static defaultProps = {
        className: undefined,
        style: undefined,
        disabled: false,
        filterOptionsOnSearch: true,
        noResultsContent: 'No results found',
        noOptionsContent: 'No options to choose from',
        options: [],
        pasteEnabled: true,
        removeButton: true,
        optionRenderer: null,
        selectedOptionRenderer: null,
        onDropdownHide: undefined,
        onDropdownShow: undefined,
        onSearch: undefined,
        initialValues: null,
        maxSelections: -1,
        maxSelectionsContent: 'Maximum selected options reached',
        renderSelectedOptions: false,
        sortSelectedOptions: false,
        values: undefined,
        onChange: undefined,
    };

    private selectContainerEl: HTMLDivElement | undefined;
    private commonSelectMultipleComponent!: CommonSelectMultipleComponent;

    public componentDidMount() {
        this.commonSelectMultipleComponent = new CommonSelectMultipleComponent(
            this.selectContainerEl!,
            this.formatReactProps(this.props)
        );
    }

    public componentDidUpdate(prevProps: SelectMultipleProps) {
        this.commonSelectMultipleComponent.setConfig(
            this.formatReactProps(this.props),
            this.formatReactProps(prevProps)
        );
    }

    public componentWillUnmount() {
        if (this.commonSelectMultipleComponent) {
            this.commonSelectMultipleComponent.destroy();
        }
    }

    /**
     * Function used to focus the select component.
     * This will focus the input element and open the dropdown.
     * @public
     */
    public focus() {
        this.commonSelectMultipleComponent.focus();
    }

    /**
     * Function used to blur the select component.
     * This will blur the input element and close the dropdown.
     * @public
     */
    public blur() {
        this.commonSelectMultipleComponent.blur();
    }

    public render() {
        const { className, style } = this.props;
        const dataAttrs = extractDataAttributes(this.props);

        return (
            <div
                {...dataAttrs}
                className={className}
                data-gs-uitk-component="gs-uitk-select-multiple"
                style={style}
                ref={this.setSelectOuterEl}
            >
                <select multiple data-cy="gs-uitk-select-multiple-inner-select-element" />
            </div>
        );
    }

    private setSelectOuterEl = (selectOuterEl: HTMLDivElement) => {
        this.selectContainerEl = selectOuterEl;
    };

    /**
     * Function used when we need to transform any of the components props.
     * @param props - The components props to transform.
     */
    private formatReactProps(props: SelectMultipleProps): CommonSelectMultipleProps {
        const {
            optionRenderer: userOptionRenderer,
            selectedOptionRenderer: userSelectedOptionRenderer,
        } = props;
        let newOptionRenderer: CommonSelectMultipleProps['optionRenderer'];
        let newSelectedOptionRenderer: CommonSelectMultipleProps['selectedOptionRenderer'];

        if (userOptionRenderer) {
            newOptionRenderer = (dataAttributes: OptionRendererData) => {
                const result = userOptionRenderer(createOptionRendererData(dataAttributes));

                // If the developer provides us a ReactElement, render it using ReactDOM.
                if (React.isValidElement(result)) {
                    const div = document.createElement('div');
                    ReactDOM.render(result, div);
                    return div;
                } else {
                    // HTMLElement or string - let the 'common' implementation handle it
                    return result;
                }
            };
        }

        // If the developer provides us a ReactElement, render it using ReactDOM.
        if (userSelectedOptionRenderer) {
            newSelectedOptionRenderer = (dataAttributes: OptionRendererData) => {
                const result = userSelectedOptionRenderer(createOptionRendererData(dataAttributes));

                if (React.isValidElement(result)) {
                    const div = document.createElement('div');
                    ReactDOM.render(result, div);
                    return div;
                } else {
                    // HTMLElement or string - let the 'common' implementation handle it
                    return result;
                }
            };
        }

        return {
            ...props,
            optionRenderer: newOptionRenderer as CommonSelectMultipleProps['optionRenderer'],
            selectedOptionRenderer: newSelectedOptionRenderer as CommonSelectMultipleProps['selectedOptionRenderer'],
        };
    }
}
