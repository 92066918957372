import { legacyIconMapping } from './legacy-icon-mapping';

/**
 * @const {object} allIconMappings - Object containing all icon name mappings
 */
const allIconMappings: { [key: string]: string } = {
    ...legacyIconMapping.solid.icons,
    ...legacyIconMapping.regular.icons,
    ...legacyIconMapping.brands.icons,
    ...legacyIconMapping.aliases.icons,
};

/**
 * Resolves Font Awesome names to Material Design Icon names
 *
 * @example
 * // returns 'calendar-blank'
 * resolveIconName('calendar');
 *
 * @example
 * // returns 'bookmark'
 * resolveIconName('bookmark');
 *
 * @example
 * // returns 'my-custom-icon'
 * resolveIconName('my-custom-icon');
 *
 * @param {string} iconName - Name of Font Awesome Icon to resolve to Material Design Icons Icon
 * @returns {string} The name of the Material Design Icon
 */
export function resolveIconName(iconName: string): string {
    return allIconMappings[iconName] || iconName;
}
