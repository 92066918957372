import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Progress, ProgressProps, CSSModule } from 'reactstrap';
import { logWarningOnce } from '@gs-ux-uitoolkit-common/core';
import { OmitIndexSignature } from '../customTypes';

export interface ProgressBarProps extends OmitIndexSignature<ProgressProps> {
    /**
     * Determines if the ProgressBar component is a bar or a container.
     * Used in combination with {@link multi}.
     */
    bar?: boolean;

    /**
     * Allows for multiple bars.
     */
    multi?: boolean;

    /**
     * Allows you to change the outermost html element.
     */
    tag?: string;

    /**
     * Current value to be displayed in the ProgressBar. Should be smaller or equal to "max" value.
     */
    value?: string | number;

    /**
     * Maximum value to be displayed in the ProgressBar.
     */
    max?: string | number;

    /**
     * If set to `true` bars will be animated.
     */
    animated?: boolean;

    /**
     * Will render the bars in `striped` mode.
     */
    striped?: boolean;

    /**
     * As of version 12, deprecated in favor of {@link status}.
     * @deprecated
     */
    color?: string;

    /**
     * Type of progress bar, can be one of "success", "info", "warning" or "danger".
     */
    status?: string;

    /**
     * The name of the class to apply to the outermost element of the component.
     *
     * Note: This attribute will only get applied to the outermost element if provided.
     * If not provided the outermost element will not have a `class` attribute
     */
    className?: string;

    /**
     * Used to add a class to the inner ProgressBar element.
     */
    barClassName?: string;

    /**
     * A [CSSModule](https://github.com/css-modules/css-modules) used to customize the component.
     */
    cssModule?: CSSModule;

    /**
     * A React node to render in place.
     */
    children?: React.ReactNode | React.ReactNode[];
}

export class ProgressBar extends React.Component<ProgressBarProps, {}> {
    public static propTypes: { [key in keyof ProgressBarProps]: any } = {
        children: PropTypes.node,
        bar: PropTypes.bool,
        multi: PropTypes.bool,
        tag: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        animated: PropTypes.bool,
        striped: PropTypes.bool,
        color: PropTypes.string,
        status: PropTypes.string,
        className: PropTypes.string,
        barClassName: PropTypes.string,
        cssModule: PropTypes.object,
    };

    render() {
        const { color, status } = this.props;
        if (color !== undefined) {
            logWarningOnce(
                `ProgressBar: As part of OneGS the "color" prop has been deprecated. ` +
                    `Use the "status" prop instead. ` +
                    `Please see http://ui.web.gs.com/toolkit/components/progress-bars.`
            );
        }
        const props = { ...this.props, color: status || color };
        return <Progress {...props} />;
    }
}
