import { HeaderNavigation } from '../options';
import { logWarningOnce } from '@gs-ux-uitoolkit-common/core';

/**
 * Given a HeaderNavigation object, retrieves the selected menu item key from either the preferred
 * `selectedMenuItem` property, or the legacy/deprecated `menuSelected` or `tabSelected` properties.
 *
 * If the legacy/deprecated properties are detected, a warning is emitted to the console.
 */
export function getSelectedMenuItemKey(navigation: HeaderNavigation): string | undefined {
    if (navigation.selectedMenuItem) {
        return navigation.selectedMenuItem;
    } else if (navigation.tabSelected) {
        warnOnOldSelectedMenuItemApi('tabSelected');
        return navigation.tabSelected;
    } else if (navigation.menuSelected) {
        warnOnOldSelectedMenuItemApi('menuSelected');
        return navigation.menuSelected;
    }
    return undefined;
}

function warnOnOldSelectedMenuItemApi(propertyName: 'tabSelected' | 'menuSelected') {
    logWarningOnce(
        `GS UI Toolkit Header: Use "selectedMenuItem" instead of "${propertyName}". Support for "${propertyName}" is deprecated in v10`
    );
}
