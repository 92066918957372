import * as Redux from 'redux';

// tslint:disable-next-line:no-submodule-imports
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { AllActionTypes } from './actions/all-action-types';
import { CrossModelState } from './cross-model-state';
import { crossModelReducer } from './reducers/cross-model-reducer';

/**
 * The store related to the crossmodel
 */
export class CrossModelStore {
    private reduxStore: Redux.Store<CrossModelState, AllActionTypes>;
    constructor() {
        const composeEnhancers = composeWithDevTools({
            name: `CrossModel Store`,
            // Specify name here, actionsBlacklist, actionsCreators and other options if needed
        });
        const middleware = Redux.applyMiddleware();

        this.reduxStore = Redux.createStore<CrossModelState, AllActionTypes, {}, {}>(
            crossModelReducer,
            composeEnhancers(middleware)
        );
    }

    public getReduxStore(): Redux.Store<CrossModelState, AllActionTypes> {
        return this.reduxStore;
    }
}
