import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from '@gs-ux-uitoolkit-react/core';
import ItemHeader from './Header';
import ItemInfo from './Info';
import Icon from '../../../../../shared/components/Icon';

class Notification extends Component<any, any> {
    public closePopover: any;
    public id: any;
    public icon: any;
    public primaryInfo: any;
    public secondaryInfo: any;
    public time: any;
    public callbacks: any;
    public groupedNotifications: any;
    public secondaryIcon: any;
    public secondaryIconColor: any;

    constructor(props) {
        super(props);
        this.state = {
            displayClose: false,
        };
    }

    handleMouseEnter = () => {
        this.setState({ displayClose: true });
    };

    handleMouseLeave = () => {
        this.setState({ displayClose: false });
    };

    action = () => {
        const { closePopover, action, id } = this.props;
        closePopover();
        action(id);
    };

    render() {
        const {
            icon,
            primaryInfo,
            secondaryInfo,
            time,
            closePopover,
            callbacks,
            id,
            groupedNotifications,
            secondaryIcon,
            secondaryIconColor,
        } = this.props;
        const visibleIcon =
            !groupedNotifications && icon ? (
                <Icon classes="notification-icon" iconName={icon} size="gi-lg" />
            ) : null;
        return (
            <ListGroupItem
                className="notification"
                onClick={this.action}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                {visibleIcon}
                <div className="notification-content">
                    <ItemHeader
                        primaryInfo={primaryInfo}
                        time={time}
                        close={this.state.displayClose}
                        closePopover={closePopover}
                        dismiss={callbacks.dismiss}
                        secondaryIcon={secondaryIcon}
                        secondaryIconColor={secondaryIconColor}
                        id={id}
                    />
                    <ItemInfo secondaryInfo={secondaryInfo} />
                </div>
            </ListGroupItem>
        );
    }

    static defaultProps = {
        action: () => {},
        closePopover: () => {},
        id: '',
        icon: null,
        primaryInfo: '',
        secondaryInfo: '',
        time: '',
        callbacks: {
            dismiss: () => {},
        },
    };

    static propTypes = {
        id: PropTypes.string.isRequired,
        primaryInfo: PropTypes.string,
        secondaryInfo: PropTypes.string,
        icon: PropTypes.string,
        time: PropTypes.string,
        action: PropTypes.func,
        callbacks: PropTypes.shape({
            action: PropTypes.func,
            dismiss: PropTypes.func,
        }),
        closePopover: PropTypes.func,
        groupedNotifications: PropTypes.bool,
        secondaryIcon: PropTypes.string,
        secondaryIconColor: PropTypes.string,
    };
}

export default Notification;
