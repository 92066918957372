import {
    NormalizedHeaderMenuItem,
    NormalizedHeaderSubmenu,
} from '../options/navigation/header-menu-item';
import { forEachMenuItem } from './for-each-menu-item';

/**
 * Utility class to answer queries on the tree of MenuItems.
 */
export class HeaderMenuItemsTree {
    public readonly menuItems: NormalizedHeaderMenuItem[];

    /**
     * A map of key -> MenuItem, used for quick lookups of a MenuItem by key.
     */
    private menuItemsMap = new Map<string, NormalizedHeaderMenuItem>();

    /**
     * A map which maps a MenuItem's key to its parent MenuItem's key (if there is one)
     */
    private parentOf = new Map<string, string | null>();

    constructor(menuItems: NormalizedHeaderMenuItem[]) {
        this.menuItems = menuItems;

        // Build the data structures to query the menu
        forEachMenuItem(menuItems, (menuItem, parentMenuItem) => {
            this.menuItemsMap.set(menuItem.key, menuItem);
            this.parentOf.set(menuItem.key, parentMenuItem && parentMenuItem.key);
        });
    }

    /**
     * Retrieves a HeaderMenuItem by its `key` at any depth in the `menuItems` tree.
     *
     * @param key The key of the HeaderMenuItem to search for.
     * @return The HeaderMenuItem object if found, or otherwise `null`.
     */
    getMenuItem(key: string): NormalizedHeaderMenuItem | null {
        return this.menuItemsMap.get(key) || null;
    }

    /**
     * Based on the given `targetMenuItemKey`, creates an array which represents the path to the
     * submenu(s) which are currently displayed.
     *
     * For example, if a submenu is activated that is 3 levels deep, the returned array will
     * look something like the following, representing the path to that open submenu:
     *
     *     [ 'submenu-1', 'submenu-1-2', 'submenu-1-2-3' ]
     *
     * This allows us to properly display the submenus that should be displayed.
     *
     * @param targetMenuItemKey
     * @return The path of keys to the given `targetMenuItemKey`
     */
    getPathToMenuItem(targetMenuItemKey: string): string[] {
        // If the targetMenuItemKey does not exist in the tree, return empty array. There is no path
        // to the key
        if (!this.menuItemsMap.has(targetMenuItemKey)) {
            return [];
        }

        const path: string[] = [];
        let currentKey: string | null | undefined = targetMenuItemKey;
        while (currentKey != null) {
            path.push(currentKey);

            currentKey = this.parentOf.get(currentKey);
        }

        return path.reverse();
    }

    /**
     * Given a MenuItem which exists inside of a Submenu, retrieves the Submenus that appear prior
     * to the Submenu that contains the MenuItem.
     *
     * This method is to support a specific query by the submenus component in order to determine
     * the 'marginTop' value it should be positioned at.
     *
     * For example, with the following tree, say we were to ask for the MenuItem 'key-1-3-1':
     *
     *     [
     *         {
     *             name: "Menu Item 1",
     *             key: "key-1",
     *             submenus: [
     *                 {
     *                     key: 'submenu-1-1',  <-- this submenu object is returned
     *                     submenuItems: [
     *                         { name: 'Menu Item 1-1-1', key: 'key-1-1-1' }
     *                         { name: 'Menu Item 1-1-2', key: 'key-1-1-2' }
     *                     ]
     *                 },
     *                 {
     *                     key: 'submenu-1-2',  <-- this submenu object is returned
     *                     submenuItems: [
     *                         { name: 'Menu Item 1-2-1', key: 'key-1-2-1' }
     *                         { name: 'Menu Item 1-2-2', key: 'key-1-2-2' }
     *                     ]
     *                 },
     *                 {
     *                     key: 'submenu-1-3',
     *                     submenuItems: [
     *                         { name: 'Menu Item 1-3-1', key: 'key-1-3-1' }  <-- queried this key
     *                         { name: 'Menu Item 1-3-2', key: 'key-1-3-2' }
     *                     ]
     *                 }
     *             ]
     *         }
     *     ]
     *
     * If the `menuItemKey` is a menu item that does not exist in a Submenu, the method returns an
     * empty array.
     *
     * @param menuItemKey The key of the menu item to retrieve the submenus prior to it.
     */
    getSubmenusPriorTo(menuItemKey: string): NormalizedHeaderSubmenu[] {
        const parentKey = this.parentOf.get(menuItemKey);
        if (!parentKey) {
            return [];
        }

        const parentMenuItem = this.menuItemsMap.get(parentKey)!;
        const submenus = parentMenuItem.submenus;
        const resultSubmenus: NormalizedHeaderSubmenu[] = [];

        for (const submenu of submenus) {
            if (!submenuContains(submenu, menuItemKey)) {
                resultSubmenus.push(submenu);
            } else {
                break;
            }
        }
        return resultSubmenus;
    }
}

/**
 * Determines if the given `submenu` contains a submenu item with the key `menuItemKey`.
 *
 * Only does this in a shallow fashion. Nested levels of the tree are not checked.
 */
function submenuContains(submenu: NormalizedHeaderSubmenu, menuItemKey: string): boolean {
    for (const submenuItem of submenu.submenuItems) {
        if (submenuItem.key === menuItemKey) {
            return true;
        }
    }
    return false;
}
