import * as React from 'react';
import * as PropTypes from 'prop-types';

// Breakpoint to switch expander behavior. Should be derived from the bootstrap Breakpoint
const WINDOW_BREAKPOINT = 680;
let key = 1;

export interface ExpanderProps {
    className?: string;
    children?: React.ReactElement | React.ReactElement[];
}

export interface ExpanderState {
    isMobile: boolean;
}

export class Expander extends React.Component<ExpanderProps, ExpanderState> {
    public static propTypes: { [key in keyof ExpanderProps]: any } = {
        children: PropTypes.node,
        className: PropTypes.string,
    };

    constructor(props: ExpanderProps) {
        super(props);
        this.state = {
            isMobile: false,
        };
        this.checkWindowDimensions = this.checkWindowDimensions.bind(this);
        this.setChildProps = this.setChildProps.bind(this);
    }

    componentDidMount() {
        this.checkWindowDimensions();
        window.addEventListener('resize', this.checkWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkWindowDimensions);
    }

    checkWindowDimensions() {
        // TODO: Use logic existing in the header
        const isMobile = window.innerWidth < WINDOW_BREAKPOINT;
        // Don't change state if it's already set
        if (this.state.isMobile === isMobile) {
            return;
        }
        this.setState({ isMobile });
    }

    setChildProps(child: React.ReactElement) {
        const props = {
            isMobile: this.state.isMobile,
            key: key++,
        };
        return React.cloneElement(child, props);
    }

    render() {
        const { children, className } = this.props;

        const expanderClassNames = ['expander', className].join(' ');

        return (
            <div className={expanderClassNames}>
                {children
                    ? React.Children.map(children, (c: React.ReactNode) =>
                          this.setChildProps(c as React.ReactElement)
                      )
                    : null}
            </div>
        );
    }
}
