import isEqual from 'lodash.isequal';
import { log } from './helper';
import { AnalyticsConfig } from './interface';

class Emitter {
    public queue: any;
    public meta: any;
    public run: boolean;
    public interval: any;
    public errorCount: number;
    public options: any;

    constructor(config: AnalyticsConfig) {
        this.queue = [];
        this.meta = {};
        this.run = true;
        this.interval = null;
        this.errorCount = 0;

        this.options = config;

        this.options.REALM = this.options.PROD;
        // TODO: Set this to PROD only on certified builds

        // Start running the interval after 5 seconds
        this.interval = setInterval(() => this.emit(), this.options.INTERVAL_DURATION);
    }

    handleError() {
        // Stop running after a max number of errors have been hit
        this.errorCount += 1;
        log(
            'Emitter post error. Will try',
            this.options.ERROR_TOLERANCE - this.errorCount,
            'more times'
        );
        if (this.errorCount >= this.options.ERROR_TOLERANCE) {
            this.run = false;
            log('Emitter stopped after hitting max errors');
        }
    }

    /**
     * Takes input an event and if exact same event exists in queue, increase its count otherwise add it to queue
     */
    addDeduplicatedEventInQueue(event) {
        let exists: boolean = false;
        delete event.properties.classification.count;
        // Need to do this so as to remove keys which have their value = undefined, because the matching logic below also has every event which is JSON parsed
        event = JSON.parse(JSON.stringify(event));
        let tempQueue = JSON.parse(JSON.stringify(this.queue));
        tempQueue.forEach((seenEvent, i) => {
            delete seenEvent.properties.classification.count;
            if (
                !exists &&
                event.event === seenEvent.event &&
                isEqual(event.properties, seenEvent.properties) &&
                isEqual(event.context, seenEvent.context)
            ) {
                this.queue[i].properties.classification.count++;
                this.queue[i].timestamp = event.timestamp;
                exists = true;
            }
        });
        if (!exists) {
            event.properties.classification['count'] = 1;
            this.queue.push(event);
        }
    }

    /**
     * Posts the data as a JSON string to the configured endpoint. Does not wait
     * for a return or perform a callback. If this call fails the error will not leak to the window.
     * @param {Object} data The data to be posted to the endpoint
     */
    post(data: any) {
        log('Emitter posting data to', this.options.REALM.URL); // , 'Data:\n', data);
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.open('POST', this.options.REALM.URL);
        xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xmlhttp.withCredentials = this.options.CREDENTIALS;
        xmlhttp.onreadystatechange = () => {
            if (xmlhttp.readyState === 4 && xmlhttp.status !== 200) {
                this.handleError();
            }
        };
        xmlhttp.send(JSON.stringify(data));
    }

    emit() {
        if (!this.run) {
            // Stop the clock
            clearInterval(this.interval);
        } else if (this.queue.length > 0) {
            // if (this.queue.length) {
            const chunk = this.queue.splice(0, this.options.MAX_BATCH_ITEMS);
            log('Chunk length', chunk.length, ' | Remainaing queue length', this.queue.length);
            this.post(chunk);
            // }
        }
    }

    push(e: any, app: any, props: any) {
        if (!this.run) {
            return;
        }

        const stat = {
            token: this.options.REALM.TOKEN,
            event: e || 'Unknown Event',
            timestamp: new Date().toISOString(),
            context: {
                host: {
                    hostname: this.meta.hostname,
                    port: this.meta.port,
                },
                application: {
                    deploymentId: this.options.REALM.DASH_UI.DEPLOYMENT_ID,
                    appId: this.options.REALM.DASH_UI.APP_ID,
                    name: app.name || null,
                    component: app.component || null,
                    version: app.version || null,
                    platform: app.platform || null,
                },
                // sourceApplication: {
                //     name: this.meta.appName,
                //     title: this.meta.title,
                // },
                client: {
                    browser: {
                        userAgent: navigator.userAgent,
                    },
                    screen: {
                        height: window.screen.height,
                        width: window.screen.width,
                    },
                },
            },
            properties: props,
        };

        if (this.options.DEDUPLICATE) {
            this.addDeduplicatedEventInQueue(stat);
        } else {
            this.queue.push(stat);
        }
    }
}

export default Emitter;
