import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Pagination as ReactstrapPagination, PaginationItem, PaginationLink } from 'reactstrap';
import { PaginationNav } from './PaginationNav';

export interface PaginationProps {
    /**
     * Whether to show the first and last caret page links.
     */
    boundaryLinks?: boolean;

    /**
     * Number of items in collection.
     */
    collectionSize?: number;

    /**
     * Whether to show the left and right caret page links
     */
    directionLinks?: boolean;

    /**
     * Whether to show ellipsis symbols and first/last page numbers when {@link maxSize} > number of pages.
     */
    ellipses?: boolean;

    /**
     * First navigation page link label.
     */
    firstNavLabel?: string;

    /**
     * Last navigation page link label.
     */
    lastNavLabel?: string;

    /**
     * Maximum number of pages to display.
     */
    maxSize?: number;

    /**
     * Next navigation page link label.
     */
    nextNavLabel?: string;

    /**
     * A callback function called on page change. New page is passed as an argument.
     */
    onChange: (pageNumber?: number) => void;

    /**
     * Current page.
     */
    page?: number;

    /**
     * Number of items per page.
     */
    pageSize?: number;

    /**
     * Previous navigation page link label.
     */
    previousNavLabel?: string;

    /**
     * Whether to rotate pages when maxSize > number of pages. Current page will be in the middle.
     */
    rotate?: boolean;

    /**
     * Whether to show the "Next" and "Previous" or/and "First" and "Last" page links.
     */
    showTextLabels?: boolean;

    /**
     * Size of the component.
     */
    size?: 'sm' | 'lg';
}

export interface PaginationState {
    page: number;
    pageCount: number;
    size?: string;
    maxSize?: number;
    ellipses?: boolean;
    rotate?: boolean;
    boundaryLinks?: boolean;
    directionLinks?: boolean;
    showTextLabels?: boolean;
    firstNavLabel?: string;
    lastNavLabel?: string;
    previousNavLabel?: string;
    nextNavLabel?: string;
}

/**
 * Numbered pagination provides an easy way to navigate through lots of content that doesn't comfortably fit on a single page or an infinite scrolling page.
 *
 * Well-established UI conventions such as pagination only require you to use  them 'as is'. Don't try to do anything unusual or you'll confuse users.
 *
 * @visibleName Pagination
 */
export class Pagination extends React.Component<PaginationProps, PaginationState> {
    public static defaultProps = {
        page: 1,
        pageSize: 10,
        maxSize: 0,
        ellipses: true,
        rotate: false,
        boundaryLinks: false,
        directionLinks: true,
        showTextLabels: false,
        firstNavLabel: 'First',
        lastNavLabel: 'Last',
        previousNavLabel: 'Previous',
        nextNavLabel: 'Next',
    };

    public static propTypes: { [key in keyof PaginationProps]: any } = {
        size: PropTypes.string,
        collectionSize: PropTypes.number,
        page: PropTypes.number,
        onChange: PropTypes.func,
        pageSize: PropTypes.number,
        maxSize: PropTypes.number,
        ellipses: PropTypes.bool,
        rotate: PropTypes.bool,
        boundaryLinks: PropTypes.bool,
        directionLinks: PropTypes.bool,
        showTextLabels: PropTypes.bool,
        firstNavLabel: PropTypes.string,
        lastNavLabel: PropTypes.string,
        previousNavLabel: PropTypes.string,
        nextNavLabel: PropTypes.string,
    };

    constructor(props: PaginationProps) {
        super(props);
        const pageCount = this.initPageCount(props.collectionSize, props.pageSize);
        const page = this.initPageValue(props.page, pageCount);
        this.state = {
            page,
            pageCount,
            size: props.size,
            maxSize: props.maxSize || 0,
            ellipses: typeof props.ellipses !== 'undefined' ? props.ellipses : true,
            rotate: props.rotate || false,
            boundaryLinks: props.boundaryLinks || false,
            directionLinks:
                typeof props.directionLinks !== 'undefined' ? props.directionLinks : true,
            showTextLabels: props.showTextLabels || false,
            firstNavLabel: props.firstNavLabel || 'First',
            lastNavLabel: props.lastNavLabel || 'Last',
            previousNavLabel: props.previousNavLabel || 'Previous',
            nextNavLabel: props.nextNavLabel || 'Next',
        };
        this.handleClick = this.handleClick.bind(this);
        this.hasPrevious = this.hasPrevious.bind(this);
        this.hasNext = this.hasNext.bind(this);
        this.isNumber = this.isNumber.bind(this);
    }

    componentWillReceiveProps(nextProps: PaginationProps) {
        let newState: PaginationState = {} as PaginationState;
        if (
            this.props.page !== nextProps.page ||
            this.props.pageSize !== nextProps.pageSize ||
            this.props.collectionSize !== nextProps.collectionSize
        ) {
            const pageCount = this.initPageCount(nextProps.collectionSize, nextProps.pageSize);
            const page = this.initPageValue(nextProps.page || this.state.page, pageCount);
            newState.pageCount = pageCount;
            newState.page = page;
        } else if (
            this.props.size !== nextProps.size ||
            this.props.maxSize !== nextProps.maxSize ||
            this.props.showTextLabels !== nextProps.showTextLabels ||
            this.props.ellipses !== nextProps.ellipses ||
            this.props.rotate !== nextProps.rotate ||
            this.props.boundaryLinks !== nextProps.boundaryLinks ||
            this.props.directionLinks !== nextProps.directionLinks ||
            this.props.firstNavLabel !== nextProps.firstNavLabel ||
            this.props.lastNavLabel !== nextProps.lastNavLabel ||
            this.props.previousNavLabel !== nextProps.previousNavLabel ||
            this.props.nextNavLabel !== nextProps.nextNavLabel
        ) {
            newState = Object.assign(this.state, newState, nextProps);
        }
        this.setState(Object.assign(this.state, newState));
    }

    handleClick(e: React.MouseEvent, pageNumber?: number) {
        e.preventDefault();
        this.props.onChange(pageNumber);
    }

    isEllipsis(pageNumber: number) {
        return pageNumber === -1;
    }

    hasPrevious() {
        return this.state.page > 1;
    }

    hasNext() {
        return this.state.page < this.state.pageCount;
    }

    initPageCount(collectionSize?: number, pageSize?: number) {
        return Math.ceil((collectionSize || 0) / (pageSize || 10));
    }

    initPageValue(page: number | undefined, pageCount: number) {
        // set page within 1..max range
        return this.getValueInRange(page || 0, pageCount, 1);
    }

    applyPagination(maxSize: number, page: number) {
        page = Math.ceil(page / maxSize) - 1;
        const start = page * maxSize;
        const end = start + maxSize;
        return [start, end];
    }

    applyRotation(page: number, pageCount: number, maxSize: number) {
        let start = 0;
        let end = pageCount;
        const leftOffset = Math.floor(maxSize / 2);
        const rightOffset = maxSize % 2 === 0 ? leftOffset - 1 : leftOffset;
        if (page <= leftOffset) {
            // very beginning, no rotation -> [0..maxSize]
            end = maxSize;
        } else if (pageCount - page < leftOffset) {
            // very end, no rotation -> [len-maxSize..len]
            start = pageCount - maxSize;
        } else {
            // rotate
            start = page - leftOffset - 1;
            end = page + rightOffset;
        }
        return [start, end];
    }

    applyEllipses(start: number, end: number, pages: number[], pageCount: number) {
        if (start > 0) {
            if (start > 1) {
                pages.unshift(-1);
            }
            pages.unshift(1);
        }
        if (end < pageCount) {
            if (end < pageCount - 1) {
                pages.push(-1);
            }
            pages.push(pageCount);
        }
        return pages;
    }

    getValueInRange(value: number, max: number, min: number = 0) {
        return Math.max(Math.min(value, max), min);
    }

    isNumber(value: number) {
        return !isNaN(this.toInteger(value));
    }

    toInteger(value: number) {
        return parseInt(`${value}`, 10);
    }

    render() {
        const {
            size,
            page,
            maxSize,
            ellipses,
            rotate,
            showTextLabels,
            boundaryLinks,
            directionLinks,
            firstNavLabel,
            lastNavLabel,
            previousNavLabel,
            nextNavLabel,
        } = this.state;
        let { pageCount } = this.state;
        if (!this.isNumber(pageCount)) {
            pageCount = 0;
        }
        // fill-in model needed to render pages
        let pages = [];
        for (let i = 1; i <= pageCount; i++) {
            pages.push(i);
        }
        // apply maxSize if necessary
        if (maxSize && maxSize > 0 && pageCount > maxSize) {
            let start = 0;
            let end = pageCount;
            // either paginating or rotating page numbers
            if (rotate) {
                [start, end] = this.applyRotation(page, pageCount, maxSize);
            } else {
                [start, end] = this.applyPagination(maxSize, page);
            }
            pages = pages.slice(start, end);
            // adding ellipses
            if (ellipses) {
                pages = this.applyEllipses(start, end, pages, pageCount);
            }
        }
        const paginationItems: React.ReactElement[] = [];
        pages.forEach((pageNumber: number, index: number) => {
            const active = pageNumber === page;
            const disabled = this.isEllipsis(pageNumber);
            const paginationLink = this.isEllipsis(pageNumber) ? (
                <PaginationLink key={index}>...</PaginationLink>
            ) : (
                <PaginationLink
                    href="#"
                    onClick={(e: React.MouseEvent) => {
                        this.handleClick(e, pageNumber);
                    }}
                    key={index}
                >
                    {pageNumber}
                </PaginationLink>
            );
            paginationItems.push(
                <PaginationItem key={index} active={active} disabled={disabled}>
                    {paginationLink}
                </PaginationItem>
            );
        });
        return (
            <ReactstrapPagination size={size} role="navigation" aria-label="Pagination Navigation">
                {boundaryLinks ? (
                    <PaginationNav
                        showTextLabels={showTextLabels}
                        navLabel={firstNavLabel}
                        navType="first"
                        goToPage={1}
                        hidden={this.hasPrevious}
                        handleClick={this.handleClick}
                    />
                ) : (
                    ''
                )}
                {directionLinks ? (
                    <PaginationNav
                        showTextLabels={showTextLabels}
                        navLabel={previousNavLabel}
                        navType="previous"
                        goToPage={page - 1}
                        hidden={this.hasPrevious}
                        handleClick={this.handleClick}
                    />
                ) : (
                    ''
                )}
                {paginationItems}
                <li className="page-display">
                    {page} of {pageCount}
                </li>
                {directionLinks ? (
                    <PaginationNav
                        showTextLabels={showTextLabels}
                        navLabel={nextNavLabel}
                        navType="next"
                        goToPage={page + 1}
                        hidden={this.hasNext}
                        handleClick={this.handleClick}
                    />
                ) : (
                    ''
                )}
                {boundaryLinks ? (
                    <PaginationNav
                        showTextLabels={showTextLabels}
                        navLabel={lastNavLabel}
                        navType="last"
                        goToPage={pageCount}
                        hidden={this.hasNext}
                        handleClick={this.handleClick}
                    />
                ) : (
                    ''
                )}
            </ReactstrapPagination>
        );
    }
}
