import * as React from 'react';
import * as PropTypes from 'prop-types';
import AbstractInputButton from './AbstractInputButton';

export interface CheckboxButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
    children?: React.ReactNode | React.ReactNode[];
}

export const CheckboxButton: React.FunctionComponent<CheckboxButtonProps> = ({
    children,
    ...other
}) => {
    return (
        <AbstractInputButton {...other} type="checkbox">
            {children}
        </AbstractInputButton>
    );
};

CheckboxButton.propTypes = {
    children: PropTypes.node,
};
