import { HintList } from '../interfaces/column-hint';
import { DataType } from '../interfaces/datatype';
import { enumHelper } from '../libraries/helpers/enum-helper';
import { DashGridColumn } from './dra-datasource';

enum DateDraTypes {
    AronDate = 'com.gs.ficc.plot.tools.AronDate',
    AronDateTime = 'com.gs.ficc.plot.tools.AronDateTime',
    Date = 'java.util.Date',
}
enum NumberDraTypes {
    Double = 'java.lang.Double',
    Float = 'java.lang.Float',
    Integer = 'java.lang.Integer',
    Long = 'java.lang.Long',
    Short = 'java.lang.Short',
}
enum RDateDraTypes {
    RDate = 'com.gs.ficc.plot.tools.RDate',
}

// we can get rid of this when Boltweb stops injecting their own viewConfig
export type DraDataType = 'Number' | 'Date' | 'RDate' | 'String' | 'Unknown';

export interface AggregationDefinition {
    [index: string]: {
        column: string | null;
        stopIfColumn: string | null;
    };
}

export interface DraColumnSortBy {
    field: string;
    direction: DraSortDirection;
    sortType: DraSortType;
}
export type DraSortDirection = 'ascending' | 'descending';
export type DraSortType = 'default' | 'abs';

export interface ActionableDraColumn {
    field: string;
    vertical: boolean;
    horizontal: boolean;
}

export interface OpenViewResponse {
    'dra.view.configuration': DraConfiguration;
    'dra.tree.id': string;
    'dra.tree.output': string;
    'dra.view.version': number;
    effectiveHeaderIndices: number[];
    'dra.tree.columns': {
        rowId: string;
        rowIdx: string;
        depth: string;
        isLeaf: string;
        nodeId: string;
        tree: string;
        numRows: string;
        isExpanded: string;
        path: string;
    };
    'dra.tree.output.header': { header: { name: string; type: string }[] };
}

interface DraConfiguration {
    autoCommit: boolean;
    rootVisible: boolean;
    columnHints: {
        [name: string]: HintList;
    };
    typeHints: {
        [name: string]: HintList;
    };
    columns: DraConfigurationColumns;
    expansionDepth: number;
    displayedColumns: string[];
    pivotColumns: string[];
    horizontalPivots: string[];
    sortColumns: [string, DraSortDirection, DraSortType?][];
    isDynamicAggregationEnabled: boolean;
    isDynamicSchemaEnabled: boolean;
    mandatoryAggregationColumns: string[];
    initialAggregationColumns: string[];
    aggregationDefinitions: { [index: string]: AggregationDefinition };
    treeColumn: string;
}

interface DraConfigurationColumns {
    [name: string]: {
        pivotable: [boolean, boolean];
        sortable: [boolean, boolean];
    };
}

/**
 * Responsible for managing the client side dra view state.
 * Supports user defined column hints as well as the parsing of
 * server side dra view config to client side dra view config.
 * @param openViewResponse - dra header information
 * @constructor
 */
export class DraViewConfig {
    public displayedColumns: string[];
    public effectiveHeaderIndices: number[];
    public pivotableColumns: any;
    public sortableColumns: any;
    public draTreeId: string;
    public draTableId: string;
    public isNewAPI: boolean;
    public header: { name: string; type: string }[];
    // field definition
    public idField: string;
    public idxField: string;
    public depthField: string;
    public isLeafField: string;
    public nodeIdField: string;
    public groupKeyField: string;
    public numRowsField: string;
    public isExpandedField: string;
    public pathField: string;

    public allFields: string[];
    // used by the draProvider
    public dateColumns: boolean[];
    public autoCommit: boolean;
    public expansionDepth: number;
    public rootVisible: boolean;
    public hPivotBy: DashGridColumn[];
    public pivotBy: DashGridColumn[];
    public sortBy: DraColumnSortBy[];
    public isPivotable: ActionableDraColumn[];
    public isSortable: ActionableDraColumn[];
    public isDynamicAggregationEnabled: boolean;
    public isDynamicSchemaEnabled: boolean;
    public mandatoryAggregationColumns: string[];
    public initialAggregationColumns: string[];
    public aggregationDefinitions: { [index: string]: AggregationDefinition };
    public treeColumn: string;
    public columnHintProcessor: {
        hints: {
            [name: string]: HintList;
        };
        userDefaults: {
            [name: string]: HintList;
        };
    } = {
        hints: {},
        userDefaults: {},
    };

    constructor(openViewResponse: OpenViewResponse) {
        if (!openViewResponse['dra.view.configuration']) {
            throw Error('Not an OpenView DRA connection');
        }
        this.draTreeId = openViewResponse['dra.tree.id'];
        this.draTableId = openViewResponse['dra.tree.output'];
        this.isNewAPI = openViewResponse['dra.view.version'] === 1;
        this.header = openViewResponse['dra.tree.output.header'].header;
        this.effectiveHeaderIndices = openViewResponse.effectiveHeaderIndices;

        // Accessors
        // Field names for meta data columns
        const treeColumns = openViewResponse['dra.tree.columns'];
        this.idField = treeColumns.rowId;
        this.idxField = treeColumns.rowIdx;
        this.depthField = treeColumns.depth;
        this.isLeafField = treeColumns.isLeaf;
        this.nodeIdField = treeColumns.nodeId;
        this.groupKeyField = treeColumns.tree;
        this.numRowsField = treeColumns.numRows;
        this.isExpandedField = treeColumns.isExpanded;
        this.pathField = treeColumns.path;

        this.populateColumnHintsProccessor(openViewResponse);

        // We use these props to map the incoming array deltas over to our row objects.
        this.allFields = this.header.map(header => header.name);
        this.dateColumns = this.header.map(header => this.getType(header.name) === DataType.Date);

        // Other properties, setting the initial state here.
        const config = openViewResponse['dra.view.configuration'];
        this.autoCommit = config.autoCommit;
        this.expansionDepth = config.expansionDepth;

        this.displayedColumns = config.displayedColumns;
        this.treeColumn = config.treeColumn;

        this.rootVisible = config.rootVisible;
        this.hPivotBy = config.horizontalPivots.map(field => ({
            field,
        }));
        this.pivotBy = config.pivotColumns.map(field => ({
            field,
        }));
        this.sortBy = config.sortColumns.map(sc => {
            return {
                field: sc[0],

                direction: sc[1].toLowerCase() as DraSortDirection,
                sortType: sc[2] ? (sc[2].toLowerCase() as DraSortType) : 'default',
            };
        });

        const entries = Object.entries(config.columns);
        this.isPivotable = entries.map(pair => ({
            field: pair[0],
            horizontal: pair[1].pivotable[1],
            vertical: pair[1].pivotable[0],
        }));
        this.isSortable = entries.map(pair => ({
            field: pair[0],
            horizontal: pair[1].sortable[1],
            vertical: pair[1].sortable[0],
        }));
        this.isDynamicAggregationEnabled = config.isDynamicAggregationEnabled || false;
        this.isDynamicSchemaEnabled = config.isDynamicSchemaEnabled || false;
        this.mandatoryAggregationColumns = config.mandatoryAggregationColumns || [];
        this.initialAggregationColumns = config.initialAggregationColumns || [];
        this.aggregationDefinitions = config.aggregationDefinitions || {};
    }
    /**
     * This method will populate the column hints processor with dra column hints
     * @param openViewResponse - OpenViewResponse
     */
    public populateColumnHintsProccessor(openViewResponse: OpenViewResponse) {
        this.columnHintProcessor.hints = openViewResponse['dra.view.configuration'].columnHints;

        this.columnHintProcessor.userDefaults = Object.keys(
            openViewResponse['dra.view.configuration'].typeHints
        ).reduce(
            (acc, key) => ({
                ...acc,
                ...{
                    [key.toLowerCase() + 'Defaults']: openViewResponse['dra.view.configuration']
                        .typeHints[key],
                },
            }),
            {}
        );
    }

    /**
     * Takes the list of displayed columns and returns a list of column objects with column hints applied.
     * getColumns is normally called by getState but also called by setUserHint hint from the dra source.
     */
    public getColumns(): DashGridColumn[] {
        return this.displayedColumns.map(dc => {
            const columnType = this.getType(dc);
            const columnhint = this.columnHintProcessor.hints[dc];
            const typeHint = this.columnHintProcessor.userDefaults[
                columnType.toLowerCase() + 'Defaults'
            ];
            let groupingDelimiter: string | undefined;
            let name: string | undefined = dc;
            if (columnhint && columnhint.groupingDelimiter) {
                if (columnhint.name && columnhint.name.includes(columnhint.groupingDelimiter)) {
                    name = columnhint.name;
                }
                groupingDelimiter = columnhint.groupingDelimiter;
            } else if (typeHint && typeHint.groupingDelimiter) {
                groupingDelimiter = typeHint.groupingDelimiter;
            } else if (this.columnHintProcessor.userDefaults.groupingDelimiter) {
                groupingDelimiter = typeHint.groupingDelimiter;
            }
            return {
                groupingDelimiter,
                name,
                field: dc,
                hidden: !!(columnhint && columnhint.enabled === false),
                type: columnType,
            };
        });
    }

    /**
     * Get the type for DRA datasource
     * @param field
     */
    public getType(field: string): DraDataType {
        const definition = this.header.find(header => header.name === field);

        if (!definition) {
            return 'Unknown';
        }

        if (definition.type && enumHelper.stringToStringEnum(definition.type, NumberDraTypes)) {
            return 'Number';
        }
        if (definition.type && enumHelper.stringToStringEnum(definition.type, DateDraTypes)) {
            return 'Date';
        }
        if (definition.type && enumHelper.stringToStringEnum(definition.type, RDateDraTypes)) {
            return 'RDate';
        }
        return 'String';
    }
}
