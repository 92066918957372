import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export interface AppTitleProps {
    appName: string;
    classes?: string;
    onClick?: () => void;
    compact: boolean;
}

const AppTitle: React.FunctionComponent<AppTitleProps> = ({
    appName,
    classes,
    onClick,
    compact,
}) => (
    <div className={cx(classes, { compact })}>
        <span onClick={onClick} data-cy="header.appName">
            {appName}
        </span>
    </div>
);

AppTitle.defaultProps = {
    appName: 'Default App',
    classes: '',
    onClick: () => {},
};

AppTitle.propTypes = {
    appName: PropTypes.string,
    classes: PropTypes.string,
    onClick: PropTypes.func,
    compact: PropTypes.bool,
};

export default AppTitle;
