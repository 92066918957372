import * as React from 'react';
import * as PropTypes from 'prop-types';
import { NavItem, NavLink } from 'reactstrap';
import { resolveIconName } from '@gs-ux-uitoolkit-common/icons';

export interface NormalTabTitleProps {
    active: boolean;
    disabled?: boolean;
    onClick?: () => void;
    onSelect: (tabKey: string | number) => void;
    tabKey: string | number;
    title?: React.ReactElement;
    iconName?: string;
    iconOnly?: boolean;
    href?: string;
}

class NormalTabTitle extends React.Component<NormalTabTitleProps, {}> {
    public static propTypes: { [key in keyof NormalTabTitleProps]: any } = {
        active: PropTypes.bool.isRequired,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        onSelect: PropTypes.func.isRequired,
        tabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        title: PropTypes.any,
        iconName: PropTypes.string,
        iconOnly: PropTypes.bool,
    };

    constructor(props: NormalTabTitleProps) {
        super(props);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick(e: React.MouseEvent) {
        e.preventDefault();
        const { onSelect, tabKey, onClick } = this.props;
        if (typeof onClick === 'function') {
            onClick();
        }
        onSelect(tabKey);
    }

    render() {
        const { active, disabled, title, href, iconName, iconOnly } = this.props;
        const navLinkContent =
            iconOnly && iconName ? (
                <i className={`gi gi-fw gi-lg gi-${iconName} gsi-${resolveIconName(iconName)}`} />
            ) : (
                title
            );
        return (
            <NavItem>
                <NavLink
                    className={active ? 'active' : ''}
                    disabled={disabled}
                    href={href || '#'}
                    onClick={this._handleClick}
                    aria-selected={active}
                >
                    {navLinkContent}
                </NavLink>
            </NavItem>
        );
    }
}

export default NormalTabTitle;
