import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../shared/components/Icon';
import AppTitle from './AppTitle';
import EnvBadge from './EnvBadge';
import { HeaderBrand } from '@gs-ux-uitoolkit-common/header';

export interface BrandProps extends HeaderBrand {
    compact: boolean;
}

const Brand: React.FunctionComponent<BrandProps> = ({
    appIcon,
    appName,
    callback,
    envBadge,
    compact,
}) => {
    return (
        <div className="brand" data-cy="header.brand">
            {appIcon && (
                <Icon
                    onClick={callback}
                    iconName={appIcon}
                    classes="app-icon clickable"
                    size="gi-lg"
                />
            )}
            <AppTitle
                compact={compact}
                onClick={callback}
                appName={appName}
                classes="app-title clickable"
            />
            {envBadge && <EnvBadge name={envBadge.name} customColor={envBadge.customColor} />}
        </div>
    );
};

Brand.propTypes = {
    appIcon: PropTypes.string,
    appName: PropTypes.string.isRequired,
    callback: PropTypes.func,
    compact: PropTypes.bool,
    envBadge: PropTypes.shape({
        name: PropTypes.string.isRequired,
        customColor: PropTypes.string,
    }) as any,
};

export default Brand;
