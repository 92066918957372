import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon, Popover, SideMenu } from '../../shared/components';
import GoldmanBrand from '../GoldmanBrand';

const Tool: React.FunctionComponent<any> = ({
    iconName,
    content,
    placement,
    customIcon,
    badge,
    slideover,
    title,
}) => {
    const target = customIcon || <Icon iconName={iconName} badge={badge} classes="clickable" />;
    const sideMenuClass = cx('toolbar-sidemenu');
    let toolContent = null;
    if (!slideover) {
        toolContent = (
            <Popover
                icon={iconName}
                placement={placement}
                popoverTarget={target}
                popoverContent={content}
            />
        );
    } else {
        toolContent = (
            <SideMenu classes={sideMenuClass} title={title} slideover={slideover} target={target}>
                {content}
                <div key="side-menu-footer" className="gs-brand-container">
                    <GoldmanBrand />
                </div>
            </SideMenu>
        );
    }
    if (title === 'install') {
        toolContent = content;
    }

    return (
        <div className="utility-item" aria-haspopup="true">
            <div>{toolContent}</div>
        </div>
    );
};

Tool.defaultProps = {
    iconName: 'cog',
    content: null,
    placement: 'bottom',
    customIcon: false,
    badge: null,
    slideover: false,
    title: '',
};

Tool.propTypes = {
    iconName: PropTypes.string.isRequired,
    content: PropTypes.node,
    badge: PropTypes.number,
    placement: PropTypes.string,
    customIcon: PropTypes.node,
    slideover: PropTypes.bool,
    title: PropTypes.string,
};

export default Tool;
