import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import Menu from './menu/Menu';
import CallToActionButton from './CTAButton';
import { Search } from '../../search/Search';
import {
    HeaderNavigation,
    HeaderSearch,
    getMenuItems,
    getSelectedMenuItemKey,
} from '@gs-ux-uitoolkit-common/header';

interface NavigationProps extends Readonly<HeaderNavigation> {
    readonly compact: boolean;
    readonly search: HeaderSearch;
    readonly responsiveView: 'mobile' | 'tablet' | 'desktop';
    readonly switchToHamburger: (useHamburger: boolean) => void;
    readonly switchToHamburgerVal: boolean;
    readonly openSubmenusOnHover: boolean;
    readonly autoSelectMenuItem: boolean;
}

interface NavigationState {
    readonly tabSelected: string;
    readonly isSearchExpanded: boolean;
    readonly fullExpansion: boolean;
    readonly switchToHamburger: boolean;
}

class Navigation extends Component<NavigationProps, NavigationState> {
    constructor(props: NavigationProps) {
        super(props);

        this.state = {
            tabSelected: this.props.menuSelected || '',
            isSearchExpanded: false,
            fullExpansion: this.props.search ? this.props.search.fullExpansion : false,
            switchToHamburger: false,
        };
        this.expandSearch = this.expandSearch.bind(this);
        this.switchToHamburger = this.switchToHamburger.bind(this);
    }

    expandSearch(expandedState) {
        this.setState({ isSearchExpanded: expandedState });
    }

    // triggered from Menu.jsx
    switchToHamburger(val: boolean) {
        this.setState({ switchToHamburger: val });
        this.props.switchToHamburger && this.props.switchToHamburger(val);
    }

    render() {
        const { isSearchExpanded, fullExpansion, switchToHamburger } = this.state;
        const {
            CTAButton,
            responsiveView,
            search,
            compact,
            useHamburger,
            switchToHamburgerVal,
            openSubmenusOnHover,
            autoSelectMenuItem,
        } = this.props;
        const menuItems = getMenuItems(this.props);
        const selectedMenuItemKey = getSelectedMenuItemKey(this.props);

        const ctaBtn = !_.isEmpty(CTAButton) ? (
            <CallToActionButton {...CTAButton} compact={compact} data-cy="header.ctaButton" />
        ) : null;
        const searchNode = !_.isEmpty(search) ? (
            <Search onExpand={this.expandSearch} responsiveView={responsiveView} {...search} />
        ) : null;
        const classes = cx('navigation', responsiveView);
        const isMobile = responsiveView === 'mobile';
        const isTablet = responsiveView === 'tablet';

        return (
            <div className={classes}>
                <div
                    className="nav-items"
                    style={{
                        width:
                            (isSearchExpanded && fullExpansion) ||
                            (isSearchExpanded && (isMobile || isTablet))
                                ? '0'
                                : '100%',
                    }}
                >
                    {!useHamburger && !switchToHamburger && !switchToHamburgerVal && (
                        <Menu
                            type="header" // as opposed to the 'hamburger' menu
                            menuItems={menuItems}
                            selectedMenuItemKey={selectedMenuItemKey}
                            enableTabs={true}
                            openSubmenusOnHover={openSubmenusOnHover}
                            autoSelectMenuItem={autoSelectMenuItem}
                            switchToHamburger={this.switchToHamburger}
                            responsiveSize={responsiveView}
                            searchIsPresentInHeader={!!this.props.search}
                        />
                    )}
                </div>
                {responsiveView === 'desktop' && (
                    <div
                        className="cta-container"
                        style={{ display: isSearchExpanded ? 'none' : 'flex' }}
                    >
                        {ctaBtn}
                    </div>
                )}
                {searchNode}
            </div>
        );
    }

    static defaultProps = {
        CTAButton: {},
    };

    static propTypes = {
        tabs: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                key: PropTypes.string.isRequired,
                callback: PropTypes.func.isRequired,
                disabled: PropTypes.bool,
            })
        ),
        menu: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                key: PropTypes.string.isRequired,
                callback: PropTypes.func,
            })
        ),
        openSubmenusOnHover: PropTypes.bool.isRequired,
        autoSelectMenuItem: PropTypes.bool.isRequired,
        tabSelected: PropTypes.string,
        menuSelected: PropTypes.string,
        CTAButton: PropTypes.shape({
            buttonText: PropTypes.string,
            callback: PropTypes.func,
            iconName: PropTypes.string,
            compact: PropTypes.bool,
        }),
        search: PropTypes.shape({
            placeholder: PropTypes.string,
            callback: PropTypes.func,
            fullExpansion: PropTypes.bool,
        }),
        responsiveView: PropTypes.oneOf(['desktop', 'mobile', 'tablet']),
        compact: PropTypes.bool,
    };
}

export default Navigation;
