import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Responsive from 'react-responsive';
import { extendComponentProps } from '../shared/functions';
import { BeforeInstallPromptEvent } from '@gs-ux-uitoolkit-common/header';

// Desktop, tablet and mobile setup
const Desktop: React.FunctionComponent<any> = ({ className, children }) => (
    <Responsive minWidth={993}>
        <div className={cx('desktop', className)}>{children}</div>
    </Responsive>
);
const Tablet: React.FunctionComponent<any> = ({ className, children }) => (
    <Responsive minWidth={577} maxWidth={992}>
        <div className={cx('tablet', className)}>{children}</div>
    </Responsive>
);
const Mobile: React.FunctionComponent<any> = ({ className, children }) => (
    <Responsive maxWidth={576}>
        <div className={cx('mobile', className)}>{children}</div>
    </Responsive>
);

interface ResponsiveWrapperProps {
    component: any;
}

interface ResponsiveWrapperState {
    beforeInstallPromptEvent: BeforeInstallPromptEvent;
}

class ResponsiveWrapper extends React.Component<ResponsiveWrapperProps, ResponsiveWrapperState> {
    constructor(props) {
        super(props);

        this.state = {
            beforeInstallPromptEvent: null,
        };

        // `typeof window !== 'undefined'` check for SSR (Server-Side Rendering) environments
        // where the 'window' object does not exist
        if (typeof window !== 'undefined') {
            window.addEventListener('beforeinstallprompt', this.storeBeforeInstallPromptEvent);
            window.addEventListener('appinstalled', this.handleAppInstalledEvent);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeinstallprompt', this.storeBeforeInstallPromptEvent);
        window.removeEventListener('appinstalled', this.handleAppInstalledEvent);
    }

    private storeBeforeInstallPromptEvent = (e: BeforeInstallPromptEvent) => {
        this.setState({
            beforeInstallPromptEvent: e,
        });
    };

    private handleAppInstalledEvent = () => {
        this.setState({
            beforeInstallPromptEvent: null,
        });
    };

    private extendComponentWithProps = (size: 'mobile' | 'tablet' | 'desktop') => {
        return extendComponentProps(this.props.component, {
            responsiveView: size,
            beforeInstallPromptEvent: this.state.beforeInstallPromptEvent,
        });
    };

    public render() {
        return (
            <div>
                <Desktop>{this.extendComponentWithProps('desktop')}</Desktop>
                <Tablet>{this.extendComponentWithProps('tablet')}</Tablet>
                <Mobile>{this.extendComponentWithProps('mobile')}</Mobile>
            </div>
        );
    }

    static propTypes = {
        component: PropTypes.object,
    };
}

Desktop.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
Tablet.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
Mobile.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export { Desktop, Tablet, Mobile, ResponsiveWrapper };
