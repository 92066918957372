import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { omit } from 'lodash';
import { extractDataAttributes } from '@gs-ux-uitoolkit-react/shared';

import { Label } from './Label';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    checked?: boolean;
    children?: React.ReactNode | React.ReactNode[];
    className?: string;
    defaultChecked?: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    invalid?: boolean;
    inline?: boolean;
    name?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
    children,
    disabled,
    invalid,
    indeterminate,
    inline,
    ...other
}) => {
    const formCheckClasses = cx('form-check', { 'form-check-inline': inline, 'mr-3': inline });
    const inputClasses = cx('form-check-input', { 'is-invalid': invalid });
    const labelClasses = cx({ 'form-check-label--disabled': disabled });
    const dataAttributes = extractDataAttributes(other);
    const nonDataAttributes = omit(other, Object.keys(dataAttributes));
    return (
        <div className={formCheckClasses} {...dataAttributes}>
            <Label className={labelClasses}>
                <input
                    {...nonDataAttributes}
                    type="checkbox"
                    className={inputClasses}
                    disabled={disabled}
                    ref={input => {
                        if (input) {
                            input.indeterminate = !!indeterminate;
                        }
                    }}
                />
                {children}
            </Label>
        </div>
    );
};

Checkbox.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
};
