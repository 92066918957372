// tslint:disable:no-submodule-imports
import * as PropTypes from 'prop-types';
import * as React from 'react';

import {
    applyOnChangeHooks,
    datepickerOptions,
    DatePickerHooks,
    DatePickerProps,
    destroyFlatpickr,
    styleDatePicker,
} from '@gs-ux-uitoolkit-common/datepicker';

import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import { BaseOptions } from '@gs-ux-uitoolkit-common/datepicker/node_modules/flatpickr/dist/types/options';

export interface ReactDatePickerProps extends DatePickerProps {
    /**
     * Children passed to the DatePicker instance, used to wrap custom inputs
     */
    children?: React.ReactNode;
}

/**
 * A date picker allows the user to select a date or multiple dates using a calendar widget.
 * @visibleName DatePicker
 */
export class DatePicker extends React.Component<ReactDatePickerProps> {
    public static defaultProps = {
        options: {},
    };

    public static propTypes = {
        /**
         * Set of options to customize flatpickr instance. See https://flatpickr.js.org/options/
         */
        options: PropTypes.object,
        /**
         * Children passed to the DatePicker instance, used to wrap custom inputs
         */
        children: PropTypes.node,
        /**
         * A callback function to apply to a flatpickr instance during setup.
         */
        instanceCallback: PropTypes.func,
    };

    private datePickerContainerRef: HTMLDivElement | HTMLInputElement | null = null;
    private userOnChange: DatePickerHooks | null = null;
    private flatpickr: Instance | null = null;

    constructor(props: ReactDatePickerProps) {
        super(props);
    }

    public componentDidMount() {
        this.setup();
    }

    public componentWillUnmount() {
        this.destroy();
    }

    /**
     * Allows the user to manually pass in a new set of options to re-instantiate flatpickr with
     * @param nextProps Options to apply to new flatpickr instance
     */
    public setup = (nextProps?: DatePickerProps) => {
        const props = nextProps || this.props;
        const options = datepickerOptions(props.options);

        if (options.mode === 'range') {
            if (options.onChange) {
                this.userOnChange = options.onChange;
            }
            options.onChange = this.onChange;
        }

        const flatpickrInstance = flatpickr(this.datePickerContainerRef!, options as Partial<BaseOptions>);

        // Flatpickr does this on instantiation:
        //     Creates empty array
        //     Loops through provided nodes and creates instances of flatpickr for each
        //     Checks the length of the array
        //         If length is 1, return object at index of 0
        //         Else return array of instances
        //
        // The problem is:
        //    If flatpickr throws an error (related to not finding the node), it will not create an instance
        //    Since we only pass in one node, we will then get back an empty array (because it only checks if length===1)
        //
        // So to provide our users with a better understanding of what's wrong we must check if the instance is an empty array
        // And throw an error to alert the user that something is wrong
        // --note: Only need to check when wrap:true as users provide the input as a child :)
        if (Array.isArray(flatpickrInstance)) {
            throw new Error(
                flatpickrInstance.length
                    ? `Multiple Flatpickr instances were returned. Please only wrap one input element for each Date Picker`
                    : `No Flatpickr instances were returned. A possible cause is that the 'data-input' attribute is not set on the input.`
            );
        } else {
            this.flatpickr = flatpickrInstance;
        }

        if (props.instanceCallback) {
            props.instanceCallback(this.flatpickr);
        }

        // Helper function to style a flatpickr instance with the correct gs styling
        styleDatePicker(this.flatpickr, options);
    };

    public render() {
        const { children, options } = this.props;
        const getRenderElements = () => {
            if (options!.wrap) {
                return <div ref={this.setDatePickerElRef}>{children}</div>;
            }
            if (options!.inline) {
                return <input className="form-control hidden" ref={this.setDatePickerElRef} />;
            }
            return <input className="form-control" ref={this.setDatePickerElRef} />;
        };

        return getRenderElements();
    }

    /**
     * Sets the Datepicker element reference, which is either a div element if wrap option is applied else an input element
     * @param el HTML element to set ref with
     */
    private setDatePickerElRef = (el: HTMLDivElement | HTMLInputElement | null) => {
        this.datePickerContainerRef = el;
    };

    /**
     * Applies the userOnChange method(s) to the parameters
     * @param selectedDates Array of dates selected
     * @param dateStr A string representing the selected date
     * @param instance Current flatpickr instance to apply userOnChange to
     */
    private onChange = (selectedDates: Date[], dateStr: string, instance: Instance) => {
        applyOnChangeHooks(selectedDates, dateStr, instance, this.userOnChange);
    };

    /**
     * Destroys the flatpickr instance(s)
     */
    private destroy = () => {
        if (this.flatpickr) {
            destroyFlatpickr(this.flatpickr);
        }
    };
}
