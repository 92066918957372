/**
 * Utility to check for an HTML event either happening inside of the given `elements`, or
 * outside of the given `elements`.
 *
 * This is useful for a use case like the Header menu, where when the user mouseovers the
 * area outside of the menu, we want to set a timer to close the menu. However, if the user
 * then moves their mouse back over the menu, we want to cancel the timeout.
 *
 * Example usage:
 *
 *     this.insideOutsideListener = new InsideOutsideEventListener(element, 'mouseover', {
 *         onInside: () => console.log('mouse was moved inside the element'),
 *         onOutside: () => console.log('mouse was moved outside the element')
 *     });
 *
 *     // ...
 *
 *     this.insideOutsideListener.remove();  // clean up when done
 */
export class InsideOutsideEventListener {
    private elements: HTMLElement[];
    private eventNames: string[];
    private onInsideHandler: (evt?: Event) => void;
    private onOutsideHandler: (evt?: Event) => void;

    /**
     * @param elements The element(s) to check events for.
     * @param eventNames The event name(s) to monitor.
     * @param handlers The callback functions for the event happening either inside the element(s),
     *   or outside the element(s)
     */
    constructor(
        elements: HTMLElement | HTMLElement[],
        eventNames: string | string[],
        handlers: {
            onInside?: (evt?: Event) => void;
            onOutside?: (evt?: Event) => void;
        }
    ) {
        this.elements = [].concat(elements);
        this.eventNames = [].concat(eventNames);
        this.onInsideHandler = handlers.onInside || (() => {});
        this.onOutsideHandler = handlers.onOutside || (() => {});

        this.subscribeToEvents();
    }

    /**
     * Subscribes to the requested events at the document level in order to capture the event from
     * either inside or outside the {@link #elements}.
     */
    private subscribeToEvents() {
        this.eventNames.forEach(evtName => {
            document.addEventListener(evtName, this.handleEvent);
        });
    }

    /**
     * Handles a subscribed event by checking if the event fell within an element inside of the
     * given {@link #elements} or outside of them.
     */
    private handleEvent = (evt: Event) => {
        const elements = this.elements;
        for (let i = 0, len = elements.length; i < len; i++) {
            if (elements[i].contains(evt.target as Node)) {
                this.onInsideHandler(evt);
                return;
            }
        }
        this.onOutsideHandler(evt);
    };

    /**
     * Cleans up by removing the event listeners.
     */
    remove() {
        this.eventNames.forEach(evtName => {
            document.removeEventListener(evtName, this.handleEvent);
        });
    }
}
