import Helper from './helper';
import { AnalyticsConfig } from './interface';
import Emitter from './emitter';
import { trackComponentVersion } from '../util/toolkit-version-checker';

// Note: `typeof window` check needed for SSR (Server-side Rendering) environments where
// `window` doesn't exist
const win = typeof window === 'undefined' ? undefined : (window as any);

/**
 * A class that captures the usage of a UI Library
 */
export default class LibUsage {
    public hostApplication: any;

    public library: {
        name: string;
        component: string;
        version: string;
        platform: string;
    };

    public logToQa: boolean;
    private static emitterInstances: { [id: string]: Emitter } = {};

    public emitter: Emitter;

    /**
     * Creates an instance of LibUsage
     * @param  {String} uniqueName  The name of the library whose usage would be captured, should be unique per AnalyticsConfig
     * @param  {String} packageName     The name of the package
     * @param  {String} version     The version of the library
     * @param  {String} platform     The platform of the library - React, Angular
     * @param  {AnalyticsConfig} config      The config containing QA. PROD endpoints
     * @return {LibUsage}           An instance of LibUsage
     */
    constructor(
        uniqueName: string,
        packageName: string,
        version: string,
        platform: string,
        config: AnalyticsConfig
    ) {
        //We track the component versions to ensure all of them are the same
        trackComponentVersion(packageName, version);

        if (typeof packageName === 'undefined' || typeof version === 'undefined') {
            console.error(
                '%cLibUsage could not be initialized. A valid library and version required!',
                'color: red'
            );
            return;
        }

        this.hostApplication = {};
        this.library = {
            name: uniqueName,
            component: packageName,
            version,
            platform,
        };

        // Should the events be logged to QA? Use this only for Toolkit DEV
        this.logToQa = false;

        this.emitter = LibUsage.getEmitterInstance(uniqueName, config);

        // The hosting application has the analytics disabled and therefore this class will do nothing.
        // This is mainly used by externally hosted applications that do now have access to GS networks.
        if (win && win.GS_UX_UITOOLKIT_DISABLE_COMPONENT_ANALYTICS) {
            Helper.log('Usage Analytics is disabled.');
            this.emitter.run = false;
        }

        Helper.log(`LibUsage initiated for ${this.library.name}`);

        // Note: window !== undefined check for SSR (Server-side Rendering) environments
        if (win && win.UITOOLKIT_ANALYTICS_LOG_TO_QA) {
            this.logToQa = true;
            this.emitter.options.REALM = this.emitter.options.QA;
        }

        // Only log things if we're in a browser environment (i.e. not in a Server-side
        // rendering environment). If this happens in an SSR environment, it will throw
        // an error due to `document` or `location` not existing in the next method and an
        // SSR watch server will quit
        if (win) {
            setTimeout(() => this.logDefaults(), 4000);
        }
    }

    static getEmitterInstance(uniqueName: string, config: AnalyticsConfig) {
        if (!(uniqueName in LibUsage.emitterInstances)) {
            LibUsage.emitterInstances[uniqueName] = new Emitter(config);
        }
        return LibUsage.emitterInstances[uniqueName];
    }

    /**
     * log a feature of the library being used
     * @param e       The event at which the feature was captured (Default: null, Required: false)
     * @param feature A nice readable name of the feature (Eg: Tabs, Sorting, DynamicLoading)
     * @param details An object with the details of the feature (Optional)
     */
    log(e: any, feature: any, details: any) {
        if (!this.emitter.run) {
            return;
        }

        // Guard if GS_UX_UITOOLKIT_DISABLE_COMPONENT_ANALYTICS was defined after the libUsage instantiation
        // See constructor for context
        if (win && win.GS_UX_UITOOLKIT_DISABLE_COMPONENT_ANALYTICS) {
            Helper.log('Usage Analytics is disabled. LibUsage monitoring will stop');
            this.stop();
            return;
        }

        if (!Helper.isAlias(location.host) && !this.logToQa) {
            Helper.log('Not a prod URL. LibUsage monitoring will stop');
            this.stop();
            return;
        }

        const classification: { [k: string]: any } = {};
        classification.object = feature;
        // classification.objectType = feature;
        classification.action = 'Load';
        classification.initiator = 'System';
        classification.count = 1;

        this.hostApplication.name = this.hostApplication.name || Helper.getAppName();
        this.hostApplication.title = this.hostApplication.title || Helper.getAppTitle();
        this.hostApplication.host = this.hostApplication.host || location.host;
        this.hostApplication.port = this.hostApplication.port || location.port;
        this.hostApplication.hostname = this.hostApplication.hostname || location.hostname;

        const props: { [k: string]: any } = {};
        props.classification = classification;
        props.app = this.hostApplication;
        props[Helper.camelize(feature)] = details;

        Helper.log(`LibUsage: ${feature} registered`, props);
        this.emitter.push(e, this.library, props);
    }

    /**
     * Internal method to log the default entry in the analytics repo. This method is called automatically
     * after 5 seconds of the library being loaded
     */
    logDefaults() {
        const features = {
            moduleSystem: 'SCRIPT',
            frameworks: [],
        };

        /* globals define */
        // @ts-ignore
        const isAMD = typeof define === 'function' && define.amd;
        // @ts-ignore
        const isCJS = typeof exports !== 'undefined';
        const isUMD = isAMD && isCJS;

        features.moduleSystem = isAMD ? 'AMD' : features.moduleSystem;
        features.moduleSystem = isCJS ? 'CJS' : features.moduleSystem;
        features.moduleSystem = isUMD ? 'UMD' : features.moduleSystem;

        // TODO: Find expected frameworks availabe based on the module system
        // Check for: JQuery, Angular, React, Vue, Ember, Backbone

        this.log('Loaded', 'Environment', features);
    }

    /**
     * Stops capturing the data. Once this is stopped it can't be restarted.
     */
    stop() {
        this.emitter.run = false;
        Helper.log('LibUsage analytics stopped');
    }

    /**
     * Logs Component data
     * @param name : name of the component
     * @param features : captured properties along with their values
     */
    logComponentUsage(name: string, features: any) {
        this.log('DOM Mounted', name, features);
    }
}
