/**
 * Represent the type of data
 */
export enum DataType {
    /**
     * Unknow type. default if uninitialized
     */
    Unknown = 'Unknown',
    /**
     * String Data
     */
    String = 'String',
    /**
     * Number data : float or fixed
     */
    Number = 'Number',
    /**
     * Date data without time
     */
    Date = 'Date',
    /**
     * Date data with time
     */
    DateTime = 'DateTime',
    /**
     * Time data
     */
    Time = 'Time',
    /**
     * Boolean data
     */
    Boolean = 'Boolean',
}

/**
 * Use a value to determine what the type is
 * @param rawValue The value of which to determine the type
 */
export const determineDataType = (rawValue: any): DataType => {
    if (rawValue instanceof Date) {
        return DataType.Date;
    }
    switch (typeof rawValue) {
        case 'string':
            return DataType.String;
        case 'number':
            return DataType.Number;
        case 'boolean':
            return DataType.Boolean;
        default:
            return DataType.Unknown;
    }
};
