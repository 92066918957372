import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';
import outy from 'outy';
import { KeyHelpers } from '@gs-ux-uitoolkit-common/shared';
import Icon from '../shared/components/Icon';
import { Close } from '@gs-ux-uitoolkit-react/shared';
import { HeaderSearch } from '@gs-ux-uitoolkit-common/header';
import { resolveIconName } from '@gs-ux-uitoolkit-common/icons';

export class Search extends Component<SearchProps, any> {
    public suggestedSearchItems: any;
    public topResultsItems: any;
    public outsideClick: any;
    public searchIcon: any;
    public textInput: any;
    public searchContainer: any;
    public clearOnClose: any;
    public closeCallback: any;
    public responsiveView: any;
    public placeholder: any;
    public fullExpansion: any;
    public searchBox: any;
    public expandedSearch: any;
    public suggestedSearchElem: any;
    public topResultElem: any;

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            expandedSearch: false,
            selectedElem: -1,
            suggestedSearchElem: [],
            topResultElem: [],
        };
        this.getSearchIcon = this.getSearchIcon.bind(this);
        this.expandSearch = this.expandSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.disableSearch = this.disableSearch.bind(this);
        this.close = this.close.bind(this);
        this.handleSearchBoxKeyDown = this.handleSearchBoxKeyDown.bind(this);
        this.changeSelectedState = this.changeSelectedState.bind(this);
        this.executeCallback = this.executeCallback.bind(this);
        this.filterSuggestedSearch = this.filterSuggestedSearch.bind(this);
        this.suggestedSearchItems = [];
        this.topResultsItems = [];
    }

    debouncedCallback = _.debounce(this.props.callback, this.props.debounce);

    componentDidMount() {
        this.setOutsideClick();
        if (
            this.props.searchBox &&
            this.props.searchBox.suggestedSearch &&
            this.props.searchBox.suggestedSearch.getSearchItems
        ) {
            this.suggestedSearchItems = this.props.searchBox.suggestedSearch.getSearchItems();
        }
        if (
            this.props.searchBox &&
            this.props.searchBox.topResults &&
            this.props.searchBox.topResults.getSearchItems
        ) {
            this.topResultsItems = this.props.searchBox.topResults.getSearchItems();
        }
    }

    componentDidUpdate(lastProps, lastState) {
        if (lastState.expandedSearch !== this.state.expandedSearch) {
            setTimeout(() => this.setOutsideClick());
        }
    }

    componentWillUnmount() {
        this.outsideClick.remove();
    }

    getSearchIcon(isExpanded) {
        const rightIcon = isExpanded ? (
            <Close onClick={this.close} />
        ) : (
            <i
                ref={node => {
                    this.searchIcon = node;
                }}
                className="gi gi-fw gi-search"
            />
        );

        return (
            <div
                onClick={this.expandSearch}
                className={cx('clickable', 'input-group-append', {
                    'group-addon': !this.state.expandedSearch,
                })}
            >
                {rightIcon}
            </div>
        );
    }

    close() {
        const { clearOnClose, closeCallback } = this.props;
        if (clearOnClose) {
            this.setState({ value: '', selectedElem: -1 });
            this.textInput && this.textInput.focus();
        }
        if (closeCallback) {
            closeCallback();
        }
    }

    setOutsideClick() {
        const elements = [this.searchContainer];

        if (this.searchIcon) {
            elements.push(this.searchIcon);
        }

        if (this.outsideClick) {
            this.outsideClick.remove();
        }

        this.outsideClick = outy(elements, ['click', 'touchstart'], this.disableSearch);
    }

    expandSearch() {
        this.props.onExpand(!this.state.expandedSearch);
        this.setState({ expandedSearch: !this.state.expandedSearch });

        if (this.state.expandedSearch === false && document.activeElement !== this.textInput) {
            this.textInput.focus();
        }
    }

    disableSearch() {
        if (this.props.clearOnBlur) {
            this.props.onExpand(false);
            this.setState({ expandedSearch: false, value: '', selectedElem: -1 });
            this.textInput.blur();
        }
    }

    handleChange(event) {
        this.setState({ value: event.target.value, selectedElem: -1 });
        this.debouncedCallback(event.target.value);
        this.updateSearchBox(event.target.value);
    }

    updateSearchBox(value) {
        this.filterSuggestedSearch(value);
        this.filterTopResults(value);
        if (document.querySelector('.search-selected')) {
            document.querySelector('.search-selected').classList.remove('search-selected');
        }
    }

    filterSuggestedSearch(value) {
        if (this.suggestedSearchItems.length > 0) {
            const filteredSearchElem = this.suggestedSearchItems.filter(
                el => el.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
            );
            this.setState({ suggestedSearchElem: filteredSearchElem });
        } else if (this.props.searchBox && this.props.searchBox.suggestedSearch) {
            this.props.searchBox.suggestedSearch
                .getSearchResults(value)
                .then(result => {
                    this.setState({ suggestedSearchElem: result });
                })
                .catch(e => {
                    console.log(
                        'Some weird error ocuured. The toolkit team is not responsible for this!'
                    ); //eslint-disable-line
                    console.error(e); //eslint-disable-line
                });
        }
    }

    filterTopResults(value) {
        if (this.topResultsItems.length > 0) {
            this.setState({ topResultElem: this.topResultsItems });
        } else if (this.props.searchBox && this.props.searchBox.topResults) {
            this.props.searchBox.topResults
                .getSearchResults(value)
                .then(result => {
                    this.setState({ topResultElem: result });
                })
                .catch(e => {
                    console.log(
                        'Some weird error ocuured. The toolkit team is not responsible for this!'
                    ); //eslint-disable-line
                    console.error(e); //eslint-disable-line
                });
        }
    }

    handleSearchBoxKeyDown(e) {
        const keyCode = e.which;

        if (this.state.value.length === 0) {
            return;
        }

        switch (keyCode) {
            case KeyHelpers.keyCode.ARROW_DOWN:
                this.setState({ selectedElem: this.state.selectedElem + 1 }, () => {
                    this.changeSelectedState();
                });
                break;
            case KeyHelpers.keyCode.ARROW_UP:
                this.setState({ selectedElem: this.state.selectedElem - 1 }, () => {
                    this.changeSelectedState();
                });
                break;
            case KeyHelpers.keyCode.ENTER:
                this.setState({ selectedElem: -1 }, () => {
                    this.executeCallback();
                });
                break;
            case KeyHelpers.keyCode.BACKSPACE:
                this.setState({ selectedElem: -1 }, () => {
                    this.changeSelectedState();
                });
                break;
            case KeyHelpers.keyCode.ESCAPE:
                this.setState({ selectedElem: -1 }, () => {
                    this.disableSearch();
                });
                break;
            default:
                break;
        }
    }

    changeSelectedState() {
        const prevSelectedElem = document.querySelector('.search-selected');
        if (prevSelectedElem) {
            prevSelectedElem.classList.remove('search-selected');
        }
        const searchboxItems = document.querySelectorAll('.searchbox-container a[role="treeitem"]');
        if (this.state.selectedElem < 0) {
            this.setState({ selectedElem: -1 });
            return;
        } else if (this.state.selectedElem > searchboxItems.length - 1) {
            this.setState({ selectedElem: searchboxItems.length - 1 });
        }
        if (this.state.selectedElem < searchboxItems.length) {
            searchboxItems[this.state.selectedElem].classList.add('search-selected');
        }
    }

    executeCallback() {
        const searchSelectedEl = document.querySelector('.search-selected') as HTMLElement | null;
        if (searchSelectedEl) {
            searchSelectedEl.click();
        } else if (this.props.searchBox && this.props.searchBox.allResults) {
            this.props.searchBox.allResults.callback(this.state.value);
        } else {
            this.debouncedCallback(this.state.value);
        }
        this.disableSearch();
    }

    setSearch(value) {
        this.setState({ value });
        this.updateSearchBox(value);
    }

    render() {
        const { responsiveView, placeholder, fullExpansion, searchBox } = this.props;
        const { expandedSearch, suggestedSearchElem, topResultElem } = this.state;
        const isMobile = responsiveView === 'mobile';
        const isTablet = responsiveView === 'tablet';
        const classes = cx('search', {
            'expand-search': expandedSearch,
            'full-expansion': fullExpansion || isMobile || isTablet,
        });
        const formGroupClasses = cx({
            'form-control': !isMobile || expandedSearch,
            control: isMobile && !expandedSearch,
            expanded: expandedSearch,
        });
        const searchIconExpanded = expandedSearch ? (
            <div className={cx('input-group-append', 'expanded')}>
                <Icon iconName={'search'} />
            </div>
        ) : null;
        return (
            <div
                className={classes}
                ref={node => {
                    this.searchContainer = node;
                }}
                data-cy="header.search"
            >
                <div className="form-group">
                    <div className="input-group">
                        {searchIconExpanded}
                        <input
                            placeholder={expandedSearch ? placeholder : ''}
                            ref={input => {
                                this.textInput = input;
                            }}
                            value={this.state.value}
                            onChange={this.handleChange}
                            onFocus={this.expandSearch}
                            type="text"
                            className={formGroupClasses}
                            onKeyDown={this.handleSearchBoxKeyDown}
                        />
                        {this.getSearchIcon(expandedSearch)}
                    </div>
                </div>
                {searchBox && expandedSearch && (
                    <div className="searchbox-container" role="tree">
                        {searchBox.suggestedSearch &&
                            (searchBox.suggestedSearch.charTriggerSearch
                                ? this.state.value.length >=
                                  searchBox.suggestedSearch.charTriggerSearch
                                : this.state.value.length > 0) && (
                                <ul className="suggested-search-container">
                                    <li className="searchbox-header">Suggested search terms</li>
                                    {suggestedSearchElem &&
                                        (suggestedSearchElem.every(elem => elem === null) ? (
                                            <li className="suggested-search-list">
                                                <div className="suggested-search-term">
                                                    No search items
                                                </div>
                                            </li>
                                        ) : (
                                            suggestedSearchElem.map(el => {
                                                const searchIndex = el.name
                                                    .toLowerCase()
                                                    .indexOf(this.state.value.toLowerCase());
                                                // condition to handle async search results
                                                if (searchIndex === -1) {
                                                    return null;
                                                }
                                                const searchTerm = [
                                                    el.name.substring(0, searchIndex),
                                                    <span className="search-highlighter">
                                                        {el.name.substring(
                                                            searchIndex,
                                                            searchIndex + this.state.value.length
                                                        )}
                                                    </span>,
                                                    el.name.substring(
                                                        searchIndex + this.state.value.length
                                                    ),
                                                ];
                                                return (
                                                    <li className="suggested-search-list">
                                                        <a
                                                            href={'#'}
                                                            key={el.key}
                                                            className="suggested-search-term"
                                                            role="treeitem"
                                                            onClick={evt => {
                                                                evt.preventDefault();
                                                                el.callback();
                                                                this.disableSearch();
                                                            }}
                                                        >
                                                            {searchTerm}
                                                        </a>
                                                    </li>
                                                );
                                            })
                                        ))}
                                </ul>
                            )}
                        {searchBox.topResults &&
                            (searchBox.topResults.charTriggerSearch
                                ? this.state.value.length >= searchBox.topResults.charTriggerSearch
                                : this.state.value.length > 0) && (
                                <ul className="top-results-container">
                                    <li className="searchbox-header">Top results</li>
                                    {topResultElem.map(el => (
                                        <li className="top-results-list">
                                            <a
                                                href={'#'}
                                                key={el.id}
                                                className="top-results-term"
                                                role="treeitem"
                                                onClick={evt => {
                                                    evt.preventDefault();
                                                    el.callback();
                                                    this.disableSearch();
                                                }}
                                            >
                                                {el.img && (
                                                    <img
                                                        src={el.img}
                                                        className="top-results-img"
                                                        alt="top results img"
                                                    />
                                                )}
                                                {el.icon && (
                                                    <div className="top-results-icon">
                                                        <i
                                                            className={`gi gi-fw gi-${
                                                                el.icon
                                                            } gsi-${resolveIconName(el.icon)}`}
                                                        />
                                                    </div>
                                                )}
                                                <div className="top-results-data-container">
                                                    <div className="top-results-terms-header">
                                                        {el.header}
                                                    </div>
                                                    <div className="top-results-terms-body">
                                                        {el.body}
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        {searchBox.allResults && this.state.value.length !== 0 && (
                            <div className="all-results-container">
                                <a
                                    href={'#'}
                                    className="all-results-tab"
                                    role="treeitem"
                                    onClick={evt => {
                                        evt.preventDefault();
                                        searchBox.allResults.callback(this.state.value);
                                        this.disableSearch();
                                    }}
                                >
                                    See all results for &lsquo;<span>{this.state.value}</span>
                                    &rsquo;
                                </a>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    static defaultProps = {
        placeholder: '',
        fullExpansion: false,
        callback: () => {},
        clearOnBlur: true,
    };

    static propTypes = {
        responsiveView: PropTypes.oneOf(['desktop', 'mobile', 'tablet']),
        onExpand: PropTypes.func,
        placeholder: PropTypes.string,
        callback: PropTypes.func,
        fullExpansion: PropTypes.bool,
        searchBox: PropTypes.shape({
            suggestedSearch: PropTypes.shape({
                charTriggerSearch: PropTypes.number,
                getSearchItems: PropTypes.func,
                getSearchResults: PropTypes.func,
            }),
            topResults: PropTypes.shape({
                charTriggerSearch: PropTypes.number,
                getSearchItems: PropTypes.func,
                getSearchResults: PropTypes.func,
            }),
            allResults: PropTypes.shape({
                callback: PropTypes.func,
            }),
        }),
        clearOnBlur: PropTypes.bool,
        clearOnClose: PropTypes.bool,
    };
}

export interface SearchProps extends HeaderSearch {
    responsiveView: 'desktop' | 'mobile' | 'tablet';
    onExpand: (expanded?: boolean) => void;
    value?: string;
}

/**
 * @deprecated 10.x Use SearchProps instead. This is the legacy name.
 */
export interface SearchComponentProps extends SearchProps {}
