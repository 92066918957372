import PropTypes from 'prop-types';

export const notificationPropTypes = {
    badge: PropTypes.number,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            category: PropTypes.string,
            notifications: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    primaryInfo: PropTypes.string,
                    secondaryInfo: PropTypes.string,
                    icon: PropTypes.string,
                    time: PropTypes.string,
                })
            ),
        })
    ),
    callbacks: PropTypes.shape({
        action: PropTypes.func,
        dismiss: PropTypes.func,
        dismissCategory: PropTypes.func,
    }),
};

export const profileContactItemPropTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
    callback: PropTypes.func,
};

export const profilePropTypes = {
    identity: PropTypes.shape({
        primaryInfo: PropTypes.string,
        secondaryInfo: PropTypes.string,
        imageUrl: PropTypes.string,
        personMenuIcon: PropTypes.bool,
    }).isRequired,
    contact: PropTypes.shape({
        email: PropTypes.shape(profileContactItemPropTypes),
        phone: PropTypes.shape(profileContactItemPropTypes),
        im: PropTypes.shape(profileContactItemPropTypes),
    }),
    links: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            icon: PropTypes.string,
            text: PropTypes.string,
            callback: PropTypes.func,
        })
    ),
    action: PropTypes.shape({
        color: PropTypes.string,
        text: PropTypes.string,
        callback: PropTypes.func,
    }),
};

export const helpPropTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            icon: PropTypes.string,
            text: PropTypes.string,
            callback: PropTypes.func,
        })
    ).isRequired,
    action: PropTypes.shape({
        color: PropTypes.string,
        text: PropTypes.string,
        callback: PropTypes.func,
    }),
};

export const settingsPropTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf([
                'title',
                'toggle',
                'divider',
                'checkbox',
                'button',
                'button-group',
                'input-number',
                'radio-buttons',
                'link',
            ]),
            options: PropTypes.shape({
                id: PropTypes.string.isRequired,
                text: PropTypes.string,
                align: PropTypes.oneOf(['left', 'center', 'right']),
                shape: PropTypes.oneOf(['rounded', 'rectangular']),
                active: PropTypes.bool,
                icon: PropTypes.string,
                buttons: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string.isRequired,
                        text: PropTypes.string,
                        icon: PropTypes.string,
                    })
                ),
                size: PropTypes.oneOf(['small', 'medium', 'large']),
            }),
        })
    ),
    callback: PropTypes.func,
};

export const installPropTypes = {
    installPromptEvent: PropTypes.object,
};
