import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Input } from './Input';

export interface CustomSliderProps {
    /**
     * Minimum value for the point slider
     */
    min?: string | number;
    /**
     * Maximum value for the point slider
     */
    max?: string | number;
    /**
     * Whether the point slider is disabled or not
     */
    disabled?: boolean;
    /**
     * Callback for emitted event on change
     */
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    /**
     * Optional name for point slider
     */
    name?: string;
    /**
     * Value of the point slider. Default value is the average of the min/max for the point slider
     */
    value?: string | number;
}

/**
 * @visibleName Slider
 */
export const Slider: React.FunctionComponent<CustomSliderProps> = ({
    min = 0,
    max = 100,
    disabled = false,
    value,
    onChange,
    name,
}) => {
    return (
        <Input
            type="range"
            min={min}
            max={max}
            disabled={disabled}
            value={value}
            onChange={onChange}
            name={name}
        />
    );
};

Slider.propTypes = {
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
};
