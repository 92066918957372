import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import TabTitles from './TabTitles';
import TabPanes from './TabPanes';

// https://github.com/souporserious/react-measure/issues/82
import * as ReactMeasure from 'react-measure';
const Measure: any = ReactMeasure as any;

// Note: `typeof window` check needed for SSR (Server-side Rendering)
// environments where `window` doesn't exist
const win = typeof window === 'undefined' ? undefined : (window as any);

const logDebug = (): boolean => win && win.GS_UX_UITOOLKIT_RESPONSIVE_TABS_DEBUG;

export interface ControlledTabsProps {
    activeTabKey: string | number;
    onSelect: (tabKey: string | number) => void;
    responsive?: boolean;
    vertical?: boolean;
    iconOnly?: boolean;
    variant: 'tabs' | 'pills';
    contentUnderneath?: boolean;
    withVerticalBar?: boolean;
    children?: React.ReactNode | React.ReactNode[];
}

export interface ControlledTabsState {
    minWidth?: number;
    overflowingTabCount: number;
}

class ControlledTabs extends React.Component<ControlledTabsProps, ControlledTabsState> {
    public static propTypes: { [key in keyof ControlledTabsProps]: any } = {
        activeTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        children: PropTypes.node.isRequired,
        onSelect: PropTypes.func.isRequired,
        responsive: PropTypes.bool,
        vertical: PropTypes.bool,
        iconOnly: PropTypes.bool,
        variant: PropTypes.oneOf(['tabs', 'pills']).isRequired,
        contentUnderneath: PropTypes.bool,
        withVerticalBar: PropTypes.bool,
    };

    public static defaultProps = {
        variant: 'tabs',
        vertical: false,
        responsive: false,
        iconOnly: false,
        contentUnderneath: true,
        withVerticalBar: false,
        onSelect: () => {},
    };

    private dom?: HTMLElement;
    private reOrderedChildren?: any;
    private childTabSizeMap?: any;
    private overflowNeedsRecalc: boolean = true;

    constructor(props: ControlledTabsProps) {
        super(props);
        this.state = { overflowingTabCount: 0 }; // for props.responsive
        this.onMeasure = this.onMeasure.bind(this);
    }

    componentDidMount() {
        this.dom = ReactDOM.findDOMNode(this) as HTMLElement; // eslint-disable-line react/no-find-dom-node
        this.recalcOverflow(true);

        // Due to a lazy rendering initialization sequence when running the tabs demo locally
        // via the webpack watch, the DOM is not fully rendered at this point in time.  The sizes
        // of the tabs are smaller than what they should be compared to a normal environment.  You can
        // put a breakpoint in resetChildTabSizeMap() and look at the browser to repro.  If this
        // happens to apps for some reason, they can set this, and we can take a closer look.
        if (win && win.GS_UX_UITOOLKIT_RESPONSIVE_TABS_RECALC_DELAY) {
            // if set to 'true', default to 100ms
            const delay = isNaN(win.GS_UX_UITOOLKIT_RESPONSIVE_TABS_RECALC_TIMER)
                ? 100
                : win.GS_UX_UITOOLKIT_RESPONSIVE_TABS_RECALC_DELAY;
            setTimeout(() => this.recalcOverflow(true), delay);
        }
    }

    componentDidUpdate() {
        if (this.overflowNeedsRecalc) {
            this.recalcOverflow(true);
        } else {
            this.overflowNeedsRecalc = true;
        }
    }

    recalcOverflow(resetChildTabSizes: boolean) {
        // TODO:  optimize when to recalc.  eg. if the tab titles and sequence didn't change,
        // then we don't need to recalc and rerender

        if (!this.dom || !Array.isArray(this.reOrderedChildren) || !this.props.responsive) {
            return;
        }
        const dimension = this.props.vertical ? 'offsetHeight' : 'offsetWidth';
        const ulContainer = this.dom.querySelector('ul.nav-tabs') as HTMLUListElement;
        const containerSize = ulContainer ? ulContainer[dimension] : this.dom[dimension]; // ul may not be in DOM yet
        const validTabs = this.reOrderedChildren;
        const dropdown = this.dom.querySelector('li.nav-item.dropdown') as HTMLLIElement;

        // TODO:  need to render the overflow ellipse button to the DOM so we can get its size.
        //        Currently, we are hardcoding it to 44 pixels which may not be correct if
        //        there are styling differences.
        const overflowElementSize = dropdown ? dropdown[dimension] : 44;

        if (resetChildTabSizes) {
            this.resetChildTabSizeMap();
        }

        // set min width so at least 1 tab is displayed
        const firstTabKey = validTabs[0].props.tabKey;
        const minWidth = overflowElementSize + this.childTabSizeMap[firstTabKey];

        // calculate if and where the tabs start to overflow
        let currentTabKey: string | number;
        let currentSize: number = 0;
        let i = 0;
        if (logDebug()) {
            console.log(`------ recalcOverflow() ----------`);
            console.log(`resetChildTabSizes: ${resetChildTabSizes}`);
            console.log(`container size: ${containerSize}`);
        }
        do {
            currentTabKey = validTabs[i].props.tabKey;
            currentSize += this.childTabSizeMap[currentTabKey];
            if (logDebug()) {
                console.log(
                    `tabKey: ${currentTabKey} tabsize: ${this.childTabSizeMap[currentTabKey]} runningTally: ${currentSize}`
                );
            }
            i += 1;
        } while (i < validTabs.length && currentSize < containerSize);

        // calculate overflow tab count
        let overflowingTabCount = 0;
        if (i !== validTabs.length || currentSize > containerSize) {
            // reset currentSize and i to previous tab before overflow
            currentSize -= this.childTabSizeMap[currentTabKey];
            i -= 1;
            // take off a tab if added overflow element doesn't fit
            overflowingTabCount =
                currentSize + overflowElementSize > containerSize
                    ? validTabs.length - i + 1
                    : validTabs.length - i;
        }
        overflowingTabCount = Math.min(validTabs.length, overflowingTabCount);

        // reset marker so we don't recalc and re-render
        this.overflowNeedsRecalc = false;

        // re-render with updated overflowingTabCount
        this.setState({
            minWidth,
            overflowingTabCount,
        });
    }

    onMeasure() {
        // recalcOverflow(false) because we wish to rerender due to containersize change,
        // our child Tabs are not changing - so we can reuse cached measurements
        // and avoid rendering tabs to the DOM + re-render for overflow calc cycle
        this.recalcOverflow(false);
    }

    resetChildTabSizeMap() {
        const validTabs = this.reOrderedChildren;
        const dimension = this.props.vertical ? 'offsetHeight' : 'offsetWidth';
        if (this.dom) {
            const tabsInDom = Array.prototype.slice.call(this.dom.querySelectorAll('li.nav-item'));
            if (tabsInDom.length === 0) {
                return;
            }
            this.childTabSizeMap = {};
            validTabs.forEach((tab: any, i: number) => {
                const tabEl = tabsInDom[i];
                if (tabEl === undefined) return;
                this.childTabSizeMap[tab.props.tabKey] = tabEl[dimension];
            });
        }
    }

    render() {
        const { minWidth, overflowingTabCount } = this.state;
        const {
            activeTabKey,
            children,
            onSelect,
            responsive,
            vertical,
            variant,
            iconOnly,
            contentUnderneath,
            withVerticalBar,
        } = this.props;
        this.reOrderedChildren = children;
        return (
            <Measure shouldMeasure={!!responsive} onMeasure={this.onMeasure}>
                <div
                    className={cx('tabs-container', {
                        'responsive-tabs-container': vertical,
                        'content-underneath': contentUnderneath,
                    })}
                    style={{ minWidth }}
                >
                    {/*
                        // if overflowNeedsRecalc=true, then we need to go through 2 render cycles because 
                        // we need the tabs written to the DOM to get their offsetWidth or offsetHeight. 
                        // Examples: child Tabs have changed by either adding, removing, changing their titles, 
                        // or changing their order.  So we will render out all the tabs to the DOM in one 
                        // render cycle by setting overflowTabCount = 0, then in componentDidUpdate() we will 
                        // recalculate the overflow bits and re-render with overflowNeedsRecalc=true.
                    */}
                    <TabTitles
                        overflowingTabCount={this.overflowNeedsRecalc ? 0 : overflowingTabCount}
                        activeTabKey={activeTabKey}
                        onSelect={onSelect}
                        responsive={!!responsive}
                        vertical={!!vertical}
                        variant={variant}
                        iconOnly={!!iconOnly && !!vertical}
                        withVerticalBar={vertical && !contentUnderneath && withVerticalBar}
                    >
                        {this.reOrderedChildren}
                    </TabTitles>
                    <TabPanes activeTabKey={activeTabKey}>{this.reOrderedChildren}</TabPanes>
                </div>
            </Measure>
        );
    }
}

export default ControlledTabs;
