import React from 'react';
import PropTypes from 'prop-types';
import ItemList from './ItemList';
import CategoryHeader from './Header';

const Category: React.FunctionComponent<any> = ({
    id,
    category,
    icon,
    notifications,
    callbacks,
    closePopover,
    groupedNotifications,
}) => {
    return (
        <div>
            {groupedNotifications && (
                <CategoryHeader
                    dismissCategory={callbacks.dismissCategory}
                    category={category}
                    id={id}
                    icon={icon}
                />
            )}
            <ItemList
                notifications={notifications}
                closePopover={closePopover}
                callbacks={callbacks}
                groupedNotifications={groupedNotifications}
            />
        </div>
    );
};

Category.defaultProps = {
    id: '',
    category: '',
    notifications: [],
    callbacks: {},
    closePopover: () => {}, // This prop comes from the Popover component
};

Category.propTypes = {
    id: PropTypes.string.isRequired,
    icon: PropTypes.string,
    category: PropTypes.string,
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            primaryInfo: PropTypes.string,
            secondaryInfo: PropTypes.string,
            icon: PropTypes.string,
            time: PropTypes.string,
        })
    ),
    callbacks: PropTypes.shape({
        action: PropTypes.func,
        dismiss: PropTypes.func,
        dismissCategory: PropTypes.func,
    }),
    closePopover: PropTypes.func,
    groupedNotifications: PropTypes.bool,
};

export default Category;
