import { NormalizedHeaderMenuItem } from '../options';
import { forEachMenuItem } from './for-each-menu-item';

/**
 * Ensures that all of the 'keys' in the given `menuItems` are unique by rewriting any duplicate key
 * to append a unique identifier.
 *
 * The menu needs all unique keys especially for React where HeaderMenuItem instances are looked up
 * by `key` in its event handlers. This is to avoid the performance problem of using arrow functions
 * in the JSX by preventing extra DOM updates on each render cycle because the function references
 * have changed.
 */
export function ensureUniqueKeysInMenuItems(menuItems: NormalizedHeaderMenuItem[]) {
    const usedKeys = new Set<string>();
    let uniqueIdCounter = 0;

    forEachMenuItem(menuItems, menuItem => {
        if (usedKeys.has(menuItem.key)) {
            const oldKey = menuItem.key;
            const newKey = createUniqueKey(menuItem.key);

            menuItem.key = newKey;

            console.warn(
                `GS UI Toolkit Header: A duplicate menu item key ('${oldKey}') has been ` +
                    `found in the Header's menu items. All menu items should have unique keys. This ` +
                    `key has been renamed internally to '${newKey}'`
            );
        }

        usedKeys.add(menuItem.key);
    });
    return menuItems;

    function createUniqueKey(key: string) {
        let candidateKey = `${key}-${++uniqueIdCounter}`;

        // If the candidate key is still not unique, continue to increment the counter until it is
        while (usedKeys.has(candidateKey)) {
            candidateKey = `${key}-${++uniqueIdCounter}`;
        }
        return candidateKey;
    }
}
