import { HeaderProfile } from './profile/header-profile';
import { HeaderNotifications } from './notification/header-notifications';
import { HeaderHelp } from './help/header-help';
import { HeaderSettings } from './settings/header-settings';
import { HeaderInstall } from './install/header-install';

export interface HeaderUtilities {
    /**
     * Object describing the Profile content
     */
    profile?: HeaderProfile;

    /**
     * Object describing the Notification content
     */
    notification?: HeaderNotifications;

    /**
     * Object describing the Settings content
     */
    settings?: HeaderSettings;

    /**
     * Object describing the Help content
     */
    help?: HeaderHelp;

    install?: HeaderInstall;

    /**
     * Primary utility to be displayed in the top right of the header on tablet size. The
     * rest of the utilities are wrapped in the hamburger menu
     */
    primaryUtility?: HeaderUtilityName;

    /**
     * Secondary utility to be displayed in the top right of the header in on tablet size
     * in the absence of Search. The rest of the utilities are wrapped in the hamburger
     * menu
     */
    secondaryUtility?: HeaderUtilityName;
}

export type HeaderUtility =
    | HeaderProfile
    | HeaderNotifications
    | HeaderSettings
    | HeaderHelp
    | HeaderInstall;

export type HeaderUtilityName = 'profile' | 'notification' | 'settings' | 'help' | 'install';

export const allHeaderUtilityNames: HeaderUtilityName[] = [
    'profile',
    'notification',
    'settings',
    'help',
    'install',
];
