// tslint:disable:no-submodule-imports
import { DayElement, Instance } from 'flatpickr/dist/types/instance';
// @ts-ignore: Hook is required to be imported because api-extractor can not parse dynamic import()'s in .d.ts files.
import { BaseOptions, Hook } from 'flatpickr/dist/types/options';
// @ts-ignore BaseOptionsModified is required to be imported because api-extractor can not parse dynamic import()'s in .d.ts files
import { DatePickerHooks, DatePickerOptions, BaseOptionsModified } from '../options';

// Helper function to style a flatpickr instance with the correct gs styling
export const styleDatePicker = (
    instance: Instance,
    options: Partial<BaseOptions> | DatePickerOptions
): Instance => {
    if (instance.calendarContainer) {
        // Need to manually unset the calculated width as it is wrong.
        instance.calendarContainer.style.width = '';
        const flatpickrDays = instance.calendarContainer.querySelector(
            '.flatpickr-days'
        ) as DayElement;
        if (flatpickrDays) {
            flatpickrDays.style.width = '';
        }

        // In order to support the scoped stylesheet (main-scoped.min.css,
        // which allows the toolkit global styles to be scoped to only a
        // subsection of a page), we need to add the 'gs-uitk-scoped' css
        // class to the calendar element. This is because the calendar
        // element is attached to the document body by default, and would
        // therefore not fall under the element that a developer has added
        // the 'gs-uitk-scoped' CSS class to
        instance.calendarContainer.classList.add('gs-uitk-scoped');
    }

    if (Object.prototype.hasOwnProperty.call(options, 'altInput')) {
        instance.element.classList.toggle('flatpickr-altInput', options.altInput);
    }

    return instance;
};

export const destroyFlatpickr = (flatpickr: Instance) => {
    // covering all bases on destruction to prevent
    // 'this.flatpickr.destroy is not a function' errors
    if (typeof flatpickr.destroy === 'function') {
        flatpickr.destroy();
    }
};

export const applyOnChangeHooks = (
    selectedDates: Date[],
    dateStr: string,
    instance: Instance,
    userOnChange: DatePickerHooks | null
) => {
    instance.calendarContainer.classList.toggle('rangeSelected', selectedDates.length > 1);

    if (userOnChange) {
        if (Array.isArray(userOnChange)) {
            userOnChange.forEach(func => func(selectedDates, dateStr, instance));
        } else {
            userOnChange(selectedDates, dateStr, instance);
        }
    }
};
