// tslint:disable:no-submodule-imports
import { Instance } from 'flatpickr/dist/types/instance';
import { hasCalendar } from '../utils/plugin-helpers';

export default () => {
    return (instance: Instance) => {
        if (!hasCalendar(instance)) {
            return {};
        }

        return {
            onReady() {
                const prevMonth = instance.calendarContainer.querySelector('.flatpickr-prev-month');

                if (prevMonth) {
                    prevMonth.classList.add('gi', 'gi-arrow-left');
                }

                const nextMonth = instance.calendarContainer.querySelector('.flatpickr-next-month');
                if (nextMonth) {
                    nextMonth.classList.add('gi', 'gi-arrow-right');
                }
            },
        };
    };
};
