import * as React from 'react';
import * as PropTypes from 'prop-types';
import { PaginationItem, PaginationLink } from 'reactstrap';

export interface PaginationNavProps {
    page?: number;
    pageCount?: number;
    hidden?: () => boolean;
    handleClick?: (event: React.MouseEvent, goToPage?: number) => void;
    showTextLabels?: boolean;
    navLabel?: string;
    navType?: string;
    goToPage?: number;
}

export const PaginationNav: React.FunctionComponent<PaginationNavProps> = ({
    navType,
    goToPage,
    handleClick,
    hidden,
    showTextLabels,
    navLabel,
}) => {
    return (
        <PaginationItem
            className={`${hidden && !hidden() ? 'hide' : ''} ${showTextLabels ? 'textLabel' : ''}`}
        >
            <PaginationLink
                className={`${navType} ${showTextLabels ? 'textLabel' : ''}`}
                href="#"
                onClick={(e: React.MouseEvent) => {
                    if (handleClick) handleClick(e, goToPage);
                }}
            >
                {showTextLabels ? navLabel : ''}
            </PaginationLink>
        </PaginationItem>
    );
};

PaginationNav.propTypes = {
    page: PropTypes.number,
    pageCount: PropTypes.number,
    hidden: PropTypes.func,
    handleClick: PropTypes.func,
    showTextLabels: PropTypes.bool,
    navLabel: PropTypes.string,
    navType: PropTypes.string,
    goToPage: PropTypes.number,
};
