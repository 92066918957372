import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import cx from 'classnames';

const TAB_TYPE_NAME: 'dropdown-tab' = 'dropdown-tab';

export interface DropdownTabProps {
    tabType?: 'dropdown-tab';
    active?: boolean;
    disabled?: boolean;
    onMouseEnterAddBorder: () => void;
    onMouseExitRemoveBorder: () => void;
    onSelect: (tabKey: number | string) => void;
    onClick: () => void;
    openDropdownsOnHover?: boolean;
    tabKey?: string | number;
    title: string;
    vertical?: boolean;
    children: React.ReactNode | React.ReactNode[];
}

export interface DropdownTabState {
    dropdownOpen: boolean;
}

export function isDropdownTabProps(props: any): props is DropdownTabProps {
    return props && props.tabType === TAB_TYPE_NAME;
}

// this dropdown tab is for visible tab with submenu item(s)
// this is slightly different than the overflow dropdown tab, DropdownTabTitle,
// it's deceiptively similar in name and functionally so the components should  probably be merged (later)
// TODO: deprecate this functionality in Toolkit v10
export class DropdownTab extends React.Component<DropdownTabProps, DropdownTabState> {
    public static propTypes: { [key in keyof DropdownTabProps]: any } = {
        active: PropTypes.bool,
        children: PropTypes.node.isRequired,
        disabled: PropTypes.bool,
        onMouseExitRemoveBorder: PropTypes.func,
        onSelect: PropTypes.func,
        openDropdownsOnHover: PropTypes.bool,
        tabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string.isRequired,
        vertical: PropTypes.bool,
        onMouseEnterAddBorder: PropTypes.func,
        onClick: PropTypes.func,
    };
    public static defaultProps = {
        tabType: TAB_TYPE_NAME,
    };
    constructor(props: DropdownTabProps) {
        super(props);
        console.warn(
            'The DropdownTab component will be deprecated in an upcoming Toolkit release.'
        );
        this.toggle = this.toggle.bind(this);
        this.openDropdown = this.openDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseExit = this.handleMouseExit.bind(this);
        this.handleOnClickParent = this.handleOnClickParent.bind(this);
        this.state = {
            dropdownOpen: false,
        };
    }
    _handleClick(tabKey: string | number, onClick: () => void) {
        // handles onClick of each submenu
        const { onSelect } = this.props;
        if (typeof onClick === 'function') {
            onClick();
        }
        if (tabKey) {
            onSelect(tabKey);
        }
        this.closeDropdown();
    }
    handleOnClickParent() {
        // handles onClick of parent with submenu
        const { onClick } = this.props;
        if (typeof onClick === 'function') {
            this._handleClick('', onClick);
        }
    }
    toggle() {
        if (!this.props.disabled) {
            this.setState({ dropdownOpen: !this.state.dropdownOpen });
        }
    }
    openDropdown() {
        if (!this.props.disabled) {
            this.setState({
                dropdownOpen: true,
            });
        }
    }
    closeDropdown() {
        this.setState({
            dropdownOpen: false,
        });
    }
    handleMouseEnter() {
        if (this.props.openDropdownsOnHover) {
            this.openDropdown();
            if (this.props.vertical) {
                this.props.onMouseEnterAddBorder();
            }
        }
    }
    handleMouseExit() {
        if (this.props.openDropdownsOnHover) {
            this.closeDropdown();
            if (this.props.vertical) {
                this.props.onMouseExitRemoveBorder();
            }
        }
    }
    render() {
        const { active, children, disabled, title, vertical } = this.props;
        const dropdownTabClass = cx('nav-item dropdown-tab', {
            'vertical-dropdown-tab': vertical,
        });
        const submenuClass = cx('dropdown-tab-menu', {
            'vertical-submenu': vertical,
        });
        return (
            <Dropdown
                isOpen={this.state.dropdownOpen}
                className={dropdownTabClass}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseExit}
                toggle={this.toggle}
                tag="li"
            >
                <DropdownToggle
                    nav
                    onClick={this.handleOnClickParent} // this onclick passes from parent
                    className={cx({
                        'contains-active': active,
                        disabled,
                    })}
                >
                    {title}
                </DropdownToggle>
                <DropdownMenu className={submenuClass}>
                    {children && Array.isArray(children)
                        ? children.map(({ props }: any) => (
                              <DropdownItem
                                  className="inset-submenu"
                                  key={props.title}
                                  onClick={this._handleClick.bind(
                                      // eslint-disable-line react/jsx-no-bind
                                      this,
                                      props.tabKey,
                                      props.onClick
                                  )}
                                  disabled={props.disabled}
                              >
                                  <a href={props.href} onClick={e => e.preventDefault()}>
                                      {props.title}
                                  </a>
                              </DropdownItem>
                          ))
                        : null}
                </DropdownMenu>
            </Dropdown>
        );
    }
}
