import React from 'react';

const GoldmanBrand = () => (
    <div className="goldman-brand">
        <svg className="gs-logo" viewBox="0 0 192.8 85">
            <path
                d="M17,21h11.5v18.6c-3.8,0.7-7.3,1.1-10.3,1.1c-12.1,0-16.7-7.1-16.7-18.9C1.5,9.2,7.6,2.1,19.8,2.1
			c1.5,0,4.6,0.1,8.2,0.8v11.8h-2.6l-1.5-4.5c-1.4-4.2-3.1-6.2-5.8-6.2c-4.8,0-7.3,6.3-7.3,17.8c0,11.7,1.2,17.2,7.2,17.2
			c0.8,0,1.8-0.1,2.8-0.4V25L17,22.5V21z M28.1,71.2C28.1,78.7,23,83,13.9,83c-2.5,0-6.1-0.4-9.5-1V67.6h2.6l1.1,3.4
			c2.4,7.8,4.2,10.3,7.4,10.3c3.3,0,5.7-2.8,5.7-6.5c0-3-1.5-5.2-4.7-6.9l-5.5-3C6.8,62.5,4.5,59,4.5,54.7c0-6.5,5.3-10.4,14.1-10.4
			c2,0,4.3,0.2,8,0.7v11.8h-2.6l-1.5-4.4c-1.5-4.6-3-6.3-6-6.3c-3.2,0-5.2,1.9-5.2,4.8c0,2.5,1.5,4.5,4.4,6.2l5.6,3.1
			C25.9,62.9,28.1,66.4,28.1,71.2z M52.6,79.9l1.1,1.6v0.8h-8.4l-0.4-3.3c-0.9,2.5-3.3,4-6.4,4c-4.3,0-7.1-2.6-7.1-7.3
			c0-4.5,2.5-7.4,10.1-10.4l3.3-1.3V60c0-3.4-0.8-4.8-2.8-4.8c-1.8,0-2.8,1-4.2,4.6L36,64.3h-2.5v-9c3.2-1,6.5-1.6,9.3-1.6
			c6.3,0,9.7,3,9.7,8.4V79.9z M55.9,26.1c0,9.4-5,14.7-11.5,14.7c-6.4,0-11.5-5.3-11.5-14.7c0-9.4,5.1-14.7,11.5-14.7
			C50.9,11.4,55.9,16.7,55.9,26.1z M44.9,75.1v-9.3l-1.1,0.4c-3.2,1.4-4.5,4.1-4.5,8.4c0,3.3,0.8,5.1,2.6,5.1
			C43.8,79.8,44.9,78,44.9,75.1z M47.8,26.1c0-10.4-0.9-13.1-3.4-13.1c-2.5,0-3.4,2.7-3.4,13.1s0.9,13.1,3.4,13.1
			C46.9,39.2,47.8,36.5,47.8,26.1z M75.2,75.4l1.3,0.7C74.8,81,72.4,83,67.9,83c-7.1,0-11-5.4-11-14.6c0-9.5,4.6-14.7,12.2-14.7
			c2,0,4.8,0.3,7.1,1.3v9.3h-2.6l-1.1-3.5c-1.3-4.4-2.1-5.6-3.6-5.6c-2.3,0-4,2.9-4,13.5c0,8.6,1.3,11.7,4.7,11.7
			C71.9,80.4,73.7,79.1,75.2,75.4z M69.4,40.1h-9.9v-0.8l1.1-1.6V7l-1.1-1.7V4.5l8.8-0.7v33.8l1.1,1.6V40.1z M83.1,40.8
			c-6.2,0-9.8-5.4-9.8-15c0-9.5,3.8-14.4,9.7-14.4c2.9,0,4.6,1.2,5.3,3.5V7l-1.1-1.7V4.5L96,3.8v33.8l1.1,1.6v0.8h-7.8L88.7,36
			C87.9,39.4,86.2,40.8,83.1,40.8z M102.3,79.9l1.1,1.6v0.8h-9.9v-0.8l1.1-1.6V60c0-2.3-0.8-3.6-2.5-3.6c-2,0-3.6,1.8-3.6,4.9v18.6
			l1.1,1.6v0.8h-9.9v-0.8l1.1-1.6V49.2l-1.1-1.7v-0.8l8.8-0.7v11.2c1.1-2.4,3.5-3.6,6.5-3.6c4.6,0,7.3,2.9,7.3,8.4V79.9z M81.4,25.9
			c0,9.8,0.9,12.9,3.4,12.9c3,0,3.6-4.3,3.6-13c0-8.4-0.7-12.5-3.6-12.5C82.2,13.3,81.4,16.3,81.4,25.9z M136.9,37.6l1.1,1.6v0.8
			h-9.9v-0.8l1.1-1.6V17.4c0-1.9-0.7-3.2-2.3-3.2c-2,0-3.5,1.9-3.5,4.9v18.6l1.1,1.6v0.8h-9.9v-0.8l1.1-1.6V17.4
			c0-1.9-0.7-3.2-2.3-3.2c-2,0-3.4,1.9-3.4,4.9v18.6l1.1,1.6v0.8h-9.9v-0.8l1.1-1.6V15.2l-1.1-1.3V13l7.8-0.9h0.7l0.2,3.1
			c1-2.5,3.5-3.8,6.6-3.8c3.3,0,5.4,1.6,6.5,4.5c0.9-2.8,3.6-4.5,6.9-4.5c4.4,0,7,2.7,7,8.3V37.6z M126.4,74.1c0,4.9-3,8.9-10.6,8.9
			c-1.9,0-4.5-0.2-8.5-0.9V71.9h2.3l0.8,2.1c1.8,5.5,3.4,7.4,6.2,7.4c2.6,0,4.2-1.7,4.2-4.4c0-2.3-1.2-3.8-3.9-5.3l-4.8-2.6
			c-3.1-1.7-4.6-4.2-4.6-7.4c0-5.1,3.9-8.1,10.4-8.1c1.9,0,4.3,0.3,6.9,0.8v8h-2.3l-1.2-2.9c-1.3-3.2-2.5-4.3-4.7-4.3
			c-2.3,0-3.6,1.2-3.6,3.4c0,1.8,1,3,3.7,4.5l4.8,2.7C124.7,67.6,126.4,70.1,126.4,74.1z M162.4,37.6l1.1,1.6v0.8h-8.4l-0.3-3.3
			c-0.9,2.5-3.3,4-6.4,4c-4.3,0-7.1-2.6-7.1-7.3c0-4.5,2.5-7.4,10.1-10.4l3.4-1.4v-4c0-3.4-0.8-4.8-2.9-4.8c-1.8,0-2.8,1-4.2,4.6
			l-1.8,4.5h-2.5v-9c3.2-1,6.5-1.6,9.3-1.6c6.3,0,9.7,3,9.7,8.4V37.6z M154.7,32.9v-9.3l-1.1,0.4c-3.2,1.4-4.6,4.1-4.6,8.4
			c0,3.3,0.9,5.1,2.7,5.1C153.6,37.5,154.7,35.7,154.7,32.9z M190.8,39.3v0.8h-9.9v-0.8l1.1-1.6V17.7c0-2.3-0.8-3.6-2.5-3.6
			c-2.1,0-3.6,1.8-3.6,4.9v18.6l1.1,1.6v0.8h-9.9v-0.8l1.1-1.6V15.2l-1.1-1.3V13l7.8-0.9h0.7l0.2,3.3c0.9-2.7,3.5-4,6.6-4
			c4.6,0,7.3,2.9,7.3,8.4v17.8L190.8,39.3z"
            />
        </svg>
    </div>
);

export default GoldmanBrand;
