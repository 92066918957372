import { NormalizedHeaderMenuItem } from '../options/navigation/header-menu-item';
import { getSubmenus } from './get-submenus';

/**
 * Recursively iterates through a list of HeaderMenuItems and provides the `callback` each menu item
 * in a depth-first manner.
 *
 * If `false` is returned from the callback, the iteration is stopped.
 *
 * @return true if the iteration through the HeaderMenuItems tree completed fully, false if a
 *   callback function stopped the iteration early.
 */
export function forEachMenuItem(
    menuItems: NormalizedHeaderMenuItem[],
    callback: (
        menuItem: NormalizedHeaderMenuItem,
        parentMenuItem: NormalizedHeaderMenuItem | null
    ) => boolean | void
): boolean {
    return doForEachMenuItem(null, menuItems, callback);
}

function doForEachMenuItem(
    parentMenuItem: NormalizedHeaderMenuItem | null,
    menuItems: NormalizedHeaderMenuItem[],
    callback: (
        menuItem: NormalizedHeaderMenuItem,
        parentMenuItem: NormalizedHeaderMenuItem | null
    ) => boolean | void
) {
    for (const menuItem of menuItems) {
        const result = callback(menuItem, parentMenuItem);
        if (result === false) {
            // A callback returned `false`, stop the iteration
            return false;
        }

        const submenus = getSubmenus(menuItem);
        for (const submenu of submenus) {
            const submenuItems: NormalizedHeaderMenuItem[] = submenu.submenuItems || [];

            const result = doForEachMenuItem(menuItem, submenuItems, callback);
            if (result === false) {
                // A callback returned `false`, stop the iteration
                return false;
            }
        }
    }
    return true;
}
