import { enumHelper } from './helpers/enum-helper';
import { logger } from './logger';

export enum FeatureFlag {
    LayoutPlugin = 'LAYOUT_PLUGIN',
    DashboardBuilderSDLC = 'DASHBOARD_BUILDER_SDLC',
}

export const prodFeatureFlags: FeatureFlag[] = [FeatureFlag.LayoutPlugin];

export const preProdFeatureFlags: FeatureFlag[] = [
    FeatureFlag.LayoutPlugin,
    FeatureFlag.DashboardBuilderSDLC,
];

const GS_UX_UITOOLKIT_FEATURE_FLAGS_ON = 'GS_UX_UITOOLKIT_FEATURE_FLAGS_ON';
const GS_UX_UITOOLKIT_FEATURE_FLAGS_OFF = 'GS_UX_UITOOLKIT_FEATURE_FLAGS_OFF';

declare global {
    interface Window {
        GS_UX_UITOOLKIT_FEATURE_FLAGS_ON?: string;
        GS_UX_UITOOLKIT_FEATURE_FLAGS_OFF?: string;
    }
}

/**
 * Gives the ability to configure the feature flags of the system
 * Can be instantiated with an initial set of feature flags in the constructor
 * Look for feature flags on/off in from the global window (which is what our demos are using to set the dev feature flags)
 * Look for feature flags on/off in from the url
 */
export class FeatureFlagService<T> {
    private featureFlagListEnabled: Set<T> = new Set();

    constructor(featureFlags: T[], private enumType: any) {
        featureFlags.forEach(ff => this.featureFlagListEnabled.add(ff));
    }

    public init() {
        if (window.GS_UX_UITOOLKIT_FEATURE_FLAGS_ON) {
            const windowFeatureFlags = window.GS_UX_UITOOLKIT_FEATURE_FLAGS_ON.split(',');
            this.addFeatureFlags(windowFeatureFlags);
        }
        if (window.GS_UX_UITOOLKIT_FEATURE_FLAGS_OFF) {
            const windowFeatureFlags = window.GS_UX_UITOOLKIT_FEATURE_FLAGS_OFF.split(',');
            this.removeFeatureFlags(windowFeatureFlags);
        }

        const urlSearch = window.location.search;
        const searchParams = new URLSearchParams(urlSearch);
        const ffOn = searchParams.get(GS_UX_UITOOLKIT_FEATURE_FLAGS_ON);
        if (ffOn) {
            const urlFeatureFlags = ffOn.split(',');
            this.addFeatureFlags(urlFeatureFlags);
        }
        const ffOff = searchParams.get(GS_UX_UITOOLKIT_FEATURE_FLAGS_OFF);
        if (ffOff) {
            const urlFeatureFlags = ffOff.split(',');
            this.removeFeatureFlags(urlFeatureFlags);
        }
        logger.info('Flags enabled', this.featureFlagListEnabled);
    }

    public isFeatureFlagSet(featureFlag: T): boolean {
        return this.featureFlagListEnabled.has(featureFlag);
    }

    public setFeatureFlag(featureFlag: T): void {
        this.featureFlagListEnabled.add(featureFlag);
    }

    private addFeatureFlags(ffStringList: string[]): void {
        ffStringList.forEach(ff => {
            const featureFlag = enumHelper.stringToStringEnum<T>(ff, this.enumType);
            if (featureFlag) {
                this.featureFlagListEnabled.add(featureFlag);
            } else {
                logger.error(`Cannot find the feature flag ${ff}`);
            }
        });
    }

    private removeFeatureFlags(ffStringList: string[]): void {
        ffStringList.forEach(ff => {
            const featureFlag = enumHelper.stringToStringEnum<T>(ff, this.enumType);
            if (featureFlag) {
                this.featureFlagListEnabled.delete(featureFlag);
            } else {
                logger.error(`Cannot find the feature flag ${ff}`);
            }
        });
    }
}

export const featureFlagService = new FeatureFlagService<FeatureFlag>(
    prodFeatureFlags,
    FeatureFlag
);
