import { normalizeFeatures, WizardOnFinish, WizardVariant } from '@gs-ux-uitoolkit-common/wizard';
import { Button, LoadingIcon, LoadingOverlay } from '@gs-ux-uitoolkit-react/core';
import * as React from 'react';
import { LibUsage } from './analytics';

export interface WizardProps<T> {
    /**
     * Position of the navigation bar
     */
    variant?: WizardVariant;

    /**
     * This is an array of objects. The objects have two properties: component and title.
     */
    steps: WizardStep<any>[];

    /**
     * In addition to the “normal” step component, it is possible to define the final wizard step,
     * which signals to the user, that he or she successfully completed the wizard. In addition,
     * the user is prevented from going back to another step, once it has been entered.
     */
    completionStep?: React.ComponentType<T>;

    completionStepProps?: T;

    /**
     * Wizard always starts with the first wizard step, after initialization.
     * Sometimes this needs to be changed. If another default wizard step needs to be used,
     * you can set it, by using the defaultStepIndex input of the wizard component.
     */
    defaultStepIndex?: number;

    /**
     * Customizable finish button text
     */
    finishBtnText?: string;

    /**
     * Gives a fixed height to the wizard container
     */
    wizardContentHeight?: string;

    wizardHeightAnimation?: boolean;

    /**
     * You can bind action to onFinish, which is executed directly before the wizard transition to the completion step.
     * onFinish is a promise which will show success/failure completion step depending on the value returned
     */
    onFinish?: WizardOnFinish;

    /**
     * It displays the cancel button on each step. You can bind an action to onCancel, which is executed each time the cancel button is clicked.
     */
    onCancel?: () => void;
}

export interface WizardState {
    completionStepStatus: boolean;
    hasFinished: boolean;
    isLoading: boolean;
    selectedStepIndex: number;
    wizardContentHeight: string;
    stepsConfig: {
        canEnter: boolean;
        step: WizardStep<any>;
    }[];
}

export interface WizardStep<T> {
    component: React.ComponentType<T & WizardStepProps>;
    componentProps?: T;
    title: string;
}

export interface WizardStepProps {
    /**
     * When called, this action allows the user to enter the next step by enabling the next or finish button.
     * When a certain validation method returns true.
     */
    canEnter: (val: boolean) => void;
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    onComplete: () => void;
}

/**
 * A wizard is a UI container that seeks to prevent overwhelming the user
 *    by breaking a large number of elements into separate screens.
 */
export class Wizard<T> extends React.Component<WizardProps<T>, WizardState> {
    public static defaultProps = {
        defaultStepIndex: 0,
        finishBtnText: 'Finish',
        variant: 'horizontal',
        wizardHeightAnimation: false,
    };

    public wizardRef: React.RefObject<HTMLDivElement>;

    constructor(props: WizardProps<T>) {
        super(props);

        this.wizardRef = React.createRef();

        const stepsConfig = this.props.steps.map((step, i) => ({
            step,
            canEnter: i < (this.props.defaultStepIndex || Wizard.defaultProps.defaultStepIndex),
        }));

        this.state = {
            stepsConfig,
            completionStepStatus: true,
            hasFinished: false,
            isLoading: false,
            selectedStepIndex: this.props.defaultStepIndex || 0,
            wizardContentHeight: this.props.wizardContentHeight || 'auto',
        };

        this.goToNextStep = this.goToNextStep.bind(this);
        this.goToPreviousStep = this.goToPreviousStep.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.canEnter = this.canEnter.bind(this);
    }

    public componentDidMount() {
        const features = normalizeFeatures(this.props);
        LibUsage.logComponentUsage('wizard', features);
    }

    public setWizardContainerHeight(element: HTMLDivElement) {
        window.requestAnimationFrame(() => {
            if (element.firstElementChild) {
                const height = (element.firstElementChild as HTMLDivElement).offsetHeight; // FirstChild is always a div
                if (`${height}px` !== this.state.wizardContentHeight) {
                    this.setState({ wizardContentHeight: `${height}px` });
                }
            }
        });
    }

    public shouldComponentUpdate() {
        if (this.props.wizardHeightAnimation) {
            const element = this.wizardRef.current;
            if (element && !this.props.wizardContentHeight && !this.state.isLoading) {
                window.requestAnimationFrame(() => {
                    this.setWizardContainerHeight(element);
                });
            }
        }
        return true;
    }

    public render() {
        const CompletionStep: any = this.props.completionStep || null;
        const steps = this.props.steps;
        const Component = steps[this.state.selectedStepIndex].component;
        const componentProps = steps[this.state.selectedStepIndex].componentProps;
        const actions = {
            canEnter: this.canEnter,
            goToNextStep: this.goToNextStep,
            goToPreviousStep: this.goToPreviousStep,
            onComplete: this.onFinish,
        };
        const title = steps[this.state.selectedStepIndex].title;

        if (this.state.hasFinished && !this.props.completionStep) {
            return '';
        }

        const getStepClass = (index: number) => {
            const stepClass = ['wizard-step-indicator'];
            if (index < this.state.selectedStepIndex) {
                stepClass.push('wizard-step-validated');
            } else if (this.state.selectedStepIndex === index) {
                stepClass.push('wizard-step-active');
            }

            if (this.state.hasFinished) {
                stepClass.push('wizard-step-on-complete wizard-step-validated');
                if (!this.state.completionStepStatus) {
                    stepClass.push('danger');
                }
            }

            return stepClass.join(' ');
        };

        const wizardStepPanel = steps.map((step, index) => (
            <li
                onClick={() => this.openSelectedStep(index)}
                key={index}
                className={getStepClass(index)}
                data-cy="gs-uitk-wizard__step-number"
            >
                <span
                    className={`${this.props.variant === 'vertical' ? 'stepTitle' : ''}  ${
                        this.state.hasFinished ? 'wizard-step-title-on-complete' : ''
                    }`}
                >
                    {step.title}
                </span>
            </li>
        ));

        return (
            <div>
                <div
                    className={`wizard ${this.props.variant} ${
                        this.state.hasFinished ? 'wizard-on-complete' : ''
                    }`}
                >
                    <div
                        className={`d-sm-none py-2 px-3 mb-3 title-mobile-only ${
                            this.state.hasFinished ? 'title-mobile-only-on-complete' : ''
                        }`}
                    >
                        <div className="step-number">
                            STEP {this.state.selectedStepIndex + 1}/{steps.length}
                        </div>
                        {title}
                    </div>
                    <ul
                        className={`wizard-steps mb-3 p-0 d-none d-sm-flex ${
                            this.state.hasFinished ? 'wizard-steps-on-complete' : ''
                        } `}
                        data-cy="gs-uitk-wizard__step-panel"
                    >
                        {wizardStepPanel}
                    </ul>
                    <div className="wizard-simple-container">
                        <div
                            ref={this.wizardRef}
                            className={`wizard-content mb-3 ${
                                this.state.hasFinished ? 'wizard-content-on-complete' : ''
                            }`}
                            style={{ height: this.state.wizardContentHeight }}
                        >
                            {this.state.isLoading ? (
                                <LoadingOverlay show={this.state.isLoading}>
                                    <LoadingIcon loadingLabel={true} />
                                </LoadingOverlay>
                            ) : (
                                ''
                            )}
                            {this.state.hasFinished ? (
                                CompletionStep && (
                                    <CompletionStep {...(this.props.completionStepProps || {})} />
                                )
                            ) : (
                                <Component {...actions} {...componentProps} />
                            )}
                        </div>
                        {this.props.onCancel && !this.state.hasFinished && !this.state.isLoading && (
                            <div className="wizard-actions float-left">
                                <Button
                                    onClick={() => this.props.onCancel && this.props.onCancel()}
                                    className="mr-2"
                                    color="secondary"
                                    data-cy="wizard-gs-uitk-wizard__cancel-button"
                                >
                                    Cancel
                                </Button>
                            </div>
                        )}
                        <div
                            className={`wizard-actions float-right
                            ${this.state.hasFinished ? 'd-none' : ''}
                            ${this.state.isLoading ? 'd-none' : ''}
                            `}
                        >
                            {this.state.selectedStepIndex !== 0 ? (
                                <Button
                                    onClick={this.goToPreviousStep}
                                    className="mr-2"
                                    color="secondary"
                                    data-cy="gs-uitk-wizard__previous-button"
                                >
                                    Previous
                                </Button>
                            ) : (
                                ''
                            )}
                            {this.state.selectedStepIndex !== steps.length - 1 ? (
                                <Button
                                    onClick={this.goToNextStep}
                                    color="primary"
                                    disabled={
                                        !this.state.stepsConfig[this.state.selectedStepIndex]
                                            .canEnter
                                    }
                                    data-cy="gs-uitk-wizard__next-button"
                                >
                                    Next
                                </Button>
                            ) : (
                                <Button
                                    onClick={this.onFinish}
                                    color="primary"
                                    disabled={
                                        !this.state.stepsConfig[this.state.selectedStepIndex]
                                            .canEnter
                                    }
                                    data-cy="gs-uitk-wizard__finish-button"
                                >
                                    {this.props.finishBtnText}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private canEnter(val: boolean) {
        if (this.state.stepsConfig[this.state.selectedStepIndex].canEnter === val) {
            // nothing to do so we prevent an unneeded render
            return;
        }
        const stepsConfig = { ...this.state.stepsConfig };
        stepsConfig[this.state.selectedStepIndex].canEnter = val;
        this.setState({ stepsConfig });
    }

    private goToNextStep() {
        const index = this.state.selectedStepIndex + 1;
        this.setState({ selectedStepIndex: index });
    }

    private goToPreviousStep() {
        const index = this.state.selectedStepIndex - 1;
        this.setState({ selectedStepIndex: index });
    }

    private onFinish() {
        if (this.props.onFinish) {
            this.getCompletionStepStatus().then(status => {
                if (typeof status !== 'undefined') {
                    this.setState({ completionStepStatus: status });
                }
                this.setState({ selectedStepIndex: this.props.steps.length - 1 });
                this.setState({ hasFinished: true });
            });
        } else {
            this.setState({ selectedStepIndex: this.props.steps.length - 1 });
            this.setState({ hasFinished: true });
        }
    }

    private async getCompletionStepStatus() {
        let status: boolean | void;
        this.setState({ isLoading: true });
        if (this.props.onFinish) {
            status = await this.props.onFinish();
        }
        this.setState({ isLoading: false });
        return status;
    }

    private openSelectedStep(index: number) {
        if (index < this.state.selectedStepIndex) {
            this.setState({ selectedStepIndex: index });
        }
    }
}
