import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import { ResponsiveWrapper } from './responsive/Responsive';
import { Brand, Navigation, Utilities, GoldmanBrand } from './components';
import Menu from './components/navigation/menu/Menu';
import {
    profilePropTypes,
    helpPropTypes,
    settingsPropTypes,
    notificationPropTypes,
    installPropTypes,
} from './shared/propTypes';
import {
    normalizeFeatures,
    HeaderProps,
    NormalizedHeaderMenuItem,
    getMenuItems,
    normalizeMenuItems,
    getSelectedMenuItemKey,
    getUtilitiesForHamburgerMenu,
    BeforeInstallPromptEvent,
    HeaderUtilities,
    HeaderUtils,
} from '@gs-ux-uitoolkit-common/header';
import { LibUsage } from './analytics';
import memoizeOne from 'memoize-one';

// 'win' and 'doc' are defaulted to an empty object for SSR (Server-Side Rendering)
// environments where 'window' and 'document' do not exist
const win = typeof window !== 'undefined' ? window : ({} as any);
const doc = typeof document !== 'undefined' ? document : ({} as any);
const supportPageOffset = win.pageYOffset !== undefined;
const isCSS1Compat = doc.compatMode === 'CSS1Compat';

export interface ResponsiveHeaderProps extends HeaderProps {
    responsiveView: 'mobile' | 'tablet' | 'desktop';
    beforeInstallPromptEvent?: BeforeInstallPromptEvent;
}

export interface HeaderInternalState {
    compact: boolean;
    switchToHamburger: boolean;
}

class HeaderInternal extends Component<ResponsiveHeaderProps, HeaderInternalState> {
    private compactOnScrollListenerAttached = false;

    /**
     * The timeout which logs a warning message if the user enables the "Install" utility and a
     * `BeforeInstallPromptEvent` is never captured.
     */
    private installEventNotCapturedTimeout: number;

    constructor(props: ResponsiveHeaderProps) {
        super(props);

        this.state = {
            compact: this.headerShouldBeCompact(),
            switchToHamburger: false,
        };

        this.updateSideEffects();
    }

    componentDidMount() {
        const features = normalizeFeatures(this.props);
        LibUsage.logComponentUsage('header', features);

        if (this.props.utilities && this.props.utilities.install) {
            this.installEventNotCapturedTimeout = setTimeout(
                HeaderUtils.warnIfInstallEventNotCaptured,
                15000
            );
        }
    }

    componentDidUpdate(prevProps: ResponsiveHeaderProps) {
        this.updateSideEffects();

        if (this.props.utilities && this.props.utilities.install === true) {
            if (!prevProps.utilities || !prevProps.utilities.install) {
                this.installEventNotCapturedTimeout = setTimeout(
                    HeaderUtils.warnIfInstallEventNotCaptured,
                    15000
                );
            }
        }

        if (this.props.beforeInstallPromptEvent && !prevProps.beforeInstallPromptEvent) {
            clearTimeout(this.installEventNotCapturedTimeout);
        }
    }

    componentWillUnmount = () => {
        this.removeScrollListener();

        clearTimeout(this.installEventNotCapturedTimeout);

        document.body.classList.remove('has-compact-header');
        document.body.classList.remove('has-fixed-header');
    };

    private updateSideEffects() {
        this.updateBodyStickToTopHeaderClass();
        this.updateBodyCompactClass();
        this.updateScrollListener();

        // in the case that `compactOnScroll` is set to true *after the document has already been
        // scrolled*, we want to set the header to 'compact' mode
        this.handleScrollToCompact();
    }

    /**
     * When the header is in `stickToTop` mode, it also requires a CSS class to be added to the
     * document body.
     */
    private updateBodyStickToTopHeaderClass() {
        document.body.classList.toggle('has-fixed-header', this.props.stickToTop);
    }

    /**
     * When the header is 'compact', it also requires a CSS class to be added to the document body.
     */
    private updateBodyCompactClass() {
        document.body.classList.toggle('has-compact-header', this.headerShouldBeCompact());
    }

    /**
     * When `compactToScroll` is enabled, we want to attach a window scroll listener so that we can
     * put the header in 'compact' mode as soon as the document is scrolled (scrollY > 0)
     */
    private updateScrollListener() {
        if (this.props.compactOnScroll && !this.props.compact) {
            // 'compactOnScroll' is enabled, but not 'compact' mode (which would always force the
            // header to be "compact" regardless of scroll position)
            this.addScrollListener();
        } else {
            this.removeScrollListener();
        }
    }

    /**
     * Adds the window scroll listener when `compactOnScroll` is enabled
     */
    private addScrollListener = () => {
        if (!this.compactOnScrollListenerAttached) {
            window.addEventListener('scroll', this.handleScrollToCompact);
            this.compactOnScrollListenerAttached = true;
        }
    };

    /**
     * Removes the window scroll listener when `compactOnScroll` is disabled, or the component is
     * destroyed.
     */
    private removeScrollListener = () => {
        if (this.compactOnScrollListenerAttached) {
            window.removeEventListener('scroll', this.handleScrollToCompact);
            this.compactOnScrollListenerAttached = false;
        }
    };

    /**
     * Handles the document scrolling when `compactOnScroll` is enabled, in order to check if we
     * should compact the header (scrollY > 0) or leave it as full size (scrollY === 0)
     */
    private handleScrollToCompact = () => {
        const headerShouldBeCompact = this.headerShouldBeCompact();

        if (this.state.compact !== headerShouldBeCompact) {
            this.setState({ compact: headerShouldBeCompact });
        }
    };

    public headerShouldBeCompact(): boolean {
        return !!this.props.compact || (!!this.props.compactOnScroll && this.getPageYScroll() > 0);
    }

    private getPageYScroll(): number {
        if (supportPageOffset) {
            return window.pageYOffset;
        } else {
            return isCSS1Compat ? document.documentElement!.scrollTop : document.body.scrollTop;
        }
    }

    // triggered from Navigation.jsx
    switchToHamburger = val => {
        this.setState({ switchToHamburger: val });
    };

    render() {
        const {
            brand = { appName: 'Default App' },
            responsiveView,
            search,
            stickToTop,
            navigation = {},
            width,
        } = this.props;
        const { compact, switchToHamburger } = this.state;

        // defaultProps only works on top-level properties, so set a default value of true if it is not set
        const openSubmenusOnHover =
            navigation.openSubmenusOnHover === undefined ? true : navigation.openSubmenusOnHover;
        const autoSelectMenuItem =
            navigation.autoSelectMenuItem === undefined ? true : navigation.autoSelectMenuItem;

        const utilities = getFinalUtilities(
            this.props.utilities,
            this.props.beforeInstallPromptEvent
        );

        const utilitiesToMoveToHamburgerMenu = getUtilitiesForHamburgerMenu(
            utilities,
            responsiveView,
            !!search
        );

        return (
            <div data-cy="header">
                <div
                    className={cx({
                        'uitoolkit-header': true,
                        'stick-to-top': stickToTop,
                        compact: compact,
                        'mobile-header': responsiveView === 'mobile',
                    })}
                    data-cy="header.contentWrapper"
                >
                    <div
                        className={cx({
                            'header-content': true,
                            compact: compact,
                            'default-max-width': width && width.setMaxWidth && !width.maxWidth,
                        })}
                        style={width && width.maxWidth && { maxWidth: width.maxWidth }}
                    >
                        {/* App Menu */}
                        {navigation.appMenu && navigation.appMenu.enable && (
                            <Menu
                                type="appMenu"
                                appMenuIcon={navigation.appMenu.icon}
                                CTAButton={navigation.CTAButton}
                                openSubmenusOnHover={openSubmenusOnHover}
                                autoSelectMenuItem={autoSelectMenuItem}
                                menuItems={this.getNormalizedAppMenuItems()}
                                selectedMenuItemKey={undefined} // TODO: Is there a key for the selected 'app'?
                                responsiveSize={responsiveView}
                                switchToHamburger={this.switchToHamburger}
                                searchIsPresentInHeader={!!this.props.search}
                            />
                        )}

                        {/* Hamburger Menu (when Header menu is not being used) */}
                        {(navigation.useHamburger ||
                            (switchToHamburger &&
                                (this.getNormalizedMenuItems().length > 0 ||
                                    utilitiesToMoveToHamburgerMenu.length > 0))) && (
                            <Menu
                                type="hamburger"
                                CTAButton={(navigation || {}).CTAButton}
                                openSubmenusOnHover={openSubmenusOnHover}
                                autoSelectMenuItem={autoSelectMenuItem}
                                menuItems={this.getNormalizedMenuItems()}
                                selectedMenuItemKey={getSelectedMenuItemKey(navigation || {})}
                                utilities={utilities}
                                enableTabs={false}
                                switchToHamburger={this.switchToHamburger}
                                responsiveSize={responsiveView}
                                searchIsPresentInHeader={!!search}
                            />
                        )}

                        <Brand {...brand} compact={compact} />

                        {/* Header Menu (when Hamburger menu is not being used) */}
                        {(navigation.useHamburger || switchToHamburger) && (
                            <Navigation
                                {...navigation}
                                compact={compact}
                                search={search}
                                openSubmenusOnHover={openSubmenusOnHover}
                                autoSelectMenuItem={autoSelectMenuItem}
                                responsiveView={responsiveView}
                                switchToHamburger={this.switchToHamburger}
                                switchToHamburgerVal={false} // TODO: Is this correct?
                            />
                        )}

                        {/* When just using Search without a Menu (seemingly) */}
                        {!navigation.useHamburger && !switchToHamburger ? (
                            <Navigation
                                {...navigation}
                                compact={compact}
                                search={search}
                                openSubmenusOnHover={openSubmenusOnHover}
                                autoSelectMenuItem={autoSelectMenuItem}
                                responsiveView={responsiveView}
                                switchToHamburger={this.switchToHamburger}
                                switchToHamburgerVal={switchToHamburger}
                            />
                        ) : null}

                        <Utilities {...utilities} search={search} responsiveSize={responsiveView} />

                        {responsiveView !== 'mobile' || _.isEmpty(utilities) ? (
                            <GoldmanBrand />
                        ) : null}
                    </div>
                </div>

                {stickToTop && (
                    <div
                        className={
                            compact ? 'account-for-compact-header' : 'account-for-default-header'
                        }
                    />
                )}
            </div>
        );

        function getFinalUtilities(
            utilities: HeaderUtilities,
            beforeInstallPromptEvent: BeforeInstallPromptEvent
        ): HeaderUtilities {
            let newUtilities = utilities;
            if (utilities.install && beforeInstallPromptEvent) {
                newUtilities = {
                    ...utilities,
                    install: {
                        installPromptEvent: beforeInstallPromptEvent,
                    },
                };
            } else {
                // Filters install out of the utilities prop
                const { install, ...filteredUtilities } = utilities;
                newUtilities = filteredUtilities;
            }

            return newUtilities;
        }
    }

    /**
     * Helper method which always returns the *normalized* HeaderMenuItem instances.
     *
     * This method makes sure to normalize all deprecated/legacy properties in these objects, and
     * returns the result. It also checks that the menu has all unique keys, or otherwise rewrites
     * them to become unique while providing a console warning to the developer.
     *
     * This method may be called multiple times performantly, where the normalization only happens
     * again if the input 'menu' array changes.
     */
    private getNormalizedMenuItems(): NormalizedHeaderMenuItem[] {
        // TODO: this method should not be needed as soon as we fix the "utilities" in the menu
        const menuItems = getMenuItems(this.props.navigation || {});

        return this.normalizeMenuItems(menuItems);
    }

    /**
     * Memoized version of {@link #normalizeMenuItems} for this Header instance.
     */
    private normalizeMenuItems = memoizeOne(normalizeMenuItems);

    /**
     * Helper method which always returns the *normalized* HeaderMenuItem instances for the app
     * menu. See {@link #getNormalizedMenuItems} for more details.
     */
    private getNormalizedAppMenuItems(): NormalizedHeaderMenuItem[] {
        const navigation = this.props.navigation || {};
        const appMenu = navigation.appMenu || {};
        const appMenuItems = appMenu.applications || [];

        return this.normalizeAppMenuItems(appMenuItems);
    }

    /**
     * Memoized version of {@link #normalizeAppMenuItems} for this Header instance.
     */
    private normalizeAppMenuItems = memoizeOne(normalizeMenuItems);

    static propTypes = {
        brand: PropTypes.shape({
            appIcon: PropTypes.string,
            appName: PropTypes.string,
            callback: PropTypes.func,
            envBadge: PropTypes.shape({
                name: PropTypes.string,
                customColor: PropTypes.string,
            }),
        }),
        navigation: PropTypes.shape({
            appMenu: PropTypes.shape({
                enable: PropTypes.bool,
                icon: PropTypes.string,
                applications: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        key: PropTypes.string.isRequired,
                        callback: PropTypes.func,
                    })
                ),
            }),
            openSubmenusOnHover: PropTypes.bool,
            autoSelectMenuItem: PropTypes.bool,
            menuItems: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    type: PropTypes.oneOf(['link', 'divider']),
                    key: PropTypes.string.isRequired,
                    callback: PropTypes.func,
                })
            ),
            menu: PropTypes.arrayOf(
                // deprecated 10.x - use menuItems instead
                PropTypes.shape({
                    name: PropTypes.string,
                    type: PropTypes.oneOf(['link', 'divider']),
                    key: PropTypes.string.isRequired,
                    callback: PropTypes.func,
                })
            ),
            tabs: PropTypes.arrayOf(
                // deprecated 10.x - use menuItems instead
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    key: PropTypes.string.isRequired,
                    callback: PropTypes.func.isRequired,
                    disabled: PropTypes.bool,
                })
            ),
            selectedMenuItem: PropTypes.string,
            menuSelected: PropTypes.string, // deprecated 10.x - use selectedMenuItem instead
            tabSelected: PropTypes.string, // deprecated 10.x - use selectedMenuItem instead
            CTAButton: PropTypes.shape({
                buttonText: PropTypes.string,
                callback: PropTypes.func,
                iconName: PropTypes.string,
            }),
        }),
        search: PropTypes.shape({
            placeholder: PropTypes.string,
            callback: PropTypes.func,
            fullExpansion: PropTypes.bool,
        }),
        utilities: PropTypes.shape({
            profile: PropTypes.shape(profilePropTypes),
            notification: PropTypes.shape(notificationPropTypes),
            install: PropTypes.shape(installPropTypes),
            help: PropTypes.shape(helpPropTypes),
            settings: PropTypes.shape(settingsPropTypes),
        }),
        responsiveView: PropTypes.oneOf(['desktop', 'mobile', 'tablet']),
        compact: PropTypes.bool,
        compactOnScroll: PropTypes.bool,
        stickToTop: PropTypes.bool,
        width: PropTypes.shape({
            setMaxWidth: PropTypes.bool,
            maxWidth: PropTypes.string,
        }),
    };

    static defaultProps = {
        utilities: {},
    };
}

/**
 * In order to promote consistency, the header is **_recommended_** for all GS web
 * applications built using Toolkit. The header is responsive and adjusts itself to any
 * form factor.
 *
 * The header should be appear on each page of your app.
 *
 * For a detailed explanation regarding all the Header options please visit
 * [this GS Topics post](https://topics.gs.com/posts/141964).
 *
 * <img src="./header-overview.png" title="Header Overview" alt="Header Overview" style="width: 100%; border: 1px solid #bfbfbf; padding: 2px;" />
 * <br><br>
 */
const Header: React.FunctionComponent<HeaderProps> = (props: ResponsiveHeaderProps) => (
    <ResponsiveWrapper component={<HeaderInternal {...props} />} />
);

export { HeaderInternal, Header };
