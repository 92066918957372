import * as React from 'react';
import * as PropTypes from 'prop-types';

export interface ExpanderMainProps {
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
}

export const ExpanderMain: React.FunctionComponent<ExpanderMainProps> = ({
    children,
    className,
}) => {
    const expanderClassNames = ['expander-main', className].join(' ');
    return <div className={expanderClassNames}>{children}</div>;
};

ExpanderMain.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
