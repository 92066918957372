import * as _ from 'lodash';

/**
 * Helper for anything Array related
 */
export class ArrayHelper {
    public moveItem<T>(arr: T[], item: T, newIndex: number, itemMember?: string): T[] {
        const oldIndex: number = itemMember
            ? arr.findIndex(x => _.result(x, itemMember) === _.result(item, itemMember))
            : arr.indexOf(item);

        if (!arr.length) {
            throw new Error('array is empty');
        } else if (oldIndex === -1) {
            throw new Error('item not in array');
        } else if (newIndex >= arr.length) {
            throw new Error('new index outside array');
        }

        const newArr: T[] = arr.filter(
            (itemInArray: T, index: number) => index !== oldIndex && itemInArray
        );
        newArr.splice(newIndex, 0, item);

        return newArr;
    }

    public sort<T extends ArrayIndex>(values: T[], descending: boolean, sortMember?: string) {
        const direction = descending ? -1 : 1;
        if (sortMember) {
            return [...values].sort((a, b) => {
                const aValue = _.result<any>(a, sortMember);
                const bValue = _.result<any>(b, sortMember);

                if (aValue === bValue) {
                    return 0;
                }
                if (aValue == null) {
                    return 1;
                }
                if (bValue == null) {
                    return -1;
                }
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return aValue.localeCompare(bValue) * direction;
                }
                return aValue < bValue ? -1 * direction : aValue > bValue ? 1 * direction : 0;
            });
        }
        return [...values].sort((a, b) => (a < b ? -1 * direction : a > b ? 1 * direction : 0));
    }

    public last<T>(arr: T[]): T | null {
        return this.getItemAt(arr, arr.length - 1);
    }

    public first<T>(arr: T[]): T | null {
        return this.getItemAt(arr, 0);
    }

    public areIdentical<T>(arr1: T[], arr2: T[]): boolean {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
    }

    public flatten<T>(array: (T | T[])[], result: T[] = []) {
        for (let i = 0, length = array.length; i < length; i = i + 1) {
            const value = array[i];
            if (Array.isArray(value)) {
                this.flatten(value, result);
            } else {
                result.push(value);
            }
        }
        return result;
    }

    private getItemAt<T>(arr: T[], position: number): T | null {
        if (!arr) {
            throw new Error('array is undefined');
        }

        return arr.length === 0 ? null : arr[position];
    }
}

export interface ArrayIndex {
    [index: string]: any;
}

export const arrayHelper = new ArrayHelper();
